import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError, finalize } from 'rxjs/operators'

import { Router } from '@angular/router'
import { AppService, LoaderService, ToastService } from '@uefa-shared/frontend'
import { BadRequest, ForbiddenUtils, NotFound } from '@uefa-svr/contracts'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private appService: AppService
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.show()

    req = req.clone({ withCredentials: true })

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err?.status === 401 && err?.error?.redirectUrl) {
          const currentUrl = `${environment.baseUrl}${this.appService.nextRoute}`
          window.location.href = `${environment.apibaseUrl}/auth/signin?currentUrl=${encodeURIComponent(currentUrl)}`
        }
        if (err?.status === 403) {
          if (ForbiddenUtils.getForRedirect().includes(err.error?.message)) {
            this.router.navigate(['/forbidden'])
          } else {
            this.toastService.error('shared.message.forbiddenRequest')
          }
        }
        if (err?.status === 400) {
          let toastMessage = 'shared.message.badRequest'

          if (err.error) {
            let message = err.error.message
            const hasMultipleMessages = Array.isArray(message)

            if (hasMultipleMessages) {
              const messages = err.error.message.join(', ')
              const exception = Object.values(BadRequest).find((br) => messages.includes(br))
              if (exception) {
                message = exception.split('.').pop()
              }
            }

            if (Object.values(BadRequest).includes(message)) {
              toastMessage = `shared.businessException.${message}`
            }
          }
          this.toastService.error(toastMessage)
        }
        if (err?.status === 404) {
          let toastMessage = 'shared.message.notFoundException.default'
          if (Object.values(NotFound).includes(err.error?.message)) {
            toastMessage = `shared.notFoundException.${err.error.message}`
          }
          this.toastService.error(toastMessage)
        }
        if (err?.status === 500) {
          this.toastService.error('shared.message.internalServerError')
        }
        return throwError(err)
      }),
      finalize(() => this.loaderService.hide())
    )
  }
}
