import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator'
import { LayerTypeSymbologyLineType, PreviewStyles } from './layer-type-symbology-line.dto'

export enum LayerTypeSymbologyPolygonHatch {
  NONE = 'None',
  SLASH = 'slash',
  DOT = 'dot',
}

export class LayerTypeSymbologyPolygonDTO {
  id?: string
  strokeColor: string
  strokeType: LayerTypeSymbologyLineType
  strokeWidth: number
  strokeOpacity: number
  fillColor: string
  fillOpacity: number
  hatch: LayerTypeSymbologyPolygonHatch
  hatchColor: string
  hatchSize: number
}

export class LayerTypeSymbologyPolygonUpdateDTO {
  @IsString()
  @IsNotEmpty()
  strokeColor: string

  @IsEnum(LayerTypeSymbologyLineType)
  @IsNotEmpty()
  strokeType: LayerTypeSymbologyLineType

  @IsNumber()
  @IsNotEmpty()
  strokeWidth: number

  @IsNumber()
  @IsNotEmpty()
  strokeOpacity: number

  @IsString()
  @IsNotEmpty()
  fillColor: string

  @IsNumber()
  @IsNotEmpty()
  fillOpacity: number

  @IsEnum(LayerTypeSymbologyPolygonHatch)
  @IsNotEmpty()
  hatch: LayerTypeSymbologyPolygonHatch

  @IsString()
  @IsOptional()
  hatchColor: string

  @IsNumber()
  @IsNotEmpty()
  hatchSize: number

  constructor(polygon?: LayerTypeSymbologyPolygonDTO) {
    this.strokeColor = polygon?.strokeColor ?? '#000000'
    this.strokeType = polygon?.strokeType ?? LayerTypeSymbologyLineType.SIMPLE
    this.strokeWidth = polygon?.strokeWidth ?? 2
    this.strokeOpacity = polygon?.strokeOpacity ?? 50
    this.fillColor = polygon?.fillColor ?? '#000000'
    this.fillOpacity = polygon?.fillOpacity ?? 100
    this.hatch = polygon?.hatch ?? LayerTypeSymbologyPolygonHatch.NONE
    this.hatchColor = polygon?.hatchColor ?? ''
    this.hatchSize = polygon?.hatchSize ?? 0
  }
}

export class PolygonStyles {
  extraBorder: PreviewStyles
  polygon: PreviewStyles
  hatch: PreviewStyles
}
export class LayerTypeSymbologyPolygonUtils {
  public static getPolygonPreview(model: LayerTypeSymbologyPolygonUpdateDTO): PolygonStyles {
    const extraBorder = LayerTypeSymbologyPolygonUtils.polygonExtraBorderPreview(model)
    const polygon = LayerTypeSymbologyPolygonUtils.polygonPreview(model)
    const hatch = LayerTypeSymbologyPolygonUtils.polygonHatch(model)

    return {
      polygon,
      extraBorder,
      hatch,
    }
  }

  private static polygonPreview(polygon: LayerTypeSymbologyPolygonUpdateDTO): PreviewStyles {
    if (!polygon) {
      return
    }

    return {
      'stroke-width': polygon.strokeWidth,
      fill: polygon.fillColor,
      'fill-opacity': polygon.fillOpacity / 100,
      'stroke-opacity': polygon.strokeOpacity / 100,
      stroke: polygon.strokeColor,
    } as PreviewStyles
  }

  private static polygonExtraBorderPreview(polygon: LayerTypeSymbologyPolygonUpdateDTO): PreviewStyles {
    if (!polygon) {
      return
    }

    return {
      'stroke-width': polygon.strokeWidth,
      fill: 'none',
      'fill-opacity': 0,
      'stroke-opacity': polygon.strokeOpacity / 100,
      stroke: polygon.strokeColor,
    }
  }

  private static polygonHatch(polygon: LayerTypeSymbologyPolygonUpdateDTO): PreviewStyles {
    if (!polygon) {
      return
    }

    if (polygon.hatch === LayerTypeSymbologyPolygonHatch.NONE) {
      return
    }

    const hatch = polygon?.hatch
    const hatchColor = polygon.hatchColor

    let repeatingBackground = `<circle cx='3' cy='3' r='1.5' fill='${hatchColor}'/>`

    if (hatch === LayerTypeSymbologyPolygonHatch.SLASH) {
      repeatingBackground = `<path d='M-1,1 l2,-2 M0,6 l6,-6 M5,7 l2,-2' stroke='${hatchColor}' stroke-width='1.5'/>`
    }

    const polygonBackground = `
      <svg xmlns='http://www.w3.org/2000/svg' width='6' height='6'>
        <rect width='6' height='6' fill='white' />
        ${repeatingBackground}
      </svg>`

    const base64Background = btoa(polygonBackground)

    return { 'background-image': `url("data:image/svg+xml;base64,${base64Background}")` }
  }
}
