import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core'
import { PaginatedResults } from '@uefa-shared/contracts'
import { DialogService, MediaField, ToastService } from '@uefa-shared/frontend'
import { MediaGalleryItem, MediaRotationDegrees, MediaType, MediaUtils, mimeTypes } from '@uefa-svr/contracts'
import { Observable, Subscription, of } from 'rxjs'
import { v4 as uuidv4 } from 'uuid'
import { environment } from '../../../../environments/environment'
import { MediaQueueService, MonitorService } from '../../services'
import { MediaGalleryData, SVRMediaGalleryComponent } from '../media-gallery/media-gallery.component'

export interface MediaListOptions {
  withMediaGallery?: boolean
  withDetails?: boolean
  addButton?: boolean
  deleteButton?: boolean
  editButton?: boolean
  customInputElement?: boolean
  assetsUrl?: string
}

const defaultOptions: MediaListOptions = {
  withMediaGallery: true,
  withDetails: true,
  addButton: true,
  deleteButton: true,
  editButton: true,
  customInputElement: false,
  assetsUrl: environment.assetsUrl + '/images/',
}

@Component({
  selector: 'svr-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss'],
})
export class MediaListComponent implements OnDestroy {
  @ViewChild('mediaInput', { static: true }) mediaInput: ElementRef

  @Input() medias: MediaField[] = []
  @Input() disabled = false
  @Input() allowedMimeTypes: string[] = [...mimeTypes.IMAGES, ...mimeTypes.DOCUMENTS]
  @Input() onChangeMediaName: (media: MediaGalleryItem) => void

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('options') set changeOptions(newOptions: MediaListOptions) {
    this.listOptions = { ...defaultOptions, ...newOptions }
  }

  @Output() dirtyChange = new EventEmitter<void>()
  @Output() focusChange = new EventEmitter<void>()
  @Output() blurChange = new EventEmitter<void>()
  @Output() mediaClick = new EventEmitter<MediaField>()
  @Output() mediasChange = new EventEmitter<MediaField[]>()
  @Output() addMediaClick = new EventEmitter<void>()

  public listOptions: MediaListOptions = defaultOptions
  private mediaQueueSubscription: Subscription

  private onChangeFocusHandler: (event: MouseEvent) => void

  constructor(
    private readonly dialogService: DialogService,
    private readonly mediaQueueService: MediaQueueService,
    private readonly monitorService: MonitorService,
    private readonly toastService: ToastService
  ) {
    this.mediaQueueSubscription = this.mediaQueueService.uploadFinishedSubject.subscribe((value) => {
      this.onUploadFinished(value.id, value.success)
    })
  }

  private onUploadFinished(id: string, success: boolean) {
    MediaField.updateMediaAfterUpload(id, success, this.medias)
  }

  public ngOnDestroy(): void {
    this.mediaQueueSubscription.unsubscribe()
  }

  public isDocument(media: MediaField) {
    return mimeTypes.DOCUMENTS.includes(media.mimeType)
  }

  public getThumbnail(media: MediaField) {
    const assetsUrl = this.listOptions.assetsUrl
    if (!media.uploadSuccess) {
      return MediaUtils.uploadFailedImage(assetsUrl)
    }
    if (this.isDocument(media)) {
      return MediaUtils.documentImage(media.mimeType, assetsUrl)
    }
    if (!media.isNewImage && media.thumbnailUrl) {
      return new URL(media.thumbnailUrl).toString()
    }
    return media.url ? new URL(media.url).toString() : ''
  }

  public isUploading(id: string) {
    return this.mediaQueueService.isMediaUploading(id)
  }

  public onFocus() {
    this.focusChange.emit()
  }

  public onBlur() {
    this.blurChange.emit()
    this.removeFocusEventListener()
  }

  public async onAddMediaClick(event?: MouseEvent) {
    event?.stopPropagation()
    if (this.disabled) return
    this.addMediaClick.emit()
    this.onFocus()
    if (!this.listOptions.customInputElement && this.mediaInput?.nativeElement) {
      this.mediaInput.nativeElement.click()
      this.addFocusEventListener()
    }
  }

  public onChange(dirty: boolean = true) {
    if (this.disabled) return
    this.mediasChange.emit(this.medias)
    if (dirty) {
      this.dirtyChange.emit()
    }
  }

  public onEditMedia(media: MediaField): void {
    if (media.isLoading || this.disabled) return
    this.onFocus()
    media.url = this.getThumbnail(media)
    this.openMediaGallery(media, [media], true)
  }

  public onDeleteMedia(media: MediaField): void {
    if (media.isLoading || this.disabled) {
      return
    }

    this.onFocus()
    this.medias = this.medias?.filter((m) => m.mediaKey !== media.mediaKey) ?? []
    this.onChange()
    this.onBlur()
  }

  public onDeleteMediaFromGallery(mediaId: string): void {
    this.medias = this.medias?.filter((m) => m.mediaKey !== mediaId) ?? []
    this.onChange()
    this.onBlur()
  }

  public onMediaExpand(): void {
    if (this.listOptions.withMediaGallery && this.medias?.length) {
      this.openMediaGallery(this.medias[0], [], false, true)
    }
  }

  public onMediaClick(event: MouseEvent, media: MediaField): void {
    event.stopPropagation()
    if (media.isLoading) return

    if (this.isDocument(media)) {
      window.open(media.downloadUrl, '_blank')
      return
    }
    this.onFocus()

    if (this.listOptions.withMediaGallery) {
      this.openMediaGallery(media)
    }

    this.mediaClick.emit(media)
  }

  public getMediaNameWithoutExtensions(media: MediaField) {
    return media.name?.replace(MediaUtils.getExtensionsRegex(), '') ?? ''
  }

  public onMediasUpload(files: FileList) {
    if (this.disabled) return

    let allFilesRead = false
    const existingMedias = this.medias
    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      if (!this.allowedMimeTypes.includes(file.type)) {
        this.toastService.error('shared.message.invalidFile', file)
        continue
      }

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (event) => {
        const url = event.target.result as string
        const newMedia = new MediaField()

        newMedia.id = null
        newMedia.file = file
        newMedia.name = file.name
        newMedia.fileName = file.name
        newMedia.mimeType = file.type
        newMedia.isNewImage = true
        newMedia.url = URL.createObjectURL(file)

        newMedia.downloadUrl = newMedia.url
        newMedia.url = this.getThumbnail(newMedia)
        newMedia.mediaKey = `${uuidv4()}`

        existingMedias.push(newMedia)

        if (i === files.length - 1) {
          allFilesRead = true
        }

        if (allFilesRead) {
          this.medias = [...existingMedias]
          this.onChange()
          this.onBlur()
        }
      }
    }
  }

  public addMedia(newMedia: MediaField) {
    const existingMedias = this.medias
    existingMedias.push(newMedia)
    this.medias = [...existingMedias]
    this.onChange()
    this.onBlur()
  }

  private onRotate(media: MediaGalleryItem, degrees: MediaRotationDegrees): void {
    const rotatedMedia = this.medias.find((m) => m.mediaKey === media.mediaKey)
    rotatedMedia.rotation = degrees
    this.medias = [...this.medias]

    this.onChange()
  }

  private onOrderChange(medias: MediaGalleryItem[]): void {
    this.medias = this.medias
      .map((m: MediaField) => {
        const item = medias.find((media) => media.mediaKey === m.mediaKey)
        if (item) {
          m.order = item.order
        }
        return m
      })
      .sort((a, b) => a.order - b.order)
    this.onChange()
  }

  private openMediaGallery(
    selected?: MediaField,
    medias: MediaField[] = [],
    enterInEditMode: boolean = false,
    enterInExpandedMode: boolean = false
  ) {
    this.dialogService.dialog
      .open<SVRMediaGalleryComponent, MediaGalleryData>(SVRMediaGalleryComponent, {
        data: {
          media: this.getMediasPaginatedAsync(medias),
          selectedMediaKey: selected?.mediaKey,
          allowEdit: !this.disabled,
          enterInEditMode,
          enterInExpandedMode,
          onSave: this.onChangeMediaName ?? undefined,
          onRotate: (media: MediaGalleryItem, degrees: MediaRotationDegrees) => this.onRotate(media, degrees),
          onDelete: (mediaId: string) => this.onDeleteMediaFromGallery(mediaId),
          onOrderChange: (medias: MediaGalleryItem[]) => this.onOrderChange(medias),
        },
        disableClose: false,
        width: '75vw',
        height: '85vh',
        panelClass: 'media-gallery-dialog',
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((updatedMedias: MediaGalleryItem[]) => {
        let mediasUpdated = false

        for (const updatedMedia of updatedMedias) {
          const media = this.medias.find((me) => me.mediaKey === updatedMedia.mediaKey)

          if (!media) {
            mediasUpdated = true
            continue
          }

          const shouldUpdateMedia = this.shouldUpdateMediaField(updatedMedia, media)

          if (!shouldUpdateMedia) {
            continue
          }

          mediasUpdated = true

          media.name = updatedMedia.name
          media.rotation = updatedMedia.rotation
          media.order = updatedMedia.order

          if (!media.uploadDate) {
            continue
          }

          this.monitorService.updateMedia(media.id, { name: media.name, rotation: media.rotation, order: media.order }).subscribe()
        }

        if (mediasUpdated) {
          this.onChange(true)
        }

        this.onBlur()
      })
  }

  private shouldUpdateMediaField(mediaGalleryItem: MediaGalleryItem, mediaField: MediaField): boolean {
    return (
      mediaGalleryItem.name !== mediaField.name ||
      mediaGalleryItem.order !== mediaField.order ||
      mediaGalleryItem.rotation !== mediaField.rotation
    )
  }

  private getMediasPaginatedAsync(medias?: MediaField[]): () => Observable<PaginatedResults<MediaGalleryItem>> {
    medias = medias.length ? medias : this.medias ?? []
    return (): Observable<PaginatedResults<MediaGalleryItem>> => {
      const gallery = medias.map((m) => this.mapMediaFieldtoMediaGalleryItem(m))
      return of(new PaginatedResults(gallery))
    }
  }

  private mapMediaFieldtoMediaGalleryItem(media: MediaField): MediaGalleryItem {
    const isDocument = this.isDocument(media)
    let url = isDocument ? this.getThumbnail(media) : media.url
    let thumbnailUrl = this.getThumbnail(media)
    let downloadUrl = media.downloadUrl

    if (!isDocument) {
      if (media.url) {
        url = new URL(media.url).toString()
      }
      if (media.thumbnailUrl && !media.isNewImage) {
        thumbnailUrl = new URL(media.thumbnailUrl).toString()
      }
    } else if (media.downloadUrl) {
      downloadUrl = new URL(media.downloadUrl).toString()
    }

    return {
      id: media.id,
      url,
      downloadUrl,
      thumbnailUrl,
      name: media.name,
      uploadedAt: media.uploadDate,
      loading: (item) => this.isUploading(item.id),
      uploadSuccess: media.uploadSuccess,
      rotation: media.rotation,
      mediaType: isDocument ? MediaType.DOCUMENT : MediaType.IMAGE,
      mediaKey: media.mediaKey,
    }
  }

  // We need to add this event listener in order to be able to dispatch
  //   the onBlur event when the user cancels a file upload
  private addFocusEventListener() {
    const handler = () => {
      this.onBlur()
    }
    this.onChangeFocusHandler = handler
    addEventListener('focus', this.onChangeFocusHandler)
  }

  private removeFocusEventListener() {
    removeEventListener('focus', this.onChangeFocusHandler)
  }
}
