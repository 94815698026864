import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LabelValueDTO, PaginatedResults, PaginatedSearchDTO } from '@uefa-shared/contracts'
import { BaseService } from '@uefa-shared/frontend'
import {
  ActionPointSearchDTO,
  EventCycleLabelValueDTO,
  EventLabelValueDTO,
  QuestionSearchDTO,
  SVRCompetitionSearchDTO,
  SVREventCycleSearchDTO,
  SVREventPrintTemplateSearchDTO,
  SVREventSearchDTO,
  SVRProjectSearchDTO,
  SVRSiteLevelSearchDTO,
  SVRSiteSearchDTO,
  SVRSiteTypeSearchDTO,
  SVRTeamSearchDTO,
  SVRVenueSearchDTO,
  SectionSearchDTO,
  SiteLabelValueDTO,
  UserEmailSearchDTO,
  UserPaginatedSearchDTO,
  WorkingVisitSearchDTO,
} from '@uefa-svr/contracts'
import { Observable } from 'rxjs'
import { environment } from '../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class DictionaryService extends BaseService {
  constructor(http: HttpClient) {
    super(environment, 'dictionary', http)
  }

  public getActionPoints(options: ActionPointSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/action-point`, options)
  }

  public getSectionsPaginated(options: SectionSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/section`, options)
  }

  public getCompetitionsPaginated(options: SVRCompetitionSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/competition`, options)
  }

  public getEventsPaginated(options: SVREventSearchDTO): Observable<PaginatedResults<EventLabelValueDTO>> {
    return this.getPaginatedResult<EventLabelValueDTO>(`${this.basePath}/event`, options)
  }

  public getEventCyclesPaginated(options: SVREventCycleSearchDTO): Observable<PaginatedResults<EventCycleLabelValueDTO>> {
    return this.getPaginatedResult<EventCycleLabelValueDTO>(`${this.basePath}/eventCycle`, options)
  }

  public getSitesPaginated(options: SVRSiteSearchDTO): Observable<PaginatedResults<SiteLabelValueDTO>> {
    return this.getPaginatedResult<SiteLabelValueDTO>(`${this.basePath}/site`, options)
  }

  public getTeamsPaginated(options: SVRTeamSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/team`, options)
  }

  public getEventPrintTemplatesPaginated(options: SVREventPrintTemplateSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/printTemplate`, options)
  }

  public getSiteTypesPaginated(options: SVRSiteTypeSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/siteType`, options)
  }

  public getVenuesPaginated(options: SVRVenueSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/venue`, options)
  }

  public getKeysPaginated(options: PaginatedSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/key`, options)
  }

  public getProjectsPaginated(options: SVRProjectSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/project`, options)
  }

  public getUsersPaginated(options: UserPaginatedSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/user`, options)
  }

  public getEmailsPaginated(options: UserEmailSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/user-email`, options)
  }

  public getCurenciesPaginated(options: PaginatedSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/currency`, options)
  }

  public getLevelsPaginated(options: SVRSiteLevelSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/level`, options)
  }

  public getOrganizationsPaginated(options: PaginatedSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/organization`, options)
  }

  public getSiteVisitsPaginated(options: PaginatedSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/siteVisit`, options)
  }

  public getWorkingVisitsPaginated(options: WorkingVisitSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/working-visit`, options)
  }

  public getQuestionsPaginated(options: QuestionSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/question`, options)
  }
}
