import { Exclude } from 'class-transformer'

export class AuthenticatedUserDTO<T = {}> {
  email: string
  name: string
  initials: string
  timezone: string
  primaryEmail?: string

  @Exclude()
  groups?: T[]
}
