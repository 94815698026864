import { IsArray, IsEnum, IsOptional } from 'class-validator'
import { ArrayParse } from '../decorators'
import { PaginatedSearchDTO } from '../shared/paginated-search.dto'
import { LayerTypeSearchSortField } from './layer-type.dto'

export class LayerTypeSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsEnum(LayerTypeSearchSortField)
  sortBy?: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  shortNames?: string[]
}
