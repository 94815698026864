import { ArrayParse, BooleanParse, PaginatedSearchDTO } from '@uefa-shared/contracts'
import { IsBoolean, IsEnum, IsOptional, IsString } from 'class-validator'
import { QuestionStatus } from '../question'

export enum WorkingVisitQuestionSortBy {
  TITLE = 'TITLE',
  DISABLED = 'DISABLED',
  STATUS = 'STATUS',
  EVENT = 'EVENT',
  VENUE = 'VENUE',
  SITE = 'SITE',
  SITE_VISIT = 'SITE_VISIT',
  WORKING_VISIT = 'WORKING_VISIT',
  LEVEL = 'LEVEL',
}

export class WorkingVisitQuestionSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @ArrayParse()
  keyIds?: string[]

  @IsOptional()
  @ArrayParse()
  projectIds?: string[]

  @IsOptional()
  @ArrayParse()
  competitionIds?: string[]

  @IsOptional()
  @ArrayParse()
  questionIds?: string[]

  @IsOptional()
  @ArrayParse()
  @IsEnum(QuestionStatus, { each: true })
  status?: QuestionStatus[]

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  completed?: boolean

  @IsOptional()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @ArrayParse()
  venueIds?: string[]

  @IsOptional()
  @ArrayParse()
  siteIds?: string[]

  @IsOptional()
  @ArrayParse()
  siteVisitIds?: string[]

  @IsOptional()
  @ArrayParse()
  workingVisitIds?: string[]

  @IsOptional()
  @ArrayParse()
  levelIds?: string[]

  @IsOptional()
  @ArrayParse()
  excludedIds?: string[]

  @IsOptional()
  @IsEnum(WorkingVisitQuestionSortBy)
  sortBy?: WorkingVisitQuestionSortBy
}

export class WorkingVisitQuestionSearchBulkUpdateDTO extends WorkingVisitQuestionSearchDTO {
  @IsEnum(QuestionStatus)
  updatedStatus: QuestionStatus

  @IsOptional()
  @IsString()
  rejectReason: string
}
