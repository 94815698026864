<form class="table-container">
  <vista-table
    #table
    [config]="tableConfig"
    [columns]="columns"
    [actions]="actions"
    [dataAsync]="loadMore"
    [isExternalFilterApplied]="isExternalFilterApplied"
    (paginationChange)="paginationChange.emit($event)"
    (selectedChange)="onSelectedChange($event)"
  >
    <!--  Status Row Template-->
    <ng-template vista-table-template templateId="statusTemplate" let-value="value">
      <div matTooltip="{{ 'shared.enums.actionPointStatus.' + value | translate }}">
        <img class="icon-cell  {{ value?.toLowerCase() }}" [src]="getStatusIcon(value)" />
      </div>
    </ng-template>

    <!--  Priority Row Template-->
    <ng-template vista-table-template templateId="priorityTemplate" let-value="value">
      <div matTooltip="{{ 'shared.enums.actionPointPriority.' + value | translate }}">
        <img class="icon-cell  {{ value?.toLowerCase() }}" [src]="getPriorityIcon(value)" />
      </div>
    </ng-template>

    <ng-template vista-table-template templateId="locationTemplate" let-value="value" let-row="row">
      <button
        *ngIf="!!value"
        mat-icon-button
        class="indicator"
        [class.active]="isActiveRow(row)"
        [class.location]="!!value"
        [matTooltip]="value?.name"
        (click)="onLocateActionPoint(value)"
      >
        <i-feather class="icon-cell" name="map-pin"></i-feather>
      </button>
    </ng-template>

    <ng-template vista-table-template templateId="lastCommentTemplate" let-value="value">
      <div *ngIf="!!value" matTooltip="{{ value.comment }}" class="last-comment">
        {{ value.comment }}
      </div>
    </ng-template>
  </vista-table>
</form>
