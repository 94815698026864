import { Transform } from 'class-transformer'
import { IsString } from 'class-validator'

export function StringTrim() {
  return function (obj: Record<string, any>, propertyName: string) {
    IsString()(obj, propertyName)
    Transform(({ value }) => value && String(value).trim())(obj, propertyName)
  }
}

export function StringCleanup() {
  return function (obj: Record<string, any>, propertyName: string) {
    // Replace unicode hyphens
    Transform(({ value }) => value?.replace(/[\u2013-\u2015]/g, '-'))(obj, propertyName)
  }
}
