import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PaginatedResults } from '@uefa-shared/contracts'
import { BaseService } from '@uefa-shared/frontend'
import {
  QuestionTemplateDTO,
  QuestionTemplateSearchDTO,
  SiteVisitConfigurationDTO,
  SiteVisitCreateDTO,
  SiteVisitDTO,
  SiteVisitDuplicateDTO,
  SiteVisitListDTO,
  SiteVisitSearchDTO,
  SiteVisitUpdateDTO,
} from '@uefa-svr/contracts'
import { Observable } from 'rxjs'
import { environment } from '../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class SiteVisitsService extends BaseService {
  constructor(http: HttpClient) {
    super(environment, 'site-visit', http)
  }

  public getPaginated(options: SiteVisitSearchDTO): Observable<PaginatedResults<SiteVisitListDTO>> {
    return this.getPaginatedResult<SiteVisitListDTO>(this.basePath, options)
  }

  public getById(id: string): Observable<SiteVisitDTO> {
    return this.http.get<SiteVisitDTO>(`${this.basePath}/${id}`)
  }

  public create(model: SiteVisitCreateDTO): Observable<SiteVisitCreateDTO> {
    return this.http.post<SiteVisitCreateDTO>(this.basePath, model)
  }

  public update(id: string, model: SiteVisitUpdateDTO): Observable<SiteVisitUpdateDTO> {
    return this.http.put<SiteVisitUpdateDTO>(`${this.basePath}/${id}`, model)
  }

  public duplicate(model: SiteVisitDuplicateDTO): Observable<string[]> {
    return this.http.post<string[]>(`${this.basePath}/duplicate`, model)
  }

  public delete(id: string): Observable<SiteVisitDTO> {
    return this.http.delete<SiteVisitDTO>(`${this.basePath}/${id}`)
  }

  public getExportQuestionLink(id: string): string {
    return `${this.basePath}/${id}/export-questions`
  }

  public getQuestionTemplates(search: QuestionTemplateSearchDTO): Observable<QuestionTemplateDTO[]> {
    let url = `${this.basePath}/questions`

    const queryParams = this.getQueryParams<QuestionTemplateSearchDTO>(search)
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`
    }

    return this.http.get<QuestionTemplateDTO[]>(url)
  }

  public getConfigurations() {
    return this.http.get<SiteVisitConfigurationDTO[]>(`${this.basePath}/configurations`)
  }
}
