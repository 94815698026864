<div class="row">
  <div class="row flex-grow header {{ medias.length ? 'margin' : '' }}">
    <div [matTooltip]="title">
      {{ title | translate }}
    </div>
    <button
      mat-icon-button
      type="button"
      class="add-button-icon"
      color="primary"
      matTooltip="{{ 'shared.buttons.add' | translate }}"
      (click)="add(svrMediaList)"
      [disabled]="disabled"
    >
      <i-feather name="plus-circle" class="primary"></i-feather>
    </button>
  </div>
  <svr-media-list
    [ngClass]="{ hidden: !medias.length }"
    class="row"
    #svrMediaList
    [disabled]="disabled"
    [medias]="medias"
    [allowedMimeTypes]="allowedMimeTypes"
    [options]="options"
    [onChangeMediaName]="onChangeMediaName"
    (mediasChange)="mediasChange.emit($event)"
    (dirtyChange)="dirtyChange.emit($event)"
    (mediaClick)="mediaClick.emit($event)"
  ></svr-media-list>
</div>
