import { Directive, ElementRef, EventEmitter, Output } from '@angular/core'
@Directive({
  selector: '[ngInit]',
})
export class NgInitDirective {
  @Output('ngInit') initEvent = new EventEmitter()
  constructor(private elem: ElementRef) {}
  public ngOnInit() {
    setTimeout(() => this.initEvent.emit(this.elem.nativeElement), 10)
  }
}
