import { IsArray, IsEnum, IsNumber, IsOptional } from 'class-validator'
import { ArrayParse, NumberParse, StringTrim } from '../decorators'

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export class BaseSearchDTO {
  @StringTrim()
  @IsOptional()
  searchTerm?: string

  @StringTrim()
  @IsOptional()
  sortBy?: string

  @IsOptional()
  @IsEnum(SortDirection)
  sortDirection?: SortDirection = SortDirection.ASC

  @IsOptional()
  @ArrayParse()
  @IsArray()
  ids?: Array<string>

  @IsOptional()
  @ArrayParse()
  @IsArray()
  integrationIds?: Array<string>
}

export class PaginatedSearchDTO extends BaseSearchDTO {
  // Skip and Take are the search properties currently in use
  @IsOptional()
  @IsNumber()
  @NumberParse()
  skip?: number

  @IsOptional()
  @IsNumber()
  @NumberParse()
  take?: number

  // For retro-compatibility we keep these options
  @IsOptional()
  @IsNumber()
  @NumberParse()
  currentPage?: number = 1

  @IsOptional()
  @IsNumber()
  @NumberParse()
  pageSize?: number = 20
}
