import { Injectable, TemplateRef } from '@angular/core'
import { LabelValueDTO } from '@uefa-shared/contracts'
import { MonitorSiteVisitDTO, WorkingVisitQuestionDTO } from '@uefa-svr/contracts'
import { Subject } from 'rxjs'
import { MonitorShellComponent } from '../../features/monitor/components'

@Injectable({ providedIn: 'root' })
export class MonitorShellService {
  private _shellComponent: MonitorShellComponent
  public projectsFilterOpen: boolean
  public keysFilterOpen: boolean
  public selectedSiteVisit: MonitorSiteVisitDTO

  public headerTemplateChange = new Subject<TemplateRef<unknown>>()
  public projectsFilterOpenChange = new Subject<boolean>()
  public keysFilterOpenChange = new Subject<boolean>()
  public projectsFilterChange = new Subject<string[]>()
  public keysFilterChange = new Subject<string[]>()
  public facilityIdChange = new Subject<string>()
  public siteVisitChange = new Subject<MonitorSiteVisitDTO>()
  public selectedWorkingVisitChange = new Subject<LabelValueDTO>()
  public questionAnswered = new Subject<WorkingVisitQuestionDTO>()

  public get shellComponent() {
    return this._shellComponent
  }
  public set shellComponent(component: MonitorShellComponent) {
    this._shellComponent = component
  }

  public siteVisit(siteVisit: MonitorSiteVisitDTO, selectedVisit?: LabelValueDTO) {
    this.selectedSiteVisit = siteVisit
    this.siteVisitChange.next(siteVisit)

    if (selectedVisit) {
      this.selectedWorkingVisit(selectedVisit)
    }
  }

  public selectedWorkingVisit(visit: LabelValueDTO) {
    this.selectedWorkingVisitChange.next(visit)
  }

  public answeredQuestion(question: WorkingVisitQuestionDTO) {
    this.questionAnswered.next(question)
  }

  /* Header */

  public changeHeaderTemplate(template?: TemplateRef<unknown>) {
    this.headerTemplateChange.next(template)
  }

  /* Projects Filter */
  public toggleProjectsFilter() {
    if (this.projectsFilterOpen) {
      this.closeProjectsFilter()
    } else {
      this.openProjectsFilter()
    }
  }

  public openProjectsFilter() {
    this.projectsFilterOpen = true
    this.projectsFilterOpenChange.next(true)
  }

  public closeProjectsFilter() {
    this.projectsFilterOpen = false
    this.projectsFilterOpenChange.next(false)
  }

  public projectsFiltered(projects: string[]) {
    this.projectsFilterChange.next(projects)
  }

  /* Keys Filter */
  public toggleKeysFilter() {
    if (this.keysFilterOpen) {
      this.closeKeysFilter()
    } else {
      this.openKeysFilter()
    }
  }

  public openKeysFilter() {
    this.keysFilterOpen = true
    this.keysFilterOpenChange.next(true)
  }

  public closeKeysFilter() {
    this.keysFilterOpen = false
    this.keysFilterOpenChange.next(false)
  }

  public keysFiltered(keys: string[]) {
    this.keysFilterChange.next(keys)
  }

  /* Facility map filter */
  public facilityId(id: string) {
    this.facilityIdChange.next(id)
  }
}
