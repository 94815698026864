import { IsNotEmpty, IsNumber, IsOptional } from 'class-validator'
import { ArrayParse, NumberParse, NumberRound } from '../decorators'
import { GeoFeatureLocationFilterDTO } from '../layer/layer.dto'
import { GeoFilterDTO } from '../level-facility'
import { ProjectDTO } from '../project/project.dto'

class KeyLevelDTO {
  layer: string
  facilities: FacilityDetailDTO[]
}

export class FacilityDetailDTO {
  id: string
  area: number
  perimeter: number
  project?: ProjectDTO
}

export class FacilityLocationSearchDTO extends GeoFilterDTO {
  @IsNotEmpty()
  @ArrayParse()
  layers: string[]

  @IsNotEmpty()
  @IsNumber()
  @NumberParse()
  latitude: number

  @IsNotEmpty()
  @IsNumber()
  @NumberParse()
  @NumberRound()
  zoom: number

  @IsNotEmpty()
  @IsNumber()
  @NumberParse()
  longitude: number
}

export class FacilityFeatureLocationSearchDTO extends GeoFeatureLocationFilterDTO {
  @IsOptional()
  @ArrayParse()
  levels: string[]

  @IsNotEmpty()
  @IsNumber()
  @NumberParse()
  latitude: number

  @IsNotEmpty()
  @IsNumber()
  @NumberParse()
  @NumberRound()
  zoom: number

  @IsNotEmpty()
  @IsNumber()
  @NumberParse()
  longitude: number
}

export class FacilityKeySearchDTO {
  id: string
  name: string
  label: string
  projects: ProjectDTO[]
  levels: KeyLevelDTO[]
}

export class FacilityIdentifyDTO {
  keyId: string
  keyName: string
  keyLabel: string
  layer: string
  facility: FacilityDetailDTO
}
