import { CommonModule, DatePipe } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { AngularEditorModule } from '@kolkov/angular-editor'
import { TranslateModule } from '@ngx-translate/core'
import { ComponentsModule, IconsModule, MaterialModule, PrimeNgModule } from '@uefa-shared/frontend'
import { VistaMapModule } from '@uefa-vista/map'
import { environment } from '../../environments/environment'
import * as c from './components'
import * as d from './directives'
import * as s from './services'

const importOnlyModules = [
  ComponentsModule.forRoot({
    environment: environment,
  }),
]

const modules = [
  CommonModule,
  FormsModule,
  MaterialModule,
  PrimeNgModule,
  IconsModule,
  HttpClientModule,
  RouterModule,
  TranslateModule,
  VistaMapModule,
  AngularEditorModule,
]

const directives = [d.PeopleValidatorDirective, d.WorkingVisitsValidatorDirective, d.NgInitDirective]

const components = [
  c.SVRMapComponent,
  c.SidePanelComponent,
  c.SVRMediaGalleryComponent,
  c.MediaListComponent,
  c.MediaListActionsComponent,
  c.MediaListContainerComponent,
  c.SharedActionPointsTableComponent,
  c.ActionPointsTableStatusDialogComponent,
  c.ActionPointsTableUpdateStatusResultDialogComponent,
  c.SharedActionPointsManagerComponent,
  c.SharedActionPointStatusDialogComponent,
  c.MonitorShellFiltersComponent,
  c.RichTextEditorComponent,
  c.CommentComponent,
  c.SignatureFieldComponent,
  c.SignatureDialogComponent,
]

const services = [
  s.SVRUserService,
  s.SectionsService,
  s.DictionaryService,
  s.SiteVisitsService,
  s.VistaService,
  s.ConfigsService,
  s.MonitorService,
  s.MonitorShellService,
  s.NavigationService,
  s.MainChangesService,
  s.ActionPointsService,
  s.MediaQueueService,
  s.WorkingVisitQuestionService,
  s.WorkingVisitService,
  s.FacilityService,
  s.TasksService,
  s.MapService,
  s.ActionPointManageService,
  s.RichTextEditorManagerService,
]

@NgModule({
  declarations: [...directives, ...components],
  imports: [...importOnlyModules, ...modules],
  exports: [...modules, ...components, ...directives],
  providers: [...services, DatePipe],
})
export class SharedModule {}
