import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PaginatedResults } from '@uefa-shared/contracts'
import { BaseService } from '@uefa-shared/frontend'
import {
  SectionChildrenSearchDTO,
  SectionCreateDTO,
  SectionDTO,
  SectionListDTO,
  SectionReorderDTO,
  SectionSearchDTO,
  SectionUpdateDTO,
} from '@uefa-svr/contracts'
import { Observable } from 'rxjs'
import { environment } from '../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class SectionsService extends BaseService {
  constructor(http: HttpClient) {
    super(environment, 'section', http)
  }

  public getPaginated(options: SectionSearchDTO): Observable<PaginatedResults<SectionListDTO>> {
    return this.getPaginatedResult<SectionListDTO>(this.basePath, options)
  }

  public getById(id: string): Observable<SectionDTO> {
    return this.http.get<SectionDTO>(`${this.basePath}/${id}`)
  }

  public getChildren(id: string, search: SectionChildrenSearchDTO): Observable<SectionListDTO[]> {
    const queryParams = this.getQueryParams<SectionChildrenSearchDTO>(search)
    return this.http.get<SectionListDTO[]>(`${this.basePath}/${id}/children?${queryParams.join('&')}`)
  }

  public create(model: SectionCreateDTO): Observable<SectionDTO> {
    return this.http.post<SectionDTO>(this.basePath, model)
  }

  public update(id: string, model: SectionUpdateDTO): Observable<SectionDTO> {
    return this.http.put<SectionDTO>(`${this.basePath}/${id}`, model)
  }

  public delete(id: string): Observable<SectionDTO> {
    return this.http.delete<SectionDTO>(`${this.basePath}/${id}`)
  }

  public reorder(model: SectionReorderDTO): Observable<void> {
    return this.http.put<void>(`${this.basePath}/reorder`, model)
  }
}
