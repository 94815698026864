import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ActionPointBulkUpdateResultDTO } from '@uefa-svr/contracts'

@Component({
  templateUrl: './update-status-result-dialog.component.html',
  styleUrls: ['./update-status-result-dialog.component.scss'],
})
export class ActionPointsTableUpdateStatusResultDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: ActionPointBulkUpdateResultDTO,
    private readonly dialogRef: MatDialogRef<ActionPointsTableUpdateStatusResultDialogComponent>
  ) {}

  public closeDialog() {
    this.dialogRef.close(null)
  }
}
