import { ArrayParse, BooleanParse, Hatch, hatchToDegrees, LabelValueDTO, StringCleanup, StringTrim } from '@uefa-shared/contracts'
import { Type } from 'class-transformer'
import {
  ArrayMinSize,
  IsArray,
  IsBoolean,
  IsEnum,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
  ValidateIf,
  ValidateNested,
} from 'class-validator'
import { SectionStatus } from '../monitor'
import { FieldAnswerType, FieldChildOperator, KeyPolicy, QuestionStatus, QuestionTarget } from '../question'
import { ColorRanking, SVRMediaDTO, WorkingVisitStatus } from '../shared'
import { SiteVisitUserDTO } from '../site-visit'
import { WorkingVisitQuestionSearchDTO } from './working-visit-question.search.dto'

export enum AnswerBlockType {
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
  TABLE = 'TABLE',
}

export enum AdvancedAnswerStyle {
  REGULAR = 'REGULAR',
  BULLETS = 'BULLETS',
  NUMBERED = 'NUMBERED',
}

export class AnswerBlock {
  @IsNotEmpty()
  @IsUUID()
  @ValidateIf(block => !!block.id)
  id?: string

  @IsOptional()
  @IsString()
  @StringCleanup()
  @StringTrim()
  @ValidateIf(block => block.type === AnswerBlockType.TEXT)
  answer?: string

  @IsNotEmpty()
  @ArrayParse()
  @ValidateNested({ each: true })
  @ValidateIf(block => block.type === AnswerBlockType.IMAGE)
  @Type(() => SVRMediaDTO)
  images?: SVRMediaDTO[]

  @IsNotEmpty()
  @IsEnum(AnswerBlockType)
  type: AnswerBlockType

  @IsNotEmpty()
  @IsEnum(AdvancedAnswerStyle)
  @ValidateIf(block => block.type === AnswerBlockType.TEXT)
  answerStyle?: AdvancedAnswerStyle
}

export interface WorkingVisitQuestionAnswer {
  answerBlocks: AnswerBlock[]
}
export interface WorkingVisitQuestionPrefilledAnswer {
  answer: AnswerBlock[]
  fields: WorkingVisitQuestionFieldDTO[]
}

export interface WorkingVisitQuestionPrefillAnswer {
  form: PrefillFromDTO
  medias: SVRMediaDTO[]
  answerBlocks: AnswerBlock[]
}

export class RejectedInfo {
  constructor(public rejectedBy: string, public rejectedAt: Date, public reject: string) {}
}

export class WorkingVisitQuestionKey {
  constructor(
    public value: string,
    public label: string,
    public color: string,
    public hatch: Hatch,
    public hatchColor: string,
    public parentId: string
  ) {}
}

export class WorkingVisitQuestionProject {
  constructor(public value: string, public label: string, public description: string) {}
}

export class WorkingVisitQuestionFieldDTO {
  id: string
  name: string
  description: string
  answer: string
  originalQuestionFieldId: string
  templates?: SVRMediaDTO[]
  documents?: SVRMediaDTO[]
  images?: SVRMediaDTO[]
  table?: WorkingVisitQuestionFieldTable
  type: FieldAnswerType
  mandatory: boolean
  order: number
  multiple?: boolean
  signatures?: SVRMediaDTO[]
  toggled?: boolean
  selectedValues?: string[]
  valueOptions?: LabelValueDTO[]
  operator?: FieldChildOperator
  parentId?: string
  parentExpectedAnswer?: string
  children?: WorkingVisitQuestionFieldDTO[]
  hasChildren?: boolean
}

export class PrefillFromDTO {
  siteVisitName: string
  workingVisitCode: string
  answeredAt: Date
  answeredBy: string
  workingVisitQuestionId?: string
  fields?: { [originalQuestionFieldId: string]: string }
}

export class DescriptionLabelValueDTO extends LabelValueDTO {
  description?: string

  constructor(value?: string, label?: string, description?: string) {
    super(value, label)
    this.description = description
  }
}

export class WorkingVisitQuestionProjectDTO extends LabelValueDTO {
  description?: string
  includedInEvent?: boolean

  constructor(value?: string, label?: string, description?: string, includedInEvent?: boolean) {
    super(value, label)
    this.description = description
    this.includedInEvent = includedInEvent
  }
}

export class WorkingVisitQuestionDTO {
  id: string
  originalQuestionId: string
  sectionId: string
  title: string
  guidelines: string
  status: QuestionStatus
  ranking: ColorRanking
  projects: WorkingVisitQuestionProjectDTO[]
  updatedBy?: string
  updatedAt?: Date
  answer?: AnswerBlock[]
  prefillQuestionId?: string
  hasChangesFromPrefill?: boolean
  rejected?: RejectedInfo
  templates?: SVRMediaDTO[]
  keys?: WorkingVisitQuestionKey[]
  level?: LabelValueDTO
  fields?: WorkingVisitQuestionFieldDTO[]
  presentUsers?: SiteVisitUserDTO[]
  workingVisitUsers?: SiteVisitUserDTO[]
  facilityId?: string
  isCompleted?: boolean
  trackPeople?: boolean
  requireLocation?: boolean
  prefillFrom?: PrefillFromDTO
  permissions?: WorkingVisitQuestionPermissions
  allowRichContent?: boolean
  mandatoryFieldsNotAnswered?: string[]
  keyPolicy: KeyPolicy
  target: QuestionTarget
}

export class WorkingVisitQuestionListDTO {
  id: string
  title: string
  disabled: boolean
  siteVisitDisabled: boolean
  status: QuestionStatus
  eventNames: string
  eventIds: string[]
  venueName: string
  siteName: string
  levelName: string
  isOverview: boolean
  siteVisitTitle: string
  siteVisitId: string
  workingVisitCode: string
  deepLink?: string
  facilityId: string
  keys?: WorkingVisitQuestionKey[]
  originalQuestionId: string
  sectionId: string
  guidelines: string
  ranking: ColorRanking
  projects: WorkingVisitQuestionProjectDTO[]
  keyPolicy: KeyPolicy
  target: QuestionTarget
  prefillQuestionId?: string
  hasChangesFromPrefill: boolean
}

export class WorkingVisitQuestionPermissions {
  constructor(public canEdit: boolean = false, public canManage: boolean = false, public canSeeDetails: boolean = false) {}
}

export class WorkingVisitQuestionFieldTableCell {
  id: string
  value: string
  isLocked: boolean
}

export class WorkingVisitQuestionFieldTable {
  @IsNotEmpty()
  @IsString()
  @IsArray()
  @ArrayParse()
  @ArrayMinSize(1)
  columns: WorkingVisitQuestionFieldTableCell[]

  @IsNotEmpty()
  @IsString()
  @IsArray()
  @ArrayParse()
  @ArrayMinSize(1)
  rows: WorkingVisitQuestionFieldTableCell[][]

  @IsOptional()
  @IsBoolean()
  adjustable?: boolean
}

export class BaseWorkingVisitQuestionFieldUpdateDTO {
  @IsNotEmpty()
  @IsUUID()
  id: string

  @IsNotEmpty()
  @IsString()
  @StringCleanup()
  @StringTrim()
  @ValidateIf(field => field.type === FieldAnswerType.NUMERIC || field.type === FieldAnswerType.PLAIN_TEXT)
  answer?: string

  @IsNotEmpty()
  @IsEnum(FieldAnswerType)
  type: FieldAnswerType

  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @IsUUID('4', { each: true })
  @ValidateIf(field => field.type === FieldAnswerType.LIST)
  selectedValues?: string[]

  @IsNotEmpty()
  @IsBoolean()
  @BooleanParse()
  @ValidateIf(field => field.type === FieldAnswerType.YES_NO)
  toggled?: boolean

  @IsNotEmpty()
  @ValidateNested()
  @ValidateIf(field => field.type === FieldAnswerType.TABLE)
  table?: WorkingVisitQuestionFieldTable

  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ValidateNested({ each: true })
  @ValidateIf(field => field?.documents?.length)
  documents?: SVRMediaDTO[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @ValidateNested({ each: true })
  @ValidateIf(field => field?.images?.length)
  images?: SVRMediaDTO[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @ValidateNested({ each: true })
  @ValidateIf(field => field?.signatures?.length)
  signatures?: SVRMediaDTO[]

  constructor(field?: WorkingVisitQuestionFieldDTO) {
    if (field) {
      this.id = field.id
      this.answer = field.answer
      this.type = field.type
      this.selectedValues = field.selectedValues ?? []
      this.toggled = field.toggled
      this.table = field.table
      this.documents = field.documents
      this.images = field.images
      this.signatures = field.signatures
    }
  }
}

export class WorkingVisitQuestionFieldUpdateDTO extends BaseWorkingVisitQuestionFieldUpdateDTO {
  @IsOptional()
  @ArrayParse()
  @ValidateNested({ each: true })
  children?: WorkingVisitQuestionFieldUpdateDTO[]

  constructor(field?: WorkingVisitQuestionFieldDTO) {
    super(field)

    if (field) {
      this.children = field.children?.map(c => new WorkingVisitQuestionFieldChildUpdateDTO(c)) ?? []
    }
  }
}

export class WorkingVisitQuestionFieldChildUpdateDTO extends BaseWorkingVisitQuestionFieldUpdateDTO {
  constructor(field?: WorkingVisitQuestionFieldDTO) {
    super(field)
  }
}

export class WorkingVisitQuestionDisableDTO {
  @IsNotEmpty()
  @ValidateNested()
  search: WorkingVisitQuestionSearchDTO

  @IsNotEmpty()
  @IsBoolean()
  @BooleanParse()
  disabled: boolean
}

export class WorkingVisitQuestionUpdateDTO {
  @IsNotEmpty()
  @Type(() => AnswerBlock)
  @ArrayParse()
  @ValidateNested({ each: true })
  answer: AnswerBlock[]

  @IsOptional()
  facilityId?: string

  @IsOptional()
  @IsUUID()
  prefillQuestionId?: string

  @IsOptional()
  @IsUUID('all', { each: true })
  @ArrayParse()
  userIds: string[]

  @IsOptional()
  @ArrayParse()
  @ValidateNested({ each: true })
  fields: WorkingVisitQuestionFieldUpdateDTO[]

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  completed?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  shouldHandlePrefillMediaCopy?: boolean
}

export class WorkingVisitQuestionRejectDTO {
  @IsNotEmpty()
  @StringCleanup()
  @StringTrim()
  reason: string

  @IsOptional()
  @IsUUID('all')
  eventId: string

  @IsOptional()
  @ValidateNested()
  @Type(() => WorkingVisitQuestionUpdateDTO)
  updateModel?: WorkingVisitQuestionUpdateDTO

  constructor(reason?: string, eventId?: string, updateModel?: WorkingVisitQuestionUpdateDTO) {
    this.reason = reason
    this.eventId = eventId
    this.updateModel = updateModel
  }
}

export class WorkingVisitQuestionAproveDTO {
  @IsOptional()
  @ValidateNested()
  @Type(() => WorkingVisitQuestionUpdateDTO)
  updateModel?: WorkingVisitQuestionUpdateDTO

  @IsOptional()
  @IsUUID('all')
  eventId: string

  constructor(updateModel?: WorkingVisitQuestionUpdateDTO, eventId?: string) {
    this.updateModel = updateModel
    this.eventId = eventId
  }
}

export class WorkingVisitQuestionUtils {
  public static getAllowedStatusForMotor(): WorkingVisitStatus[] {
    return [WorkingVisitStatus.TO_DO, WorkingVisitStatus.IN_PROGRESS, WorkingVisitStatus.SUBMITTED, WorkingVisitStatus.REJECTED]
  }

  public static getGenerateCompletedStatus() {
    return [QuestionStatus.APPROVED, QuestionStatus.REJECTED]
  }

  public static rankingColor(ranking: ColorRanking): string {
    switch (ranking) {
      case ColorRanking.GREY:
        return 'gray'
      case ColorRanking.GREEN:
        return 'green'
      case ColorRanking.YELLOW:
        return 'yellow'
      case ColorRanking.ORANGE:
        return 'orange'
      case ColorRanking.RED:
        return 'red'
      default:
        return ''
    }
  }

  public static statusColorClass(status: QuestionStatus | SectionStatus, completed?: boolean): string {
    if ((completed && status === QuestionStatus.IN_PROGRESS) || status === SectionStatus.COMPLETED) {
      return '#FFF8E5'
    }

    switch (status) {
      case QuestionStatus.REJECTED:
        return '#FAE7E9'
      case QuestionStatus.APPROVED:
        return '#E6F4E9'
      case QuestionStatus.IN_PROGRESS:
        return '#FFF8E5'
      case QuestionStatus.SUBMITTED:
        return '#E7F7FC'
      default:
        return ''
    }
  }

  public static keyColorStyle(key: WorkingVisitQuestionKey) {
    let colorStyle = `${key.color} 10px`
    if (key.hatch !== Hatch.NONE) {
      const degrees = hatchToDegrees(key.hatch)
      colorStyle = `repeating-linear-gradient(${degrees}deg, ${key.hatchColor || '#ffffff'} 3px, ${key.hatchColor || '#ffffff'} 4px, ${
        key.color
      } 5px, ${key.color} 10px)`
    }
    return colorStyle
  }

  public static isStatusAllowedForReject(status: QuestionStatus) {
    return this.getAllowedStatusForReject().includes(status)
  }

  public static isStatusAllowedForApprove(status: QuestionStatus) {
    return this.getAllowedStatusForApprove().includes(status)
  }

  public static getAllowedStatusForReject() {
    return [QuestionStatus.APPROVED, QuestionStatus.REJECTED, QuestionStatus.SUBMITTED]
  }

  public static getAllowedStatusForApprove() {
    return [QuestionStatus.APPROVED, QuestionStatus.REJECTED, QuestionStatus.SUBMITTED]
  }

  public static isStatusAllowedForSubmit(status: QuestionStatus) {
    return this.getAllowedStatusForSubmit().includes(status)
  }

  public static getAllowedStatusForSubmit() {
    return [QuestionStatus.IN_PROGRESS, QuestionStatus.TO_DO]
  }
}
