import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { AppService, ToastService } from '@uefa-shared/frontend'
import { SVRAuthenticatedUserDTO } from '@uefa-svr/contracts'
import { Observable, of } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { NavigationService, SVRUserService } from '../services'

@Injectable({
  providedIn: 'root',
})
export class AuthorizeGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private userService: SVRUserService,
    private toastService: ToastService,
    private appService: AppService
  ) {}

  public canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAuthenticated(state).pipe(map(() => this.isAuthorized(state)))
  }

  private isAuthenticated(routeState: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (!this.userService.isAuthenticated) {
      this.appService.nextRoute = routeState.url

      return this.userService.getInfo().pipe(
        tap((response) => this.updateUserInfo(response)),
        map(() => true)
      )
    } else {
      return of(true)
    }
  }

  private isAuthorized(state: RouterStateSnapshot): boolean {
    const area = NavigationService.menuNavigation.find((nav) => state.url.startsWith(nav.link))
    const isAuthorized = !area || this.userService.canAccess(...area.allowed)
    if (!isAuthorized) {
      this.toastService.error('shared.message.forbiddenRequest')
      window.location.href = `${environment.baseUrl}/forbidden`
    }
    return isAuthorized
  }

  private updateUserInfo(userInfo: SVRAuthenticatedUserDTO) {
    this.userService.userInfo = userInfo
  }
}
