import { DatePipe } from '@angular/common'
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { DialogService } from '@uefa-shared/frontend'
import { ActionPointCommentDTO, SVRAuthenticatedUserDTO } from '@uefa-svr/contracts'
import { SVRUserService } from '../../../services'

@Component({
  selector: 'svr-action-point-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnChanges {
  @Input() public disabled: boolean = false
  @Input() public actionPointId: string
  @Input() public comments: ActionPointCommentDTO[] = []
  @Output() public commentsChange = new EventEmitter<ActionPointCommentDTO[]>()

  public _comments: ActionPointCommentDTO[] = []

  private editingId: string

  private userInfo: SVRAuthenticatedUserDTO

  constructor(
    private readonly userService: SVRUserService,
    private readonly dialogService: DialogService,
    private readonly dataPipe: DatePipe
  ) {
    this.userService.getInfo().subscribe((userInfo) => {
      this.userInfo = userInfo
      this.add()
    })

    window.setInterval(() => {
      const addComment = this._comments.find((c) => this.isAdded(c))
      if (addComment) {
        addComment.createdAt = new Date()
      }
    }, 1000)
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.comments && this.comments) {
      this.refresh()
    }
  }

  public isEditableByUser(comment: ActionPointCommentDTO): boolean {
    return comment.userId === this.userInfo?.id
  }

  public add(): void {
    if (this._comments.find((c) => this.isAdded(c))) {
      return
    }

    const newComment = new ActionPointCommentDTO(this.userInfo)
    newComment.id = `new-${newComment.id}-added`
    this._comments.unshift(newComment)
  }

  public getTitleLabels(comment: ActionPointCommentDTO) {
    return {
      name: comment.userName,
      createdAt: this.getDateLabel(comment.createdAt),
    }
  }
  public getTitleUpdatedAtLabels(comment: ActionPointCommentDTO) {
    return {
      date: this.getDateLabel(comment.updatedAt),
    }
  }

  public canEditComment(comment: ActionPointCommentDTO): boolean {
    return this.userInfo?.id == comment.userId && !this.isEditing(comment)
  }

  public canDeleteComment(comment: ActionPointCommentDTO): boolean {
    return this.userInfo?.id == comment.userId
  }

  public onEditCancel(comment: ActionPointCommentDTO): void {
    this.editingId = undefined
    const commentIndex = this.findCommentIdIndex(comment.id)
    comment.comment = this.comments[commentIndex].comment
  }

  public onEditing(comment: ActionPointCommentDTO) {
    this.editingId = comment.id
  }

  public isAdded(comment: ActionPointCommentDTO): boolean {
    return comment.id && comment.id.endsWith('-added')
  }

  public isEditing(comment: ActionPointCommentDTO): boolean {
    return comment.id && comment.id == this.editingId
  }

  public onAdd(comment: ActionPointCommentDTO): void {
    if (!comment.comment?.length) {
      return
    }

    comment.id = comment.id.replace('-added', '')

    const newComment: ActionPointCommentDTO = JSON.parse(JSON.stringify(comment))
    this.comments.unshift(newComment)
    this.commentsChange.next(this.comments)
    this.add()
  }

  public onEdit(comment: ActionPointCommentDTO): void {
    this.editingId = undefined
    comment.updatedAt = new Date()
    const commentIndex = this.findCommentIdIndex(comment.id)
    this.comments[commentIndex].comment = comment.comment
    this.comments[commentIndex].updatedAt = comment.updatedAt
    this.reorderComments()
    this.commentsChange.next(this.comments)
  }

  public onDelete(comment: ActionPointCommentDTO, skipValidation = false): void {
    const onDeleteConfirmation = () => {
      this.delete(comment?.id)
    }

    if (skipValidation) {
      onDeleteConfirmation()
      return
    }

    this.dialogService.confirm(
      {
        action: 'delete',
        message: 'shared.actionPointsManager.comments.message.confirmDelete',
      },
      onDeleteConfirmation
    )
  }

  private refresh() {
    this._comments = JSON.parse(JSON.stringify(this.comments))
    if (this.userInfo) {
      this.add()
    }
  }

  private delete(commentId: string) {
    const commentIndex = this.findCommentIdIndex(commentId)
    this.comments.splice(commentIndex, 1)
    this.commentsChange.next(this.comments)

    const componentCommentIndex = this.findComponentCommentIdIndex(commentId)
    this._comments.splice(componentCommentIndex, 1)
  }

  private findCommentIdIndex(id: string): number {
    return this.comments.findIndex((c) => c.id == id)
  }

  private findComponentCommentIdIndex(id: string): number {
    return this._comments.findIndex((c) => c.id == id)
  }

  private reorderComments() {
    this.comments = this.comments.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    this.refresh()
  }

  private getDateLabel(date: string | Date) {
    return new Date(date).getTime() + 30000 > new Date().getTime() ? 'Now' : this.dataPipe.transform(date, 'YYYY-MM-dd - HH:mm')
  }
}
