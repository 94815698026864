<div class="vista-dialog-header">
  <h1 class="vista-dialog-title">{{ data.title || 'features.monitor.question.signatureDialog.title' | translate }}</h1>
  <button mat-icon-button color="primary" mat-dialog-close>
    <i-feather name="x-circle"></i-feather>
  </button>
</div>
<div class="vista-dialog-content" mat-dialog-content>
  <form #f="ngForm" class="fields-container">
    <mat-form-field class="name font-x-small">
      <mat-label>{{ 'features.monitor.question.signatureDialog.name' | translate }}</mat-label>
      <input name="signature-name" matInput [(ngModel)]="name" [required]="true" [disabled]="disabled" />
    </mat-form-field>
    <vista-select-search
      class="organization font-x-small"
      label="features.monitor.question.signatureDialog.organization"
      name="signature-organization"
      [(ngModel)]="organization"
      [data]="organizationOptions"
      [showSearch]="false"
      [required]="true"
      [disabled]="disabled"
    ></vista-select-search>
  </form>
  <div>
    <vista-canvas
      #canvas
      *ngIf="!selected"
      [ngClass]="{ error: signatureError }"
      [resetButton]="false"
      [height]="data.height"
      [width]="data.width"
      [disabled]="!!data.selected || disabled"
      (change)="onSignatureChange($event)"
    ></vista-canvas>
    <div
      *ngIf="selected"
      class="selected-image"
      [style]="{
        'background-image': 'url(' + selected.url + ')',
        height: data.height + 'px',
        width: data.width + 'px'
      }"
    ></div>
  </div>
</div>
<div mat-dialog-actions class="vista-dialog-actions">
  <button mat-raised-button color="accent" (click)="onClearSignature()" [disabled]="disabled || !finalSignatureImage">
    {{ 'shared.buttons.clear' | translate | uppercase }}
  </button>
  <button class="back-button" mat-raised-button (click)="onClose()">{{ 'shared.buttons.close' | translate | uppercase }}</button>
  <button mat-raised-button color="primary" (click)="onSave(f)" [disabled]="disabled" cdkFocusInitial>
    {{ 'shared.buttons.save' | translate | uppercase }}
  </button>
</div>
