import { IsNotEmpty, IsOptional } from 'class-validator'
import { ArrayParse } from '../decorators'
import { FeatureMediaDTO } from '../geo/media.dto'
import { KeyListDTO } from '../key/key.dto'
import { Scope } from '../layer'
import { PanoramaImageStatus } from '../panorama'
import { ProjectDTO } from '../project/project.dto'
import { LevelLayerType } from '../site/site.dto'

interface LatLng {
  lat: number
  lng: number
}

export class FeatureDTO {
  id: string
  polygon?: LatLng[]
  multiPolygon?: LatLng[][]
  key: KeyListDTO
  projects: ProjectDTO[]
  documents?: FeatureMediaDTO[]
  medias?: FeatureMediaDTO[]
  notes: string
  scope?: Scope
  area?: number
  perimeter?: number
  activityPeriods?: string[]
  supplier?: string
  length?: number
  symbology?: string
  panoramaFileName?: string
  panoramaStatus?: PanoramaImageStatus
  nameFullMatch?: boolean
  labelFullMatch?: boolean
  keySearchFullMatch?: boolean
  polygonCentroid?: LatLng
  originalId?: string
  metadata?: FeatureMetadata
}

export class PanoramaFeatureDTO {
  id: string
  status: PanoramaImageStatus
  filename: string
  latitude: number
  longitude: number
  canManage: boolean
}

export class LevelFacilityDTO {
  isOverview?: boolean
  levelId?: string
  siteId?: string
  levelName?: string
  facilities: Array<FeatureDTO | PanoramaFeatureDTO>
  layerType: LevelLayerType
  layerName?: string
}

export class SiteEventFacilities {
  [siteEventPair: string]: LevelFacilityDTO[]
}

export class FacilityData {
  id?: string
  key: KeyListDTO
  geometry: LatLng[]
  multiGeometry?: LatLng[][]
  notes?: string
  createdBy?: string
  updatedBy?: string
  featureMetadata?: FeatureMetadata
}

export class FeatureBbox {
  southWest: LatLng
  northEast: LatLng
}

export class FeatureMetadata {
  styling?: object
  metadata?: object
  notes?: string
  subEventId?: string
  templateId?: string
  contractRules?: object
  bbox?: FeatureBbox
  rotationRadians?: number
}
export class FacilityDTO {
  keySearch: string
  multiGeometry?: LatLng[][]
  notes?: string
  createdBy?: string
  updatedBy?: string

  featureMetadata?: FeatureMetadata
}

export class FeatureDuplicateDTO {
  // required
  @IsNotEmpty()
  fromLayerName: string

  @IsNotEmpty()
  @ArrayParse()
  toLayerNames: string[]

  @IsNotEmpty()
  venueId: string

  @IsNotEmpty()
  layerTypeShortName: string

  @IsOptional()
  levelId: string

  @IsOptional()
  siteId: string

  @IsOptional()
  @ArrayParse()
  ids?: string[]

  @IsOptional()
  @ArrayParse()
  subEventIds?: string[]

  @IsOptional()
  @ArrayParse()
  templateIds?: string[]

  // Overrides
  @IsOptional()
  @ArrayParse()
  overrideSubEventIds?: string[]

  @IsOptional()
  @ArrayParse()
  overrideTemplateIds?: string[]
}

export class FeatureCreateDTO {
  @IsOptional()
  levelId: string

  @IsOptional()
  siteId: string

  @IsNotEmpty()
  venueId: string

  @IsNotEmpty()
  layerTypeShortName: string

  @IsOptional()
  features: FacilityData[]
}
