import { Directive, forwardRef } from '@angular/core'
import { AbstractControl, FormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms'
import { RegExpUtils } from '@uefa-shared/utils'
import { SiteVisitUserUpdateDTO } from '@uefa-svr/contracts'

@Directive({
  selector: '[svrPeople]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PeopleValidatorDirective),
      multi: true,
    },
  ],
})
export class PeopleValidatorDirective implements Validator {
  validator: ValidatorFn

  constructor() {
    this.validate = this.isValid()
  }

  validate(c: FormControl) {
    return this.validate(c)
  }

  isValid(): ValidatorFn {
    return (c: AbstractControl) => {
      if (!c.value || c.value.length === 0) {
        return null
      }
      const invalid = c.value
        .map((person: SiteVisitUserUpdateDTO, index: number) => {
          const isInvalidEmail = !person.email || !RegExpUtils.getEmailRegExp().test(person.email)
          if (!person.name || isInvalidEmail || !person.role) {
            return index
          }
          return null
        })
        .filter((v: null | number) => v !== null)

      if (invalid.length > 0) {
        return { svrPeople: invalid }
      } else {
        return null
      }
    }
  }
}
