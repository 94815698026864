import { IsArray, IsEnum, IsOptional } from 'class-validator'
import { ArrayParse, StringTrim } from '../decorators'
import { PaginatedSearchDTO } from '../shared/paginated-search.dto'

export enum KeyListFormat {
  PLAIN = 'plain',
  TREE = 'tree',
}

export class KeySearchDTO {
  @IsOptional()
  @StringTrim()
  siteTypeId?: string

  @IsOptional()
  @StringTrim()
  eventId?: string

  @IsOptional()
  @StringTrim()
  eventCycleId?: string

  @IsOptional()
  @StringTrim()
  eventIntegrationId?: string

  @IsOptional()
  @StringTrim()
  siteId?: string

  @IsOptional()
  @IsEnum(KeyListFormat)
  format?: string = KeyListFormat.PLAIN
}

export class KeySearchPaginatedDTO extends PaginatedSearchDTO {
  @IsOptional()
  @StringTrim()
  siteTypeId?: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIntegrationIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  siteIds?: string[]

  @IsOptional()
  @StringTrim()
  parentId?: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  competitionIds?: string[]
}
