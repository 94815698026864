<div class="media-gallery">
  <div class="panel-header">
    <div class="title-container">
      <div class="title">
        <div *ngIf="!isEditing" class="text-truncate" [matTooltip]="selectedMedia?.name">
          {{ selectedMedia?.name }}
        </div>
        <div *ngIf="isEditing" class="full-width">
          <mat-form-field class="media-list-name-field full-width">
            <mat-label>{{ 'features.monitor.gallery.name' | translate }}</mat-label>
            <input [id]="editFieldId" name="media-name" matInput [(ngModel)]="editingMedia.name" required />
          </mat-form-field>
        </div>
        <button
          *ngIf="data.allowEdit && !isEditing"
          mat-icon-button
          (click)="onStartEdit()"
          [disabled]="isUploading(selectedMedia?.id) || !selectedMedia?.uploadSuccess"
        >
          <i-feather
            class="icon"
            type="button"
            [ngClass]="{ disabled: isUploading(selectedMedia?.id) || !selectedMedia?.uploadSuccess }"
            name="edit-2"
            matTooltip="{{ 'features.monitor.gallery.edit' | translate }}"
            [matTooltipDisabled]="isUploading(selectedMedia?.id)"
          ></i-feather>
        </button>
        <button *ngIf="data.allowEdit && isEditing" mat-icon-button (click)="onSave()" [disabled]="isUploading(selectedMedia?.id)">
          <i-feather
            class="icon"
            type="button"
            [ngClass]="{ disabled: isUploading(selectedMedia?.id) }"
            name="save"
            matTooltip="{{ 'features.monitor.gallery.save' | translate }}"
            [matTooltipDisabled]="isUploading(selectedMedia?.id)"
          ></i-feather>
        </button>
      </div>
      <div class="subtitle" *ngIf="showSubtitle">
        {{ selectedMedia?.subTitle }}
      </div>
    </div>
    <div class="flex-grow"></div>
    <div class="metadata" (click)="onFinishEdit()">
      <i-feather class="icon" name="calendar" matTooltip="{{ 'features.monitor.gallery.uploadedAt' | translate }}"></i-feather>
      <span>{{ selectedMedia?.uploadedAt | date: 'yyyy-MM-dd - HH:mm' }}</span>
    </div>
    <div class="metadata" *ngIf="selectedMedia?.uploadedBy">
      <i-feather class="icon" name="user" matTooltip="{{ 'features.monitor.gallery.uploadedBy' | translate }}"></i-feather>
      <span>{{ selectedMedia?.uploadedBy }}</span>
    </div>
    <button mat-icon-button color="primary" (click)="gallery.downloadImage()" [disabled]="!selectedGalleryImage || data.downloadDisabled">
      <i-feather
        class="icon"
        name="download"
        [matTooltipDisabled]="!selectedGalleryImage || data.downloadDisabled"
        matTooltip="{{ 'features.monitor.gallery.download' | translate }}"
      ></i-feather>
    </button>
    <button mat-icon-button color="primary" (click)="onClose()">
      <i-feather class="icon" name="x-circle" matTooltip="{{ 'features.monitor.gallery.close' | translate }}"></i-feather>
    </button>
  </div>
  <div class="panel-body" (click)="onFinishEdit()">
    <vista-image-gallery
      #gallery
      [carrouselExpanded]="data?.enterInExpandedMode"
      [images]="records?.data"
      [downloadDisabled]="data.downloadDisabled"
      [(selected)]="selectedGalleryImage"
      (selectedChange)="isEditing = false"
      (scrollEnding)="onScrollEnding()"
      (rotate)="onRotate($event)"
      (deleteMedia)="deleteMedia($event)"
      (onOrderChange)="onOrderChange($event)"
    ></vista-image-gallery>
  </div>
</div>
