<svr-media-list
  [medias]="signatures"
  [disabled]="disabled"
  (mediasChange)="onSignaturesChange($event)"
  (mediaClick)="onSignatureClick($event)"
  (addMediaClick)="openSignatureDialog()"
  (focusChange)="onFocus()"
  (blurChange)="onBlur()"
  [disabled]="disabled"
  [options]="mediaListOptions"
></svr-media-list>
