import { IsArray, IsBoolean, IsOptional, IsUUID } from 'class-validator'
import { ArrayParse, BooleanParse } from '../decorators'
import { PaginatedSearchDTO } from '../shared/paginated-search.dto'

export class ProjectSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIntegrationIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @IsUUID()
  eventId?: string

  @IsOptional()
  @IsUUID()
  venueId?: string

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  allEvents?: boolean = false
}
