import { CdkTextareaAutosize } from '@angular/cdk/text-field'
import { Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { TranslateService } from '@ngx-translate/core'
import { LabelValueDTO, PaginatedResults, PaginatedSearchDTO } from '@uefa-shared/contracts'
import { MediaField, SelectOption, SelectSearchComponent, ToastService } from '@uefa-shared/frontend'
import {
  ActionPointCommentDTO,
  ActionPointCreateDTO,
  ActionPointDTO,
  ActionPointExportSearchDTO,
  ActionPointLocationLevel,
  ActionPointPriority,
  ActionPointStand,
  ActionPointStatus,
  ActionPointType,
  ActionPointUtils,
  ExportFormat,
  MediaThumbnailSize,
  MonitorQuestionSearchDTO,
  MonitorSectionSearchDTO,
  SVRMediaDTO,
  SVRProjectSearchDTO,
  SVRSiteLevelSearchDTO,
  SiteVisitDTO,
  SiteVisitType,
  mimeTypes,
} from '@uefa-svr/contracts'
import { FeatureDTO, SiteDTO } from '@uefa-vista/contracts'
import { VistaMapMarker } from '@uefa-vista/map'
import * as moment from 'moment'
import { Observable, Subject, Subscription, of } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators'
import { environment } from '../../../../environments/environment'
import {
  ActionPointEventAction,
  ActionPointManageService,
  ActionPointsService,
  ConfigsService,
  DictionaryService,
  EditEntity,
  EditStateService,
  IconsService,
  MapService,
  MediaQueueService,
  MediaToUpload,
  MonitorService,
  SiteVisitsService,
  VistaService,
} from '../../services'
import { FileUtils } from '../../utils/file.utils'
import { LatLng, SVRMapMarker } from '../map'
import { SharedActionPointStatusDialogComponent } from './status-dialog/action-point-dialog.component'

@Component({
  selector: 'svr-shared-action-points-manager',
  templateUrl: './action-points-manager.component.html',
  styleUrls: ['./action-points-manager.component.scss'],
})
export class SharedActionPointsManagerComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(NgForm, { static: false }) set setNgForm(form: NgForm) {
    this.ngForm = form
    this.setForm(form)
  }
  @ViewChild('autosize') autosize: CdkTextareaAutosize
  @ViewChild('sectionSelect') sectionSelect: SelectSearchComponent<string>
  @ViewChild('questionSelect') questionSelect: SelectSearchComponent<string>
  @ViewChild('levelSelect') levelSelect: SelectSearchComponent<string>
  @ViewChild('keySelect') keySelect: SelectSearchComponent<string>

  @Input() actionPointId: string
  @Input() questionId: string
  @Input() sectionId: string
  @Input() eventId: string
  @Input() venueId: string
  @Input() siteId: string
  @Input() facilityId: string
  @Input() siteVisitId: string
  @Input() set type(type: ActionPointType) {
    if (!this.actionPointId) this._type = type
  }
  @Input() workingVisit: LabelValueDTO
  @Input() closeMapButton = false

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter<void>()
  @Output() saved = new EventEmitter<ActionPointDTO>()
  @Output() actionPointChange = new EventEmitter<ActionPointDTO>()
  @Output() addActionPointLocation = new EventEmitter<SVRMapMarker>()

  public assetsUrl = environment.assetsUrl

  public original: ActionPointDTO
  public data: ActionPointCreateDTO = new ActionPointCreateDTO()
  public actionsLocked = false
  public siteVisit: SiteVisitDTO
  public site: SiteDTO

  public documents: MediaField[] = []
  public medias: MediaField[] = []
  public signatures: MediaField[] = []

  private noFeedbackOptionId = 'no-feedback'
  public selectedDamageAccepted: SelectOption<string>
  public damageAcceptedOptions: SelectOption<string>[] = [
    { label: this.noFeedbackOptionId, value: this.noFeedbackOptionId },
    { label: 'false', value: 'false' },
    { label: 'true', value: 'true' },
  ]
  public statusOptions: SelectOption<string>[] = []
  public standOptions: SelectOption<string>[] = []
  public priorityOptions: SelectOption<string>[] = []
  public documentsMimeTypes: string[] = mimeTypes.DOCUMENTS
  public imagesMimeTypes: string[] = mimeTypes.IMAGES

  public shouldValidate = true

  public loading = false

  public canEdit = false
  public selectedTabIndex = 0

  private $ngUnsubscribe = new Subject<void>()
  private onReset = new Subject<void>()

  private mediaQueueSubscription: Subscription
  private currentMediaQueue: MediaToUpload[] = []
  private changesSubscription: Subscription
  private editCloseSubscription: Subscription

  private mediaChanged = false

  private mapMarker: SVRMapMarker

  private locationDebounce: number
  private isEdit = false
  private _type: ActionPointType

  protected ngForm: NgForm

  constructor(
    private readonly toastService: ToastService,
    private readonly monitorService: MonitorService,
    private readonly mapService: MapService,
    private readonly actionPointsService: ActionPointsService,
    private readonly actionPointManageService: ActionPointManageService,
    private readonly dictionaryService: DictionaryService,
    private readonly vistaService: VistaService,
    private readonly dialog: MatDialog,
    private readonly ngZone: NgZone,
    private readonly editStateService: EditStateService,
    private readonly configsService: ConfigsService,
    private readonly mediaQueue: MediaQueueService,
    private readonly translateService: TranslateService,
    private readonly siteVisitsService: SiteVisitsService,
    private readonly iconsService: IconsService
  ) {}

  public get title(): string {
    if (this.isInjuryReport) {
      return 'shared.actionPointsManager.newInjuryReport'
    }

    if (this.isDamageReport) {
      return 'shared.actionPointsManager.newDamageReport'
    }

    return 'shared.actionPointsManager.newActionPoint'
  }

  public get damageDateLabel(): string {
    if (this.isInjuryReport) {
      return 'shared.actionPointsManager.injuryDate'
    }
    return 'shared.actionPointsManager.damageDate'
  }

  public get damageCausedByLabel(): string {
    if (this.isInjuryReport) {
      return 'shared.actionPointsManager.injuryCausedBy'
    }
    return 'shared.actionPointsManager.damageCausedBy'
  }

  public get locationIconUrl(): string {
    if (!this.data.status || !this.data.priority) {
      return
    }
    const iconUrl = ActionPointUtils.getMarkerUrl(this.data.status, this.data.priority)
    return iconUrl ? `${environment.apibaseUrl}/${iconUrl}` : ''
  }

  public get removeLocationIconUrl(): string {
    const iconUrl = ActionPointUtils.getRemoveMarkerUrl()
    return iconUrl ? `${environment.apibaseUrl}/${iconUrl}` : ''
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const siteVisitChanged = changes.siteVisitId?.currentValue !== changes.siteVisitId?.previousValue
    const workingVisitChanged = changes.workingVisit?.currentValue?.value !== changes.workingVisit?.previousValue?.value
    const actionPointChanged = changes.actionPointId?.currentValue !== changes.actionPointId?.previousValue
    const siteChanged = changes.siteId?.currentValue !== changes.siteId?.previousValue

    if (siteVisitChanged) {
      this.getSiteVisit()
    }

    if (siteChanged) {
      this.getSite()
    }

    if (!this.siteId && !this.siteVisitId) {
      // force to close the map when there is no site or site visit
      this.mapService.closeMap()
    }

    if (workingVisitChanged && !this.isEdit && this.data) {
      this.data.workingVisitId = this.workingVisit.value
    }

    if (actionPointChanged) {
      this.onActionPointSelected()
    }
  }

  public ngOnInit(): void {
    this.setupEventListeners()
    this.setupStaticOptions()
    this.mediaQueueSubscription = this.mediaQueue.uploadSubject.subscribe((medias) => {
      this.currentMediaQueue = medias
      this.updateMediaUploads(medias)
    })

    this.onReset.pipe(takeUntil(this.$ngUnsubscribe), debounceTime(1)).subscribe(() => this.reset())
  }

  public ngOnDestroy(): void {
    this.$ngUnsubscribe.next()
    this.$ngUnsubscribe.complete()
    this.$ngUnsubscribe.unsubscribe()
    this.mediaQueueSubscription?.unsubscribe()
  }

  public get isDamageReport(): boolean {
    return this._type == ActionPointType.DAMAGE_REPORT
  }

  public get isInjuryReport(): boolean {
    return this._type == ActionPointType.INJURY_REPORT
  }

  public get altered(): boolean {
    return this.editStateService.isEdit(EditEntity.ACTION_POINT)
  }

  public get statusIcon(): string {
    return this.iconsService.getActionPointStatus(this.original?.status)
  }

  public get priorityIcon(): string {
    return this.iconsService.getActionPointPriority(this.original?.priority)
  }

  public get isDraft() {
    return this.data?.status === ActionPointStatus.DRAFT || false
  }

  public get mapOpen() {
    return this.mapService.mapOpen
  }

  public get isSiteAvailable(): boolean {
    return !!this.siteId || !!this.siteVisit?.site
  }

  public isSaveDisabled(): boolean {
    if (!this.canEdit || this.actionsLocked) {
      return true
    }

    if (!this.altered && !this.ngForm?.dirty) {
      return true
    }

    return false
  }

  public triggerResize(): void {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(takeUntil(this.$ngUnsubscribe), take(1)).subscribe(() => this.autosize.resizeToFitContent(true))
  }

  public onDamageAcceptedChange(selected: SelectOption<string> | SelectOption<string>[], form: NgForm): void {
    this.selectedDamageAccepted = selected as SelectOption<string>
    form?.control?.markAsDirty()
  }

  public onInformedChange(selected: SelectOption<string> | SelectOption<string>[]): void {
    this.data.informed = selected as SelectOption<string>[]
  }

  public onLabelValueChange(property: string, selected: SelectOption<string> | SelectOption<string>[]): void {
    this.data[property] = selected as SelectOption<string>
  }

  public onCloseMap(): void {
    this.mapService.toggleMap()
  }

  public onSectionChange(): void {
    if (!this.data) {
      return
    }
    this.data.workingVisitQuestion = null
    this.data.workingVisitQuestionId = null
    this.questionSelect.reloadDataAsync()
  }

  public async onLevelChange(levelId: string, fromContext = false, isNew = true, isLoad = false): Promise<void> {
    if (!this.data) {
      return
    }

    this.data.levelId = levelId
    if (this.data.location?.level) {
      this.data.location.level.id = levelId
    }
    const isOverview = levelId === 'OV'

    // If the level changed and there is a map marker, handle marker change event
    // This triggers any required form updates due to the marker having changed in the building level
    if (this.mapMarker && !isNew) {
      await this.onMapMarkerChange(this.mapMarker, isLoad)
    }

    if (fromContext) {
      return
    }

    if (isOverview) {
      this.mapService.updateMapContext({ overviewToggled: true })
    } else {
      this.mapService.updateMapContext({ levelId })
    }
  }

  public onRemoveLocation(): void {
    if (this.data) {
      this.data.location.level = null
      this.data.location.latitude = null
      this.data.location.longitude = null
      this.data.key = null
      this.data.keyId = null
    }

    this.ngForm?.control.markAsDirty()
    this.editStateService.edit(EditEntity.ACTION_POINT)
    this.mapMarker = null
    this.mapService.updateMarkers([])
  }

  public onAddLocation(): void {
    if (!this.data) {
      return
    }

    this.ngForm?.control.markAsDirty()
    this.editStateService.edit(EditEntity.ACTION_POINT)
    const levelId = this.mapService.currentOverviewToggle ? 'OV' : this.mapService.currentLevelId
    this.onLevelChange(levelId, false, false)
    this.data.location.level = new ActionPointLocationLevel(levelId)
    this.updateLocationMarker()
  }

  public onSubmit(): void {
    for (const ctrl in this.ngForm.controls) {
      this.ngForm.controls[ctrl].markAsTouched()
      this.ngForm.controls[ctrl].updateValueAndValidity()
    }
  }

  public async onClose(): Promise<void> {
    if (!(await this.editStateService.editGuard(EditEntity.ACTION_POINT).toPromise())) {
      return
    }
    this.close.emit()
  }

  public onSave(): void {
    this.ngForm.onSubmit(undefined)

    if (
      this.siteVisit?.type === SiteVisitType.HANDOVER_HANDBACK &&
      this.data.damageDate &&
      moment(this.data.damageDate).isAfter(moment.now(), 'day')
    ) {
      this.ngForm.controls['damageDate'].setErrors({ incorrect: true })
    }

    if (!this.ngForm.valid) {
      this.toastService.error('shared.actionPointsManager.invalidFormMessage', 'shared.message.invalidFormTitle')
      return
    }

    if (!this.isDraft) {
      this.save()
      return
    }

    const isValid = ActionPointUtils.validateActionPoint(this.data, this.siteVisit?.type)
    if (isValid) {
      this.changeStatusTodo()
    } else {
      this.save()
    }
  }

  public onPDF(): void {
    const exportSearch: ActionPointExportSearchDTO = {
      siteVisitId: this.siteVisit?.id,
      ids: [this.original.id],
      allEvents: true,
      format: ExportFormat.PDF,
    }
    this.actionPointsService.generateActionPointExport(exportSearch).subscribe((file) => {
      FileUtils.downloadFile(file.data.data, file.name)
    })
  }

  public onMediasChanged(): void {
    this.mediaChanged = true
    this.editStateService.edit(EditEntity.ACTION_POINT)
  }

  public onCommentsChange(): void {
    this.onLastCommentChange(this.data.comments)
    this.editStateService.edit(EditEntity.ACTION_POINT)
  }

  public isFieldRequired(name: keyof ActionPointCreateDTO) {
    if (ActionPointUtils.invalidStatus.includes(this.data?.status)) {
      return false
    }
    const fields = ActionPointUtils.getRequiredFieldsBySiteVisitType(this.data, this.siteVisit?.type)
    return fields.includes(name)
  }

  public onStatusChange(): void {
    this.editStateService.edit(EditEntity.ACTION_POINT)
    this.updateMarkerImage()
  }

  public questionOptionsAsync = (searchTerm: string, records: number, page: number): Observable<PaginatedResults<SelectOption<string>>> => {
    if (!this.siteVisit) {
      return of(new PaginatedResults())
    }
    const searchOptions: MonitorQuestionSearchDTO = {
      pageSize: records,
      searchTerm,
      currentPage: page,
      sectionIds: this.data.sectionId ? [this.data.sectionId] : [],
      siteVisitIds: [this.siteVisit.id],
      workingVisitIds: this.workingVisit ? [this.workingVisit.value] : [],
      facilityIds: this.facilityId ? [this.facilityId] : [],
    }
    return this.monitorService.getQuestionsPaginated(searchOptions).pipe(
      takeUntil(this.$ngUnsubscribe),
      map((res) => ({
        ...res,
        data: res.data.map((d) => new LabelValueDTO(d.id, d.title)),
      }))
    )
  }

  public sectionOptionsAsync = (searchTerm: string, records: number, page: number): Observable<PaginatedResults<SelectOption<string>>> => {
    if (!this.siteVisit) {
      return of(new PaginatedResults())
    }
    const options: MonitorSectionSearchDTO = {
      searchTerm: searchTerm,
      currentPage: page,
      pageSize: records,
      siteVisitIds: [this.siteVisit.id],
      workingVisitIds: this.workingVisit ? [this.workingVisit.value] : [],
    }
    return this.monitorService.getSectionsPaginated(options).pipe(
      takeUntil(this.$ngUnsubscribe),
      map((results) => {
        const data: SelectOption<string>[] = results.data.map((d) => ({ label: d.name, value: d.id }))
        return {
          ...results,
          data,
        }
      })
    )
  }

  public projectOptionsAsync = (searchTerm: string, records: number, page: number): Observable<PaginatedResults<SelectOption<string>>> => {
    const options: SVRProjectSearchDTO = {
      searchTerm: searchTerm,
      currentPage: page,
      pageSize: records,
      myProjects: false,
      eventIds: this.eventId ? [this.eventId] : [],
    }
    return this.dictionaryService.getProjectsPaginated(options)
  }

  public localCurrencyOptionsAsync = (
    searchTerm: string,
    records: number,
    page: number
  ): Observable<PaginatedResults<SelectOption<string>>> => {
    const options: PaginatedSearchDTO = {
      searchTerm: searchTerm,
      currentPage: page,
      pageSize: records,
    }
    return this.dictionaryService.getCurenciesPaginated(options)
  }

  public levelOptionsAsync = (searchTerm: string, records: number, page: number): Observable<PaginatedResults<SelectOption<string>>> => {
    const siteId = this.siteVisit?.site.value ?? this.siteId
    if (!siteId) {
      return of(new PaginatedResults())
    }

    const options: SVRSiteLevelSearchDTO = {
      searchTerm: searchTerm,
      currentPage: page,
      pageSize: records,
      siteId,
    }
    return this.dictionaryService.getLevelsPaginated(options).pipe(
      takeUntil(this.$ngUnsubscribe),
      map((results) => {
        let data = results.data
        const siteVisitAllowsOverview = this.siteVisit?.includeOverview ?? false

        if (siteVisitAllowsOverview && this.mapService?.mapComponent?.overviewLayers?.length) {
          data = [{ label: 'OV', value: 'OV' }].concat(data)
        }

        return {
          ...results,
          data,
        }
      })
    )
  }

  private save(): void {
    if (!this.data) {
      return
    }

    if (this.siteVisit?.type === SiteVisitType.HANDOVER_HANDBACK && this.selectedDamageAccepted) {
      this.data.damageAccepted =
        this.selectedDamageAccepted.value === this.noFeedbackOptionId ? null : this.selectedDamageAccepted.value === 'true'
    }

    this.data.images = this.mapMediaFieldImageToMediaDTO(this.medias)
    this.data.documents = this.mapMediaFieldImageToMediaDTO(this.documents)
    this.data.signatures = this.mapMediaFieldImageToMediaDTO(this.signatures)
    this.data.isInjuryReport = this.isInjuryReport

    for (const comment of this.data.comments) {
      if (comment.id?.includes('new-')) {
        comment.id = undefined
      }
    }

    this.actionsLocked = true
    let request: Observable<ActionPointDTO>
    if (this.original) {
      request = this.actionPointsService.update(this.original.id, this.data)
    } else {
      request = this.actionPointsService.create(this.data as ActionPointCreateDTO)
    }

    request
      .pipe(
        takeUntil(this.$ngUnsubscribe),
        tap((result) => {
          if (this.mediaChanged) {
            const images = this.medias
            const documents = this.documents
            const signatures = this.signatures
            // delay sending media to the media queue to avoid
            // async visual issues between the queue and loading the AP
            // causing incorrect flagging a media as a failed upload
            setTimeout(() => {
              this.uploadMedias(result, images, documents, signatures)
            }, 500)
          }
          this.actionPointChange.emit(result)
          if (this.isEdit) {
            this.actionPointManageService.actionPointChanged(result, this.original)
          } else {
            this.actionPointManageService.actionPointCreated(result)
          }
        })
      )
      .subscribe(
        (result) => {
          this.saved.emit(result)
          this.actionPointId = result.id
          this.onActionPointSelected()
        },
        () => (this.actionsLocked = false),
        () => (this.actionsLocked = false)
      )
  }

  private changeStatusTodo(): void {
    const apName = this.translateService.instant(`shared.actionPointsManager.draftDialog.${this._type}`)
    this.dialog
      .open<SharedActionPointStatusDialogComponent, { apName: string }, { status: ActionPointStatus }>(
        SharedActionPointStatusDialogComponent,
        {
          data: { apName },
          disableClose: false,
          panelClass: 'action-point-status-dialog',
        }
      )
      .afterClosed()
      .subscribe((res) => {
        if (!res) {
          return
        }

        this.data.status = res.status
        this.save()
      })
  }

  private updateMediaUploads(uploadingMedias: MediaToUpload[]): void {
    this.medias.forEach((m) => {
      m.isLoading = !!uploadingMedias.find((um) => um.id === m.id)
    })
    this.documents.forEach((m) => {
      m.isLoading = !!uploadingMedias.find((um) => um.id === m.id)
    })
  }

  private reset(clearOriginal = true) {
    this.removeActionPointMarker()
    this.ngForm?.resetForm(this.getCreateDTO())
    this.changesSubscription?.unsubscribe()
    this.changesSubscription = null
    this.editCloseSubscription?.unsubscribe()
    this.editCloseSubscription = null
    this.editStateService.discard(EditEntity.ACTION_POINT)
    this.data = new ActionPointCreateDTO()
    this.mediaChanged = false
    this.actionsLocked = false
    this.medias = []
    this.documents = []
    this.signatures = []

    if (clearOriginal) {
      this.original = null
    }
  }

  private onActionPointSelected(): void {
    this.loadActionPoint(this.actionPointId)
  }

  private create(): void {
    this.isEdit = false
    this.canEdit = true

    this.data = this.getCreateDTO()

    this.getQuestionAndChangeTitle()

    this.selectedDamageAccepted = this.damageAcceptedOptions.find((o) => o.value === this.noFeedbackOptionId)
  }

  private getCreateDTO(): ActionPointCreateDTO {
    const configuration = this.configsService.findActionPointConfiguration({ default: true, siteVisitType: this.siteVisit?.type })
    const data = new ActionPointCreateDTO()

    data.levelId = this.mapService.mapComponent?.levelId
    data.workingVisitQuestionId = this.questionId
    data.sectionId = this.sectionId
    data.workingVisitId = this.workingVisit?.value
    data.priority = configuration?.priority ?? ActionPointPriority.LOW
    data.status = configuration?.status ?? ActionPointStatus.DRAFT

    data.siteId = this.siteId
    data.venueId = this.venueId
    data.eventIds = this.eventId ? [this.eventId] : []
    data.workingVisitId = this.workingVisit?.value

    data.isInjuryReport = this.isInjuryReport

    return data
  }

  private getQuestionAndChangeTitle(): void {
    if (!this.siteVisitId || !this.questionId || this.isEdit || !this.data) {
      return
    }

    this.monitorService.getQuestionsPaginated({ siteVisitIds: [this.siteVisitId], ids: [this.questionId] }).subscribe((res) => {
      const [question] = res.data
      this.data.title = question.title
      this.data.workingVisitQuestionId = question.id
      this.data.sectionId = question.sectionId
      this.data.workingVisitQuestion = new LabelValueDTO(question.id, question.title)
      this.data.section = new LabelValueDTO(question.sectionId, question.sectionName)
    })
  }

  private loadActionPoint(id?: string): void {
    this.editCloseSubscription = this.editStateService.$state
      .pipe(
        takeUntil(this.$ngUnsubscribe),
        map((set) => set.has(EditEntity.ACTION_POINT)),
        distinctUntilChanged(),
        filter((has) => !has)
      )
      .subscribe(() => this.onReset.next())

    if (!id) {
      this.reset()
      this.create()
      return
    }

    this.reset(false)

    this.loading = true
    this.actionPointsService.getById(id).subscribe((actionPoint) => {
      this.isEdit = true
      this.original = actionPoint
      this.canEdit = actionPoint.canEdit
      this.data = new ActionPointCreateDTO(actionPoint)

      this.selectedDamageAccepted = this.damageAcceptedOptions.find(
        (o) => o.value === ([true, false].includes(this.data.damageAccepted) ? `${this.data.damageAccepted}` : this.noFeedbackOptionId)
      )

      this.eventId = actionPoint.events?.[0]?.value
      this.venueId = actionPoint.venue?.value
      this.siteId = actionPoint.site?.value
      this.siteVisitId = actionPoint.siteVisit?.value
      this._type = actionPoint.type


      this.onLevelChange(this.data.levelId, false, false, true)
      this.updateLocationMarker(false, true)
      this.loadImages()
      this.loadDocuments()
      this.loadSignatures()
      this.loading = false
    })
  }

  public async updateLocationMarker(isNew = true, isLoad = false): Promise<void> {
    this.removeActionPointMarker()
    if (!this.data?.location?.level?.id) {
      return
    }

    // this ensures that the marker does not go to 0,0 when the map hasn't been initialized yet
    if (!this.mapService.mapInitialized) {
      this.mapService.openMap()
      await new Promise((resolve) => setTimeout(resolve, 500))
    }

    const lat = this.data?.location?.latitude ?? this.mapService.currentMapCenter?.lat
    const lng = this.data?.location?.longitude ?? this.mapService.currentMapCenter?.lng
    if (lat === undefined || lng === undefined) {
      return
    }
    const latLng: LatLng = { lat, lng }

    this.mapMarker = await this.createLocationMarker(latLng, isNew, isLoad)
    this.updateMarkerImage()
  }

  public updateMarkerImage(): void {
    if (!this.data || !this.data.location?.level?.id || !this.data.status || !this.mapMarker) return

    this.mapMarker.imageUrl = this.locationIconUrl
    this.mapService.setMarker(this.mapMarker)
  }

  private setupEventListeners(): void {
    this.actionPointManageService.actionPointEvent.pipe(takeUntil(this.$ngUnsubscribe)).subscribe((event) => {
      if (event.action === ActionPointEventAction.SELECTED) {
        this.actionPointId = event.id
        this.onActionPointSelected()
      }
      if (event.action === ActionPointEventAction.CLOSED) {
        this.onReset.next()
        this.close.emit()
      }
    })

    this.mapService.mapContextChange.pipe(takeUntil(this.$ngUnsubscribe)).subscribe((context) => {
      if (!context || !this.data) return
      if (this.data?.levelId !== 'OV' && context.overviewToggled) this.onLevelChange('OV', true)
      if (!context.overviewToggled && this.data.levelId !== context.levelId) this.onLevelChange(context.levelId, true)
    })
  }

  private setupStaticOptions(): void {
    this.statusOptions = Object.keys(ActionPointStatus).map((p) => ({
      label: p,
      value: p,
    }))

    this.priorityOptions = Object.keys(ActionPointPriority).map((p) => ({
      label: p,
      value: p,
    }))

    this.standOptions = Object.keys(ActionPointStand).map((s) => ({
      label: s,
      value: s,
    }))
  }

  private loadImages(): void {
    this.medias = this.data?.images.map((i) => this.mapSVRMediaDTOtoMediaField(i)) || []
  }

  private loadDocuments(): void {
    this.documents = this.data?.documents.map((d) => this.mapSVRMediaDTOtoMediaField(d)) || []
  }

  private loadSignatures(): void {
    this.signatures = this.data?.signatures.map((s) => this.mapSVRMediaDTOtoMediaField(s)) || []
  }

  private uploadMedias(dto: ActionPointDTO, images: MediaField[], documents: MediaField[], signatures: MediaField[]): void {
    const savedMedias = [...dto.images, ...dto.documents, ...dto.signatures]
    const editedMedias = [...images, ...documents, ...signatures]

    const mediasToUpload: MediaToUpload[] = []

    for (const savedMedia of savedMedias) {
      const editedMedia = editedMedias.find((m) => m.mediaKey === savedMedia.mediaKey)

      if ((savedMedia.id !== editedMedia.id && (editedMedia.file || editedMedia.copyFromId)) || editedMedia.rotation) {
        const file = editedMedia.file
        mediasToUpload.push({ id: savedMedia.id, file, rotation: editedMedia.rotation, mediaKey: savedMedia.mediaKey })
      }
    }

    this.mediaQueue.addToQueue(mediasToUpload)
  }

  private mapSVRMediaDTOtoMediaField(dto: SVRMediaDTO): MediaField {
    const url = this.monitorService.getMediaFileUrl(dto.url)
    const date = Date.now()
    return {
      id: dto.id,
      url: `${url}?size=${MediaThumbnailSize.FULL}`,
      thumbnailUrl: `${url}?size=${MediaThumbnailSize.THUMB_100x100}&${date}`,
      downloadUrl: `${url}?size=${MediaThumbnailSize.FULL}`,
      name: dto.name,
      fileName: dto.fileName,
      uploadDate: dto.uploadDate,
      uploadBy: dto.uploadedBy,
      mimeType: dto.mimeType,
      isLoading: !!this.currentMediaQueue.find((m) => m.id === dto.id),
      uploadSuccess: dto.uploadSuccess,
      mediaKey: dto.mediaKey,
    }
  }

  private mapMediaFieldImageToMediaDTO(medias: MediaField[] = []): SVRMediaDTO[] {
    return medias.map(
      (m) =>
        ({
          id: m.isNewImage ? undefined : m.id,
          name: m.name,
          fileName: m.fileName,
          mimeType: m.mimeType,
          uploadDate: m.uploadDate,
          url: m.isNewImage ? undefined : m.url,
          order: m.order,
          mediaKey: m.mediaKey,
        } as SVRMediaDTO)
    )
  }

  private setForm(form: NgForm): void {
    if (!form) {
      this.changesSubscription?.unsubscribe()
      this.changesSubscription = null
      return
    }
    if (this.changesSubscription) {
      return
    }
    this.changesSubscription = this.ngForm.statusChanges
      .pipe(
        takeUntil(this.$ngUnsubscribe),
        map(() => this.ngForm.dirty),
        distinctUntilChanged(),
        filter((d) => d)
      )
      .subscribe(() => this.editStateService.edit(EditEntity.ACTION_POINT))
  }

  private async createLocationMarker(latLng: LatLng, isNew = true, isLoad = false): Promise<SVRMapMarker> {
    const { lat, lng } = latLng

    const mapMarker: SVRMapMarker = new SVRMapMarker()
    mapMarker.id = 'add-location-marker'
    mapMarker.label = this.translateService.instant('shared.actionPointsManager.pinTooltip')

    mapMarker.draggable = true
    mapMarker.lat = lat
    mapMarker.lng = lng

    mapMarker.onChange = async (marker: VistaMapMarker) => {
      clearTimeout(this.locationDebounce)
      this.locationDebounce = window.setTimeout(async () => await this.onMapMarkerChange(marker, isLoad), 500)
    }
    // On create a location marker trigger the marker change handler
    // This triggers any required form updates due to the marker having spawned on the map
    if (isNew) {
      await this.onMapMarkerChange(mapMarker, isLoad)
    }

    return mapMarker
  }

  private async onMapMarkerChange(marker: VistaMapMarker, isLoad = false): Promise<void> {
    if (!marker || marker?.lat === undefined || marker?.lng === undefined) {
      return
    }

    const facilities = await this.vistaService
      .findFacilities(this.eventId, this.siteVisit?.site.value, this.data?.location?.level?.id, [{ lat: marker.lat, lng: marker.lng }])
      .toPromise()

    if (!facilities?.length) {
      this.clearDataKey(isLoad)
      return
    }
    const facility: FeatureDTO = (facilities[0]?.facilities[0] as FeatureDTO) ?? null
    if (!facility) {
      this.clearDataKey(isLoad)
      return
    }
    this.data.location.latitude = marker.lat
    this.data.location.longitude = marker.lng

    this.data.key = {
      label: `${facility.key.name} - ${facility.key.label}`,
      value: facility.key.vistaKeyId,
    }

    this.data.keyId = facility.key.vistaKeyId

    if (!isLoad) {
      this.ngForm?.control?.markAsDirty()
      this.editStateService.edit(EditEntity.ACTION_POINT)
    }
  }

  private clearDataKey(isLoad = false): void {
    this.data.keyId = null
    this.data.key = null
    if (!isLoad) {
      this.ngForm?.control?.markAsDirty()
      this.editStateService.edit(EditEntity.ACTION_POINT)
    }
  }

  private removeActionPointMarker(): void {
    if (this.mapMarker) {
      this.mapService.updateMarkers([])
      this.mapMarker = null
    }
  }

  private getSiteVisit(): void {
    if (!this.siteVisitId) {
      return
    }
    this.siteVisitsService.getById(this.siteVisitId).subscribe((siteVisit) => {
      this.siteVisit = siteVisit
      this.reloadDropdowns()
    })
  }

  private getSite(): void {
    if (!this.siteId || !this.eventId) {
      return
    }
    this.vistaService.getMapSite(this.siteId, this.eventId).subscribe((site) => {
      this.site = site
      this.venueId = site.venueId
      this.data.venueId = site.venueId
      this.reloadDropdowns()
    })
  }

  private reloadDropdowns(): void {
    this.sectionSelect?.reloadDataAsync()
    this.questionSelect?.reloadDataAsync()
    this.keySelect?.reloadDataAsync()
    this.levelSelect?.reloadDataAsync()
  }

  private onLastCommentChange(comments: ActionPointCommentDTO[]): void {
    const [lastUpdatedComment] = comments.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    this.data.lastComment = lastUpdatedComment
  }

  public actionPointStatusIcon(status: ActionPointStatus): string {
    return this.iconsService.getActionPointStatus(status)
  }

  public actionPointsPriorityIcon(priority: ActionPointPriority): string {
    return this.iconsService.getActionPointPriority(priority)
  }
}
