import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PaginatedResults } from '@uefa-shared/contracts'
import { BaseService } from '@uefa-shared/frontend'
import { MainChangeCreateDTO, MainChangeDTO, MainChangeSearchDTO, MainChangeUpdateDTO } from '@uefa-svr/contracts'
import { Observable } from 'rxjs'
import { environment } from '../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class MainChangesService extends BaseService {
  constructor(http: HttpClient) {
    super(environment, 'main-change', http)
  }

  public getPaginated(options: MainChangeSearchDTO): Observable<PaginatedResults<MainChangeDTO>> {
    return this.getPaginatedResult<MainChangeDTO>(this.basePath, options)
  }

  public update(id: string, model: MainChangeUpdateDTO): Observable<MainChangeDTO> {
    return this.http.put<MainChangeDTO>(`${this.basePath}/${id}`, model)
  }

  public create(model: MainChangeCreateDTO): Observable<MainChangeDTO> {
    return this.http.post<MainChangeDTO>(this.basePath, model)
  }

  public delete(id: string): Observable<MainChangeDTO> {
    return this.http.delete<MainChangeDTO>(`${this.basePath}/${id}`)
  }
}
