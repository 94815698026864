import { ArrayParse, BooleanParse, PaginatedSearchDTO } from '@uefa-shared/contracts'
import { IsArray, IsBoolean, IsOptional, IsString } from 'class-validator'
import { SVRVistaListDTO } from './vista.dto'

export class SVRProjectListDTO extends SVRVistaListDTO {
  description?: string
}

export class SVRProjectSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsString()
  role?: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  keyIds?: string[]

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  myProjects?: boolean

  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @BooleanParse()
  withActionPoints?: boolean

  @IsOptional()
  @BooleanParse()
  actionPointProject?: boolean

  @IsOptional()
  @BooleanParse()
  actionPointsAssigned?: boolean

  @IsOptional()
  @BooleanParse()
  actionPointInformed?: boolean
}
