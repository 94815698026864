import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { InjectionToken, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { ComponentsModule, ModuleConfiguration, UserService } from '@uefa-shared/frontend'
import { VistaMapModule } from '@uefa-vista/map'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { vistaMapConfiguration } from './map-configuration'
import { SharedModule } from './shared'
import { AppHttpInterceptor } from './shared/http'
import { NavigationService, SVRUserService } from './shared/services'

export const SHARED_COMPONENTS_MODULE_CONFIG = new InjectionToken<ModuleConfiguration>('components.config')

export const componentsConfig: ModuleConfiguration = {
  environment: environment,
  menu: NavigationService.menuNavigation,
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient],
      },
    }),
    VistaMapModule.forRoot(vistaMapConfiguration),
    HttpClientModule,
    ComponentsModule.forRoot(componentsConfig),
  ],
  providers: [
    {
      provide: SHARED_COMPONENTS_MODULE_CONFIG,
      useValue: componentsConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
    { provide: UserService, useClass: SVRUserService },
  ],
  bootstrap: [AppComponent],
  exports: [SharedModule],
})
export class AppModule {}
