import { IsOptional, IsString } from 'class-validator'

export interface FileDTO {
  originalname?: string
  encoding?: string
  buffer: Buffer
  mimetype: string
  size?: number
  customname?: string
}

export class WorkFileDTO {
  id?: string
  name: string
  contentDisposition?: string
  file: FileDTO
}

export class CreatedFileDTO {
  fileName: string
}

export class MediaPatchDTO {
  @IsOptional()
  @IsString()
  name: string
}

export enum ImageDefaultSize {
  Full = 'full',
  Size48x48 = '48x48',
  Size100x100 = '100x100',
  Size200x200 = '200x200',
  Size340x340 = '340x340',
  Size500x300 = '500x300',
}
