import { Component, Inject, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { LabelValueDTO } from '@uefa-shared/contracts'
import { CanvasComponent, MediaField, ToastService } from '@uefa-shared/frontend'
import { SVROrganization, SVROrganizationUtils } from '@uefa-svr/contracts'

export interface SignatureDialogOptions {
  height?: number
  width?: number
  title?: string
  disabled?: boolean
  selected?: MediaField
}

export class SignatureDialogResult {
  constructor(public image?: HTMLImageElement, public name?: string, public organization?: SVROrganization) {}
}

@Component({
  selector: 'svr-signature-dialog',
  templateUrl: './signature-dialog.component.html',
  styleUrls: ['./signature-dialog.component.scss'],
})
export class SignatureDialogComponent {
  @ViewChild('canvas', { static: false }) canvas: CanvasComponent

  public name: string
  public organization: SVROrganization
  public disabled = false
  public organizationOptions: LabelValueDTO[] = []
  public finalSignatureImage: HTMLImageElement = null
  public signatureError = false
  public selected: MediaField = null

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: SignatureDialogOptions,
    private readonly dialogRef: MatDialogRef<SignatureDialogComponent, SignatureDialogResult>,
    private readonly toastService: ToastService
  ) {
    this.setupOrganizationOptions()
    this.selected = data.selected
    this.name = data.selected?.name
    this.disabled = data.disabled
    this.organization = data.selected?.organization
  }

  public onSignatureChange(image: HTMLImageElement) {
    this.finalSignatureImage = image
    this.signatureError = false
  }

  public onClose() {
    this.dialogRef.close()
  }

  public onSave(form: NgForm) {
    form.onSubmit(undefined)
    this.signatureError = !this.finalSignatureImage && !this.selected

    if (!form.valid || this.signatureError) {
      this.toastService.error('shared.message.invalidFormMessage', 'shared.message.invalidFormTitle')
      return
    }

    const newSignature = new SignatureDialogResult(this.finalSignatureImage, this.name, this.organization)
    this.dialogRef.close(newSignature)
  }

  public onClearSignature() {
    this.canvas?.reset()
  }

  private setupOrganizationOptions() {
    this.organizationOptions = Object.keys(SVROrganization).map((o) => ({
      label: SVROrganizationUtils.getOrganizationLabel(o as SVROrganization),
      value: o,
    }))
  }
}
