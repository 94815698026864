<div class="svr-angular-editor-text-area" [id]="id">
  <angular-editor
    [id]="id"
    [name]="id"
    placeholder="{{ 'shared.richTextEditor.writeYourAnswer' | translate }}"
    [(ngModel)]="answer"
    [config]="richTextConfig"
    (ngModelChange)="onChange()"
  ></angular-editor>
</div>
