import { IsArray, IsOptional } from 'class-validator'
import { ArrayParse } from '../decorators'
import { PaginatedSearchDTO } from '../shared/paginated-search.dto'

export class RoundSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  competitionIntegrationIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  competitionIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: Array<string>
}
