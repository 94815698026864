import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class RichTextEditorManagerService {
  private readonly blurSubject = new Subject<string>()
  private readonly focusSubject = new Subject<string>()

  public onFocus(fieldId: string) {
    this.focusSubject.next(fieldId)
  }
  
  public onFocusChange() {
    return this.focusSubject.asObservable()
  }
  
  public onBlur(fieldId: string) {
    this.blurSubject.next(fieldId)
  }

  public onBlurChange() {
    return this.blurSubject.asObservable()
  }
}
