import { LabelValueDTO } from '@uefa-shared/contracts'
import { ArrayParse } from '@uefa-vista/contracts'
import { IsArray, IsNotEmpty } from 'class-validator'

export class SiteVisitNotificationDTO {
  id: string
  siteVisitId: string
  projects: LabelValueDTO[]
  emails: LabelValueDTO[]
}

export class SiteVisitNotificationUpdateDTO {
  id?: string
  siteVisitId?: string

  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  projects: string[]

  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  emails: LabelValueDTO[]

  constructor(model?: SiteVisitNotificationDTO) {
    this.id = model?.id
    this.siteVisitId = model?.siteVisitId
    this.projects = model?.projects.map(p => p.value) ?? []
    this.emails = model?.emails ?? []
  }
}
