import { StringCleanup } from '@uefa-shared/contracts'
import { IsEnum, IsNotEmpty, IsOptional } from 'class-validator'
import { SVROrganization } from '../shared/enums'

export class SiteVisitUserDTO {
  id: string
  name: string
  email: string
  role: SVROrganization
  visitIds?: string[]
  freeText?: boolean
}

export class SiteVisitUserUpdateDTO {
  @IsOptional()
  id?: string

  @IsNotEmpty()
  @StringCleanup()
  name?: string

  @IsNotEmpty()
  @StringCleanup()
  email?: string

  @IsNotEmpty()
  @IsEnum(SVROrganization)
  role?: SVROrganization

  freeText?: boolean

  constructor(model?: SiteVisitUserDTO) {
    if (model) {
      this.id = model.id
      this.name = model.name
      this.email = model.email
      this.role = model.role
      this.freeText = model.freeText
    }
  }
}
