import { Directive, forwardRef, Input } from '@angular/core'
import { AbstractControl, FormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms'
import { SiteVisitType, WorkingVisitUpdateDTO } from '@uefa-svr/contracts'

@Directive({
  selector: '[svrWorkingVisits]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => WorkingVisitsValidatorDirective),
      multi: true,
    },
  ],
})
export class WorkingVisitsValidatorDirective implements Validator {
  @Input('siteVisitType') siteVisitType: SiteVisitType
  validator: ValidatorFn

  constructor() {
    this.validate = this.isValid()
  }

  validate(c: FormControl) {
    return this.validate(c)
  }

  isValid(): ValidatorFn {
    return (c: AbstractControl) => {
      if (!c.value || c.value.length === 0) {
        return { svrWorkingVisits: [] }
      }
      const invalid = c.value
        .map((v: WorkingVisitUpdateDTO, i: number) => {
          if (!v.status || !v.start || !v.end || !v.code || !v.description || !this.isHandoverHandbackValid(v)) {
            return i
          }
          return null
        })
        .filter((v: null | number) => v !== null)
      if (invalid.length > 0) {
        return { svrWorkingVisits: invalid }
      } else {
        return null
      }
    }
  }

  private isHandoverHandbackValid(value: WorkingVisitUpdateDTO) {
    if (this.siteVisitType !== SiteVisitType.HANDOVER_HANDBACK) {
      return true
    }
    return value.from?.length > 0 && value.to?.length > 0
  }
}
