import { IsBoolean, IsEnum, IsOptional } from 'class-validator'
import { BooleanParse } from '../decorators'
import { PaginatedSearchDTO } from '../shared/paginated-search.dto'

export enum TaskOwner {
  AM = 'AM',
  SVR = 'SVR',
  VISTA = 'VISTA',
}

export enum TaskStatus {
  READY = 'ready',
  RUNNING = 'running',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}

export class TaskListDTO {
  id: string
  name: string
  schedule: string
  lastSuccess?: Date
  lastRun?: Date
  status: TaskStatus
  owner: TaskOwner
}

export enum TaskSearchSortField {
  NAME = 'name',
  LAST_SUCCESS = 'lastSuccess',
  LAST_RUN = 'lastRun',
  STATUS = 'status',
  SCHEDULE = 'schedule',
}

export class TaskSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsEnum(TaskSearchSortField)
  sortBy?: string

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  showInManager?: boolean
}

export class TaskFindDTO {
  @IsOptional()
  @IsEnum(TaskOwner)
  owner?: TaskOwner
}
