import { Injectable } from '@angular/core'
import {
  ActionPointConfigurationDTO,
  BaseConfigurationDTO,
  QuestionConfigurationDTO,
  SiteVisitConfigurationDTO,
  SiteVisitType,
} from '@uefa-svr/contracts'
import { ActionPointsService, QuestionsService, SiteVisitsService } from './api'

@Injectable({ providedIn: 'root' })
export class ConfigsService {
  private _siteVisitConfigurations: SiteVisitConfigurationDTO[] = []
  private _questionConfigurations: QuestionConfigurationDTO[] = []
  private _actionPointConfigurations: ActionPointConfigurationDTO[] = []

  constructor(
    private readonly siteVisitsService: SiteVisitsService,
    private readonly questionsService: QuestionsService,
    private readonly actionPointsService: ActionPointsService
  ) {}

  public get siteVisitConfigurations() {
    return this._siteVisitConfigurations
  }

  public get questionConfigurations() {
    return this._questionConfigurations
  }

  public get actionPointConfigurations() {
    return this._actionPointConfigurations
  }

  public findAndSaveConfigs() {
    if (this.siteVisitConfigurations.length === 0) {
      this.siteVisitsService.getConfigurations().subscribe((configs) => {
        this._siteVisitConfigurations = configs
      })
    }

    if (this.questionConfigurations.length === 0) {
      this.questionsService.getConfigurations().subscribe((configs) => {
        this._questionConfigurations = configs
      })
    }

    if (this.actionPointConfigurations.length === 0) {
      this.actionPointsService.getConfigurations().subscribe((configs) => {
        this._actionPointConfigurations = configs
      })
    }
  }

  public findSiteVisitConfigurations(search: SearchConfigurationDTO): SiteVisitConfigurationDTO {
    return this.find(search, this.siteVisitConfigurations)
  }

  public findQuestionConfiguration(search: SearchConfigurationDTO): QuestionConfigurationDTO {
    return this.find(search, this.questionConfigurations)
  }

  public findActionPointConfiguration(search: SearchConfigurationDTO): ActionPointConfigurationDTO {
    return this.find(search, this.actionPointConfigurations)
  }

  public find(search: SearchConfigurationDTO, configurations: BaseConfigurationDTO[]) {
    let result = null

    if (search.default !== undefined) {
      result = configurations.find((c) => c.default === search.default)
    }

    if (search.siteVisitType !== undefined) {
      result = configurations.find((c) => c.siteVisitType === search.siteVisitType)
    }

    return result
  }
}

export class SearchConfigurationDTO {
  siteVisitType?: SiteVisitType
  default?: boolean
}
