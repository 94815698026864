import { LabelValueDTO } from '../shared'

export enum SiteVisitType {
  REGULAR = 'REGULAR',
  HANDOVER_HANDBACK = 'HANDOVER_HANDBACK',
  TECHNICAL_VISIT = 'TECHNICAL_VISIT',
}

export class SiteVisitDTO {
  id?: string
  start: Date
  end: Date
  code: string
  description: string
  siteVisit: LabelValueDTO
}
