<div class="vista-dialog-header">
  <h1 class="vista-dialog-title" mat-dialog-title>{{ 'shared.actionPointsTable.updateStatusResultDialog.title' | translate }}</h1>
  <button mat-icon-button color="primary" mat-dialog-close>
    <i-feather name="x-circle"></i-feather>
  </button>
</div>
<div mat-dialog-content class="vista-dialog-content body-dialog">
  <div class="panel-body" *ngIf="data">
    <div class="vista-dialog-content-description" *ngIf="!data.failed">
      {{ 'shared.actionPointsTable.updateStatusResultDialog.success' | translate: { total: data.total } }}
    </div>
    <div class="vista-dialog-content-description" *ngIf="data.failed">
      {{ 'shared.actionPointsTable.updateStatusResultDialog.failed' | translate: { success: data.success, failed: data.failed } }}
      <div>
        <ul class="failed-list">
          <li *ngFor="let item of data.actionPointsFailed">
            {{ item.label }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="vista-dialog-actions">
  <button color="primary" mat-raised-button mat-dialog-close>
    {{ 'shared.buttons.close' | translate | uppercase }}
  </button>
</div>
