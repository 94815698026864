import { IsNotEmpty, IsString } from 'class-validator'
import { BaseDTO } from '../shared'

export enum NotificationEntityEnum {
  EVENT = 'event',
  KEY = 'key',
  PROJECT = 'project',
  SITE = 'site',
  VENUE = 'venue',
}

export enum DeviceSystemEnum {
  IOS = 'ios',
  ANDROID = 'android',
  WINDOWS = 'windows',
  MACOS = 'macos',
  WEB = 'web',
}

export class NotificationEntityDTO extends BaseDTO {
  id: string
  name: string
  description: string
}

export class NotificationEntityListDTO extends NotificationEntityDTO {}

export class NotificationEntityCreateDTO {
  @IsNotEmpty()
  @IsString()
  name: string

  @IsNotEmpty()
  @IsString()
  description: string
}

export class NotificationEntityUpdateDTO extends NotificationEntityCreateDTO {}
