import { IsDateString, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator'
import { StringCleanup, StringTrim } from '../decorators'
import { VistaDTO } from '../shared/vista.dto'

export class DesignationDTO extends VistaDTO {
  upn?: string
  deployedFrom?: Date
  deployedTo?: Date
  subCategoryCode?: string
}

export class DesignationListDTO extends DesignationDTO {
  constructor(designation?: DesignationDTO) {
    super()
    this.upn = designation?.upn
    this.subCategoryCode = designation?.subCategoryCode
  }
}

export class DesignationUpdateDTO {
  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  integrationId: string

  @IsNumber()
  @IsNotEmpty()
  compOfficialId: number

  @IsNumber()
  @IsNotEmpty()
  idResourceBinding: number

  @IsNumber()
  @IsNotEmpty()
  foffActivityId: number

  @IsNumber()
  @IsNotEmpty()
  idResource: number

  @IsNumber()
  @IsNotEmpty()
  idResourceClass: number

  @IsNumber()
  @IsNotEmpty()
  officialSubCategory: number

  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  subCategoryCode: string

  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  subCategoryName: string

  @IsNumber()
  @IsNotEmpty()
  activityResourceId: number

  @IsNumber()
  @IsNotEmpty()
  activityResourceClassId: number

  @IsNumber()
  @IsNotEmpty()
  foffActivitySetupRoleId: number

  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  typeOfficialRoleName: string

  @IsDateString()
  @IsNotEmpty()
  dateDeployedFrom: Date

  @IsDateString()
  @IsNotEmpty()
  dateDeployedTo: Date

  @IsNumber()
  @IsNotEmpty()
  statusDesignation: number

  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  statusDesignationName: string

  @IsNumber()
  @IsNotEmpty()
  statusValidation: number

  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  statusValidationName: string

  @IsNumber()
  @IsNotEmpty()
  statusValidationBW: number

  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  activityName: string

  @StringTrim()
  @StringCleanup()
  @IsOptional()
  upn: string

  @IsString()
  @IsOptional()
  firstName: string

  @IsString()
  @IsOptional()
  lastName: string
}

export class DesignationCreateDTO extends DesignationUpdateDTO {}
