import { IsArray, IsEnum, IsInt, IsNumber, IsOptional } from 'class-validator'
import { ArrayParse, NumberParse, StringTrim } from '../decorators'

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

// using PrimeNG filter match modes
export type PaginatedSearchFilterMatchModeDTO = DateFilterMatchMode | TextFilterMatchMode | NumberFilterMatchMode

export enum PaginatedSearchFilterOperatorDTO {
  AND = 'and',
  OR = 'or',
}

export enum DateFilterMatchMode {
  DATE_IS = 'dateIs',
  DATE_IS_NOT = 'dateIsNot',
  DATE_BEFORE = 'dateBefore',
  DATE_AFTER = 'dateAfter',
}

export enum TextFilterMatchMode {
  STARTS_WITH = 'startsWith',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'notContains',
  ENDS_WITH = 'endsWith',
  EQUALS = 'equals',
  NOT_EQUALS = 'notEquals',
}

export enum NumberFilterMatchMode {
  EQUALS = 'equals',
  NOT_EQUALS = 'notEquals',
  LESS_THAN = 'lt',
  LESS_THAN_OR_EQUAL_TO = 'lte',
  GREATER_THAN = 'gt',
  GREATER_THAN_OR_EQUAL_TO = 'gte',
}

export class BaseSearchDTO {
  @StringTrim()
  @IsOptional()
  searchTerm?: string

  @StringTrim()
  @IsOptional()
  sortBy?: string

  @IsOptional()
  @IsEnum(SortDirection)
  sortDirection?: SortDirection = SortDirection.ASC

  @IsOptional()
  @ArrayParse()
  @IsArray()
  ids?: Array<string>

  @IsOptional()
  @ArrayParse()
  @IsArray()
  integrationIds?: Array<string>
}

export class CustomFilterDTO {
  customFilters: PaginatedSearchFilterDTO
}

export class PaginatedSearchFilterDTO {
  [s: string]: {
    value?: Date
    matchMode?: PaginatedSearchFilterMatchModeDTO
    operator?: PaginatedSearchFilterOperatorDTO
  }[]
}

export class PaginatedSearchDTO extends BaseSearchDTO {
  // Skip and Take are the search properties currently in use
  @IsOptional()
  @IsInt()
  @NumberParse()
  skip?: number = 0

  @IsOptional()
  @IsInt()
  @NumberParse()
  take?: number = 20

  // For retro-compatibility we keep these options
  @IsOptional()
  @IsNumber()
  @NumberParse()
  currentPage?: number

  @IsOptional()
  @IsNumber()
  @NumberParse()
  pageSize?: number

  @IsOptional()
  customFilters?: PaginatedSearchFilterDTO
}
