import { Component, Input } from '@angular/core'
import { MonitorShellComponent } from '../../../features/monitor/components'
import { MonitorShellService } from '../../services'

@Component({
  selector: 'svr-monitor-shell-filters',
  templateUrl: './monitor-shell-filters.component.html',
  styleUrls: ['./monitor-shell-filters.component.scss'],
})
export class MonitorShellFiltersComponent {
  @Input() keyFilter = true
  @Input() projectFilter = true
  @Input() facilityFilter = true

  public shell: MonitorShellComponent

  constructor(private readonly monitorShellService: MonitorShellService) {
    this.shell = this.monitorShellService.shellComponent
  }
}
