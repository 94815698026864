import { Exclude } from 'class-transformer'
import { UserGroupDTO } from '../user'

export class AuthenticatedUserDTO {
  email: string
  name: string
  initials: string
  timezone: string
  primaryEmail?: string

  @Exclude()
  groups?: UserGroupDTO[]
}
