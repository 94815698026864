import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { SVRUserService } from '../../services'

@Component({ selector: 'svr-redirect', templateUrl: './redirect.component.html' })
export class RedirectComponent {
  constructor(private userService: SVRUserService, private router: Router) {
    if (this.userService.userInfo?.isAdmin || this.userService.userInfo?.isManager || this.userService.userInfo?.isEditor) {
      this.router.navigate(['dashboard'])
    } else {
      this.router.navigate(['monitor'])
    }
  }
}
