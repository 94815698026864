import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, IsUUID, ValidateIf } from 'class-validator'

export enum LayerTypeSymbologyPointType {
  SYMBOL = 'symbol',
  IMAGE = 'image',
}

enum LayerTypeSymbologyLineType {
  SIMPLE = 'simple',
  DASHED = 'dashed',
  DOTTED = 'dotted',
  DASH_1 = 'dash_1',
  DASH_2 = 'dash_2',
  DASH_3 = 'dash_3',
  DASH_4 = 'dash_4',
  DASH_5 = 'dash_5',
  DASH_6 = 'dash_6',
  DASH_7 = 'dash_7',
  DASH_8 = 'dash_8',
  DASH_9 = 'dash_9',
  DASH_10 = 'dash_10',
  DASH_11 = 'dash_11',
  DASH_12 = 'dash_12',
  DASH_13 = 'dash_13',
  TWO_LINES = 'two_lines',
  DASH_DOT = 'dash_dot',
  DASH_DASH_DOT = 'dash_dash_dot',
  DASH_DOT_DOT = 'dash_dot_dot',
}

export class LayerTypeSymbologyPointDTO {
  id?: string
  strokeColor: string
  strokeType: LayerTypeSymbologyLineType
  strokeWidth: number
  strokeOpacity: number
  fillColor: string
  fillOpacity: number
  pointType: LayerTypeSymbologyPointType
  pointSymbolId?: string
  pointImage?: string
  pointSize: number
  pointColor: string
  pointOpacity: number
  rotationLayerTypeFieldId: string
  rotationFixedValue: string
}

export class LayerTypeSymbologyPointUpdateDTO {
  @IsString()
  @IsNotEmpty()
  strokeColor: string

  @IsEnum(LayerTypeSymbologyLineType)
  @IsNotEmpty()
  strokeType: LayerTypeSymbologyLineType

  @IsNumber()
  @IsNotEmpty()
  strokeWidth: number

  @IsNumber()
  @IsNotEmpty()
  strokeOpacity: number

  @IsEnum(LayerTypeSymbologyPointType)
  @IsNotEmpty()
  pointType: LayerTypeSymbologyPointType

  @ValidateIf((point: LayerTypeSymbologyPointUpdateDTO) => point.pointType === LayerTypeSymbologyPointType.SYMBOL)
  @IsUUID()
  @IsNotEmpty()
  pointSymbolId?: string

  @ValidateIf((point: LayerTypeSymbologyPointUpdateDTO) => point.pointType === LayerTypeSymbologyPointType.IMAGE)
  @IsOptional()
  pointImage?: string

  @IsNumber()
  @IsNotEmpty()
  pointSize: number

  @IsString()
  @IsNotEmpty()
  pointColor: string

  @IsNumber()
  @IsNotEmpty()
  pointOpacity: number

  @IsUUID()
  @IsOptional()
  rotationLayerTypeFieldId: string

  @IsString()
  @IsOptional()
  rotationFixedValue: string

  constructor(point?: LayerTypeSymbologyPointDTO) {
    this.strokeColor = point?.strokeColor ?? '#000000'
    this.strokeType = point?.strokeType ?? LayerTypeSymbologyLineType.SIMPLE
    this.strokeWidth = point?.strokeWidth ?? 0
    this.strokeOpacity = point?.pointOpacity ?? 100
    this.pointType = point?.pointType ?? LayerTypeSymbologyPointType.SYMBOL
    this.pointSymbolId = point?.pointSymbolId ?? ''
    this.pointImage = point?.pointImage ?? null
    this.pointSize = point?.pointSize ?? 25
    this.pointColor = point?.pointColor ?? '#000000'
    this.pointOpacity = point?.pointOpacity ?? 100
    this.rotationLayerTypeFieldId = point?.rotationLayerTypeFieldId ?? ''
    this.rotationFixedValue = point?.rotationFixedValue ?? '0'
  }
}
