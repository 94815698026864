import { ArrayParse, PaginatedSearchDTO, StringCleanup } from '@uefa-shared/contracts'
import { IsArray, IsOptional, IsString } from 'class-validator'
import { SVRVistaListDTO } from './vista.dto'

export class SVRTeamListDTO extends SVRVistaListDTO {
  teamCode?: string
  logoUrl?: string
  logoThumbnailUrl?: string
  nationalTeam?: boolean
  teamType?: string
  teamTypeDetails?: string
  countryName?: string
  integrationId?: string
}

export class SVRTeamSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @IsString()
  @StringCleanup()
  teamType?: string

  @IsOptional()
  @IsString()
  @StringCleanup()
  teamTypeDetails?: string
}
