import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LabelValueDTO, PaginatedResults } from '@uefa-shared/contracts'
import { BaseService } from '@uefa-shared/frontend'
import {
  ActionPointListDTO,
  ActionPointSearchDTO,
  ExportOptionsDTO,
  ExportOptionsSearchDTO,
  MediaPatchDTO,
  MediaRotationDegrees,
  MonitorEventListDTO,
  MonitorEventSearchDTO,
  MonitorMediaFile,
  MonitorMediaSearchDTO,
  MonitorQuestionListDTO,
  MonitorQuestionSearchDTO,
  MonitorSectionListDTO,
  MonitorSectionMediaCountDTO,
  MonitorSectionSearchDTO,
  MonitorSiteVisitDTO,
  MonitorSiteVisitListDTO,
  MonitorSiteVisitSearchDTO,
  MonitorVistaSearchDTO,
  MonitorWorkingVisitSearchDTO,
  MonitorWorkingVisitSummary,
  MonitorWorkingVisitSummarySearchDTO,
  SubmittableProject,
  WorkingVisitExportSearchDTO,
  WorkingVisitSubmitDTO,
} from '@uefa-svr/contracts'
import { Observable, of } from 'rxjs'
import { environment } from '../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class MonitorService extends BaseService {
  constructor(http: HttpClient) {
    super(environment, 'monitor', http)
  }

  public getEventsPaginated(options: MonitorEventSearchDTO): Observable<PaginatedResults<MonitorEventListDTO>> {
    return this.getPaginatedResult<MonitorEventListDTO>(`${this.basePath}/events`, options)
  }

  public getEventCyclesPaginated(options: MonitorEventSearchDTO): Observable<PaginatedResults<MonitorEventListDTO>> {
    return this.getPaginatedResult<MonitorEventListDTO>(`${this.basePath}/event-cycles`, options)
  }

  public getEventCyclesAndEventsPaginated(options: MonitorEventSearchDTO): Observable<PaginatedResults<MonitorEventListDTO>> {
    return this.getPaginatedResult<MonitorEventListDTO>(`${this.basePath}/event-cycles-events`, options)
  }

  public getSiteVisitsPaginated(options: MonitorSiteVisitSearchDTO): Observable<PaginatedResults<MonitorSiteVisitListDTO>> {
    return this.getPaginatedResult<MonitorSiteVisitListDTO>(`${this.basePath}/site-visits`, options)
  }

  public getActionPointsPaginated(options: ActionPointSearchDTO): Observable<PaginatedResults<ActionPointListDTO>> {
    return this.getPaginatedResult<ActionPointListDTO>(`${this.basePath}/action-points`, options)
  }

  public getQuestionsPaginated(options: MonitorQuestionSearchDTO): Observable<PaginatedResults<MonitorQuestionListDTO>> {
    return this.getPaginatedResult<MonitorQuestionListDTO>(`${this.basePath}/working-visit-questions`, options)
  }

  public getSectionsPaginated(options: MonitorSectionSearchDTO): Observable<PaginatedResults<MonitorSectionListDTO>> {
    return this.getPaginatedResult<MonitorSectionListDTO>(`${this.basePath}/sections`, options)
  }

  public getSiteVisit(id: string, options?: MonitorWorkingVisitSearchDTO): Observable<MonitorSiteVisitDTO> {
    const queryParams = this.getQueryParams(options)
    return this.http.get<MonitorSiteVisitDTO>(`${this.basePath}/${id}?${queryParams.join('&')}`)
  }

  public getSiteVisitSummary(id: string, options: MonitorWorkingVisitSummarySearchDTO): Observable<MonitorWorkingVisitSummary> {
    const queryParams = this.getQueryParams(options)
    return this.http.get<MonitorWorkingVisitSummary>(`${this.basePath}/${id}/summary?${queryParams.join('&')}`)
  }

  public getSectionMediaCount(options: MonitorMediaSearchDTO): Observable<MonitorSectionMediaCountDTO[]> {
    const queryParams = this.getQueryParams(options)
    return this.http.get<MonitorSectionMediaCountDTO[]>(`${this.basePath}/sections-medias-count?${queryParams.join('&')}`)
  }

  public getMediaPaginated(options: MonitorMediaSearchDTO): Observable<PaginatedResults<MonitorMediaFile>> {
    return this.getPaginatedResult<MonitorMediaFile>(`${this.basePath}/media`, options)
  }

  public getMediaExportUrl(options: MonitorMediaSearchDTO): string {
    const queryParams = this.getQueryParams(options)
    return `${this.basePath}/media-export?${queryParams.join('&')}`
  }

  public getSubmittableProjects(siteVisitId: string, options: MonitorWorkingVisitSearchDTO): Observable<SubmittableProject[]> {
    const queryParams = this.getQueryParams(options)
    return this.http.get<SubmittableProject[]>(`${this.basePath}/${siteVisitId}/submittable-projects?${queryParams.join('&')}`)
  }

  public copyMedia(fileId: string, copyFromId: string): Observable<void> {
    return this.http.post<void>(`${this.basePath}/media/${fileId}/copy/${copyFromId}`, undefined)
  }

  public uploadMedia(fileId: string, file: File, rotation?: MediaRotationDegrees, mediaKey?: string): Observable<void> {
    if (!file) {
      return of()
    }
    const uploadData = new FormData()
    uploadData.append('file', file, file.name)
    const queryParams = this.getQueryParams({ rotation, mediaKey })
    return this.http.post<void>(`${this.basePath}/media/${fileId}?${queryParams.join('&')}`, uploadData)
  }

  public updateMedia(fileId: string, model: MediaPatchDTO): Observable<void> {
    return this.http.put<void>(`${this.basePath}/media/${fileId}`, model)
  }

  public getMediaFileUrl(mediaUrl: string): string {
    return `${this.configuration.apibaseUrl}/${mediaUrl}`
  }

  public getExportOptions(siteVisitId: string, options: ExportOptionsSearchDTO): Observable<ExportOptionsDTO> {
    const queryParams = this.getQueryParams<ExportOptionsSearchDTO>(options)
    return this.http.get<ExportOptionsDTO>(`${this.basePath}/${siteVisitId}/export-options?${queryParams.join('&')}`)
  }

  public generateReport(search: WorkingVisitExportSearchDTO): Observable<void> {
    return this.http.post<void>(`${this.configuration.apibaseUrl}/working-visit/export`, search)
  }

  public submitWorkingVisit(id: string, model: WorkingVisitSubmitDTO): Observable<void> {
    return this.http.put<void>(`${this.basePath}/working-visit/${id}/submit`, model)
  }

  //#region LabelValue Vista Entities
  public getMonitorEvents(options: MonitorVistaSearchDTO): Observable<LabelValueDTO[]> {
    const queryParams = this.getQueryParams(options)
    return this.http.get<LabelValueDTO[]>(`${this.basePath}/vista/event/all?${queryParams.join('&')}`)
  }

  public getMonitorEventsPaginated(options: MonitorVistaSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/vista/event`, options)
  }

  public getMonitorLevelsPaginated(options: MonitorVistaSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/vista/level`, options)
  }

  public getMonitorCompetitionsPaginated(options: MonitorVistaSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/vista/competition`, options)
  }

  public getMonitorSitesPaginated(options: MonitorVistaSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/vista/site`, options)
  }

  public getMonitorVenuesPaginated(options: MonitorVistaSearchDTO): Observable<PaginatedResults<LabelValueDTO>> {
    return this.getPaginatedResult<LabelValueDTO>(`${this.basePath}/vista/venue`, options)
  }
  //#endregion
}
