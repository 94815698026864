import { IsArray, IsLatitude, IsLongitude, IsNotEmpty, IsOptional, IsUUID, Min } from 'class-validator'
import { StringCleanup, StringTrim } from '../decorators'
import { EventCycleDTO } from '../event-cycle'
import { EventDTO } from '../event/event.dto'
import { VistaDTO, VistaListDTO } from '../shared/vista.dto'
import { SiteTypeDTO } from '../site-type/site-type.dto'
import { UserGroupRole } from '../user/user.dto'
import { VenueDTO } from '../venue/venue.dto'

export class Bbox {
  minx: number
  maxx: number
  miny: number
  maxy: number
}

/* eslint-disable @typescript-eslint/camelcase */
export enum LevelLayerType {
  BLUEPRINT = 'blueprint',
  FACILITIES = 'facilities',
  KEYS = 'keys',
  ACCS_Dots = 'ACCS_Dots',
  ACCS_Flows = 'ACCS_Flows',
  ACCS_Zoning = 'ACCS_Zoning',
  LABELS = 'labels',
  PANORAMA = 'panorama',
}

export class LevelLayerDTO {
  id?: string
  integrationId: string
  eventId?: string
  name: string
  type: LevelLayerType
  order: number
  displayName?: string
}

export class SiteLevelDTO {
  id: string
  description: string
  default: boolean
  layers: LevelLayerDTO[]
  order: number
  includedInEvent: boolean
  numberOfPanoramas: number
  blueprintLayerId: string
  blueprintLayerDisplayName: string
  blueprintLayerCreatedAt: Date

  constructor() {
    this.description = '0'
    this.default = true
    this.order = 0
  }
}

export class SiteConfigurationDTO {
  id: string
  marker: string
  bbox: Bbox
  levels: Array<SiteLevelDTO>
  siteLayers?: LevelLayerDTO[]

  constructor() {
    this.id = null
    this.marker = null
    this.bbox = null
    this.levels = [new SiteLevelDTO()]
    this.siteLayers = []
  }
}

export class SiteOverviewConfigurationDTO {
  id: string
  bbox: Bbox
  layers: Array<LevelLayerDTO> = []
}

export class EventDefaultConfigurationDTO {
  eventId: string
  eventName: string
  isDefaultSite?: boolean
}

export class SiteDTO extends VistaDTO {
  venueId?: string
  configuration: SiteConfigurationDTO
  overviewConfiguration?: SiteOverviewConfigurationDTO
  siteType: SiteTypeDTO
  latitude: number
  longitude: number
  imageUrl?: string
  imageUrl_200x200?: string
  imageId: string
  volunteerGeofence: number
  address: string
  eventOrEventCycleId?: string
  events?: Array<EventDTO>
  eventCycles?: Array<EventCycleDTO>
  acronym: string
  mapifyBuildingId?: string
  venue?: VenueDTO
  role: UserGroupRole
  printRotationDegrees?: number
  alias?: string
  allLevelsHaveKeysLayer?: boolean
  allLevelsHaveZonesLayer?: boolean
  allLevelsHaveFlowsLayer?: boolean
  allLevelsHaveDotsLayer?: boolean
  allLevelsHaveLabelsLayer?: boolean
  eventsAlias?: SiteEventAlias[]
  eventSiteType?: EventSiteType[]
  eventsDefaultConfiguration?: EventDefaultConfigurationDTO[]
  googleMyMapsLink?: string

  constructor() {
    super()
    this.venueId = null
    this.eventOrEventCycleId = null
    this.address = null
    this.volunteerGeofence = null
    this.siteType = null
    this.latitude = null
    this.longitude = null
    this.acronym = null
    this.mapifyBuildingId = null
    this.imageId = null
    this.imageUrl = null
    this.imageUrl_200x200 = null
  }
}

export interface SiteEventDTO {
  id: string
  name: string
  siteAlias: string
  siteTypeId: string
  venueAlias?: string
}

export interface EventSiteType {
  eventId: string
  siteTypeId: string
}

export interface SiteEventAlias {
  eventId: string
  alias: string
}

export class SiteListDTO extends VistaListDTO {
  venueName?: string
  venueCountry?: string
  venueId?: string
  configuration: SiteConfigurationDTO
  siteType: SiteTypeDTO
  latitude: number
  longitude: number
  imageUrl?: string
  imageUrl_200x200?: string
  numberOfLevels: number
  numberOfEvents: number
  events?: SiteEventDTO[]

  constructor() {
    super()
    this.siteType = null
    this.latitude = null
    this.longitude = null
    this.imageUrl = null
    this.imageUrl_200x200 = null
  }
}

export class SiteUpdateDTO {
  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  name: string

  @IsUUID()
  @IsNotEmpty()
  venueId: string

  @StringTrim()
  @IsOptional()
  integrationId: string

  @StringTrim()
  @IsOptional()
  googleMyMapsLink?: string

  @IsLatitude()
  @IsNotEmpty()
  latitude?: number

  @IsLongitude()
  @IsNotEmpty()
  longitude?: number

  @StringTrim()
  @IsOptional()
  address?: string

  @IsOptional()
  @Min(0)
  volunteerGeofence?: number

  @IsNotEmpty()
  @Min(0)
  printRotationDegrees: number

  @IsOptional()
  configuration: SiteConfigurationDTO

  @IsArray()
  @IsUUID(undefined, { each: true })
  @IsOptional()
  eventIds?: string[]

  @IsUUID()
  @IsNotEmpty()
  siteTypeId?: string

  constructor(data?: SiteDTO) {
    this.latitude = 0
    this.longitude = 0
    this.address = ''
    this.volunteerGeofence = 0
    this.printRotationDegrees = 0

    this.configuration = new SiteConfigurationDTO()

    if (data) {
      this.name = data.name
      this.siteTypeId = data.siteType.id
      this.venueId = data.venueId
      this.integrationId = data.isFameIntegrated ? data.integrationId : ''
      this.latitude = data.latitude
      this.longitude = data.longitude
      this.address = data.address
      this.volunteerGeofence = data.volunteerGeofence
      this.printRotationDegrees = data.printRotationDegrees
      this.configuration = data.configuration ?? this.configuration
      this.eventIds = data.events?.map(e => e.id)
      this.googleMyMapsLink = data.googleMyMapsLink
    }
  }
}

export class SiteCreateDTO extends SiteUpdateDTO {}
