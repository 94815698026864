import { ArrayParse, BooleanParse, PaginatedSearchDTO } from '@uefa-shared/contracts'
import { IsArray, IsBoolean, IsEnum, IsNotEmpty, IsOptional, ValidateIf } from 'class-validator'
import { QuestionStatus } from '../question/question.dto'
import { SectionDTO } from '../section/section.dto'
import { ColorRanking } from '../shared'

export class MonitorSectionSearchDTO extends PaginatedSearchDTO {
  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  siteVisitIds: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  workingVisitIds?: string[]

  @IsOptional()
  @ArrayParse()
  keyIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  facilityIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  projectIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  levelIds?: string[]

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  isOwner?: boolean

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @IsEnum(QuestionStatus, { each: true })
  @ValidateIf((search: MonitorSectionSearchDTO) => search.status?.length > 0)
  status?: QuestionStatus[]
}

export class MonitorSectionListDTO {
  id: string
  name: string
  totalActionPoints: number
  activeActionPoints: number
  totalQuestions: number
  activeQuestions: number
  progress?: number
  rankings?: ColorRanking[]
  status?: SectionStatus
  parentId?: string

  constructor(model?: SectionDTO) {
    if (model) {
      this.id = model.id
      this.name = model.name
    }
  }
}

export class MonitorSectionMediaCountDTO {
  id: string
  totalMedias: number
  totalDocuments: number
}

export enum SectionStatus {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  COMPLETED = 'COMPLETED',
}
