<div class="svr-action-point-comments">
  <div class="comment-row" *ngFor="let comment of _comments">
    <div class="user-icon">
      <span class="profile-icon">{{ comment.userInitials }}</span>
    </div>
    <div class="comment-content">
      <div class="comment-title">
        <span>{{ 'shared.actionPointsManager.comments.title' | translate: getTitleLabels(comment) }}</span>
        <i-feather
          name="clock"
          class="icon info-icon"
          *ngIf="comment.updatedAt && comment.updatedAt != comment.createdAt"
          matTooltip="{{ 'shared.actionPointsManager.comments.titleUpdatedAt' | translate: getTitleUpdatedAtLabels(comment) }}"
        ></i-feather>
        <button mat-stroked-button class="filled" color="primary" (click)="onEdit(comment)" *ngIf="isEditing(comment) && !isAdded(comment)">
          <i-feather name="send" class="icon"></i-feather> {{ 'shared.actionPointsManager.comments.update' | translate }}
        </button>
        <div
          matTooltip="{{
            (disabled ? 'shared.actionPointsManager.comments.tooltip.disabled' : 'shared.actionPointsManager.comments.tooltip.edit')
              | translate
          }}"
        >
          <button
            mat-stroked-button
            color="primary"
            (click)="onEditing(comment)"
            *ngIf="canEditComment(comment) && !isAdded(comment)"
            [disabled]="disabled"
          >
            <i-feather name="edit-2" class="icon"></i-feather> {{ 'shared.actionPointsManager.comments.edit' | translate }}
          </button>
        </div>
        <button mat-stroked-button color="default" (click)="onEditCancel(comment)" *ngIf="isEditing(comment) && !isAdded(comment)">
          <i-feather name="x" class="icon"></i-feather> {{ 'shared.actionPointsManager.comments.discard' | translate }}
        </button>
        <div
          matTooltip="{{
            (disabled ? 'shared.actionPointsManager.comments.tooltip.disabled' : 'shared.actionPointsManager.comments.tooltip.delete')
              | translate
          }}"
        >
          <button
            mat-stroked-button
            color="warn"
            (click)="onDelete(comment)"
            *ngIf="canDeleteComment(comment) && !isAdded(comment)"
            [disabled]="disabled"
          >
            <i-feather name="trash" class="icon"></i-feather> {{ 'shared.actionPointsManager.comments.delete' | translate }}
          </button>
        </div>
      </div>
      <div class="comment-content-edit" *ngIf="isEditing(comment) || isAdded(comment)">
        <div
          class="textarea-content"
          [ngClass]="{ disabled: disabled }"
          matTooltip="{{ (disabled ? 'shared.actionPointsManager.comments.tooltip.disabledCreate' : '') | translate }}"
        >
          <textarea
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="4"
            matInput
            [(ngModel)]="comment.comment"
            type="text"
            required
            [disabled]="disabled"
          ></textarea>
        </div>
        <div
          class="add-comment"
          [ngClass]="{ disabled: disabled }"
          *ngIf="isAdded(comment)"
          matTooltip="{{
            (disabled ? 'shared.actionPointsManager.comments.tooltip.disabledCreate' : 'shared.actionPointsManager.comments.add')
              | translate
          }}"
        >
          <button mat-button (click)="onAdd(comment)" [disabled]="disabled">
            <i-feather name="send" class="icon"></i-feather><br />
            <span>{{ 'shared.actionPointsManager.comments.add' | translate }}</span>
          </button>
        </div>
      </div>
      <div class="comment-content-view" *ngIf="!isEditing(comment) && !isAdded(comment)">
        <textarea
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          matInput
          [ngModel]="comment.comment"
          type="text"
          readonly
        ></textarea>
      </div>
    </div>
  </div>
</div>
