import { Type } from 'class-transformer'
import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, IsUUID, ValidateIf, ValidateNested } from 'class-validator'
import { LayerTypeSymbologyPointDTO, LayerTypeSymbologyPointType, LayerTypeSymbologyPointUpdateDTO } from './layer-type-symbology-point.dto'

export enum LayerTypeSymbologyLinePointType {
  SYMBOL = 'symbol',
  IMAGE = 'image',
}

export enum LayerTypeSymbologyLineType {
  SIMPLE = 'simple',
  DASHED = 'dashed',
  DOTTED = 'dotted',
  DASH_1 = 'dash_1',
  DASH_2 = 'dash_2',
  DASH_3 = 'dash_3',
  DASH_4 = 'dash_4',
  DASH_5 = 'dash_5',
  DASH_6 = 'dash_6',
  DASH_7 = 'dash_7',
  DASH_8 = 'dash_8',
  DASH_9 = 'dash_9',
  DASH_10 = 'dash_10',
  DASH_11 = 'dash_11',
  DASH_12 = 'dash_12',
  DASH_13 = 'dash_13',
  TWO_LINES = 'two_lines',
  DASH_DOT = 'dash_dot',
  DASH_DASH_DOT = 'dash_dash_dot',
  DASH_DOT_DOT = 'dash_dot_dot',
}

export class LayerTypeSymbologyLineDTO {
  id?: string
  lineColor: string
  lineType: LayerTypeSymbologyLineType
  lineWidth: number
  lineOpacity: number
  haloType: LayerTypeSymbologyLineType
  haloColor: string
  haloWidth: number
  haloOpacity: number
  startSymbolPoint?: LayerTypeSymbologyPointDTO
  endSymbolPoint?: LayerTypeSymbologyPointDTO
}

export class LayerTypeSymbologyLinePointUpdateDTO {
  @IsUUID()
  @IsOptional()
  id?: string

  @IsEnum(LayerTypeSymbologyPointType)
  @IsNotEmpty()
  pointType: LayerTypeSymbologyPointType

  @ValidateIf((point: LayerTypeSymbologyPointUpdateDTO) => point.pointType === LayerTypeSymbologyPointType.SYMBOL)
  @IsUUID()
  @IsNotEmpty()
  pointSymbolId?: string

  @ValidateIf((point: LayerTypeSymbologyPointUpdateDTO) => point.pointType === LayerTypeSymbologyPointType.IMAGE)
  @IsOptional()
  pointImage?: string

  @IsNumber()
  @IsNotEmpty()
  pointSize: number

  @ValidateIf((point: LayerTypeSymbologyPointUpdateDTO) => point.pointType === LayerTypeSymbologyPointType.SYMBOL)
  @IsString()
  @IsNotEmpty()
  pointColor: string

  @IsNumber()
  @IsNotEmpty()
  pointOpacity: number

  constructor(point?: LayerTypeSymbologyPointDTO) {
    if (point) {
      this.id = point.id
      this.pointType = point.pointType
      this.pointSymbolId = point.pointSymbolId
      this.pointImage = point.pointImage
      this.pointSize = point.pointSize
      this.pointColor = point.pointColor
      this.pointOpacity = point.pointOpacity
    }
  }
}

export class LayerTypeSymbologyLineUpdateDTO {
  @IsString()
  @IsNotEmpty()
  lineColor: string

  @IsEnum(LayerTypeSymbologyLineType)
  @IsNotEmpty()
  lineType: LayerTypeSymbologyLineType

  @IsNumber()
  @IsNotEmpty()
  lineWidth: number

  @IsNumber()
  @IsNotEmpty()
  lineOpacity: number

  @IsEnum(LayerTypeSymbologyLineType)
  @IsNotEmpty()
  haloType: LayerTypeSymbologyLineType

  @IsString()
  @IsNotEmpty()
  haloColor: string

  @IsNumber()
  @IsNotEmpty()
  haloWidth: number

  @IsNumber()
  @IsNotEmpty()
  haloOpacity: number

  @ValidateNested()
  @Type(() => LayerTypeSymbologyLinePointUpdateDTO)
  @IsOptional()
  startSymbolPoint?: LayerTypeSymbologyLinePointUpdateDTO

  @ValidateNested()
  @Type(() => LayerTypeSymbologyLinePointUpdateDTO)
  @IsOptional()
  endSymbolPoint?: LayerTypeSymbologyLinePointUpdateDTO

  constructor(line?: LayerTypeSymbologyLineDTO) {
    this.lineColor = line?.lineColor ?? '#000000'
    this.lineType = line?.lineType ?? LayerTypeSymbologyLineType.SIMPLE
    this.lineWidth = line?.lineWidth ?? 2
    this.lineOpacity = line?.lineOpacity ?? 100
    this.haloType = line?.haloType ?? LayerTypeSymbologyLineType.SIMPLE
    this.haloColor = line?.haloColor ?? '#000000'
    this.haloWidth = line?.haloWidth ?? 1
    this.haloOpacity = line?.haloOpacity ?? 100

    if (line?.startSymbolPoint) {
      this.startSymbolPoint = new LayerTypeSymbologyLinePointUpdateDTO(line.startSymbolPoint)
    }

    if (line?.endSymbolPoint) {
      this.endSymbolPoint = new LayerTypeSymbologyLinePointUpdateDTO(line.endSymbolPoint)
    }
  }
}

export interface PreviewStyles {
  fill?: string
  'fill-opacity'?: number
  stroke?: string
  'stroke-width'?: number
  'stroke-opacity'?: number
  'background-image'?: string
}

export class LayerTypeSymbologyLineUtils {
  public static getLinePointPreview(symbol: string, point: LayerTypeSymbologyLinePointUpdateDTO, noColor = false): PreviewStyles {
    if (!symbol || !point) {
      return
    }

    if (symbol.includes('arrow')) {
      return LayerTypeSymbologyLineUtils.getArrowPreview(point, noColor)
    }

    return LayerTypeSymbologyLineUtils.getSymbolPreview(point)
  }

  public static getLinePreview(line: LayerTypeSymbologyLineUpdateDTO): PreviewStyles {
    if (!line) {
      return
    }

    return {
      'stroke-width': line.lineWidth,
      'stroke-opacity': line.lineOpacity / 100,
      stroke: line.lineColor,
    }
  }

  public static getHaloLinePreview(line: LayerTypeSymbologyLineUpdateDTO): PreviewStyles {
    if (!line) {
      return
    }

    return {
      'stroke-width': line.lineWidth + line.haloWidth * 2,
      'stroke-opacity': line.haloOpacity / 100,
      stroke: line.haloColor,
    }
  }

  private static getSymbolPreview(point: LayerTypeSymbologyLinePointUpdateDTO) {
    if (!point) {
      return
    }

    return {
      fill: point.pointColor,
      'fill-opacity': point.pointOpacity / 100,
      stroke: 'none',
    }
  }

  private static getArrowPreview(point: LayerTypeSymbologyLinePointUpdateDTO, noColor = false) {
    if (!point) {
      return
    }
    return {
      fill: noColor ? '#000000' : point.pointColor,
      stroke: noColor ? '#000000' : point.pointColor,
      'stroke-opacity': point.pointOpacity / 100,
      'fill-opacity': point.pointOpacity / 100,
    }
  }
}

export class LayerTypeSymbologyLineTypeUtils {
  public static getLineTypes(geometry: string) {
    switch (geometry) {
      case 'point':
        return LayerTypeSymbologyLineTypeUtils.getPointLineTypes()
      case 'line':
        return LayerTypeSymbologyLineTypeUtils.getLineLineTypes()
      case 'polygon':
        return LayerTypeSymbologyLineTypeUtils.getPolygonLineTypes()
    }
  }

  private static getPointLineTypes(): LayerTypeSymbologyLineType[] {
    return [LayerTypeSymbologyLineType.SIMPLE, LayerTypeSymbologyLineType.DOTTED, LayerTypeSymbologyLineType.DASHED]
  }

  private static getLineLineTypes(): LayerTypeSymbologyLineType[] {
    return [
      LayerTypeSymbologyLineType.SIMPLE,
      LayerTypeSymbologyLineType.DOTTED,
      LayerTypeSymbologyLineType.DASHED,
      LayerTypeSymbologyLineType.DASH_1,
      LayerTypeSymbologyLineType.DASH_2,
      LayerTypeSymbologyLineType.DASH_3,
      LayerTypeSymbologyLineType.DASH_4,
      LayerTypeSymbologyLineType.DASH_5,
      LayerTypeSymbologyLineType.DASH_6,
      LayerTypeSymbologyLineType.DASH_7,
      LayerTypeSymbologyLineType.DASH_8,
      LayerTypeSymbologyLineType.DASH_9,
      LayerTypeSymbologyLineType.DASH_10,
      LayerTypeSymbologyLineType.DASH_11,
      LayerTypeSymbologyLineType.DASH_12,
      LayerTypeSymbologyLineType.DASH_13,
      LayerTypeSymbologyLineType.TWO_LINES,
    ]
  }

  private static getPolygonLineTypes(): LayerTypeSymbologyLineType[] {
    return [
      LayerTypeSymbologyLineType.SIMPLE,
      LayerTypeSymbologyLineType.DOTTED,
      LayerTypeSymbologyLineType.DASHED,
      LayerTypeSymbologyLineType.DASH_DASH_DOT,
      LayerTypeSymbologyLineType.DASH_DOT,
      LayerTypeSymbologyLineType.DASH_DOT_DOT,
    ]
  }
}
