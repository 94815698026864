import { IsEnum, IsOptional } from 'class-validator'
import { ArrayParse } from '../decorators'
import { PaginatedSearchDTO } from '../shared/paginated-search.dto'
import { NotificationStatus } from './notification.dto'

export class NotificationSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @ArrayParse()
  @IsEnum(NotificationStatus, { each: true })
  status?: NotificationStatus[]
}
