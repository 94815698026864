export enum Forbidden {
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  MISSING_GROUPS = 'MISSING_GROUPS',
  MISSING_ROLES = 'MISSING_ROLES',
  SITE_VISIT = 'SITE_VISIT',
  WORKING_VISIT = 'WORKING_VISIT',
  WORKING_VISIT_QUESTION = 'WORKING_VISIT_QUESTION',
  ACTION_POINT = 'ACTION_POINT',
}

export class ForbiddenUtils {
  public static getForRedirect(): Forbidden[] {
    return [Forbidden.USER_NOT_FOUND, Forbidden.MISSING_GROUPS, Forbidden.MISSING_ROLES]
  }
}
