import { BooleanParse, PaginatedSearchDTO } from '@uefa-shared/contracts'
import { ArrayParse } from '@uefa-vista/contracts'
import { IsArray, IsBoolean, IsOptional, IsString } from 'class-validator'
import { SVRVistaListDTO } from './vista.dto'

export class SVRVenueListDTO extends SVRVistaListDTO {}

export class SVRVenueSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @IsString()
  role?: string

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  withActionPoints?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  withoutSiteVisits?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  allEvents?: boolean

  @IsOptional()
  @IsArray()
  @ArrayParse()
  siteVisitEventIds?: string[]
}
