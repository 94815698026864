import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PaginatedResults } from '@uefa-shared/contracts'
import { BaseService } from '@uefa-shared/frontend'
import {
  SVREventListDTO,
  SVREventSearchDTO,
  SVRKeyListDTO,
  SVRKeySearchDTO,
  SVRProjectListDTO,
  SVRProjectSearchDTO,
} from '@uefa-svr/contracts'
import { LatLng, LevelFacilityDTO, SiteDTO } from '@uefa-vista/contracts'
import { LevelFacility } from '@uefa-vista/map/lib/services/models/geo'
import { Observable, of } from 'rxjs'
import { environment } from '../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class VistaService extends BaseService {
  constructor(http: HttpClient) {
    super(environment, 'vista', http)
  }

  public getVistaKeysPaginated(options: SVRKeySearchDTO): Observable<PaginatedResults<SVRKeyListDTO>> {
    return this.getPaginatedResult<SVRKeyListDTO>(`${this.basePath}/key`, options)
  }

  public getVistaKeysByEventAndSite(eventId: string, siteId: string): Observable<SVRKeyListDTO[]> {
    return this.http.get<SVRKeyListDTO[]>(`${this.basePath}/key/event/${eventId}/site/${siteId}`)
  }

  public getProjectsPaginated(options: SVRProjectSearchDTO): Observable<PaginatedResults<SVRProjectListDTO>> {
    return this.getPaginatedResult<SVRProjectListDTO>(`${this.basePath}/project`, options)
  }

  public getAllVistaKeys(options: SVRKeySearchDTO): Observable<SVRKeyListDTO[]> {
    const queryParams = this.getQueryParams(options)
    return this.http.get<SVRKeyListDTO[]>(`${this.basePath}/key/all?${queryParams.join('&')}`)
  }

  public getEventsPaginated(options: SVREventSearchDTO): Observable<PaginatedResults<SVREventListDTO>> {
    return this.getPaginatedResult<SVREventListDTO>(`${this.basePath}/event`, options)
  }

  public getMapSite(id: string, eventId: string): Observable<SiteDTO> {
    return this.http.get<SiteDTO>(`${environment.apibaseUrl}/site/v2/${id}/event/${eventId}`)
  }

  public getFacility(facilityId: string, siteId: string, eventId: string): Observable<LevelFacility[]> {
    const queryParams = [`facilityId=${facilityId}`, `layerTypes=keys`, `ìncludeOverview=true`]

    const queryString = queryParams.join('&')
    return this.http.get<LevelFacility[]>(
      `${environment.apibaseUrl}/map/${environment.mapApplicationId}/${siteId}/event/${eventId}/facility?${queryString}`
    )
  }

  public findFacilities(
    eventId: string,
    siteId: string,
    levelId: string,
    points: LatLng[],
    keyIds: string[] = []
  ): Observable<LevelFacilityDTO[]> {
    if (!eventId || !siteId || !levelId || !points?.length) {
      return of()
    }

    const queryParams = []
    if (levelId == 'OV') {
      queryParams.push('includeOverview=true')
    } else {
      queryParams.push(`levelId=${levelId}`)
    }

    points.forEach((c) => queryParams.push(`pointsCoordinates=${c.lat},${c.lng}`))
    if (keyIds?.length) {
      keyIds.forEach((k) => queryParams.push(`keyIds=${k}`))
    }
    const url = `${environment.apibaseUrl}/map/${environment.mapApplicationId}/${siteId}/event/${eventId}/facilities?${queryParams.join(
      '&'
    )}`
    return this.http.get<LevelFacilityDTO[]>(url)
  }
}
