import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PaginatedResults, PaginatedSearchDTO, PaginatedSearchFilterDTO } from '@uefa-shared/contracts'
import { BaseService } from '@uefa-shared/frontend'
import {
  ActionPointBulkUpdateDTO,
  ActionPointBulkUpdateResultDTO,
  ActionPointConfigurationDTO,
  ActionPointCountDTO,
  ActionPointCreateDTO,
  ActionPointDTO,
  ActionPointExportSearchDTO,
  ActionPointListDTO,
  ActionPointSearchDTO,
  ActionPointUpdateDTO,
  MapMarker,
} from '@uefa-svr/contracts'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class ActionPointsService extends BaseService {
  constructor(http: HttpClient) {
    super(environment, 'action-point', http)
  }

  public getPaginated(options: ActionPointSearchDTO): Observable<PaginatedResults<ActionPointListDTO>> {
    return this.getPaginatedResult<ActionPointListDTO>(this.basePath, options)
  }

  // eslint-disable-next-line max-len
  public getPaginatedPost(
    search: ActionPointSearchDTO,
    customFilters?: PaginatedSearchFilterDTO
  ): Observable<PaginatedResults<ActionPointListDTO>> {
    search = search ?? {}

    if (search.ids?.length) {
      search.currentPage = 1
      search.pageSize = search.ids.length
    }

    search.currentPage = search.currentPage || 1
    search.pageSize = search.pageSize || 20
    search.searchTerm = search.searchTerm || undefined

    const queryParams = this.getQueryParams<PaginatedSearchDTO>({ ...search, customFilters: undefined })
    return this.http.post<PaginatedResults<ActionPointListDTO>>(`${this.basePath}/list?${queryParams.join('&')}`, { customFilters })
  }

  public getById(id: string): Observable<ActionPointDTO> {
    return this.http.get<ActionPointDTO>(`${this.basePath}/${id}`)
  }

  public countActionPoints(search: ActionPointSearchDTO): Observable<ActionPointCountDTO> {
    const customFilters = search.customFilters
    const queryParams = this.getQueryParams<ActionPointSearchDTO>({ ...search, customFilters: undefined })
    return this.http.post<ActionPointCountDTO>(`${this.basePath}/count?${queryParams.join('&')}`, { customFilters })
  }

  public update(id: string, model: ActionPointUpdateDTO): Observable<ActionPointDTO> {
    return this.http.put<ActionPointDTO>(`${this.basePath}/${id}`, model)
  }

  public bulkUpdate(model: ActionPointBulkUpdateDTO): Observable<ActionPointBulkUpdateResultDTO> {
    return this.http.put<ActionPointBulkUpdateResultDTO>(`${this.basePath}/bulk`, model)
  }

  public create(model: ActionPointCreateDTO): Observable<ActionPointDTO> {
    return this.http.post<ActionPointDTO>(this.basePath, model)
  }

  public delete(id: string): Observable<ActionPointDTO> {
    return this.http.delete<ActionPointDTO>(`${this.basePath}/${id}`)
  }

  public getMarkers(search: ActionPointSearchDTO): Observable<MapMarker[]> {
    const params = this.getQueryParams(search)
    return this.http.get<MapMarker[]>(`${this.basePath}/markers?${params.join('&')}`)
  }

  public getConfigurations() {
    return this.http.get<ActionPointConfigurationDTO[]>(`${this.basePath}/configurations`)
  }

  public generateActionPointExport(search: ActionPointExportSearchDTO): Observable<ExportResponse> {
    const queryParams: string[] = this.getQueryParams<ActionPointExportSearchDTO>({ ...search, customFilters: undefined })

    const request = this.http.post(
      `${this.basePath}/export?${queryParams.join('&')}`,
      { customFilters: search.customFilters },
      { responseType: 'arraybuffer', observe: 'response' }
    )

    return request.pipe(
      map((res) => {
        const response = new ExportResponse()
        response.data = { data: res.body }
        let contentDisposition = res.headers.get('Content-Disposition')
        response.name = contentDisposition.match(/filename="(.*)"/)[1]
        return response
      })
    )
  }
}

export class ExportResponse {
  data: { data: ArrayBuffer }
  name: string
}
