import { ApplicationName } from '@uefa-vista/contracts'
import { richTextConfig } from './rich-text-config'

export const environment = {
  production: false,
  name: ApplicationName.SVR,
  environment: 'dev',
  apibaseUrl: 'https://svr-api.uefa-dev.city-platform.com',
  baseUrl: 'https://svr.uefa-dev.city-platform.com/',
  googleMapsAPIKey: 'AIzaSyA8mUDe5J6CuW1c6o1L2mpokp1OYC3qxjk',
  dashboardLink: 'https://datastudio.google.com/embed/reporting/3aaf2c20-050a-4f96-a46c-8ab0da146c9f/page/og3AB',
  homeDashboardLink: 'https://datastudio.google.com/embed/reporting/23508122-a1d2-4c2f-9c77-6e491ec4c146/page/og3AB',
  mapApplicationId: 'site-visit-report',
  assetsUrl: '/assets',
  richTextConfig,
}
