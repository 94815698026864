import { IsNotEmpty, IsOptional } from 'class-validator'
import { StringCleanup, StringTrim } from '../decorators'
import { EventDTO } from '../event/event.dto'
import { VistaDTO, VistaListDTO } from '../shared/vista.dto'

export class CompetitionDTO extends VistaDTO {
  shortName?: string
  events?: Array<EventDTO>
  teamType?: string
  teamTypeDetails?: string

  constructor() {
    super()
    this.shortName = null
    this.events = null
  }
}

export class CompetitionListDTO extends VistaListDTO {
  shortName?: string
  numberOfEvents: number
  teamType?: string
  teamTypeDetails?: string

  constructor() {
    super()
    this.shortName = null
    this.numberOfEvents = 0
    this.teamType = null
    this.teamTypeDetails = null
  }
}

export class CompetitionUpdateDTO {
  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  name: string

  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  shortName: string

  @StringTrim()
  @IsOptional()
  integrationId?: string

  @StringTrim()
  @IsOptional()
  teamType?: string

  @StringTrim()
  @IsOptional()
  teamTypeDetails?: string

  constructor(model?: CompetitionDTO) {
    this.name = model?.name
    this.shortName = model?.shortName
    this.integrationId = model?.isFameIntegrated ? model.integrationId : ''
    this.teamType = model?.teamType
    this.teamTypeDetails = model?.teamTypeDetails
  }
}

export class CompetitionCreateDTO extends CompetitionUpdateDTO {}
