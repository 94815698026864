import { PaginatedSearchDTO } from '@uefa-shared/contracts'
import { IsOptional, IsString } from 'class-validator'
import { SVRVistaListDTO } from './vista.dto'

export class SVRCompetitionListDTO extends SVRVistaListDTO {
  shortName?: string
}

export class SVRCompetitionSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsString()
  role?: string
}
