<div class="actions">
  <button
    type="button"
    *ngIf="deleteButton"
   mat-mini-fab
    color="accent"
    class="media-list-button delete-button"
    matTooltip="{{ 'shared.buttons.delete' | translate }}"
    (click)="deleteClick.emit()"
    [disabled]="disabled"
  >
    <i-feather name="trash" class="media-list-icon"></i-feather>
  </button>
  <button
    type="button"
    *ngIf="editButton"
    mat-mini-fab
    color="primary"
    class="media-list-button edit-button"
    matTooltip="{{ 'shared.buttons.edit' | translate }}"
    (click)="editClick.emit()"
    [disabled]="disabled"
  >
    <i-feather name="edit-2" class="media-list-icon"></i-feather>
  </button>
</div>
