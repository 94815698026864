import { IsArray, IsBoolean, IsEnum, IsNotEmpty, IsOptional, IsUUID } from 'class-validator'
import { ArrayParse, BooleanParse } from '../decorators'
import { PaginatedSearchDTO } from '../shared/paginated-search.dto'

export enum SiteSortField {
  NAME = 'name',
  SITE_TYPE_ORDER = 'site_type_order',
  SITE_TYPE_NAME = 'site_type_name',
}

export class SiteSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventCycleIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  venueIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  siteTypeIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIntegrationIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  venueIntegrationIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  siteIntegrationIds?: string[]

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  allEvents?: boolean = false

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  withEvents?: boolean = false

  @IsOptional()
  @IsEnum(SiteSortField)
  sortBy?: string

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  eventShowPortal?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  eventShowAM?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  eventShowSVR?: boolean
}

export class SiteFindByIdDTO {
  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  allEvents?: boolean = false

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  includeEvents?: boolean = false

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  eventShowPortal?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  eventShowAM?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  eventShowSVR?: boolean

  @IsOptional()
  @IsNotEmpty()
  @IsUUID()
  eventId?: string
}
