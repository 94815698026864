import { ArrayParse, StringCleanup, StringTrim } from '@uefa-shared/contracts'
import { IsArray, IsNotEmpty, IsNumber, IsOptional, IsString, IsUUID, ValidateIf, ValidateNested } from 'class-validator'
import { SVRMediaDTO } from '../shared'

export interface LatLng {
  lat: number
  lng: number
}
export abstract class BaseDTO {
  id: string
  name: string
}

export class KeyDTO extends BaseDTO {
  label: string
  keysetId: number
  projectIds: string[]
  vistaKeyId?: string
}

class ProjectDTO {
  id: string
  name: string
  description: string
}

export class FacilityDTO {
  id: string
  notes: string
  polygon: LatLng[]
  key: KeyDTO
  projects: ProjectDTO[]
  scope?: string
  area?: number
  perimeter?: number
  documents?: SVRMediaDTO[]
  medias?: SVRMediaDTO[]
  activityPeriods?: string[]
  supplier?: string
  length?: number
  symbology?: string
}

export class FacilityUpdateDTO {
  @IsOptional()
  @IsString()
  @StringCleanup()
  @StringTrim()
  notes?: string

  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ValidateNested({ each: true })
  polygon: LatLng[]

  @IsNotEmpty()
  @IsUUID()
  key: string

  @IsOptional()
  @ArrayParse()
  @ValidateNested({ each: true })
  @ValidateIf(facility => facility?.documents?.length)
  documents?: SVRMediaDTO[]

  @IsOptional()
  @ArrayParse()
  @ValidateNested({ each: true })
  @ValidateIf(facility => facility?.medias?.length)
  medias?: SVRMediaDTO[]

  @IsOptional()
  @IsNumber()
  area: number

  @IsOptional()
  @IsNumber()
  perimeter: number

  constructor(model?: FacilityDTO) {
    this.notes = model?.notes ?? null
    this.polygon = model?.polygon?.slice() ?? []
    this.key = model?.key.vistaKeyId ?? null
    this.perimeter = model?.perimeter ?? 0
    this.area = model?.area ?? 0
    this.documents = []
    this.medias = []
  }
}

export class FacilityCreateDTO extends FacilityUpdateDTO {}

export class MapMarker {
  id?: string
  label?: string
  lat: number
  lng: number
  draggable?: boolean
  imageUrl?: string
}
