import { ArrayParse, PaginatedSearchDTO, StringCleanup } from '@uefa-shared/contracts'
import { RegExpUtils } from '@uefa-shared/utils'
import { Transform } from 'class-transformer'
import { IsArray, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, IsUUID, ValidateIf } from 'class-validator'
import { ReportType, SVROrganization } from './enums'

export enum MediaResource {
  SITE_VISIT = 'SITE_VISIT',
  ACTION_POINT = 'ACTION_POINT',
  QUESTION = 'QUESTION',
  QUESTION_FIELD = 'QUESTION_FIELD',
  WORKING_VISIT = 'WORKING_VISIT',
  QUESTION_TEMPLATE = 'QUESTION_TEMPLATE',
  QUESTION_FIELD_TEMPLATE = 'QUESTION_FIELD_TEMPLATE',
}

export enum MediaThumbnailSize {
  FULL = 'full',
  THUMB_48x48 = '48x48',
  THUMB_100x100 = '100x100',
  THUMB_200x200 = '200x200',
  THUMB_500x300 = '500x300',
}

// media rotation in degrees supported by svr
export enum MediaRotationDegrees {
  NONE = 0,
  DEGREES_90 = 90,
  DEGREES_180 = 180,
  DEGREES_270 = 270,
}

export class MediaPatchDTO {
  @IsOptional()
  id?: string

  @IsOptional()
  @IsString()
  name?: string

  @IsOptional()
  @IsEnum(MediaRotationDegrees)
  rotation?: MediaRotationDegrees

  @IsOptional()
  order?: number
}

export enum ExportStatus {
  GENERATING = 'GENERATING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export interface MediaMetadata {
  createdBy?: string
  createdAt?: Date
  status?: ExportStatus
}

export interface SignatureMetadata extends MediaMetadata {
  name: string
  organization: SVROrganization
  dateOfSignature: Date
}

export interface ZipMetadata extends MediaMetadata {
  reportId: string
  audience?: string[]
  eventIds?: string[]
  isInformationRestricted?: boolean
}

export interface ReportMetadata extends MediaMetadata {
  type: ReportType
  exportMedia: boolean
  audience?: string[]
  eventIds?: string[]
  isInformationRestricted?: boolean
}

export class SVRMediaDTO {
  @IsNotEmpty()
  @IsUUID()
  @ValidateIf(block => !!block.id)
  id?: string

  @IsNotEmpty()
  @IsString()
  @StringCleanup()
  @Transform(({ value }) => value?.toLowerCase())
  mimeType: string

  @IsNotEmpty()
  @IsString()
  @StringCleanup()
  name: string

  @IsString()
  fileName: string

  @IsOptional()
  @IsString()
  copyFromId?: string

  @IsOptional()
  @IsNumber()
  order?: number

  @IsOptional()
  @IsString()
  path?: string

  @IsOptional()
  @IsString()
  mediaKey?: string

  url?: string
  metadata?: SignatureMetadata | ReportMetadata | ZipMetadata
  uploadDate?: Date
  uploadedBy?: string
  isNew?: boolean
  uploadSuccess?: boolean
}

export class SVRMediaUploadDTO {
  buffer: Buffer
  mimetype: string
}

export enum MediaType {
  DOCUMENT = 'document',
  IMAGE = 'image',
  SIGNATURE = 'signature',
}

export const mimeTypes = {
  DOCUMENTS: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text',
    'application/zip',
    'application/x-bzip',
    'application/x-bzip2',
    'application/x-7z-compressed',
    'application/x-tar',
    'application/vnd.rar',
    'application/gzip',
    'text/plain',
    'text/csv',
  ],
  IMAGES: ['image/png', 'image/jpeg', 'image/jpg'],
}

export class MediaUtils {
  public static documentImage(mimeType: string, baseUrl: string) {
    if (mimeType === 'application/pdf') {
      return baseUrl + 'pdf.png'
    }
    if (
      mimeType === 'application/msword' ||
      mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      mimeType === 'application/vnd.oasis.opendocument.text'
    ) {
      return baseUrl + 'doc.png'
    }
    if (
      mimeType === 'application/ms-excel' ||
      mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      mimeType === 'application/vnd.oasis.opendocument.spreadsheet'
    ) {
      return baseUrl + 'xls.png'
    }
    if (mimeType === 'text/csv') {
      return baseUrl + 'csv.png'
    }
    if (
      mimeType === 'application/ms-powerpoint' ||
      mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
      mimeType === 'application/vnd.oasis.opendocument.presentation'
    ) {
      return baseUrl + 'ppt.png'
    }
    if (
      mimeType === 'application/zip' ||
      mimeType === 'application/x-bzip' ||
      mimeType === 'application/x-bzip2' ||
      mimeType === 'application/x-7z-compressed' ||
      mimeType === 'application/x-tar' ||
      mimeType === 'application/vnd.rar' ||
      mimeType === 'application/gzip'
    ) {
      return baseUrl + 'zip.png'
    }

    return baseUrl + 'file.png'
  }

  public static isDocument(mimeType: string) {
    return mimeTypes.DOCUMENTS.includes(mimeType)
  }

  public static getExtensionsRegex() {
    return RegExpUtils.getExtensionsRegExp()
  }

  public static getExtensionBasedOnMimeType(mimeType: string): string {
    switch (mimeType) {
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/vnd.oasis.opendocument.text':
        return '.docx'
      case 'application/pdf':
        return '.pdf'
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.oasis.opendocument.presentation':
      case 'application/vnd.ms-powerpoint':
        return '.pptx'
      case 'application/vnd.ms-excel':
      case 'application/vnd.oasis.opendocument.spreadsheet':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return '.xlsx'
      case 'application/x-bzip':
      case 'application/x-bzip2':
      case 'application/x-7z-compressed':
      case 'application/zip':
      case 'application/x-tar':
      case 'application/vnd.rar':
      case 'application/gzip':
        return '.zip'
      case 'text/plain':
        return '.txt'
      case 'text/csv':
        return '.csv'
      case 'image/png':
        return '.png'
      case 'image/jpeg':
      case 'image/jpg':
        return '.jpg'
      default:
        return '.txt'
    }
  }

  public static uploadFailedImage(baseUrl: string) {
    return baseUrl + 'uploadFailed.svg'
  }
}

export class MonitorMediaSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  @IsEnum(MediaType, { each: true })
  @ValidateIf(search => search.types?.length)
  types?: MediaType[]

  @IsNotEmpty()
  @IsUUID()
  siteVisitId: string

  @IsOptional()
  @IsUUID()
  workingVisitId?: string

  @IsOptional()
  @IsUUID()
  eventId?: string

  @IsOptional()
  @IsUUID()
  sectionId?: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @IsEnum(MediaResource, { each: true })
  @ValidateIf(search => search.owners?.length)
  owners?: MediaResource[]
}
