import { Injectable } from '@angular/core'
import { ActionPointDTO, ActionPointType } from '@uefa-svr/contracts'
import { Subject } from 'rxjs'

export enum ActionPointEventAction {
  CREATE = 'CREATE',
  CREATED = 'CREATED',
  SELECTED = 'SELECTED',
  CLOSED = 'CLOSED',
  CHANGED = 'CHANGED',
}

export class ActionPointEvent {
  action: ActionPointEventAction
  type?: ActionPointType
  dto?: ActionPointDTO
  old?: ActionPointDTO
  id?: string
}

@Injectable({ providedIn: 'root' })
export class ActionPointManageService {
  public actionPointsOpen: boolean
  public actionPointsOpenChange = new Subject<boolean>()
  public actionPointEvent = new Subject<ActionPointEvent>()

  /* Action Points */
  public toggleActionPoints() {
    if (this.actionPointsOpen) {
      this.closeActionPoints()
    } else {
      this.openActionPoints()
    }
  }

  public openActionPoints() {
    this.actionPointsOpen = true
    this.actionPointsOpenChange.next(true)
  }

  public closeActionPoints() {
    this.actionPointsOpen = false
    this.actionPointsOpenChange.next(false)
  }

  public actionPointChanged(dto: ActionPointDTO, old: ActionPointDTO) {
    this.actionPointEvent.next({
      id: old?.id,
      dto,
      old,
      action: ActionPointEventAction.CHANGED,
    })
  }

  public actionPointCreated(actionPoint: ActionPointDTO) {
    this.actionPointEvent.next({
      dto: actionPoint,
      action: ActionPointEventAction.CREATED,
    })
  }

  public actionPointCreate(type: ActionPointType) {
    this.actionPointEvent.next({
      id: null,
      type,
      action: ActionPointEventAction.CREATE,
    })
  }

  public actionPointSelect(actionPointId: string) {
    this.actionPointEvent.next({
      id: actionPointId,
      action: ActionPointEventAction.SELECTED,
    })
  }

  public actionPointClose() {
    this.actionPointEvent.next({
      action: ActionPointEventAction.CLOSED,
    })
  }
}
