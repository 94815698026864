import { Type } from 'class-transformer'
import { IsArray, IsBooleanString, IsOptional, IsString, IsUUID, ValidateNested } from 'class-validator'
import { BaseDTO } from '../shared'
import { LayerTypeFieldValueDTO } from './layer-type-field-value.dto'
import { LayerTypeSymbologyLineDTO, LayerTypeSymbologyLineUpdateDTO } from './layer-type-symbology-line.dto'
import { LayerTypeSymbologyPointDTO, LayerTypeSymbologyPointUpdateDTO } from './layer-type-symbology-point.dto'
import { LayerTypeSymbologyPolygonDTO, LayerTypeSymbologyPolygonUpdateDTO } from './layer-type-symbology-polygon.dto'

export enum LayerTypeSymbologyType {
  POINT = 'point',
  LINE = 'line',
  POLYGON = 'polygon',
}

export class LayerTypeSymbologySymbolDTO extends BaseDTO {
  symbol: string
  startSymbol: boolean
  endSymbol: boolean
  pointSymbol: boolean
}

export class LayerTypeSymbologySymbolSearchDTO {
  @IsOptional()
  @IsBooleanString()
  startSymbol?: boolean

  @IsOptional()
  @IsBooleanString()
  endSymbol?: boolean

  @IsOptional()
  @IsBooleanString()
  pointSymbol?: boolean
}

export class LayerTypeSymbologyDTO extends BaseDTO {
  layerTypeValueId: string
  type: LayerTypeSymbologyType
  point: LayerTypeSymbologyPointDTO
  line: LayerTypeSymbologyLineDTO
  polygon: LayerTypeSymbologyPolygonDTO
}

export class LayerTypeSymbologyUpdateDTO {
  @IsUUID()
  @IsOptional()
  id?: string

  @IsString()
  @IsOptional()
  layerTypeValueId: string

  @ValidateNested({ each: true })
  @Type(() => LayerTypeSymbologyPointUpdateDTO)
  @IsOptional()
  point: LayerTypeSymbologyPointUpdateDTO

  @ValidateNested({ each: true })
  @Type(() => LayerTypeSymbologyLineUpdateDTO)
  @IsOptional()
  line: LayerTypeSymbologyLineUpdateDTO

  @ValidateNested({ each: true })
  @Type(() => LayerTypeSymbologyPolygonUpdateDTO)
  @IsOptional()
  polygon: LayerTypeSymbologyPolygonUpdateDTO

  @IsArray()
  projects: string[] = []

  constructor(symbology?: LayerTypeSymbologyDTO, value?: LayerTypeFieldValueDTO) {
    if (symbology) {
      this.id = symbology.id
      this.layerTypeValueId = symbology.layerTypeValueId

      if (symbology.point) {
        this.point = new LayerTypeSymbologyPointUpdateDTO(symbology.point)
      }

      if (symbology.line) {
        this.line = new LayerTypeSymbologyLineUpdateDTO(symbology.line)
      }

      if (symbology.polygon) {
        this.polygon = new LayerTypeSymbologyPolygonUpdateDTO(symbology.polygon)
      }
    }

    if (value) {
      this.projects = value.projects
    }
  }
}
