import { ArrayParse, BooleanParse, LabelValueDTO, PaginatedSearchDTO } from '@uefa-shared/contracts'
import { IsEnum, IsOptional, IsUUID } from 'class-validator'
import { QuestionListDTO, QuestionSectionDTO } from '../question/question.dto'
import { SiteVisitType } from '../shared/enums'

export enum SiteVisitSearchSortField {
  TITLE = 'title',
  TYPE = 'type',
  COMPETITION = 'competitions',
  EVENT = 'events',
  VENUE = 'venueName',
  SITE = 'siteName',
  TEAM = 'teamName',
  NEXT_VISIT = 'nextVisit',
}

export class SectionTemplateWorkingVisitInfo {
  workingVisitId: string
  workingVisitCode: string
  excluded: boolean
}
export class QuestionTemplateWorkingVisitInfo {
  workingVisitId: string
  workingVisitCode: string
  numberOfQuestions: number
  excluded: boolean
}

export class CustomLabelValueDTO extends LabelValueDTO {
  description?: string

  constructor(value?: string, label?: string, description?: string) {
    super(value, label)
    this.description = description
  }
}

export class QuestionTemplateDTO extends QuestionListDTO {
  section: SectionTemplateDTO
  order: number
  visits: QuestionTemplateWorkingVisitInfo[]
  projects: CustomLabelValueDTO[]
}

export class SectionTemplateDTO extends QuestionSectionDTO {
  visits: SectionTemplateWorkingVisitInfo[]
  order: number
}

export class QuestionTemplateSearchDTO {
  @IsOptional()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @ArrayParse()
  siteVisitTypes?: string[]

  @IsOptional()
  @IsUUID()
  siteVisitId?: string

  @IsOptional()
  @ArrayParse()
  ids?: string[]

  @IsOptional()
  @ArrayParse()
  projectIds?: string[]
}

export class SiteVisitSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsEnum(SiteVisitType)
  type?: string

  @IsOptional()
  @ArrayParse()
  competitionsIds?: string[]

  @IsOptional()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  eventCycleId?: string

  @IsOptional()
  @ArrayParse()
  venueIds?: string[]

  @IsOptional()
  @ArrayParse()
  siteIds?: string[]

  @IsOptional()
  @ArrayParse()
  teamIds?: string[]

  @IsOptional()
  @IsEnum(SiteVisitSearchSortField)
  sortBy?: string

  @IsOptional()
  @BooleanParse()
  completed?: boolean

  @IsOptional()
  @BooleanParse()
  withActionPoints?: boolean
}
