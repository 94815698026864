export enum SVROrganization {
  UEFA_ON_SITE_COMPETENCE_CENTRE = 'UEFA_ON_SITE_COMPETENCE_CENTRE',
  BAILIFF_INSURANCE_COMPANY = 'BAILIFF_INSURANCE_COMPANY',
  UEFA = 'UEFA',
  STADIUM = 'STADIUM',
  THIRD_PARTY = 'THIRD_PARTY',
}

export class SVROrganizationUtils {
  public static getOrganizationLabel(org: SVROrganization) {
    switch (org) {
      case SVROrganization.BAILIFF_INSURANCE_COMPANY:
        return 'Bailiff / Insurance Company'
      case SVROrganization.STADIUM:
        return 'Venue'
      case SVROrganization.THIRD_PARTY:
        return '3rd Party'
      case SVROrganization.UEFA:
        return 'UEFA'
      case SVROrganization.UEFA_ON_SITE_COMPETENCE_CENTRE:
        return 'LOS / LOC / NA'
    }
  }
}
