import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { SelectOption } from '@uefa-shared/frontend'
import { ActionPointStatus } from '@uefa-svr/contracts'

@Component({
  templateUrl: './status-dialog.component.html',
  styleUrls: ['./status-dialog.component.scss'],
})
export class ActionPointsTableStatusDialogComponent {
  public statusOptions: SelectOption<string>[] = Object.keys(ActionPointStatus).map((p) => ({
    label: p,
    value: p,
  }))

  public status: ActionPointStatus = ActionPointStatus.TO_DO

  constructor(private readonly dialogRef: MatDialogRef<ActionPointsTableStatusDialogComponent, ActionPointStatus>) {}

  public closeDialog() {
    this.dialogRef.close(null)
  }
}
