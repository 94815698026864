export enum NotFound {
  SITE_VISIT = 'SITE_VISIT',
  WORKING_VISIT = 'WORKING_VISIT',
  ACTION_POINT = 'ACTION_POINT',
  MEDIA = 'MEDIA',
  WORKING_VISIT_QUESTION_FIELD = 'WORKING_VISIT_QUESTION_FIELD',
  WORKING_VISIT_QUESTION = 'WORKING_VISIT_QUESTION',
  WORKING_VISIT_CHANGE = 'WORKING_VISIT_CHANGE',
  ASSIGNED_TO_PROJECT = 'ASSIGNED_TO_PROJECT',
  PROJECT = 'PROJECT',
  SECTION = 'SECTION',
  KEY = 'KEY',
  LEVEL = 'LEVEL',
  QUESTION = 'QUESTION',
  VENUE = 'VENUE',
  EVENT = 'EVENT',
  SITE = 'SITE',
}
