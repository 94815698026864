import { ArrayParse, PaginatedSearchDTO, StringCleanup, StringTrim } from '@uefa-shared/contracts'
import { IsArray, IsEnum, IsNotEmpty, IsOptional, IsUUID, ValidateIf } from 'class-validator'
import { SVROrganization } from '../shared'

export enum MainChangeSortBy {
  APPROVAL = 'approval',
  WORKING_VISIT = 'workingVisit',
  IMPACT = 'impact',
  CHANGES = 'changes',
  TOPIC = 'topic',
}

export class MainChangeSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsEnum(MainChangeSortBy)
  @ValidateIf(ap => !!ap.sortBy)
  sortBy?: string

  @IsNotEmpty()
  @IsUUID()
  siteVisitId: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  workingVisitIds?: string[]

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  topic?: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  changes?: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  impact?: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @IsEnum(SVROrganization, { each: true })
  @ValidateIf(mC => !!mC.approval)
  approval?: SVROrganization[]
}

export class MainChangeDTO {
  id: string
  workingVisitId: string
  workingVisitCode: string
  topic?: string
  changes: string
  impact?: string
  approval?: SVROrganization
}

export class MainChangeUpdateDTO {
  @IsOptional()
  @StringTrim()
  @StringCleanup()
  topic?: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  changes?: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  impact?: string

  @IsOptional()
  @IsEnum(SVROrganization)
  @ValidateIf(mC => !!mC.approval)
  approval?: SVROrganization

  constructor(mainChange?: MainChangeDTO) {
    if (mainChange) {
      this.topic = mainChange.topic
      this.approval = mainChange.approval
      this.changes = mainChange.changes
      this.impact = mainChange.impact
    }
  }
}

export class MainChangeCreateDTO extends MainChangeUpdateDTO {
  @IsNotEmpty()
  @IsUUID()
  workingVisitId: string

  @IsNotEmpty()
  @StringTrim()
  @StringCleanup()
  changes: string

  @IsNotEmpty()
  @StringTrim()
  @StringCleanup()
  topic?: string

  @IsNotEmpty()
  @StringTrim()
  @StringCleanup()
  impact?: string

  constructor(mainChange?: MainChangeDTO) {
    super(mainChange)
    if (mainChange) {
      this.workingVisitId = mainChange.workingVisitId
    }
  }
}
