import {
  ArrayParse,
  BooleanParse,
  Hatch,
  LabelValueDTO,
  PaginatedSearchDTO,
  PaginatedSearchFilterDTO,
  StringTrim,
} from '@uefa-shared/contracts'
import { StringCleanup } from '@uefa-vista/contracts'
import { Type } from 'class-transformer'
import {
  IsArray,
  IsBoolean,
  IsDateString,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsUUID,
  ValidateIf,
  ValidateNested,
} from 'class-validator'
import { BaseConfigurationDTO } from '../entity-configurations'
import { ActionPointCommentDTO, SVRMediaDTO, SiteVisitType } from '../shared'

export enum ActionPointResourceType {
  ICON = 'ICON',
}

export class ActionPointLocationLevel {
  @IsNotEmpty()
  @IsUUID()
  id: string

  @IsNotEmpty()
  @StringTrim()
  @StringCleanup()
  description: string

  constructor(id?: string) {
    this.id = id
  }
}
export class ActionPointLocationFacility {
  @IsNotEmpty()
  @IsUUID()
  id: string

  @IsNotEmpty()
  @IsUUID()
  keyId: string

  @IsNotEmpty()
  @IsArray()
  @IsUUID('4', { each: true })
  projectIds: string[]
}
export class ActionPointLocation {
  @IsNotEmpty()
  @StringTrim()
  @StringCleanup()
  name: string

  @IsOptional()
  @ValidateIf(location => !!location.level)
  level?: ActionPointLocationLevel

  @IsNotEmpty()
  @IsNumber()
  latitude: number

  @IsNotEmpty()
  @IsNumber()
  longitude: number
}

export enum ActionPointStatus {
  DRAFT = 'DRAFT',
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  DROPPED = 'DROPPED',
}

export enum ActionPointPriority {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum ActionPointStand {
  MAIN = 'MAIN',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  OPPOSITE = 'OPPOSITE',
}

export enum ActionPointSortBy {
  TITLE = 'title',
  STATUS = 'status',
  PRIORITY = 'priority',
  DUE_DATE = 'due_date',
  VENUE = 'venue',
  EVENTS = 'events',
  WORKING_VISIT = 'workingVisit',
  ASSIGNED = 'assigned',
  RAISED_BY = 'raised_by',
  INFORMED = 'informed',
  CODE = 'code',
  KEY = 'key',
  UPDATED_AT = 'updated_at',
  CREATED_BY = 'createdBy',
  CREATED_AT = 'createdAt',
}

export enum ActionPointType {
  ACTION_POINT = 'AP',
  DAMAGE_REPORT = 'DR',
  INJURY_REPORT = 'IR',
}

export class ActionPointKey {
  constructor(public value: string, public label: string, public color: string, public hatch: Hatch, public hatchColor: string) {}
}

export class ActionPointUtils {
  public static readonly requiredFieldsActionPoint: (keyof ActionPointCreateDTO)[] = [
    'title',
    'assignedToId',
    'dueDate',
    'description',
    'priority',
    'projectId',
  ]

  public static readonly requiredFieldsDamageReport: (keyof ActionPointCreateDTO)[] = [
    'title',
    'assignedToId',
    'damageDate',
    'description',
    'priority',
    'projectId',
    'damageCausedBy',
    'uefaRepresentative',
  ]

  public static readonly requiredFieldsInjuryReport: (keyof ActionPointCreateDTO)[] = [
    'title',
    'assignedToId',
    'damageDate',
    'description',
    'priority',
    'status',
    'projectId',
    'damageCausedBy',
  ]

  public static readonly invalidStatus: ActionPointStatus[] = [ActionPointStatus.DRAFT, ActionPointStatus.DROPPED]

  public static statusLabel(status: ActionPointStatus): string {
    switch (status) {
      case ActionPointStatus.DONE:
        return 'Done'
      case ActionPointStatus.DROPPED:
        return 'Dropped'
      case ActionPointStatus.IN_PROGRESS:
        return 'In Progress'
      case ActionPointStatus.DRAFT:
        return 'Draft'
      case ActionPointStatus.TO_DO:
        return 'To Do'
    }
  }

  public static priorityLabel(priority: ActionPointPriority): string {
    switch (priority) {
      case ActionPointPriority.HIGH:
        return 'High'
      case ActionPointPriority.MEDIUM:
        return 'Medium'
      default:
        return 'Low'
    }
  }

  public static statusAsOptions() {
    return Object.keys(ActionPointStatus).map(k => ({
      label: k,
      value: ActionPointStatus[k],
    }))
  }

  public static standAsOptions() {
    return Object.keys(ActionPointStand).map(k => ({
      label: k,
      value: ActionPointStand[k],
    }))
  }

  public static priorityAsOptions() {
    return Object.keys(ActionPointPriority).map(k => ({
      label: k,
      value: ActionPointPriority[k],
    }))
  }

  public static getActiveActionPointStatus(): ActionPointStatus[] {
    return [ActionPointStatus.IN_PROGRESS, ActionPointStatus.TO_DO]
  }

  public static getRequiredFieldsBySiteVisitType(
    model: ActionPointCreateDTO,
    siteVisitType: SiteVisitType
  ): (keyof ActionPointCreateDTO)[] {
    if (siteVisitType === SiteVisitType.HANDOVER_HANDBACK && !model.isInjuryReport) {
      return this.requiredFieldsDamageReport
    }

    if (siteVisitType === SiteVisitType.HANDOVER_HANDBACK && model.isInjuryReport) {
      return this.requiredFieldsInjuryReport
    }

    return this.requiredFieldsActionPoint
  }

  public static validateActionPoint(model: ActionPointCreateDTO, siteVisitType: SiteVisitType) {
    const requiredFields = this.getRequiredFieldsBySiteVisitType(model, siteVisitType)
    const modelKeys = Object.keys(model)

    const modelHasRequiredProperties = requiredFields.every(f => modelKeys.includes(f))

    const isValid = modelKeys.every((k: keyof ActionPointCreateDTO) => {
      if (!requiredFields.includes(k)) return true
      return model[k] !== null && model[k] !== undefined && model[k] !== ''
    })

    return modelHasRequiredProperties && isValid
  }

  public static priorityColorClass(priority: ActionPointPriority): string {
    switch (priority) {
      case ActionPointPriority.HIGH:
        return 'orange'
      case ActionPointPriority.MEDIUM:
        return 'yellow'
      case ActionPointPriority.LOW:
        return 'gray'
    }
  }

  public static getMarkerUrl(status: ActionPointStatus, priority: ActionPointPriority): string {
    if (!status || !priority) return ''
    return `icon/action-point/marker?status=${status}${priority ? `&priority=${priority}` : ''}`
  }

  public static getRemoveMarkerUrl(): string {
    return `icon/action-point/marker/remove`
  }
}

export class ActionPointResourceSearchDTO {
  @IsNotEmpty()
  @IsEnum(ActionPointResourceType)
  type: ActionPointResourceType

  @IsNotEmpty()
  @IsEnum(ActionPointStatus)
  status: ActionPointStatus

  @IsNotEmpty()
  @IsEnum(ActionPointPriority)
  priority: ActionPointPriority
}

export class ActionPointSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsEnum(ActionPointSortBy)
  @ValidateIf(ap => !!ap.sortBy)
  sortBy?: string

  @IsOptional()
  @IsUUID()
  siteVisitId?: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  siteVisitIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  venueIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  workingVisitIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  workingVisitQuestionIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  sectionIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  informedIds?: string[]

  @StringTrim()
  @IsOptional()
  code?: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  keyIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  createdBy?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  updatedBy?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  projectIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  assignedToIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @IsEnum(ActionPointStatus, { each: true })
  @ValidateIf(ap => !!ap.status?.length)
  status?: ActionPointStatus[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @IsEnum(ActionPointPriority, { each: true })
  @ValidateIf(ap => !!ap.priority?.length)
  priority?: ActionPointPriority[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @IsEnum(SiteVisitType, { each: true })
  @ValidateIf(ap => !!ap.siteVisitType?.length)
  siteVisitTypes?: SiteVisitType[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @IsEnum(ActionPointType, { each: true })
  @ValidateIf(ap => !!ap.types?.length)
  types?: ActionPointType[]

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  allEvents?: boolean

  @StringTrim()
  @IsOptional()
  title?: string

  @IsOptional()
  customFilters?: PaginatedSearchFilterDTO

  @IsOptional()
  @ArrayParse()
  excludedIds?: string[]
}

export enum ExportFormat {
  XLSX = 'csv',
  PDF = 'pdf',
}

export enum ExportType {
  DAMAGE_REPORT = 'DAMAGE_REPORT',
  ACTION_POINT_REPORT = 'ACTION_POINT_REPORT',
  SV_REPORT = 'SV_REPORT',
}

export class ActionPointExportSearchDTO extends ActionPointSearchDTO {
  @IsNotEmpty()
  @IsEnum(ExportFormat)
  format: ExportFormat

  constructor() {
    super()
    this.format = ExportFormat.XLSX
  }
}

export class ActionPointListDTO {
  id: string
  location?: ActionPointLocation
  title: string
  workingVisitId: string
  workingVisitCode: string
  workingVisit?: LabelValueDTO
  code: string
  type: ActionPointType
  key: ActionPointKey
  assignedToId?: string
  assignedToLabel?: string
  status: ActionPointStatus
  priority: ActionPointPriority
  canEdit?: boolean
  raisedById?: string
  raisedByLabel?: string
  informedIds?: string[]
  informedLabels?: string[]
  dueDate?: Date
  venueId?: string
  venueName?: string
  siteId?: string
  siteName?: string
  eventNames?: string
  eventIds?: string[]
  siteVisitId?: string
  siteVisitName?: string
  levelId?: string
  lastComment?: ActionPointCommentDTO

  createdBy?: string
  createdAt?: Date
  updatedBy?: string
  updatedAt?: Date
}

export class ActionPointCountDTO {
  regularApCount: number
  hohbApCount: number
  irApCount: number
}

export class ActionPointDTO {
  id: string
  title: string
  workingVisit: LabelValueDTO
  code: string
  type: ActionPointType
  status: ActionPointStatus
  priority: ActionPointPriority
  siteVisit: LabelValueDTO
  venue: LabelValueDTO
  site: LabelValueDTO
  events: LabelValueDTO[]
  updatedAt: Date
  createdAt: Date
  location?: ActionPointLocation
  key?: ActionPointKey
  project?: LabelValueDTO
  informed?: LabelValueDTO[]
  stand?: ActionPointStand
  description?: string
  updatedBy?: string
  createdBy?: string
  assignedTo?: LabelValueDTO
  dueDate?: Date
  damageDate?: Date
  question?: LabelValueDTO
  section?: LabelValueDTO
  level?: LabelValueDTO
  damageCausedBy?: string
  damageAccepted?: boolean
  uefaComment?: string
  uefaRepresentative?: string
  stadiumRepresentative?: string
  localCurrency?: LabelValueDTO
  localCost?: number
  cost?: number
  images: SVRMediaDTO[]
  documents: SVRMediaDTO[]
  signatures: SVRMediaDTO[]
  canEdit: boolean
  isInjuryReport: boolean
  comments: ActionPointCommentDTO[]
  numberComments?: number
  lastComment?: ActionPointCommentDTO
}

export class ActionPointUpdateDTO {
  @IsOptional()
  @IsUUID()
  workingVisitId?: string

  @IsNotEmpty()
  @StringTrim()
  @StringCleanup()
  @ValidateIf(ap => !ActionPointUtils.invalidStatus.includes(ap.status))
  title?: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  description?: string

  @IsOptional()
  @IsEnum(ActionPointStand)
  stand?: ActionPointStand

  @IsOptional()
  @IsUUID()
  localCurrencyId?: string

  @IsOptional()
  @IsNumber()
  localCost?: number

  @IsOptional()
  @IsNumber()
  cost?: number

  @IsNotEmpty()
  @IsEnum(ActionPointStatus)
  status?: ActionPointStatus

  @IsNotEmpty()
  @IsUUID()
  @ValidateIf(ap => !ActionPointUtils.invalidStatus.includes(ap.status))
  assignedToId?: string

  @IsOptional()
  @IsUUID()
  keyId?: string

  @IsOptional()
  @IsUUID()
  @ValidateIf(ap => ap.levelId !== 'OV')
  levelId?: string

  @IsOptional()
  @IsUUID('all', { each: true })
  informedIds?: string[]

  @IsOptional()
  @IsUUID()
  projectId?: string

  @IsNotEmpty()
  @IsEnum(ActionPointPriority)
  @ValidateIf(ap => !ActionPointUtils.invalidStatus.includes(ap.status))
  priority?: ActionPointPriority

  @IsOptional()
  @IsUUID()
  workingVisitQuestionId?: string

  @IsOptional()
  @IsUUID()
  sectionId?: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  damageCausedBy?: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  uefaComment?: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  uefaRepresentative?: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  stadiumRepresentative?: string

  @IsOptional()
  @IsBoolean()
  damageAccepted?: boolean

  @IsOptional()
  @IsBoolean()
  isInjuryReport?: boolean

  @IsOptional()
  @IsDateString()
  damageDate?: Date

  @IsOptional()
  @IsDateString()
  dueDate?: Date

  @IsOptional()
  @ValidateIf(ap => !!ap.location)
  location?: ActionPointLocation

  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ValidateNested({ each: true })
  @Type(() => SVRMediaDTO)
  @ValidateIf(ap => !ActionPointUtils.invalidStatus.includes(ap.status))
  images: SVRMediaDTO[]

  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ValidateNested({ each: true })
  @Type(() => SVRMediaDTO)
  @ValidateIf(ap => !ActionPointUtils.invalidStatus.includes(ap.status))
  signatures: SVRMediaDTO[]

  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ValidateNested({ each: true })
  @Type(() => SVRMediaDTO)
  @ValidateIf(ap => !ActionPointUtils.invalidStatus.includes(ap.status))
  documents: SVRMediaDTO[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @ValidateNested({ each: true })
  @Type(() => ActionPointCommentDTO)
  comments: ActionPointCommentDTO[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @IsUUID('all', { each: true })
  eventIds: string[]

  @IsOptional()
  @IsUUID()
  venueId?: string

  @IsOptional()
  @IsUUID()
  siteId?: string

  key?: LabelValueDTO
  level?: LabelValueDTO
  informed?: LabelValueDTO[]
  project?: LabelValueDTO
  workingVisitQuestion?: LabelValueDTO
  section?: LabelValueDTO
  assignedTo?: LabelValueDTO
  numberComments?: number

  lastComment?: ActionPointCommentDTO

  constructor(actionPoint?: ActionPointDTO) {
    if (actionPoint) {
      this.title = actionPoint.title
      this.workingVisitId = actionPoint.workingVisit?.value
      this.description = actionPoint.description
      this.status = actionPoint.status
      this.assignedToId = actionPoint.assignedTo?.value
      this.keyId = actionPoint.key?.value
      this.levelId = actionPoint.level?.value
      this.projectId = actionPoint.project?.value
      this.informedIds = actionPoint.informed?.map(i => i.value)
      this.priority = actionPoint.priority
      this.workingVisitQuestionId = actionPoint.question?.value
      this.sectionId = actionPoint.section?.value
      this.damageCausedBy = actionPoint.damageCausedBy
      this.uefaComment = actionPoint.uefaComment
      this.uefaRepresentative = actionPoint.uefaRepresentative
      this.stadiumRepresentative = actionPoint.stadiumRepresentative
      this.damageAccepted = actionPoint.damageAccepted
      this.damageDate = actionPoint.damageDate
      this.dueDate = actionPoint.dueDate
      this.location = actionPoint.location ?? new ActionPointLocation()
      this.stand = actionPoint.stand
      this.localCurrencyId = actionPoint.localCurrency?.value
      this.localCost = actionPoint.localCost
      this.cost = actionPoint.cost
      this.images = actionPoint.images
      this.documents = actionPoint.documents
      this.signatures = actionPoint.signatures
      this.key = actionPoint.key
      this.level = actionPoint.level
      this.informed = actionPoint.informed
      this.project = actionPoint.project
      this.workingVisitQuestion = actionPoint.question
      this.section = actionPoint.section
      this.assignedTo = actionPoint.assignedTo
      this.comments = actionPoint.comments
      this.venueId = actionPoint.venue?.value
      this.siteId = actionPoint.site?.value
      this.eventIds = actionPoint.events?.map(e => e.value)
    } else {
      this.localCost = 0
      this.cost = 0
      this.images = []
      this.documents = []
      this.signatures = []
      this.location = new ActionPointLocation()
      this.comments = []
    }
    this.numberComments = actionPoint?.numberComments ?? 0
    this.lastComment = actionPoint?.lastComment ?? null
  }
}

export class ActionPointCreateDTO extends ActionPointUpdateDTO {
  @IsNotEmpty()
  @StringTrim()
  @StringCleanup()
  @ValidateIf(ap => !ActionPointUtils.invalidStatus.includes(ap.status))
  title?: string

  @IsNotEmpty()
  @IsEnum(ActionPointPriority)
  @ValidateIf(ap => !ActionPointUtils.invalidStatus.includes(ap.status))
  priority?: ActionPointPriority

  @IsNotEmpty()
  @IsEnum(ActionPointStatus)
  status?: ActionPointStatus

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  @ValidateIf(ap => !ActionPointUtils.invalidStatus.includes(ap.status))
  description?: string

  @IsOptional()
  @IsUUID()
  workingVisitId?: string

  @IsOptional()
  @IsBoolean()
  isInjuryReport?: boolean

  @IsNotEmpty()
  @StringTrim()
  @StringCleanup()
  @ValidateIf(ap => !ActionPointUtils.invalidStatus.includes(ap.status))
  projectId?: string

  constructor(actionPoint?: ActionPointDTO) {
    super(actionPoint)
    if (actionPoint) {
      this.title = actionPoint.title
      this.projectId = actionPoint.project?.value
      this.priority = actionPoint.priority
      this.status = actionPoint.status
      this.isInjuryReport = actionPoint.isInjuryReport || false
      this.assignedToId = actionPoint.assignedTo?.value
      this.description = actionPoint.description
      this.levelId = actionPoint.level?.value
    }
  }
}

export class ActionPointConfigurationDTO extends BaseConfigurationDTO {
  priority: ActionPointPriority
  status: ActionPointStatus
  // TODO: what others?
}

export class ActionPointBulkUpdateDTO extends ActionPointSearchDTO {
  @IsNotEmpty()
  @IsEnum(ActionPointStatus)
  newStatus: ActionPointStatus

  // If true, update all records that match the search options, except the ones specified in the ids property.
  @IsOptional()
  @BooleanParse()
  all?: boolean
}

export class ActionPointBulkUpdateResultDTO {
  total: number = 0
  success: number = 0
  failed: number = 0
  actionPointsFailed: LabelValueDTO[] = []
}
