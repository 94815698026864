import { ArrayParse, BooleanParse, PaginatedSearchDTO } from '@uefa-shared/contracts'
import { UserGroupRole } from '@uefa-vista/contracts'
import { IsArray, IsBoolean, IsEnum, IsOptional, IsUUID } from 'class-validator'

export class UserSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @IsUUID()
  venueId?: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  projectIds?: string[]

  @IsOptional()
  @IsEnum(UserGroupRole)
  role?: UserGroupRole
}

export class UserEmailSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  userIds?: string[]
}

export class UserPaginatedSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  withActionPoints?: boolean
}
