export class FileUtils {
  public static downloadFile(data: ArrayBuffer, name: string) {
    const blob = new Blob([new Uint8Array(data)], { type: 'octet/stream' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = name
    link.click()
    URL.revokeObjectURL(url)
  }
}
