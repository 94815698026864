export enum ApplicationName {
  PORTAL = 'portal',
  MANAGER = 'manager',
  AM = 'am',
  SVR = 'svr',
  VISTA = 'vista',
  DESIGNER = 'designer',
  ERT = 'ert',
  ACTIVITY_PLAN = 'activity-plan',
  MATCH_DAY_REPORT = 'match-day-report',
  INCIDENTS = 'incidents',
  BROADCASTERS = 'broadcasters',
  IOS_VISTA_APP = 'ios-vista-app',
  ANDROID_VISTA_APP = 'android-vista-app',
  LIVE_LOGGING = 'live-logging',
  EVENT_REPORTING = 'event-reporting',
  M2M_PRIMARY = 'uefa-m2m-primary',
}

export class ApplicationDTO {
  id?: string
  name?: ApplicationName
  longName?: string
  description?: string
  url?: string
  icon?: string
  dashboard?: string
}
