import { Type } from 'class-transformer'
import { IsNotEmpty, IsOptional, IsString, IsUUID, ValidateNested } from 'class-validator'
import { BaseDTO, BaseParametersDTO } from '../shared'
import { LayerTypeSymbologyDTO, LayerTypeSymbologyUpdateDTO } from './layer-type-symbology.dto'

export class FindFieldValuesParametersDTO extends BaseParametersDTO {
  @IsUUID()
  fieldId: string
}

export class LayerTypeFieldValueDTO extends BaseDTO {
  name: string
  order: number
  default: boolean
  projects?: string[]
}

export class LayerTypeFieldValueCreateDTO {
  @IsString()
  @IsNotEmpty()
  name: string

  @ValidateNested()
  @Type(() => LayerTypeSymbologyUpdateDTO)
  symbology?: LayerTypeSymbologyUpdateDTO
}

export class LayerTypeFieldValueUpdateDTO extends LayerTypeFieldValueCreateDTO {
  @IsUUID()
  @IsOptional()
  id: string

  constructor(value?: LayerTypeFieldValueDTO, symbology?: LayerTypeSymbologyDTO) {
    super()

    if (value) {
      this.id = value.id
      this.name = value.name

      if (symbology) {
        this.symbology = new LayerTypeSymbologyUpdateDTO(symbology, value)
      }
    }
  }
}
