import { Type } from 'class-transformer'
import {
  ArrayMinSize,
  IsArray,
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Max,
  Min,
  ValidateNested,
} from 'class-validator'
import { LatLng } from '../geo'
import { GeoFilterDTO } from '../level-facility'

export enum PrintViewMode {
  CurrentView,
  Site,
}

export type BaseMapType = 'google-roadmap' | 'google-hybrid' | 'uefa-light' | 'uefa-dark'
export type LayerPrintImageFormatType = 'image/png' | 'image/jpeg' | 'image/svg'

export class PrintBoxSize {
  public static widthHeightRatio = 1.15

  @IsNumber()
  @Min(0)
  width: number

  @IsNumber()
  @Min(0)
  height: number

  constructor(width?: number, height?: number) {
    this.width = width || 0
    this.height = height || 0
  }

  public getRotationBuffers(rotationDegrees: number): { bufferWidth: number; bufferHeight: number } {
    let bufferWidth = 0
    let bufferHeight = 0
    if (!rotationDegrees) {
      return { bufferWidth, bufferHeight }
    }

    let newWidth = this.width
    let newHeight = this.height

    rotationDegrees = rotationDegrees % 180
    if (rotationDegrees <= 90) {
      const rotationInRadians = (rotationDegrees * Math.PI) / 180

      newWidth = this.width * Math.cos(rotationInRadians) + this.height * Math.sin(rotationInRadians)
      newHeight = this.height * Math.cos(rotationInRadians) + this.width * Math.sin(rotationInRadians)
    } else {
      const rotationAdjusted = rotationDegrees - 90
      const rotationInRadians = (rotationAdjusted * Math.PI) / 180

      newWidth = this.height * Math.cos(rotationInRadians) + this.width * Math.sin(rotationInRadians)
      newHeight = this.width * Math.cos(rotationInRadians) + this.height * Math.sin(rotationInRadians)
    }

    if (newHeight * PrintBoxSize.widthHeightRatio > newWidth) {
      newWidth = newHeight * PrintBoxSize.widthHeightRatio
    } else {
      newHeight = newWidth / PrintBoxSize.widthHeightRatio
    }

    bufferWidth = newWidth - this.width
    bufferHeight = newHeight - this.height

    return { bufferWidth, bufferHeight }
  }

  public getRotationSizeIncreaseRatio(rotationDegrees: number): { widthRatio: number; heightRatio: number } {
    const { bufferWidth, bufferHeight } = this.getRotationBuffers(rotationDegrees)

    return {
      widthRatio: (this.width + bufferWidth) / this.width,
      heightRatio: (this.height + bufferHeight) / this.height,
    }
  }
}

export class PrintGenerateLevelDTO {
  @IsUUID()
  @IsNotEmpty()
  id: string

  @IsArray()
  blueprints: string[]

  @IsArray()
  layers: string[]
}

export class PrintGenerateDTO extends GeoFilterDTO {
  @IsUUID()
  @IsNotEmpty()
  templateId: string

  @IsUUID()
  @IsNotEmpty()
  siteId: string

  @IsUUID()
  @IsNotEmpty()
  eventId: string

  @IsBoolean()
  rotateMap: boolean

  @IsNumber()
  @Min(0)
  @Max(360)
  rotateMapValue: number

  @IsBoolean()
  displayMap: boolean

  @IsBoolean()
  displayMapPOI: boolean

  @IsBoolean()
  displayLabels: boolean

  @IsNotEmpty()
  mapType: BaseMapType

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => PrintGenerateLevelDTO)
  levels: PrintGenerateLevelDTO[]

  @IsArray()
  @ArrayMinSize(2)
  boundingBox: LatLng[]

  @IsNotEmpty()
  @Min(0)
  zoomLevel: number

  @ValidateNested()
  @Type(() => PrintBoxSize)
  boxSize: PrintBoxSize

  constructor() {
    super()
    this.rotateMap = false
    this.rotateMapValue = 0
    this.displayMap = true
    this.displayMapPOI = false
    this.displayLabels = false
    this.templateId = null
    this.levels = []
    this.boundingBox = null
    this.boxSize = null
  }
}

export class LayerPrintGenerateDTO {
  @IsNotEmpty()
  @IsUUID()
  siteId: string

  @IsNotEmpty()
  @IsUUID()
  eventId: string

  @IsNotEmpty()
  @IsArray()
  @ArrayMinSize(1)
  layerTypeShortNames: string[]

  @IsOptional()
  @IsUUID()
  levelId: string

  @IsOptional()
  @IsBoolean()
  rotateMap: boolean

  @IsOptional()
  @IsNumber()
  @Min(0)
  @Max(360)
  rotateMapValue: number

  @IsOptional()
  @IsBoolean()
  displayText: boolean

  @IsOptional()
  @IsArray()
  @ArrayMinSize(2)
  boundingBox: LatLng[]

  @IsNumber()
  @Min(0)
  width: number

  @IsNumber()
  @Min(0)
  height: number

  @IsString()
  @IsOptional()
  subEventId?: string

  @IsString()
  @IsOptional()
  templateId?: string

  @IsOptional()
  format?: LayerPrintImageFormatType

  constructor() {
    this.levelId = null
    this.rotateMapValue = 0
    this.displayText = false
    this.boundingBox = null
  }
}
