import { DatePipe } from '@angular/common'
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { TranslateService } from '@ngx-translate/core'
import { PaginatedResults, PaginatedSearchDTO } from '@uefa-shared/contracts'
import {
  SelectOption,
  TableColumn,
  TableColumnFilter,
  TableComponent,
  TableConfig,
  TableDateFilterComponentConfig,
  TableInputFilterComponentConfig,
  TableMultiSelectFilterComponentConfig,
  TablePaginationInfo,
  TableRowAction,
  TableSelected,
} from '@uefa-shared/frontend'
import {
  ActionPointBulkUpdateDTO,
  ActionPointBulkUpdateResultDTO,
  ActionPointExportSearchDTO,
  ActionPointListDTO,
  ActionPointPriority,
  ActionPointSearchDTO,
  ActionPointSortBy,
  ActionPointStatus,
  ActionPointType,
  ExportFormat,
  SVRProjectSearchDTO,
  SVRVenueSearchDTO,
  UserPaginatedSearchDTO,
  WorkingVisitSearchDTO,
} from '@uefa-svr/contracts'
import { ArrayUtils } from '@uefa-vista/contracts'
import { Observable, Subject, of } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import {
  ActionPointEventAction,
  ActionPointManageService,
  ActionPointsService,
  ConfigsService,
  DictionaryService,
  IconsService,
} from '../../services'
import { FileUtils } from '../../utils/file.utils'
import { ActionPointsTableStatusDialogComponent } from './status-dialog/status-dialog.component'
import { ActionPointsTableUpdateStatusResultDialogComponent } from './update-status-result-dialog/update-status-result-dialog.component'

export class ActionPointTableDisplayColumn {
  type: ActionPointTableDisplayColumnType
  width?: string
  smallTextMenu?: boolean
  dateFormat?: boolean
}

export enum ActionPointTableDisplayColumnType {
  location = 'location',
  title = 'title',
  workingVisit = 'workingVisit',
  status = 'status',
  priority = 'priority',
  assignedTo = 'assignedTo',
  createdBy = 'createdBy',
  updatedAt = 'updatedAt',
  dueDate = 'dueDate',
  venue = 'venue',
  event = 'event',
  raisedBy = 'raisedBy',
  identifier = 'identifier',
  createdAt = 'createdAt',
  updatedBy = 'updatedBy',
  lastComment = 'lastComment',
  informed = 'informed',
}

export enum ActionPointTableColumnTemplate {
  STATUS = 'statusTemplate',
  PRIORITY = 'priorityTemplate',
  LOCATION = 'locationTemplate',
  LAST_COMMENT = 'lastCommentTemplate',
}

const defaultColumns: ActionPointTableDisplayColumn[] = [
  { type: ActionPointTableDisplayColumnType.location },
  { type: ActionPointTableDisplayColumnType.title },
  { type: ActionPointTableDisplayColumnType.workingVisit },
  { type: ActionPointTableDisplayColumnType.status },
  { type: ActionPointTableDisplayColumnType.priority },
  { type: ActionPointTableDisplayColumnType.assignedTo },
  { type: ActionPointTableDisplayColumnType.createdBy },
  { type: ActionPointTableDisplayColumnType.updatedAt },
]

@Component({
  selector: 'svr-shared-action-points-table',
  templateUrl: './action-points-table.component.html',
  styleUrls: ['./action-points-table.component.scss'],
  providers: [DatePipe],
})
// TODO remove "shared" after removing the other action points table from Monitor
export class SharedActionPointsTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('table') table: TableComponent<ActionPointListDTO>
  @Input() actionPointTypes: ActionPointType[] = [
    ActionPointType.ACTION_POINT,
    ActionPointType.DAMAGE_REPORT,
    ActionPointType.INJURY_REPORT,
  ]
  @Input() allEvents = false
  @Input() eventIds: string[] = []
  @Input() siteVisitId: string
  @Input() workingVisits: SelectOption<string>[] = []
  @Input() keyIds: string[] = []
  @Input() selectedId: string
  @Input() displayColumns: ActionPointTableDisplayColumn[] = defaultColumns
  @Input() actions: TableRowAction<ActionPointListDTO>[]
  @Input() searchTerm = ''
  @Input() selectable = false
  @Input() isExternalFilterApplied: boolean = false
  @Input() defaultStatusFilters: ActionPointStatus[] = [ActionPointStatus.TO_DO, ActionPointStatus.IN_PROGRESS]

  @Output() actionPointClick = new EventEmitter<ActionPointListDTO>()
  @Output() locate = new EventEmitter<ActionPointListDTO>()
  @Output() paginatedChange = new EventEmitter<ActionPointSearchDTO>()
  @Output() paginationChange = new EventEmitter<TablePaginationInfo>()
  @Output() selectedChange = new EventEmitter<TableSelected<ActionPointListDTO>>()

  public dateTimeFormat = `yyyy/MM/dd HH:mm`
  public dateFormat = `yyyy/MM/dd`

  public columns: TableColumn<ActionPointListDTO>[]
  public tableConfig: TableConfig<ActionPointListDTO> = {
    actionColumnWidth: '45px',
    actionColumnSticky: true,
    actionColumnShadow: true,
    scrollable: true,
    selectable: this.selectable,
    onRowClick: (row: ActionPointListDTO) => this.onActionPointClick(row),
    rowStyleClass: (_, row) => (this.isActiveRow(row) ? 'action-point-selected' : ''),
    defaultSort: {
      field: 'title',
      order: 'ASC',
    },
  }

  public selection: TableSelected<ActionPointListDTO> = {
    allSelected: false,
    data: [],
    excluded: [],
  }

  private workingVisitIds: string[]
  private venueIds: string[]
  private assignedToIds: string[]
  private raisedByIds: string[]
  private informedIds: string[]
  private createdByUpns: string[]
  private updatedByUpns: string[]
  private title: string
  private code: string

  private status: SelectOption<ActionPointStatus>[]
  private priority: ActionPointPriority[]
  private lastActionPointPaginatedOptions: ActionPointSearchDTO = null

  private $ngUnsubscribe = new Subject<void>()

  constructor(
    private readonly actionPointManageService: ActionPointManageService,
    private readonly actionPointsService: ActionPointsService,
    private readonly translateService: TranslateService,
    private readonly dictionaryService: DictionaryService,
    private readonly datePipe: DatePipe,
    private readonly iconsService: IconsService,
    private readonly dialog: MatDialog,
    private readonly configsService: ConfigsService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    const actionPointTypes =
      changes.actionPointTypes && !ArrayUtils.isArrayEqual(changes.actionPointTypes.currentValue, changes.actionPointTypes.previousValue)
    const allEvents = changes.allEvents && changes.allEvents.currentValue !== changes.allEvents.previousValue
    const eventIds = changes.eventIds && !ArrayUtils.isArrayEqual(changes.eventIds.currentValue, changes.eventIds.previousValue)
    const searchTermChange = changes.searchTerm && changes.searchTerm.currentValue !== changes.searchTerm.previousValue
    const displayColumChange =
      changes.displayColumns && changes.displayColumns.currentValue?.length !== changes.displayColumns.previousValue?.length
    const selectableChange = changes.selectable && changes.selectable.currentValue !== changes.selectable.previousValue

    if (actionPointTypes || allEvents || eventIds || searchTermChange) {
      this.table?.search(this.searchTerm ?? ' ')
    }

    if (displayColumChange) {
      this.setupTableConfig(true)
    }

    if (selectableChange) {
      this.tableConfig = { ...this.tableConfig, selectable: this.selectable }
    }
  }

  public ngOnInit(): void {
    this.status = this.getStatusOptions(this.defaultStatusFilters)
    this.configsService.findAndSaveConfigs()
    this.setupTableConfig()
    this.setupListeners()
  }

  public ngOnDestroy() {
    this.$ngUnsubscribe.next()
    this.$ngUnsubscribe.complete()
  }

  public reloadTableData() {
    this.table?.search('')
  }

  public isActiveRow(row: ActionPointListDTO): boolean {
    return this.selectedId === row.id
  }

  public hasActionPointWithLocation() {
    return this.table?.data?.some((d) => d.location)
  }

  public getStatusIcon(status: ActionPointStatus) {
    return this.iconsService.getActionPointStatus(status)
  }

  public getPriorityIcon(priority: ActionPointPriority) {
    return this.iconsService.getActionPointPriority(priority)
  }

  public getActionPointStatusString(value: ActionPointStatus): string {
    return this.translateService.instant('shared.enums.actionPointStatus.' + value)
  }

  public getActionPointPriorityString(value: ActionPointPriority): string {
    return this.translateService.instant('shared.enums.actionPointPriority.' + value)
  }

  public async onActionPointClick(actionPoint: ActionPointListDTO) {
    const isSameRecord = actionPoint.id === this.selectedId
    this.selectedId = isSameRecord ? null : actionPoint.id
    this.actionPointClick.emit(isSameRecord ? null : actionPoint)
  }

  public onLocateActionPoint(actionPoint: ActionPointListDTO) {
    this.locate.emit(actionPoint)
  }

  public resetFilters() {
    this.table.resetFilters()
    this.setTableColumns()
  }

  public onSelectedChange(event: TableSelected<ActionPointListDTO>) {
    this.selection = event
    this.selectedChange.emit(event)
  }

  public loadMore = (options: PaginatedSearchDTO): Observable<PaginatedResults<ActionPointListDTO>> => {
    this.lastActionPointPaginatedOptions = {
      ...options,
      sortBy: this.sortByToActionPointSortBy(options.sortBy as keyof ActionPointListDTO),
      searchTerm: this.searchTerm ?? undefined,
      title: this.title ?? undefined,
      workingVisitIds: this.workingVisitIds?.length ? this.workingVisitIds : undefined,
      venueIds: this.venueIds?.length ? this.venueIds : undefined,
      status: this.status?.length ? this.status.map((s) => s.value) : undefined,
      priority: this.priority?.length ? this.priority : undefined,
      assignedToIds: this.assignedToIds?.length ? this.assignedToIds : undefined,
      projectIds: this.raisedByIds?.length ? this.raisedByIds : undefined,
      informedIds: this.informedIds?.length ? this.informedIds : undefined,
      types: this.actionPointTypes?.length ? this.actionPointTypes : undefined,
      eventIds: this.eventIds?.length ? this.eventIds : undefined,
      createdBy: this.createdByUpns?.length ? this.createdByUpns : undefined,
      updatedBy: this.updatedByUpns?.length ? this.updatedByUpns : undefined,
      siteVisitId: this.siteVisitId ? this.siteVisitId : undefined,
      keyIds: this.keyIds?.length ? this.keyIds : undefined,
      code: this.code ?? undefined,
      allEvents: this.allEvents,
      customFilters: options.customFilters,
    }

    this.paginatedChange.emit(this.lastActionPointPaginatedOptions)
    return this.actionPointsService.getPaginatedPost(this.lastActionPointPaginatedOptions, options.customFilters)
  }

  private sortByToActionPointSortBy(sortBy: keyof ActionPointListDTO): ActionPointSortBy {
    switch (sortBy) {
      case 'code':
        return ActionPointSortBy.CODE
      case 'title':
        return ActionPointSortBy.TITLE
      case 'status':
        return ActionPointSortBy.STATUS
      case 'priority':
        return ActionPointSortBy.PRIORITY
      case 'dueDate':
        return ActionPointSortBy.DUE_DATE
      case 'venueName':
        return ActionPointSortBy.VENUE
      case 'eventNames':
        return ActionPointSortBy.EVENTS
      case 'workingVisitCode':
        return ActionPointSortBy.WORKING_VISIT
      case 'raisedByLabel':
        return ActionPointSortBy.RAISED_BY
      case 'informedLabels':
        return ActionPointSortBy.INFORMED
      case 'assignedToLabel':
        return ActionPointSortBy.ASSIGNED
      case 'createdBy':
        return ActionPointSortBy.CREATED_BY
      case 'updatedAt':
        return ActionPointSortBy.UPDATED_AT
      case 'createdAt':
        return ActionPointSortBy.CREATED_AT

      default:
        return ActionPointSortBy.TITLE
    }
  }

  public exportToXLSX() {
    const exportSearch: ActionPointExportSearchDTO = {
      ...this.lastActionPointPaginatedOptions,
      ids: this.selection.allSelected ? [] : this.selection.data.map((d) => d.id),
      excludedIds: this.selection.allSelected ? this.selection.excluded.map((ex) => ex.id) : [],
      format: ExportFormat.XLSX,
    }
    // Clear the status when only some items are partial selected.
    // This ensures that newly created action points, which may not match the current filters,
    //are still included in the export.
    if (!this.selection.allSelected && exportSearch.ids?.length) {
      exportSearch.status = undefined
    }

    if (exportSearch?.ids.length == 0 && !this.selection.allSelected) return
    this.actionPointsService.generateActionPointExport(exportSearch).subscribe((file) => {
      FileUtils.downloadFile(file.data.data, file.name)
    })
  }

  public exportToPDF() {
    const exportSearch: ActionPointExportSearchDTO = {
      ...this.lastActionPointPaginatedOptions,
      ids: this.selection.allSelected ? [] : this.selection.data.map((d) => d.id),
      excludedIds: this.selection.allSelected ? this.selection.excluded.map((ex) => ex.id) : [],
      format: ExportFormat.PDF,
    }
    // Clear the status when only some items are partial selected.
    // This ensures that newly created action points, which may not match the current filters,
    //are still included in the export.
    if (!this.selection.allSelected && exportSearch.ids?.length) {
      exportSearch.status = undefined
    }

    if (exportSearch?.ids.length == 0 && !this.selection.allSelected) return
    this.actionPointsService.generateActionPointExport(exportSearch).subscribe((file) => {
      FileUtils.downloadFile(file.data.data, file.name)
    })
  }

  public async openBulkStatusUpdateDialog() {
    if (!this.selection.allSelected && !this.selection.data.length && !this.selection.excluded.length) {
      return
    }

    this.dialog
      .open<ActionPointsTableStatusDialogComponent, void, ActionPointStatus>(ActionPointsTableStatusDialogComponent, {
        panelClass: 'vista-dialog',
        width: '350px',
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return
        }

        const dto: ActionPointBulkUpdateDTO = {
          ...this.lastActionPointPaginatedOptions,
          all: this.selection.allSelected,
          ids: this.selection.allSelected ? this.selection.excluded.map((d) => d.id) : this.selection.data.map((d) => d.id),
          newStatus: result,
        }

        this.actionPointsService.bulkUpdate(dto).subscribe((result) => {
          this.dialog
            .open<ActionPointsTableUpdateStatusResultDialogComponent, ActionPointBulkUpdateResultDTO>(
              ActionPointsTableUpdateStatusResultDialogComponent,
              {
                panelClass: 'body-dialog',
                data: result,
              }
            )
            .afterClosed()
            .subscribe(() => {
              this.table?.search('')
            })
        })
      })
  }

  private setupListeners() {
    this.actionPointManageService.actionPointEvent.pipe(takeUntil(this.$ngUnsubscribe)).subscribe((event) => {
      if (event.action === ActionPointEventAction.CHANGED || event.action === ActionPointEventAction.CREATED) {
        const { dto, action } = event
        this.actionPointsService.getPaginatedPost({ ids: [dto.id], allEvents: this.allEvents }).subscribe(({ data: [res] }) => {
          if (!res) {
            return
          }

          if (action === ActionPointEventAction.CREATED) {
            this.table.addRow(res)
            // Make sure to recalculate the table headers, filters may have changed
            this.setTableColumns()
            return
          }
          this.table?.updateRow(res, (row: ActionPointListDTO) => row.id === res.id)
          // Make sure to recalculate the table headers, filters may have changed
          this.setTableColumns()
        })
      }
    })
  }

  private setupTableConfig(force = false) {
    if (!this.columns && !force) {
      this.setTableColumns()
    }

    if (!this.actions && !force) {
      this.setTableActions()
    }
  }

  public setTableColumns() {
    this.columns = []

    for (const displayColumn of this.displayColumns) {
      const column = this.getColumnFromDisplayColumn(displayColumn)
      this.columns.push(column)
    }
  }

  private getColumnFromDisplayColumn(options: ActionPointTableDisplayColumn) {
    const { type } = options
    switch (type) {
      case ActionPointTableDisplayColumnType.location:
        return this.locationColumn(options)
      case ActionPointTableDisplayColumnType.title:
        return this.titleColumn(options)
      case ActionPointTableDisplayColumnType.workingVisit:
        return this.workingVisitColumn(options)
      case ActionPointTableDisplayColumnType.status:
        return this.statusColumn(options)
      case ActionPointTableDisplayColumnType.priority:
        return this.priorityColumn(options)
      case ActionPointTableDisplayColumnType.assignedTo:
        return this.assignedToColumn(options)
      case ActionPointTableDisplayColumnType.dueDate:
        return this.dueDateColumn(options)
      case ActionPointTableDisplayColumnType.venue:
        return this.venueColumn(options)
      case ActionPointTableDisplayColumnType.event:
        return this.eventColumn(options)
      case ActionPointTableDisplayColumnType.raisedBy:
        return this.raisedByColumn(options)
      case ActionPointTableDisplayColumnType.informed:
        return this.informedColumn(options)
      case ActionPointTableDisplayColumnType.identifier:
        return this.identifierColumn(options)
      case ActionPointTableDisplayColumnType.createdBy:
        return this.createdByColumn(options)
      case ActionPointTableDisplayColumnType.createdAt:
        return this.createdAtColumn(options)
      case ActionPointTableDisplayColumnType.updatedBy:
        return this.updatedByColumn(options)
      case ActionPointTableDisplayColumnType.updatedAt:
        return this.updatedAtColumn(options)
      case ActionPointTableDisplayColumnType.lastComment:
        return this.lastCommentColumn(options)
    }
  }

  private setTableActions() {
    this.actions = [
      {
        icon: 'map-pin',
        color: 'primary',
        tooltip: (row) => (row.location ? row.location.name : ''),
        disabled: (row) => !row.location,
        onClick: (row) => this.onLocateActionPoint(row),
      },
    ]
  }

  // #region Table Options async
  public workingVisitOptionsAsync = (
    searchTerm: string,
    pageSize: number,
    page: number
  ): Observable<PaginatedResults<SelectOption<string>>> => {
    const options: WorkingVisitSearchDTO = {
      searchTerm: searchTerm,
      currentPage: page,
      pageSize,
      siteVisitIds: this.siteVisitId ? [this.siteVisitId] : undefined,
      withSiteVisitName: true,
      withActionPoints: true,
    }
    return this.dictionaryService.getWorkingVisitsPaginated(options)
  }

  public venueOptionsAsync = (searchTerm: string, pageSize: number, page: number): Observable<PaginatedResults<SelectOption<string>>> => {
    const options: SVRVenueSearchDTO = {
      searchTerm: searchTerm,
      currentPage: page,
      pageSize,
      withActionPoints: true,
    }
    return this.dictionaryService.getVenuesPaginated(options)
  }

  public projectOptionsAsync = (searchTerm: string, _records: number, page: number): Observable<PaginatedResults<SelectOption<string>>> => {
    const options: SVRProjectSearchDTO = {
      searchTerm: searchTerm,
      currentPage: page,
      pageSize: 50,
      withActionPoints: true,
      actionPointProject: true,
    }
    return this.dictionaryService.getProjectsPaginated(options)
  }

  public informedProjectOptionsAsync = (
    searchTerm: string,
    records: number,
    page: number
  ): Observable<PaginatedResults<SelectOption<string>>> => {
    const options: SVRProjectSearchDTO = {
      searchTerm: searchTerm,
      currentPage: page,
      pageSize: records,
      withActionPoints: true,
      actionPointInformed: true,
    }
    return this.dictionaryService.getProjectsPaginated(options)
  }

  public userOptionsAsync = (searchTerm: string, _records: number, page: number): Observable<PaginatedResults<SelectOption<string>>> => {
    const options: UserPaginatedSearchDTO = {
      searchTerm: searchTerm,
      currentPage: page,
      pageSize: 50,
      withActionPoints: true,
    }
    return this.dictionaryService.getUsersPaginated(options)
  }

  public assignedToOptionsAsync = (
    searchTerm: string,
    _records: number,
    page: number
  ): Observable<PaginatedResults<SelectOption<string>>> => {
    const options: SVRProjectSearchDTO = {
      searchTerm: searchTerm,
      currentPage: page,
      pageSize: 50,
      withActionPoints: true,
      actionPointsAssigned: true,
      eventIds: this.eventIds,
    }
    return this.dictionaryService.getProjectsPaginated(options)
  }

  public statusOptionsAsync = (searchTerm: string): Observable<PaginatedResults<SelectOption<string>>> => {
    const data = this.getStatusOptions().filter((o) => o.label.toLowerCase().includes(searchTerm ?? ''))

    return of(new PaginatedResults(data))
  }

  private getStatusOptions(statusFilter?: ActionPointStatus[]) {
    let options: string[] = Object.keys(ActionPointStatus)

    if (statusFilter) {
      options = options.filter((s) => statusFilter.includes(ActionPointStatus[s]))
    }
    return options.map((k) => ({
      label: this.getActionPointStatusString(ActionPointStatus[k]),
      value: ActionPointStatus[k],
      icon: this.iconsService.getActionPointStatus(ActionPointStatus[k]),
    }))
  }

  public priorityOptionsAsync = (searchTerm: string): Observable<PaginatedResults<SelectOption<string>>> => {
    const data = Object.keys(ActionPointPriority)
      .map((k) => ({
        label: this.getActionPointPriorityString(ActionPointPriority[k]),
        value: ActionPointPriority[k],
        icon: this.iconsService.getActionPointPriority(ActionPointPriority[k]),
      }))
      .filter((o) => o.label.toLowerCase().includes(searchTerm ?? ''))

    return of(new PaginatedResults(data))
  }
  // #endregion

  // #region Table Columns

  // #region No filter
  private locationColumn(options?: ActionPointTableDisplayColumn): TableColumn<ActionPointListDTO> {
    return this.generateTableColumn('location', null, options?.width ?? '2.9rem', true, null, ActionPointTableColumnTemplate.LOCATION)
  }

  private eventColumn(options?: ActionPointTableDisplayColumn): TableColumn<ActionPointListDTO> {
    return this.generateTableColumn('eventNames', 'shared.actionPointsTable.events', options?.width ?? '8rem', true)
  }

  private lastCommentColumn(options?: ActionPointTableDisplayColumn): TableColumn<ActionPointListDTO> {
    return this.generateTableColumn(
      'lastComment',
      'shared.actionPointsTable.comments',
      options?.width ?? '16rem',
      false,
      null,
      ActionPointTableColumnTemplate.LAST_COMMENT
    )
  }
  // #endregion

  // #region Date Columns
  private dueDateColumn(options?: ActionPointTableDisplayColumn): TableColumn<ActionPointListDTO> {
    return this.generateDateTableColumn('dueDate', options?.width ?? '7.5rem', options?.smallTextMenu, options?.dateFormat)
  }

  private updatedAtColumn(options?: ActionPointTableDisplayColumn): TableColumn<ActionPointListDTO> {
    return this.generateDateTableColumn('updatedAt', options?.width ?? '7.5rem', options?.smallTextMenu, options?.dateFormat)
  }

  private createdAtColumn(options?: ActionPointTableDisplayColumn): TableColumn<ActionPointListDTO> {
    return this.generateDateTableColumn('createdAt', options?.width ?? '7.5rem', options?.smallTextMenu, options?.dateFormat)
  }
  // #endregion

  // #region Input columns
  private titleColumn(options?: ActionPointTableDisplayColumn): TableColumn<ActionPointListDTO> {
    const input = this.getTableColumnInputFilter(
      'title',
      this.translateService.instant('shared.actionPointsTable.title'),
      (value) => (this.title = value)
    )
    return this.generateTableColumn('title', 'shared.actionPointsTable.title', options?.width ?? '9rem', true, { input })
  }

  private identifierColumn(options?: ActionPointTableDisplayColumn): TableColumn<ActionPointListDTO> {
    const input = this.getTableColumnInputFilter(
      'code',
      this.translateService.instant('shared.actionPointsTable.code'),
      (value) => (this.code = value)
    )
    return this.generateTableColumn('code', 'shared.actionPointsTable.code', options?.width ?? '9rem', true, { input })
  }
  // #endregion

  // #region Multi select columns
  private statusColumn(options?: ActionPointTableDisplayColumn): TableColumn<ActionPointListDTO> {
    const multiselect = this.getTableColumnMulitselectFilter<ActionPointStatus>(
      'status',
      (options) => (this.status = options),
      this.statusOptionsAsync,
      this.status
    )
    return this.generateTableColumn(
      'status',
      'shared.actionPointsTable.status',
      options?.width ?? '4rem',
      true,
      { multiselect },
      ActionPointTableColumnTemplate.STATUS
    )
  }

  private workingVisitColumn(options?: ActionPointTableDisplayColumn): TableColumn<ActionPointListDTO> {
    const onSelect = (options) => (this.workingVisitIds = options.map((o) => o.value))
    const multiselect = this.getTableColumnMulitselectFilter<string>(
      'workingVisitId',
      onSelect,
      this.workingVisitOptionsAsync,
      this.workingVisits
    )
    return this.generateTableColumn('workingVisitCode', 'shared.actionPointsTable.workingVisit', options?.width ?? '4.5rem', true, {
      multiselect,
    })
  }

  private venueColumn(options?: ActionPointTableDisplayColumn): TableColumn<ActionPointListDTO> {
    const onSelect = (options) => (this.venueIds = options.map((o) => o.value))
    const multiselect = this.getTableColumnMulitselectFilter<ActionPointStatus>('venueId', onSelect, this.venueOptionsAsync)
    return this.generateTableColumn('venueName', 'shared.actionPointsTable.venue', options?.width ?? '8rem', true, { multiselect })
  }

  private assignedToColumn(options?: ActionPointTableDisplayColumn): TableColumn<ActionPointListDTO> {
    const onSelect = (options) => (this.assignedToIds = options.map((o) => o.value))
    const multiselect = this.getTableColumnMulitselectFilter<string>('assignedToLabel', onSelect, this.assignedToOptionsAsync)
    return this.generateTableColumn('assignedToLabel', 'shared.actionPointsTable.assignedTo', options?.width ?? '5.5rem', true, {
      multiselect,
    })
  }

  private raisedByColumn(options?: ActionPointTableDisplayColumn): TableColumn<ActionPointListDTO> {
    const onSelect = (options) => (this.raisedByIds = options.map((o) => o.value))
    const multiselect = this.getTableColumnMulitselectFilter<string>('raisedByLabel', onSelect, this.projectOptionsAsync)
    return this.generateTableColumn('raisedByLabel', 'shared.actionPointsTable.raisedBy', options?.width ?? '5.5rem', true, { multiselect })
  }

  private informedColumn(options?: ActionPointTableDisplayColumn): TableColumn<ActionPointListDTO> {
    const onSelect = (options) => (this.informedIds = options.map((o) => o.value))
    const multiselect = this.getTableColumnMulitselectFilter<string[]>('informedLabels', onSelect, this.informedProjectOptionsAsync)
    return this.generateTableColumn('informedLabels', 'shared.actionPointsTable.informed', options?.width ?? '10rem', true, { multiselect })
  }

  private createdByColumn(options?: ActionPointTableDisplayColumn): TableColumn<ActionPointListDTO> {
    const onSelect = (options) => (this.createdByUpns = options.map((o) => o.value))
    const multiselect = this.getTableColumnMulitselectFilter<string>('createdBy', onSelect, this.userOptionsAsync)
    return this.generateTableColumn('createdBy', 'shared.actionPointsTable.createdBy', options?.width ?? '7rem', true, { multiselect })
  }

  private updatedByColumn(options?: ActionPointTableDisplayColumn): TableColumn<ActionPointListDTO> {
    const onSelect = (options) => (this.updatedByUpns = options.map((o) => o.value))
    const multiselect = this.getTableColumnMulitselectFilter<string>('updatedBy', onSelect, this.userOptionsAsync)
    return this.generateTableColumn('updatedBy', 'shared.actionPointsTable.updatedBy', options?.width ?? '7rem', true, { multiselect })
  }

  private priorityColumn(options?: ActionPointTableDisplayColumn): TableColumn<ActionPointListDTO> {
    const onSelect = (options: SelectOption<ActionPointPriority>[]) => (this.priority = options.map((o) => o.value))
    const multiselect = this.getTableColumnMulitselectFilter<ActionPointPriority>('priority', onSelect, this.priorityOptionsAsync)
    return this.generateTableColumn(
      'priority',
      'shared.actionPointsTable.priority',
      options?.width ?? '4rem',
      true,
      { multiselect },
      ActionPointTableColumnTemplate.PRIORITY
    )
  }

  // #endregion

  // #region Table columns generators
  private generateDateTableColumn(
    field: keyof ActionPointListDTO,
    width = '14.5%',
    smallTextMenu = false,
    dateFormat = false,
    header: string = null,
    sortable = true,
    templateId?: string
  ) {
    header = header ?? `shared.actionPointsTable.${field}`
    const date = this.getTableColumnDateFilter(field, smallTextMenu)
    const formatter = dateFormat ? this.dateFormat : this.dateTimeFormat
    const render = (value: Date) => this.datePipe.transform(value, formatter)
    return this.generateTableColumn(field, header, width, sortable, { date }, templateId, render)
  }

  private generateTableColumn(
    field: keyof ActionPointListDTO,
    header: string = null,
    width: string = '2rem',
    sortable: boolean = false,
    filter?: TableColumnFilter,
    templateId?: string,
    render?: (value: any, row?: ActionPointListDTO, col?: TableColumn<ActionPointListDTO>) => string
  ): TableColumn<ActionPointListDTO> {
    return { field, header, width, sortable, filter, templateId, render }
  }

  private getTableColumnMulitselectFilter<T>(
    filterField: keyof ActionPointListDTO,
    onSelect: (selected: SelectOption<T>[]) => void,
    optionsAsync: (searchTerm: string, records: number, page: number) => Observable<PaginatedResults<SelectOption<string>>>,
    selected?: SelectOption<string>[]
  ): TableMultiSelectFilterComponentConfig<string> {
    return {
      filterField,
      selected,
      onSelect: (selected) => onSelect((selected as unknown) as SelectOption<T>[]),
      optionsAsync,
    }
  }

  private getTableColumnInputFilter(
    filterField: keyof ActionPointListDTO,
    placeholder: string,
    onSearch: (value: string) => void
  ): TableInputFilterComponentConfig {
    return {
      filterField,
      placeholder,
      onSearch,
    }
  }

  private getTableColumnDateFilter(filterField: keyof ActionPointListDTO, smallTextMenu = false): TableDateFilterComponentConfig {
    return { filterField, smallTextMenu }
  }
  // #endregion
  // #endregion
}
