import { Type } from 'class-transformer'
import { IsArray, IsNotEmpty, IsOptional, IsUUID, ValidateNested } from 'class-validator'
import { BaseDTO } from '../shared'
import { LayerTypeFieldValueDTO, LayerTypeFieldValueUpdateDTO } from './layer-type-field-value.dto'
import { LayerTypeSymbologyDTO } from './layer-type-symbology.dto'

export enum LayerTypeFieldType {
  KEY = 'key',
  PROJECT = 'project',
  TEXT = 'text',
  IMAGE = 'image',
  NUMERIC = 'numeric',
  OBSERVATIONS = 'observations',
  DOCUMENT = 'document',
  DATE_TIME = 'dateTime',
  DATE_PERIOD = 'datePeriod',
  VALUE_LIST = 'valueList',
  SUB_EVENT = 'subEvent'
}

export enum LayerTypeFieldAutofill {
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',
  LATITUDE = 'latitude',
  LONGITUDE = 'longitude',
  LENGTH = 'length',
  AREA = 'area',
  PERIMETER = 'perimeter',
  CREATED_ON = 'createdOn',
  UPDATED_ON = 'updatedOn',
}

export class LayerTypeFieldDTO extends BaseDTO {
  name: string
  fieldName: string // Only on db
  order: number
  type: LayerTypeFieldType
  multi: boolean
  autoFill: LayerTypeFieldAutofill
  showOnPortal: boolean
  showOnDesigner: boolean
  filterableOnPortal: boolean // Only on db
  filterableOnDesigner: boolean // Only on db
  values: LayerTypeFieldValueDTO[]
}

export class LayerTypeFieldCreateDTO {
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => LayerTypeFieldValueUpdateDTO)
  @IsOptional()
  values: LayerTypeFieldValueUpdateDTO[]

  name: string

  type: LayerTypeFieldType
}

export class LayerTypeFieldUpdateDTO extends LayerTypeFieldCreateDTO {
  @IsUUID()
  @IsNotEmpty() // for now it's not possible to create new fields
  id: string

  constructor(field?: LayerTypeFieldDTO, symbologies?: LayerTypeSymbologyDTO[]) {
    super()

    if (field) {
      this.id = field.id
      this.name = field.name
      this.type = field.type

      this.values = field.values.map(v => {
        const symbology = symbologies.find(s => s.layerTypeValueId === v.id)
        return new LayerTypeFieldValueUpdateDTO(v, symbology)
      })
    }
  }
}
