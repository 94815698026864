<div class="vista-dialog-header">
  <h1 mat-dialog-title class="vista-dialog-title">{{ 'shared.actionPointsManager.draftDialog.title' | translate }}</h1>
  <div class="flex-grow"></div>
  <button mat-icon-button mat-dialog-close color="primary">
    <i-feather name="x-circle"></i-feather>
  </button>
</div>
<div mat-dialog-content class="vista-dialog-content">
  <p class="vista-dialog-content-description">{{ 'shared.actionPointsManager.draftDialog.message' | translate: { name: data.apName } }}</p>
</div>
<div mat-dialog-actions class="vista-dialog-actions">
  <button mat-raised-button color="primary" (click)="onSaveAsDraft()">
    {{ 'shared.actionPointsManager.draftDialog.saveAsDraft' | translate | uppercase }}
  </button>
  <button mat-raised-button color="primary" (click)="onSaveAsTodo()">
    {{ 'shared.actionPointsManager.draftDialog.saveAsTodo' | translate | uppercase }}
  </button>
</div>
