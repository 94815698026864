import { IsArray, IsOptional } from 'class-validator'
import { ArrayParse, BooleanParse } from '../decorators'

export class FindFacilitiesSearchDTO {
  @IsOptional()
  levelId?: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  keyIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  projectIds?: string[]

  @IsOptional()
  keySearch?: string

  @IsOptional()
  @BooleanParse()
  includeOverview: boolean

  @IsOptional()
  @IsArray()
  @ArrayParse()
  pointsCoordinates?: string[]
}

export class FeatureNotifyMessageDTO {
  id: number
  levelId: string
  geom: string
  keyId: string
}
