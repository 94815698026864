import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PaginatedSearchDTO } from '@uefa-shared/contracts'
import { UserService } from '@uefa-shared/frontend'
import { SVRAuthenticatedUserDTO, UserSearchDTO } from '@uefa-svr/contracts'
import { UserGroupRole, UserListDTO } from '@uefa-vista/contracts'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class SVRUserService extends UserService {
  constructor(http: HttpClient) {
    super(environment, http)
  }

  public get userInfo(): SVRAuthenticatedUserDTO {
    return super.userInfo as SVRAuthenticatedUserDTO
  }

  public set userInfo(user: SVRAuthenticatedUserDTO) {
    super.userInfo = user
  }

  public getMany(search: UserSearchDTO) {
    const queryParams = this.getQueryParams<UserSearchDTO>(search)
    return this.http.get<UserListDTO[]>(`${this.basePath}/all?${queryParams.join('&')}`)
  }

  public findManyPaginated(search: PaginatedSearchDTO) {
    return this.getPaginatedResult<UserListDTO>(this.basePath, search)
  }

  public getInfo(userEmail?: string): Observable<SVRAuthenticatedUserDTO> {
    return super.getInfo().pipe(map((user) => user as SVRAuthenticatedUserDTO))
  }

  public canAccess(...roles: UserGroupRole[] | string[]): boolean {
    if (!this.userInfo) {
      return false
    }
    return (
      (this.userInfo?.isAdmin && (roles.includes(UserGroupRole.ADMINISTRATOR) || roles.includes(UserGroupRole.HOHB_ADMIN))) ||
      (this.userInfo?.isManager && (roles.includes(UserGroupRole.MANAGER) || roles.includes(UserGroupRole.HOHB_MANAGER))) ||
      (this.userInfo?.isEditor && (roles.includes(UserGroupRole.EDITOR) || roles.includes(UserGroupRole.HOHB_EDITOR))) ||
      (this.userInfo?.isReader && (roles.includes(UserGroupRole.READER) || roles.includes(UserGroupRole.HOHB_READER)))
    )
  }
}
