import { ApprovalWorkflow, SiteVisitType } from '../shared/enums'

export class SiteVisitUtils {
  public static getApprovalWorkflowLabel(approvalWorkflow: ApprovalWorkflow): string {
    switch (approvalWorkflow) {
      case ApprovalWorkflow.DISABLED:
        return 'Disabled'
      case ApprovalWorkflow.REGULAR:
        return 'Regular'
      case ApprovalWorkflow.AUTO_ON_PREFILL:
        return 'Auto approve with pre-fill'
      default:
        ''
    }
  }

  public static getSiteVisitTypeLabel(siteVisitType: SiteVisitType): string {
    switch (siteVisitType) {
      case SiteVisitType.HANDOVER_HANDBACK:
        return 'Handover/Handback'
      case SiteVisitType.REGULAR:
        return 'Regular'
      default:
        ''
    }
  }

  public static typeIcon(item: SiteVisitType): string {
    switch (item) {
      case SiteVisitType.HANDOVER_HANDBACK:
        return 'pen-tool'
      case SiteVisitType.REGULAR:
        return 'file-text'
      default:
        return 'hard-drive'
    }
  }
}

export class SiteVisitNomenclatureUtils {
  public static calculateName(options: CalculateNameOptions): string {
    const { siteName, eventNames, eventCycleName, teamName, freeEditingName } = options
    if (this.isFreeEditableName(eventCycleName, eventNames)) {
      return freeEditingName
    }

    const nameParts = [teamName, siteName, eventCycleName ?? eventNames[0]].filter(x => !!x)
    return nameParts.join(' - ')
  }

  public static calculateTitle(options: CalculateTitleOptions): string {
    const { name, prefix, suffix } = options
    const titleParts = [prefix, name, suffix].filter(x => !!x)
    return titleParts.join(' - ')
  }

  public static isFreeEditableName(eventCycleId: string, eventIds: string[]): boolean {
    return !eventCycleId && eventIds.length > 1
  }
}

class CalculateNameOptions {
  teamName?: string
  siteName?: string
  eventNames?: string[] = []
  eventCycleName?: string
  freeEditingName?: string
}

class CalculateTitleOptions {
  name: string
  prefix?: string
  suffix?: string
}
