export class PaginatedResults<T> {
  data: Array<T>
  skip?: number
  take?: number
  currentPage?: number
  pageSize?: number
  totalPages?: number
  totalResults: number

  constructor(data?: Array<T>, totalResults?: number, currentPage?: number, pageSize?: number, skip = 0, take = 20) {
    totalResults = totalResults ?? 0
    this.skip = skip
    this.take = take
    this.data = data ?? []
    this.currentPage = currentPage ? currentPage : 1
    this.pageSize = pageSize ? pageSize : totalResults
    this.totalPages = pageSize ? Math.ceil(totalResults / pageSize) : 1
    this.totalResults = totalResults
  }
}
