export enum SiteVisitType {
  REGULAR = 'REGULAR',
  HANDOVER_HANDBACK = 'HANDOVER_HANDBACK',
}

export enum WorkingVisitDirection {
  TO = 'TO',
  FROM = 'FROM',
}

export enum WorkingVisitReportProcessTrigger {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export enum WorkingVisitStatus {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum ApprovalWorkflow {
  REGULAR = 'REGULAR',
  AUTO_ON_PREFILL = 'AUTO_ON_PREFILL',
  DISABLED = 'DISABLED',
}

export enum Prefill {
  SITE = 'SITE',
  COMPETITION = 'COMPETITION',
  TEAM = 'TEAM',
  TEAM_TYPE = 'TEAM_TYPE',
}
