import { IsArray, IsOptional } from 'class-validator'
import { ArrayParse } from '../decorators'
import { PaginatedSearchDTO } from '../shared'

export class TeamSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  venueIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  siteIds?: Array<string>
}
