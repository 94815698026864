import { Type } from 'class-transformer'
import { IsArray, IsEnum, IsNotEmpty, IsOptional, IsString, ValidateNested } from 'class-validator'
import { ArrayParse } from '../decorators'
import { BaseDTO } from '../shared'

export enum NotificationType {
  EMAIL = 'email',
  PUSH = 'push',
}

export enum NotificationStatus {
  IN_QUEUE = 'in_queue',
  PROCESSING = 'processing',
  PROCESSED = 'processed',
  FAILED = 'failed',
}

export enum NotificationEntity {
  Match = 'Match',
  EventReportingReport = 'EventReportingReport',
  Incident = 'Incident',
  Info = 'Info',
}

export enum NotificationAction {
  Announcements = 'Announcements',
  Lineups = 'Lineups',
  Score = 'Score',
  Attendance = 'Attendance',
  TimeLine = 'TimeLine',
  IFB = 'IFB',
  Contacts = 'Contacts',
  ReportDue = 'ReportDue',
  Create = 'Create',
  Update = 'Update',
}

export class NotificationConfiguration {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  cc?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  bcc?: string[]

  @IsOptional()
  @IsString()
  subject?: string
}

export class NotificationSubstitutions {
  @IsOptional()
  @IsString()
  message?: string
}

export class NotificationDTO extends BaseDTO {
  id: string
  to: string
  template: string
  substitutions: NotificationSubstitutions
  status: NotificationStatus
  type: NotificationType
  config: NotificationConfiguration
}

export class NotificationMessageData {
  id?: string
  target: string
  template: string
  config: NotificationConfiguration
  substitutions: NotificationSubstitutions
  patchStatusBaseUrl: string
}

export class NotificationPushMessageDTO {
  id?: string
  action: string
  entity: string
  subject: string
  templateName: string
  target: string
  type: string
  context: any
  metadata: any
}

export class NotificationListDTO extends BaseDTO {
  id: string
  to: string
  status: NotificationStatus
  type: NotificationType
}

export class NotificationUpdateDTO {
  @IsNotEmpty()
  @IsEnum(NotificationStatus)
  status: NotificationStatus

  @IsOptional()
  @IsString()
  error?: string
}

export class NotificationCreateDTO {
  @IsNotEmpty()
  @IsString()
  to: string

  @IsNotEmpty()
  @IsString()
  template: string

  @IsOptional()
  @Type(() => NotificationSubstitutions)
  @ValidateNested()
  substitutions: NotificationSubstitutions

  @IsOptional()
  @Type(() => NotificationConfiguration)
  @ValidateNested()
  config: NotificationConfiguration
}

export class BulkNotificationCreateDTO {
  @IsNotEmpty()
  @Type(() => NotificationCreateDTO)
  @ValidateNested({ each: true })
  notifications: NotificationCreateDTO[]
}

export class PushNotificationCreateDTO {
  @IsNotEmpty()
  @IsString()
  userId: string

  @IsNotEmpty()
  @IsString()
  template: string

  @IsNotEmpty()
  @IsString()
  subject: string

  @IsNotEmpty()
  @IsString()
  entity: NotificationEntity

  @IsNotEmpty()
  @IsString()
  entityId: string

  @IsNotEmpty()
  @IsString()
  action: NotificationAction

  @IsOptional()
  @Type(() => NotificationSubstitutions)
  @ValidateNested()
  substitutions: NotificationSubstitutions
}

export class BulkPushNotificationCreateDTO {
  @IsNotEmpty()
  @Type(() => PushNotificationCreateDTO)
  @ValidateNested({ each: true })
  notifications: PushNotificationCreateDTO[]
}
