import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ForbiddenComponent, ShellComponent } from '@uefa-shared/frontend'
import { RedirectComponent } from './shared/components'
import { AuthorizeGuard, EditGuard } from './shared/guards'

const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    canActivateChild: [EditGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        canActivate: [AuthorizeGuard],
        component: RedirectComponent,
      },
      {
        path: 'dashboard',
        canActivate: [AuthorizeGuard],
        loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'builder',
        canActivate: [AuthorizeGuard],
        loadChildren: () => import('./features/builder/builder.module').then((m) => m.BuilderModule),
      },
      {
        path: 'questions',
        canActivate: [AuthorizeGuard],
        loadChildren: () => import('./features/question-management/question-management.module').then((m) => m.QuestionManagementModule),
      },
      {
        path: 'templates',
        canActivate: [AuthorizeGuard],
        loadChildren: () => import('./features/questions/questions.module').then((m) => m.QuestionsModule),
      },
      {
        path: 'sections',
        canActivate: [AuthorizeGuard],
        loadChildren: () => import('./features/sections/sections.module').then((m) => m.SectionsModule),
      },
      {
        path: 'monitor',
        canActivate: [AuthorizeGuard],
        loadChildren: () => import('./features/monitor/monitor.module').then((m) => m.MonitorModule),
      },
      {
        path: 'action-points',
        canActivate: [AuthorizeGuard],
        loadChildren: () => import('./features/incidents/incidents.module').then((m) => m.ActionPointsModule),
      },
      { path: 'surveyor', pathMatch: 'full', redirectTo: 'mobile' },
      {
        path: 'mobile',
        canActivate: [AuthorizeGuard],
        loadChildren: () => import('./features/surveyor/surveyor.module').then((m) => m.SurveyorModule),
      },
      {
        path: 'tasks',
        canActivate: [AuthorizeGuard],
        loadChildren: () => import('./features/tasks/tasks.module').then((m) => m.TasksModule),
      },
    ],
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
  },
  { path: '**', redirectTo: '' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
