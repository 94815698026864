<div class="tool-buttons">
  <div
    class="clear-filters-label"
    *ngIf="
      (projectFilter && shell?.hasProjectsSelected) ||
      (keyFilter && shell?.hasKeysSelected) ||
      (facilityFilter && shell?.hasFacilityIdSelected)
    "
  >
    <span (click)="shell?.onClearFiltersClick()">
      {{ 'shared.buttons.clearFilters' | translate }}
    </span>
  </div>
  <div *ngIf="projectFilter">
    <button
      class="tool-button"
      [class.primary]="shell?.projectsFilterActive"
      [class.active]="shell?.hasProjectsSelected"
      matTooltip="{{ 'features.monitor.sections.projects' | translate }}"
      (click)="shell?.onProjectsClick($event)"
    >
      <i-feather name="users" class="icon"></i-feather>
    </button>
  </div>
  <div *ngIf="keyFilter">
    <button
      class="tool-button"
      [class.primary]="shell?.keysFilterActive"
      [class.active]="shell?.hasKeysSelected"
      matTooltip="{{ 'features.monitor.sections.keys' | translate }}"
      (click)="shell?.onKeysClick($event)"
    >
      <i-feather name="map-pin" class="icon"></i-feather>
    </button>
  </div>
  <div>
    <button
      class="tool-button"
      [class.primary]="shell?.actionPointsVisible"
      matTooltip="{{ 'features.monitor.sections.' + (shell.isHandoverHandback ? 'damageReports' : 'actionPoints') | translate }}"
      (click)="shell?.onActionPointsClick()"
    >
      <i-feather [name]="shell.isHandoverHandback ? 'zap' : 'flag'" class="icon"></i-feather>
    </button>
  </div>
  <div>
    <button
      class="tool-button"
      [class.primary]="shell?.mapVisible"
      [class.active]="facilityFilter && shell?.hasFacilityIdSelected"
      matTooltip="{{ 'features.monitor.sections.map' | translate }}"
      (click)="shell?.onMapClick()"
    >
      <i-feather name="map" class="icon"></i-feather>
    </button>
  </div>
</div>
