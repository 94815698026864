import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router'
import { DialogService } from '@uefa-shared/frontend'
import { Observable } from 'rxjs'
import { EditStateService } from '../services/edit-state.service'

@Injectable({
  providedIn: 'root',
})
export class EditGuard implements CanActivateChild {
  constructor(private readonly dialogService: DialogService, private readonly editStateService: EditStateService) {}

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.editStateService.editGuard()
  }
}
