import { ArrayParse, BooleanParse } from '@uefa-shared/contracts'
import { IsEnum, IsOptional } from 'class-validator'
import { SiteVisitType } from '../shared'
import { SiteVisitSearchDTO } from '../site-visit'

export class MonitorVistaSearchDTO extends SiteVisitSearchDTO {
  @IsOptional()
  eventCycleId?: string

  @IsOptional()
  @IsEnum(SiteVisitType)
  siteVisitType?: string

  @IsOptional()
  @ArrayParse()
  siteVisitIds?: string[]

  @IsOptional()
  @BooleanParse()
  completed?: boolean
}
