export enum Hatch {
    NONE = 'None',
    HATCH_0 = '0º',
    HATCH_45 = '45º',
    HATCH_90 = '90º',
    HATCH_135 = '135º'
}

export const hatchToDegrees = (hatch: Hatch): number => {
    switch (hatch) {
        case Hatch.HATCH_45:
            return 45
        case Hatch.HATCH_90:
            return 90
        case Hatch.HATCH_135:
            return 135
        default:
            return 0
    }
}
