import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PaginatedResults } from '@uefa-shared/contracts'
import { BaseService } from '@uefa-shared/frontend'
import {
  PlanningResultDTO,
  QuestionBulkUpdateDTO,
  QuestionConfigurationDTO,
  QuestionCreateDTO,
  QuestionDTO,
  QuestionListDTO,
  QuestionPlanDTO,
  QuestionSearchDTO,
  QuestionUpdateDTO,
} from '@uefa-svr/contracts'
import { Observable } from 'rxjs'
import { environment } from '../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class QuestionsService extends BaseService {
  constructor(http: HttpClient) {
    super(environment, 'question', http)
  }

  public getPaginated(options: QuestionSearchDTO): Observable<PaginatedResults<QuestionListDTO>> {
    return this.getPaginatedResult<QuestionListDTO>(this.basePath, options)
  }

  public getById(id: string): Observable<QuestionDTO> {
    return this.http.get<QuestionDTO>(`${this.basePath}/${id}`)
  }

  public create(model: QuestionCreateDTO): Observable<QuestionDTO> {
    return this.http.post<QuestionDTO>(this.basePath, model)
  }

  public plan(model: QuestionPlanDTO): Observable<PlanningResultDTO[]> {
    return this.http.post<PlanningResultDTO[]>(`${this.basePath}/plan`, model)
  }

  public bulkPlan(model: QuestionBulkUpdateDTO): Observable<PlanningResultDTO[]> {
    return this.http.post<PlanningResultDTO[]>(`${this.basePath}/bulk-plan`, model)
  }

  public bulkUpdate(model: QuestionBulkUpdateDTO): Observable<void> {
    return this.http.post<void>(`${this.basePath}/bulk-edit`, model)
  }

  public update(id: string, model: QuestionUpdateDTO): Observable<QuestionDTO> {
    return this.http.put<QuestionDTO>(`${this.basePath}/${id}`, model)
  }

  public delete(id: string): Observable<QuestionDTO> {
    return this.http.delete<QuestionDTO>(`${this.basePath}/${id}`)
  }

  public getConfigurations() {
    return this.http.get<QuestionConfigurationDTO[]>(`${this.basePath}/configurations`)
  }

  public getMediaFileUrl(mediaUrl: string): string {
    return `${this.configuration.apibaseUrl}/${mediaUrl}`
  }
}
