import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ActionPointStatus } from '@uefa-svr/contracts'

@Component({
  templateUrl: './action-point-dialog.component.html',
  styleUrls: ['./action-point-dialog.component.scss'],
})
export class SharedActionPointStatusDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SharedActionPointStatusDialogComponent, { status?: ActionPointStatus }>,
    @Inject(MAT_DIALOG_DATA) public data: { apName: string }
  ) {}

  public onClose() {
    this.dialogRef.close()
  }

  public onSaveAsTodo() {
    this.dialogRef.close({ status: ActionPointStatus.TO_DO })
  }

  public onSaveAsDraft() {
    this.dialogRef.close({ status: ActionPointStatus.DRAFT })
  }
}
