import { Type } from 'class-transformer'
import { IsArray, IsNotEmpty, IsOptional, IsString, ValidateNested } from 'class-validator'
import { BaseDTO } from '../shared/vista.dto'
import { ArrayUtils } from '../utils'
import { LayerTypeFieldDTO, LayerTypeFieldUpdateDTO } from './layer-type-field.dto'
import { LayerTypeSymbologyDTO, LayerTypeSymbologyUpdateDTO } from './layer-type-symbology.dto'

export enum LayerTypeGeometry {
  POINT = 'point',
  LINE = 'line',
  POLYGON = 'polygon',
  SHAPE = 'shape',
}

export enum LayerTypeSymbology {
  SIMPLE = 'simple',
  BY_FIELD = 'byField',
  BY_KEY = 'byKey',
}

export class LayerTypeListDTO extends BaseDTO {
  name: string
  shortName: string
  geometry: LayerTypeGeometry
  symbology: LayerTypeSymbology
  managerImageUrl: string
  portalImageUrl: string
  numberOfLayers: number
  gwc?: boolean
  canFilterKeys?: boolean
  canFilterProjects?: boolean
  canFilterSymbologies?: boolean
  canFilterInterval?: boolean
  canFilterScopes?: boolean
  canRender360Panoramas?: boolean
  canEditFeaturesOnMap?: boolean
  renderOrder?: number
}

export class LayerTypeDTO extends BaseDTO {
  name: string
  shortName: string
  geometry: LayerTypeGeometry
  symbology: LayerTypeSymbology
  fromShapeFile: boolean
  managerImageUrl: string
  portalImageUrl: string
  fields: LayerTypeFieldDTO[]
  symbologies: LayerTypeSymbologyDTO[]
  gwc?: boolean
  canFilterKeys?: boolean
  canFilterProjects?: boolean
  canFilterSymbologies?: boolean
  canFilterInterval?: boolean
  canFilterScopes?: boolean
  order?: number
}

export class LayerTypeUpdateDTO {
  @IsString()
  @IsNotEmpty()
  name: string

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => LayerTypeFieldUpdateDTO)
  fields: LayerTypeFieldUpdateDTO[] = []

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => LayerTypeSymbologyUpdateDTO)
  @IsOptional()
  symbologies: LayerTypeSymbologyUpdateDTO[] = []

  constructor(layerType?: LayerTypeDTO) {
    if (layerType) {
      this.name = layerType.name
      this.fields = layerType.fields.map(field => new LayerTypeFieldUpdateDTO(field, layerType.symbologies))

      if (layerType.symbology !== LayerTypeSymbology.BY_FIELD) {
        const values = ArrayUtils.flatten(layerType.fields.map(f => f.values))
        this.symbologies = layerType.symbologies.map(s => {
          const value = values.find(v => v.id === s.layerTypeValueId)
          return new LayerTypeSymbologyUpdateDTO(s, value)
        })
      }
    }
  }
}

export class LayerTypeCreateDTO extends LayerTypeUpdateDTO {}

export enum LayerTypeSearchSortField {}

export class LayerTypeUtils {
  public static getGeometryLabel(geometry: LayerTypeGeometry) {
    switch (geometry) {
      case LayerTypeGeometry.LINE:
        return 'Line'
      case LayerTypeGeometry.POINT:
        return 'Point'
      case LayerTypeGeometry.POLYGON:
        return 'Polygon'
      default:
        return 'No Geometry'
    }
  }

  public static getSymbologyLabel(symbology: LayerTypeSymbology) {
    switch (symbology) {
      case LayerTypeSymbology.BY_FIELD:
        return 'By Field'
      case LayerTypeSymbology.BY_KEY:
        return 'By Key'
      case LayerTypeSymbology.SIMPLE:
        return 'Simple'
      default:
        return 'No Symbology'
    }
  }
}
