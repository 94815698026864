import { ArrayParse, BooleanParse, LabelValueDTO, PaginatedSearchDTO, StringCleanup, StringTrim } from '@uefa-shared/contracts'
import { Type } from 'class-transformer'
import {
  ArrayMinSize,
  IsArray,
  IsBoolean,
  IsEnum,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
  Length,
  ValidateIf,
  ValidateNested,
} from 'class-validator'
import { BaseConfigurationDTO } from '../entity-configurations'
import { SVRMediaDTO } from '../shared'
import { SiteVisitType } from '../shared/enums'
import { FieldDTO, FieldUpdateDTO } from './question-field.dto'

export class QuestionSectionDTO extends LabelValueDTO {
  parentId?: string

  constructor(value?: string, label?: string, parentId?: string) {
    super(value, label)
    this.parentId = parentId
  }
}

export enum QuestionSortBy {
  TITLE = 'title',
  CODE = 'code',
  PROJECT = 'projects',
  EVENT = 'events',
  SECTION = 'section',
  SITE_VISIT_TYPE = 'siteVisitType',
}

export enum QuestionStatus {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum QuestionTarget {
  PER_KEY_SINGLE = 'PER_KEY_SINGLE',
  PER_KEY_MULTIPLE = 'PER_KEY_MULTIPLE',
  PER_FACILITY = 'PER_FACILITY',
}

export enum KeyPolicy {
  NO_KEYS = 'NO_KEYS',
  ALL_KEYS = 'ALL_KEYS',
  SELECTED_KEYS = 'SELECTED_KEYS',
}

export class QuestionListDTO {
  id: string
  code: string
  title: string
  projects: LabelValueDTO[]
  section: QuestionSectionDTO
  siteVisitType: SiteVisitType
  competitions: LabelValueDTO[]
  events: LabelValueDTO[]
  target: QuestionTarget
  keys: string[]
  keyPolicy: KeyPolicy
  trackPeople: boolean

  constructor(model?: QuestionDTO) {
    if (model) {
      this.id = model.id
      this.code = model.code
      this.title = model.title
      this.projects = model.projects
      this.section = model.section
      this.siteVisitType = model.siteVisitType
      this.competitions = model.competitions
      this.events = model.events
      this.target = model.target
      this.keys = model.keys.selected ?? []
      this.keyPolicy = model.keyPolicy
      this.trackPeople = model.trackPeople
    }
  }
}

export class QuestionKeyDTO {
  selected: string[]
  relatedIds: string[]
}

export class QuestionDTO {
  id: string
  code: string
  title: string
  guidelines?: string
  projects?: LabelValueDTO[]
  section: QuestionSectionDTO
  siteVisitType: SiteVisitType
  target: QuestionTarget
  trackPeople: boolean
  requireLocation: boolean
  audience?: LabelValueDTO[]
  keyPolicy: KeyPolicy
  competitions?: LabelValueDTO[]
  events?: LabelValueDTO[]
  keys?: QuestionKeyDTO
  fields?: FieldDTO[]
  templates?: SVRMediaDTO[]
  allowRichContent?: boolean
}

export class QuestionUpdateDTO {
  @Length(1)
  @StringTrim()
  title: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  guidelines?: string

  @IsUUID()
  sectionId: string

  @IsNotEmpty()
  @IsEnum(SiteVisitType)
  siteVisitType: SiteVisitType

  @IsNotEmpty()
  @IsEnum(QuestionTarget)
  target: QuestionTarget

  @IsBoolean()
  @BooleanParse()
  trackPeople: boolean

  @IsBoolean()
  @BooleanParse()
  requireLocation: boolean

  @IsBoolean()
  @BooleanParse()
  allowRichContent: boolean

  @IsArray()
  @IsNotEmpty()
  @ArrayParse()
  @ArrayMinSize(1)
  projectIds?: string[]

  @IsArray()
  @IsOptional()
  @ArrayParse()
  audience?: string[]

  @IsOptional()
  @IsEnum(KeyPolicy)
  @ValidateIf(question => !!question.keyPolicy)
  keyPolicy?: KeyPolicy

  @IsOptional()
  @IsArray()
  @ArrayParse()
  events?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  keys?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @Type(() => FieldUpdateDTO)
  @ValidateNested({ each: true })
  fields?: FieldUpdateDTO[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @Type(() => SVRMediaDTO)
  @ValidateNested({ each: true })
  @ValidateIf(question => !!question.templates)
  templates?: SVRMediaDTO[]

  constructor(model?: QuestionDTO) {
    this.keys = []
    this.events = []
    this.templates = []
    this.fields = []
    this.audience = []
    this.allowRichContent = true
    this.requireLocation = false

    if (model) {
      this.audience = model.audience?.map(c => c.value) ?? []
      this.events = model.events?.map(c => c.value) ?? []
      this.fields = model.fields?.map(f => new FieldUpdateDTO(f)) ?? []
      this.guidelines = model.guidelines
      this.keys = model.keys?.selected ?? []
      this.projectIds = model.projects?.map(c => c.value) ?? []
      this.sectionId = model.section.value
      this.siteVisitType = model.siteVisitType
      this.target = model.target
      this.title = model.title
      this.trackPeople = model.trackPeople
      this.requireLocation = model.requireLocation
      this.allowRichContent = model.allowRichContent
      this.keyPolicy = model.keyPolicy ?? KeyPolicy.SELECTED_KEYS
      this.templates = model.templates ?? []
    }
  }
}

export class QuestionCreateDTO extends QuestionUpdateDTO {}

export class QuestionPlanDTO extends QuestionCreateDTO {
  id?: string
  planDelete?: boolean
}

export class QuestionSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsEnum(QuestionSortBy)
  @ValidateIf(q => !!q.sortBy)
  sortBy?: string

  @IsOptional()
  @ArrayParse()
  keyIds?: string[]

  @IsOptional()
  @ArrayParse()
  projectIds?: string[]

  @IsOptional()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @ArrayParse()
  sectionIds?: string[]

  @IsOptional()
  @ArrayParse()
  siteVisitTypes?: string[]

  @IsOptional()
  @ArrayParse()
  excludedIds?: string[]

  @IsOptional()
  @StringTrim()
  title?: string

  @IsOptional()
  @StringTrim()
  code?: string

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  deleted?: boolean
}

export class QuestionConfigurationDTO extends BaseConfigurationDTO {
  trackPeople: boolean
  target: QuestionTarget
}

export class QuestionBulkUpdateDTO {
  @IsNotEmpty()
  @ValidateNested()
  @Type(() => QuestionSearchDTO)
  search: QuestionSearchDTO

  @IsOptional()
  @IsUUID('all')
  eventToAdd: string

  @IsOptional()
  @IsUUID('all')
  eventToRemove: string
}

export class QuestionBulkStatusUpdateDTO {
  @IsEnum(QuestionStatus)
  @IsNotEmpty()
  status: QuestionStatus

  @IsOptional()
  @IsString()
  rejectReason: string
}
