import { AngularEditorConfig } from '@kolkov/angular-editor'

export const richTextConfig: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: 'auto',
  minHeight: '3rem',
  translate: 'no',
  defaultParagraphSeparator: 'br',
  sanitize: true,
  toolbarHiddenButtons: [
    [
      'removeFormat',
      'redo',
      'undo',
      'fontName',
      'strikeThrough',
      'subscript',
      'superscript',
      'justifyLeft',
      'justifyCenter',
      'justifyRight',
      'justifyFull',
      'backgroundColor',
      'customClasses',
      'link',
      'unlink',
      'insertImage',
      'insertVideo',
      'insertHorizontalRule',
      'toggleEditorMode',
    ],
  ],
  customClasses: [
    {
      name: 'quote',
      class: 'quote',
    },
    {
      name: 'redText',
      class: 'redText',
    },
    {
      name: 'titleText',
      class: 'titleText',
      tag: 'h1',
    },
  ],
  outline: false
}
