import { IsNotEmpty, IsString, IsUUID, ValidateIf } from 'class-validator'
import { StringTrim } from '../decorators'
import { VistaDTO } from '../shared'

export class RoundDTO extends VistaDTO {
  competitionId: string
}

export class RoundListDTO extends RoundDTO {}

export class RoundCreateDTO {
  @StringTrim()
  @IsNotEmpty()
  integrationId?: string

  @ValidateIf(round => !round.competitionId)
  @IsNotEmpty()
  @IsString()
  competitionIntegrationId?: string

  @ValidateIf(round => !round.competitionIntegrationId)
  @IsUUID()
  @IsNotEmpty()
  competitionId?: string

  @IsNotEmpty()
  @IsString()
  name: string

  @IsNotEmpty()
  public seasonId: number

  public roundModeId: number
  public roundModeCode: string
  public roundModeName: string
  public teamsNumber: number
  public typeCoefficient: number
  public floor: number
  public importance: number
  public adjustmentFactor: number
  public typeTournamentId: number
  public ownerPrintName: string
  public ownerCode: string
  public ownerName: string
  public ownerId: number
  public order: number
  public roundLevelCode: string
  public roundLevelId: number
  public roundTypeId: number
  public roundTypeCode: string
  public hosting: string
  public dateFrom: Date
  public dateTo: Date
  public validationId: number
  public validationCode: string
  public validationName: string
  public statusPublishId: number
  public statusPublishCode: string
  public statusPublishName: string
  public statusId: number
  public statusName: string
  public typeStadiumName: number
  public typeStadiumNameDesc: string
}

export class RoundUpdateDTO extends RoundCreateDTO {}
