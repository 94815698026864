import { ArrayParse, BooleanParse, PaginatedSearchDTO } from '@uefa-shared/contracts'
import { IsArray, IsBoolean, IsEnum, IsOptional } from 'class-validator'
import { SiteVisitType, WorkingVisitStatus } from '../shared/enums/site-visit.enum'

export class WorkingVisitSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @ArrayParse()
  siteVisitIds?: string[]

  @IsOptional()
  @ArrayParse()
  questionIds?: string[]

  @IsOptional()
  @ArrayParse()
  competitionsIds?: string[]

  @IsOptional()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @ArrayParse()
  venueIds?: string[]

  @IsOptional()
  @ArrayParse()
  siteIds?: string[]

  @IsOptional()
  @ArrayParse()
  teamIds?: string[]

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  withSiteVisitName?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  withActionPoints?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  completed?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  includeFutureWorkingVisits?: boolean

  @IsOptional()
  @IsEnum(SiteVisitType, { each: true })
  @IsArray()
  @ArrayParse()
  siteVisitTypes?: SiteVisitType[]
}

export class WorkingVisitOverviewSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  venueIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  projectIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  siteIds?: string[]

  @IsOptional()
  @ArrayParse()
  @IsEnum(SiteVisitType, { each: true })
  types?: SiteVisitType[]

  @IsOptional()
  @ArrayParse()
  @IsEnum(WorkingVisitStatus, { each: true })
  status?: WorkingVisitStatus[]

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  showCompletedEvents?: boolean
}

export class WorkingVisitAudienceSearchDTO {
  @ArrayParse()
  ids: string[]

  @ArrayParse()
  eventIds?: string[]
}
