import { Injectable } from '@angular/core'
import { HeaderNotificationItem, HeaderNotificationService } from '@uefa-shared/frontend'
import { MediaRotationDegrees } from '@uefa-svr/contracts'
import { Observable, Subject } from 'rxjs'
import { FacilityService, MonitorService } from './api'

export class MediaToUpload {
  file: File
  id?: string // when uploading a media to a Facility we don't have the media id
  copyFromId?: string
  facilityId?: string
  rotation?: MediaRotationDegrees
  mediaKey?: string
}

export class MediaToDelete {
  mediaId: string
  entityId: string
  mediaKey?: string
}

@Injectable({ providedIn: 'root' })
export class MediaQueueService {
  private isUploading = false
  private isDeleting = false
  private _mediaIdsToDelete: MediaToDelete[] = []
  private _mediasToUpload: MediaToUpload[] = []
  public deleteSubject = new Subject<MediaToDelete[]>()
  public uploadSubject = new Subject<MediaToUpload[]>()
  public uploadFinishedSubject = new Subject<{ id: string; success: boolean }>()
  public deleteFinishedSubject = new Subject<MediaToDelete>()

  private totalNumberOfMediasToUpload = 0
  private currentMediaCount = 0
  private mediaQueueNotificationsId = 'media-queue-notification-id'

  constructor(
    private readonly monitorService: MonitorService,
    private readonly facilityService: FacilityService,
    private readonly headerNotificationService: HeaderNotificationService
  ) {
    this.uploadSubject.subscribe((mediasToUpload) => {
      if (!this.isUploading && mediasToUpload.length) {
        this.uploadMedia(mediasToUpload[0])
      }

      if (!mediasToUpload.length) {
        this.removeCloseBrowserCheck()
      }
    })

    this.deleteSubject.subscribe((mediasToDelete) => {
      if (!this.isDeleting && mediasToDelete.length) {
        this.deleteMedia(mediasToDelete[0])
      }

      if (!mediasToDelete.length) {
        this.removeCloseBrowserCheck()
      }
    })
  }

  public isMediaUploading(id: string) {
    return this._mediasToUpload.some((m) => m.id === id)
  }

  public addToQueue(medias: MediaToUpload[]) {
    this._mediasToUpload = [...this._mediasToUpload, ...medias]
    this.totalNumberOfMediasToUpload = this.totalNumberOfMediasToUpload += medias.length
    this.uploadSubject.next(this._mediasToUpload)
    this.addCloseBrowserCheck()
  }

  public addToDeleteQueue(mediaIds: MediaToDelete[]) {
    this._mediaIdsToDelete = [...this._mediaIdsToDelete, ...mediaIds]
    this.deleteSubject.next(this._mediaIdsToDelete)
    this.addCloseBrowserCheck()
  }

  private uploadMedia(media: MediaToUpload) {
    this.currentMediaCount = this.currentMediaCount + 1
    const currentMediaCount = this.currentMediaCount
    this.sendHeaderNotification()
    this.isUploading = true
    let observable: Observable<void>

    if (!media.file && media.rotation) {
      observable = this.monitorService.updateMedia(media.id, { id: media.id, rotation: media.rotation })
    } else if (media.copyFromId) {
      observable = this.monitorService.copyMedia(media.id, media.copyFromId)
    } else if (media.facilityId) {
      observable = this.facilityService.uploadMedia(media.facilityId, media.file)
    } else {
      observable = this.monitorService.uploadMedia(media.id, media.file, media.rotation, media.mediaKey)
    }

    observable.subscribe(
      () => {
        this.isUploading = false
        this.uploadFinished(media.id, currentMediaCount)
      },
      (err) => {
        console.log(err)
        this.isUploading = false
        this.uploadFinished(media.id, currentMediaCount, false)
      }
    )
  }

  private deleteMedia(media: MediaToDelete) {
    if (!media.entityId) {
      return
    }

    this.isDeleting = true

    this.facilityService.deleteMedia(media.entityId, media.mediaId).subscribe(
      () => {
        this.isDeleting = false
        this.deleteFinished(media.mediaId)
      },
      (err) => {
        console.log(err)
        this.isDeleting = false
        this.deleteFinished(media.mediaId)
      }
    )
  }

  private uploadFinished(id: string, currentMediaCount: number, success: boolean = true) {
    const mediaIndex = this._mediasToUpload.findIndex((m) => m.id === id)
    this._mediasToUpload.splice(mediaIndex, 1)

    this.uploadSubject.next(this._mediasToUpload)
    this.uploadFinishedSubject.next({ id, success })

    if (currentMediaCount === this.totalNumberOfMediasToUpload) {
      this.removeMediaNotifications()
    }
  }

  private deleteFinished(id: string) {
    const mediaIndex = this._mediaIdsToDelete.findIndex((m) => m.mediaId === id)
    const [deletedMedia] = this._mediaIdsToDelete.splice(mediaIndex, 1)
    this.deleteSubject.next(this._mediaIdsToDelete)
    this.deleteFinishedSubject.next(deletedMedia)
  }

  private sendHeaderNotification() {
    this.headerNotificationService.isLoading = true
    const existingNotifications =
      this.headerNotificationService.currentNotifications?.filter((n) => n.id !== this.mediaQueueNotificationsId) ?? []
    const newNotification: HeaderNotificationItem = {
      id: this.mediaQueueNotificationsId,
      date: new Date(),
      icon: 'image',
      message: `Uploading ${this.currentMediaCount} of ${this.totalNumberOfMediasToUpload} medias`,
      animatedEllipsis: true,
      progress: (this.currentMediaCount / this.totalNumberOfMediasToUpload) * 100,
    }
    this.headerNotificationService.changeNotifications([newNotification, ...existingNotifications])
  }

  private removeMediaNotifications() {
    const existingNotifications =
      this.headerNotificationService.currentNotifications?.filter((n) => n.id !== this.mediaQueueNotificationsId) ?? []
    this.headerNotificationService.changeNotifications(existingNotifications)
    this.headerNotificationService.isLoading = false
    this.totalNumberOfMediasToUpload = 0
    this.currentMediaCount = 0
  }

  private addCloseBrowserCheck() {
    if (!this._mediaIdsToDelete.length && !this._mediasToUpload.length) {
      return
    }

    window.onbeforeunload = function (event) {
      if (event) {
        event.returnValue = 'There are medias still being uploaded.. Are you sure you want to leave?'
      }
    }
  }

  private removeCloseBrowserCheck() {
    if (this._mediaIdsToDelete.length || this._mediasToUpload.length) {
      return
    }

    window.onbeforeunload = null
  }
}
