import { Hatch, PaginatedSearchDTO } from '@uefa-shared/contracts'
import { ArrayParse } from '@uefa-vista/contracts'
import { IsArray, IsOptional, IsString } from 'class-validator'
import { SVRVistaListDTO } from './vista.dto'

export enum SVRKeyListFormat {
  TREE = 'TREE',
  PLAIN = 'PLAIN',
}

export class SVRKeySearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  siteTypeIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  siteIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventOrEventCycleIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  competitionIds?: string[]

  @IsOptional()
  @IsString()
  format?: SVRKeyListFormat

  @IsOptional()
  parentId?: string
}

export class SVRKeyListDTO extends SVRVistaListDTO {
  label: string
  keysetId: number
  color?: string
  order?: number
  hatch?: Hatch
  hatchColor?: string
  parentId?: string
  siteTypeId?: string
  siteTypeName?: string
  numberOfChildren?: number
  children?: SVRKeyListDTO[]
}
