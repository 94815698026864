import { ArrayMinSize, IsArray, IsNotEmpty, IsNumber, IsOptional, IsUUID, ValidateNested } from 'class-validator'
import { StringCleanup, StringTrim } from '../decorators'
import { LabelValueDTO } from '../shared'
import { VistaDTO, VistaListDTO } from '../shared/vista.dto'

export class SiteTypeDTO extends VistaDTO {
  numberOfKeys: number
  numberOfSites: number
  keys?: string[]
  sites?: LabelValueDTO[]
  imageUrl?: string
  order: number

  constructor() {
    super()
    this.keys = null
    this.sites = null
    this.imageUrl = null
  }
}

export class SiteTypeListDTO extends VistaListDTO {
  numberOfKeys: number
  numberOfSites: number
  imageUrl?: string
  order: number

  constructor() {
    super()
    this.numberOfKeys = 0
    this.numberOfSites = 0
    this.imageUrl = null
  }
}

export class SiteTypeUpdateDTO {
  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  name: string

  @StringTrim()
  @IsOptional()
  integrationId?: string

  constructor(model?: SiteTypeDTO) {
    this.name = model?.name
    this.integrationId = model?.isFameIntegrated ? model.integrationId : ''
  }
}

export class SiteTypeCreateDTO extends SiteTypeUpdateDTO {}

export class SiteTypeOrderDTO {
  @IsUUID()
  @IsNotEmpty()
  id: string

  @IsNumber()
  @IsNotEmpty()
  order: number
}
export class SiteTypeSortDTO {
  @IsArray()
  @ValidateNested({ each: true })
  @ArrayMinSize(2)
  orders: SiteTypeOrderDTO[]
}
