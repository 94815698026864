import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { HeaderApplicationsService } from '@uefa-shared/frontend'
import { ApplicationSearchDTO } from '@uefa-vista/contracts'
import { environment } from '../environments/environment'
import { ApplicationsService } from './shared/services/api'

@Component({
  selector: 'svr-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  constructor(
    private readonly translateService: TranslateService,
    private readonly applicationService: ApplicationsService,
    private readonly headerApplicationService: HeaderApplicationsService
  ) {}

  public ngOnInit(): void {
    this.translateService.addLangs(['en'])
    this.translateService.setDefaultLang('en')

    const filter = new ApplicationSearchDTO()
    filter.inApplicationMenu = true

    this.applicationService.getAll(filter).subscribe((apps) => {
      this.headerApplicationService.setApplications(apps.filter((app) => app.name !== environment.name))
    })
  }
}
