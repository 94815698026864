import { Injectable } from '@angular/core'
import { LatLng, WorkingVisitQuestionDTO } from '@uefa-svr/contracts'
import { Subject } from 'rxjs'
import { FacilitySelect, SVRMapComponent, SVRMapMarker, SVRMapOptions } from '../components/map'

export interface MapContext {
  eventId?: string
  venueId?: string
  siteId?: string
  question?: WorkingVisitQuestionDTO
  actionPointId?: string
  levelId?: string
  center?: LatLng
  overviewToggled?: boolean
  facilityId?: string
}

const regularMapOptions: SVRMapOptions = {
  levelSwitchControl: true,
  mapTypeControl: false,
  searchFacilitiesControl: true,
  zoomControl: true,
  markerClustering: true,
  keysFilter: false,
  collapsableSearch: true,
  fitBoundsOnSite: true,
  facilityInfoControl: 'none',
  fitBoundsOnMarkers: false,
  showAddFacilityButton: true,
  showLayersButton: true,
  showFullScreenButton: true,
  allowIdentifyEdit: true,
}

const disableEditMapOptions: SVRMapOptions = {
  ...regularMapOptions,
  showAddFacilityButton: false,
  allowIdentifyEdit: false,
}

const actionPointMapOptions: SVRMapOptions = {
  ...disableEditMapOptions,
  searchFacilitiesControl: false,
}

@Injectable({ providedIn: 'root' })
export class MapService {
  private _mapContext: MapContext = {}
  private _mapComponent: SVRMapComponent
  private mapEditDisabled = false
  public mapOpen: boolean
  public mapInitialized = false

  public mapOpenChange = new Subject<boolean>()

  public mapContextChange = new Subject<MapContext>()
  public facilitySelectedChange = new Subject<FacilitySelect>()
  public questionFacilityChange = new Subject<FacilitySelect>()
  public markersChange = new Subject<SVRMapMarker[]>()

  public get mapComponent() {
    return this._mapComponent
  }
  public set mapComponent(component: SVRMapComponent) {
    this._mapComponent = component
  }

  public get mapContext() {
    return this._mapContext
  }

  /* Map */

  public toggleMap() {
    if (this.mapOpen) {
      this.closeMap()
    } else {
      this.openMap()
    }
  }

  public disableMapEdit() {
    this.mapEditDisabled = true
  }

  public enableMapEdit() {
    this.mapEditDisabled = false
  }

  public updateMapContext(context: MapContext) {
    context.overviewToggled = context.overviewToggled ?? this._mapContext.overviewToggled ?? false
    this._mapContext = { ...this._mapContext, ...context }
    this.mapContextChange.next(this._mapContext)
  }

  public setMarker(marker: SVRMapMarker) {
    if (!this.mapOpen) {
      this.openMap()
    }
    this.updateMarkers([marker])
  }

  public updateMarkers(markers: SVRMapMarker[]) {
    this.markersChange.next(markers)
  }

  public getMapOptions(isOnlyReader = false, allowEditMap = false) {
    const mapOptions = this.calcMapOptions(allowEditMap)

    if (isOnlyReader) {
      mapOptions.allowIdentifyEdit = false
      mapOptions.showAddFacilityButton = false
    }

    return mapOptions
  }

  public get currentSiteId(): string {
    return this._mapContext.siteId
  }

  public get currentMapCenter(): LatLng {
    return this._mapContext.center
  }

  public get currentLevelId(): string {
    return this._mapContext.levelId ?? this.getSiteDefaultLevelId()
  }

  public get currentOverviewToggle(): boolean {
    return this._mapContext.overviewToggled
  }

  public get currentEventId(): string {
    return this._mapContext.eventId
  }

  public get currentVenueId(): string {
    return this._mapContext.venueId
  }

  public get currentFacilityId(): string {
    return this._mapContext.facilityId
  }

  public openMap() {
    this.mapInitialized = true
    this.mapOpen = true
    this.mapOpenChange.next(true)
  }

  public closeMap() {
    this.mapOpen = false
    this.mapOpenChange.next(false)
  }

  public facilitySelected(facility: FacilitySelect) {
    this.facilitySelectedChange.next(facility)
  }

  public setQuestionFacility(facility: FacilitySelect) {
    this.questionFacilityChange.next(facility)
  }

  public getSiteDefaultLevelId(): string {
    return this.mapComponent.site.levels.find((l) => l.default).id
  }

  private calcMapOptions(allowEditMap = true) {
    if (this._mapContext?.actionPointId) {
      return actionPointMapOptions
    }

    if (!allowEditMap || this.mapEditDisabled) {
      return disableEditMapOptions
    }

    return regularMapOptions
  }
}
