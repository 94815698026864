import { VistaMapConfiguration } from '@uefa-vista/map'
import { environment } from '../environments/environment'

export const vistaMapConfiguration: VistaMapConfiguration = {
  applicationId: environment.mapApplicationId,
  googleMapsAPIKey: environment.googleMapsAPIKey,
  vistaApiURL: environment.apibaseUrl,
  baseMaps: ['uefa-light', 'uefa-dark', 'google-hybrid'],
  useCache: false,
  allowLevelToggle: true,
  mapComponent: {
    minZoom: 3,
  },
}
