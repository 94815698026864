import { IsNotEmpty, IsOptional } from 'class-validator'
import { BadRequest } from '../exceptions'
import { SVRAuthenticatedUserDTO } from '../user'

export class ActionPointCommentDTO {
  @IsOptional()
  id: string

  @IsNotEmpty()
  userId: string

  @IsNotEmpty()
  userUpn: string

  @IsOptional()
  createdAt?: Date

  @IsOptional()
  updatedAt?: Date

  @IsNotEmpty({ message: BadRequest.ACTION_POINT_COMMENT_SHOULD_NOT_BE_EMPTY })
  comment?: string

  userName?: string
  userInitials?: string

  constructor(userDto?: SVRAuthenticatedUserDTO, id?: string) {
    this.id = id ?? `${Math.round(Math.random() * 1000 * 100)}-${+new Date()}`

    if (userDto) {
      this.userId = userDto.id
      this.userUpn = userDto.email
      this.userInitials = userDto.initials
      this.userName = userDto.name
    }
    this.createdAt = new Date()
  }
}
