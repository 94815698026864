<div class="action-points-manager">
  <div class="panel-header">
    <div class="title-container" *ngIf="original">
      <span class="title text-truncate" matTooltip="{{ original?.code }} - {{ original?.title }}"
        >{{ original?.code }} - {{ original?.title }}</span
      >
      <div *ngIf="siteVisit" class="subtitle">{{ siteVisit?.title }} > {{ original?.workingVisit?.label }}</div>
    </div>
    <div class="title-container" *ngIf="!original">
      <span>{{ title | translate }}</span>
      <div class="subtitle">
        {{ siteVisit ? siteVisit.title : '' }}
        {{ siteVisit && workingVisit ? '>' : '' }}
        {{ workingVisit ? workingVisit.label : '' }}
      </div>
    </div>
    <div class="flex-grow"></div>
    <button
      mat-icon-button
      [class.selected]="mapOpen"
      color="primary"
      [disabled]="!isSiteAvailable"
      (click)="onCloseMap()"
      *ngIf="closeMapButton"
    >
      <i-feather *ngIf="mapOpen" class="icon" name="map" matTooltip="{{ 'shared.actionPointsManager.closeMap' | translate }}"></i-feather>
      <i-feather *ngIf="!mapOpen" class="icon" name="map" matTooltip="{{ 'shared.actionPointsManager.openMap' | translate }}"></i-feather>
    </button>
    <button mat-icon-button color="primary" (click)="onClose()">
      <i-feather class="icon" name="x" matTooltip="{{ 'shared.actionPointsManager.close' | translate }}"></i-feather>
    </button>
  </div>
  <div class="panel-body" *ngIf="!loading">
    <form #f="ngForm" autocomplete="off" (ngSubmit)="onSubmit()">
      <div class="top-form">
        <div class="summary" *ngIf="original && data">
          <img class="icon" [src]="statusIcon" />
          <img class="icon" [src]="priorityIcon" />
          <div class="user-info" *ngIf="original.createdBy || original.updatedBy">
            <span *ngIf="original.createdBy"
              ><b>{{
                'shared.actionPointsManager.createdBy'
                  | translate: { name: original.createdBy, date: original.createdAt | date: 'yyyy/MM/dd - HH:mm' }
              }}</b></span
            >
            <span *ngIf="original.updatedBy"
              ><b>{{
                'shared.actionPointsManager.updatedBy'
                  | translate: { name: original.updatedBy, date: original?.updatedAt | date: 'yyyy/MM/dd - HH:mm' }
              }}</b></span
            >
          </div>
          <div class="flex-grow" *ngIf="isDraft"></div>
          <div class="draft-badge" *ngIf="isDraft">{{ 'shared.enums.actionPointStatus.DRAFT' | translate }}</div>
        </div>

        <div class="grid-fields" *ngIf="data">
          <mat-form-field class="col-span-4">
            <mat-label>{{ 'shared.actionPointsManager.title' | translate }}</mat-label>
            <input name="title" matInput [(ngModel)]="data.title" [required]="isFieldRequired('title')" />
          </mat-form-field>
          <vista-select-search
            class="col-span-2"
            label="shared.actionPointsManager.assignedTo"
            name="assignedTo"
            [selected]="data.assignedTo"
            [(ngModel)]="data.assignedToId"
            (selectedChange)="onLabelValueChange('assignedTo', $event)"
            [dataAsync]="projectOptionsAsync"
            [required]="isFieldRequired('assignedToId')"
            [disabled]="!canEdit"
            [disableLocalFilter]="true"
          ></vista-select-search>
          <mat-form-field class="col-span-2" *ngIf="!isDamageReport && !isInjuryReport" (click)="dueDatePicker.open()">
            <mat-label>{{ 'shared.actionPointsManager.dueDate' | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="dueDatePicker"
              name="dueDate"
              [(ngModel)]="data.dueDate"
              [required]="isFieldRequired('dueDate')"
            />
            <mat-datepicker-toggle matSuffix [for]="dueDatePicker">
              <mat-icon matDatepickerToggleIcon [style]="{ 'font-size': '1.5rem !important' }">arrow_drop_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #dueDatePicker [disabled]="!canEdit"></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="col-span-2" *ngIf="isDamageReport || isInjuryReport" (click)="damageDatePicker.open()">
            <mat-label>{{ damageDateLabel | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="damageDatePicker"
              name="damageDate"
              [(ngModel)]="data.damageDate"
              [required]="isFieldRequired('damageDate')"
            />
            <mat-error>{{ 'shared.actionPointsManager.invalidDamageDateError' | translate }}</mat-error>
            <mat-datepicker-toggle matSuffix [for]="damageDatePicker">
              <mat-icon matDatepickerToggleIcon [style]="{ 'font-size': '1.5rem !important' }">arrow_drop_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #damageDatePicker [disabled]="!canEdit"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <mat-tab-group class="col-span-8" [(selectedIndex)]="selectedTabIndex">
        <mat-tab>
          <div class="grid-fields">
            <ng-template mat-tab-label>
              <span>{{ 'shared.actionPointsManager.tabs.details' | translate }}</span>
            </ng-template>
            <mat-form-field class="col-span-8">
              <mat-label>{{ 'shared.actionPointsManager.description' | translate }}</mat-label>
              <textarea
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                matInput
                type="text"
                name="description"
                [(ngModel)]="data.description"
                [required]="isFieldRequired('description')"
              ></textarea>
            </mat-form-field>
            <vista-select-search
              #sectionSelect
              class="col-span-4"
              label="shared.actionPointsManager.section"
              name="section"
              [selected]="data.section"
              [(ngModel)]="data.sectionId"
              [dataAsync]="sectionOptionsAsync"
              (ngModelChange)="onSectionChange()"
              (selectedChange)="onLabelValueChange('section', $event)"
              [allowEmpty]="true"
              [disabled]="!canEdit || !siteVisit"
              [disableLocalFilter]="true"
              [required]="isFieldRequired('sectionId')"
            ></vista-select-search>
            <vista-select-search
              class="col-span-4"
              #questionSelect
              label="shared.actionPointsManager.question"
              name="question"
              [selected]="data.workingVisitQuestion"
              [(ngModel)]="data.workingVisitQuestionId"
              [dataAsync]="questionOptionsAsync"
              (selectedChange)="onLabelValueChange('workingVisitQuestion', $event)"
              [allowEmpty]="true"
              [disabled]="!canEdit || !data.sectionId || !siteVisit"
              [disableLocalFilter]="true"
              [required]="isFieldRequired('workingVisitQuestionId')"
            ></vista-select-search>
            <vista-select-search
              class="col-span-2"
              label="shared.actionPointsManager.priority"
              name="priority"
              [(ngModel)]="data.priority"
              (ngModelChange)="updateMarkerImage()"
              [optionTemplate]="priorityTemplate"
              [valueTemplate]="priorityTemplate"
              [data]="priorityOptions"
              [showSearch]="false"
              [required]="isFieldRequired('priority')"
              [disabled]="!canEdit"
            ></vista-select-search>
            <vista-select-search
              class="col-span-2"
              label="shared.actionPointsManager.status"
              name="status"
              [(ngModel)]="data.status"
              (ngModelChange)="onStatusChange()"
              [optionTemplate]="statusTemplate"
              [valueTemplate]="statusTemplate"
              [data]="statusOptions"
              [showSearch]="false"
              [required]="true"
              [disabled]="!canEdit"
            ></vista-select-search>
            <vista-select-search
              class="col-span-2"
              label="shared.actionPointsManager.project"
              name="project"
              [selected]="data.project"
              [(ngModel)]="data.projectId"
              [dataAsync]="projectOptionsAsync"
              (selectedChange)="onLabelValueChange('project', $event)"
              [disabled]="!canEdit"
              [selected]="original?.project"
              [allowEmpty]="true"
              [required]="isFieldRequired('projectId')"
              [disableLocalFilter]="true"
            ></vista-select-search>
            <vista-select-search
              class="col-span-2"
              label="shared.actionPointsManager.informed"
              name="informed"
              [multiple]="true"
              [selected]="data.informed"
              [(ngModel)]="data.informedIds"
              [dataAsync]="projectOptionsAsync"
              (selectedChange)="onLabelValueChange('informed', $event)"
              [disabled]="!canEdit"
              [disableLocalFilter]="true"
              [required]="isFieldRequired('informedIds')"
            ></vista-select-search>
            <div class="col-span-8 add-location-placeholder">{{ 'shared.actionPointsManager.addLocationPlaceholder' | translate }}</div>
            <div class="col-span-1 location-wrapper">
              <button
                type="button"
                [ngStyle]="{ 'background-image': 'url(' + locationIconUrl + ')' }"
                mat-icon-button
                color="primary"
                class="col-span-5"
                (click)="onAddLocation()"
                [disabled]="!isSiteAvailable"
                matTooltip="{{ 'shared.actionPointsManager.addLocation' | translate }}"
              ></button>
              <button
                type="button"
                [ngStyle]="{ 'background-image': 'url(' + removeLocationIconUrl + ')' }"
                mat-icon-button
                color="accent"
                class="col-span-5"
                (click)="onRemoveLocation()"
                [disabled]="!canEdit || !data.location?.level?.id"
                matTooltip="{{ 'shared.actionPointsManager.removeLocation' | translate }}"
              ></button>
            </div>
            <vista-select-search
              #levelSelect
              [class]="'col-span-1'"
              label="shared.actionPointsManager.level"
              name="level"
              [selected]="data.level"
              [(ngModel)]="data.levelId"
              (ngModelChange)="onLevelChange($event)"
              [dataAsync]="levelOptionsAsync"
              (selectedChange)="onLabelValueChange('level', $event)"
              [disabled]="!canEdit || !isSiteAvailable"
              [disableLocalFilter]="true"
              [required]="isFieldRequired('levelId')"
            ></vista-select-search>
            <mat-form-field class="col-span-3" floatLabel="always">
              <mat-label>{{ 'shared.actionPointsManager.key' | translate }}</mat-label>
              <input
                name="key"
                matInput
                [ngModel]="data.key ? data.key.label : ''"
                placeholder="{{ 'shared.actionPointsManager.keyPlaceholder' | translate }}"
                [disabled]="true"
                [ngStyle]="{ 'font-style': !data.key ? 'italic' : '' }"
              />
            </mat-form-field>
            <mat-form-field class="col-span-3" floatLabel="always">
              <mat-label>{{ 'shared.actionPointsManager.location' | translate }}</mat-label>
              <input
                name="locationName"
                matInput
                [(ngModel)]="data.location.name"
                placeholder="{{ 'shared.actionPointsManager.addNotesPlaceholder' | translate }}"
                [ngStyle]="{ 'font-style': !data.location.name ? 'italic' : '' }"
              />
            </mat-form-field>
            <vista-select-search
              class="col-span-2"
              *ngIf="isDamageReport"
              label="shared.actionPointsManager.damageAccepted"
              name="damageAccepted"
              [selected]="selectedDamageAccepted"
              (selectedChange)="onDamageAcceptedChange($event, f)"
              [optionTemplate]="damageAcceptedTemplate"
              [data]="damageAcceptedOptions"
              [showSearch]="false"
              [multiple]="false"
              [required]="isFieldRequired('damageAccepted')"
              [disabled]="!canEdit"
            ></vista-select-search>
            <mat-form-field class="col-span-4" *ngIf="isDamageReport || isInjuryReport">
              <mat-label>{{ damageCausedByLabel | translate }}</mat-label>
              <textarea
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="2"
                matInput
                type="text"
                name="damageCausedBy"
                [(ngModel)]="data.damageCausedBy"
                [required]="isFieldRequired('damageCausedBy')"
              ></textarea>
            </mat-form-field>
            <vista-select-search
              *ngIf="isDamageReport"
              label="shared.actionPointsManager.stand"
              name="stand"
              class="col-span-2"
              [(ngModel)]="data.stand"
              [data]="standOptions"
              [optionTemplate]="standTemplate"
              [showSearch]="false"
              [allowEmpty]="true"
              [disabled]="!canEdit"
              [required]="isFieldRequired('stand')"
            ></vista-select-search>
            <vista-select-search
              *ngIf="isDamageReport"
              class="col-span-2"
              label="shared.actionPointsManager.localCurrency"
              name="localCurrency"
              [(ngModel)]="data.localCurrencyId"
              [dataAsync]="localCurrencyOptionsAsync"
              [allowEmpty]="true"
              [disabled]="!canEdit"
              [disableLocalFilter]="true"
            ></vista-select-search>
            <mat-form-field class="col-span-2" *ngIf="isDamageReport">
              <mat-label>{{ 'shared.actionPointsManager.localCost' | translate }}</mat-label>
              <input
                type="number"
                name="localCost"
                matInput
                [min]="0"
                [(ngModel)]="data.localCost"
                [vistaMinNumber]="0"
                vistaNumber
                [vistaAllowDecimals]="true"
                data-lpignore="true"
              />
            </mat-form-field>
            <mat-form-field class="col-span-2" *ngIf="isDamageReport">
              <mat-label>{{ 'shared.actionPointsManager.cost' | translate }}</mat-label>
              <input
                type="number"
                name="cost"
                matInput
                [min]="0"
                [(ngModel)]="data.cost"
                [vistaMinNumber]="0"
                vistaNumber
                [vistaAllowDecimals]="true"
                data-lpignore="true"
                [required]="isFieldRequired('cost')"
              />
            </mat-form-field>
            <mat-form-field class="col-span-8" *ngIf="isDamageReport">
              <mat-label>{{ 'shared.actionPointsManager.uefaComment' | translate }}</mat-label>
              <textarea
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="2"
                matInput
                type="text"
                name="uefaComment"
                [(ngModel)]="data.uefaComment"
                [required]="shouldValidate && selectedDamageAccepted?.value === 'false'"
                [disabled]="!canEdit"
                [required]="isFieldRequired('uefaComment')"
              ></textarea>
            </mat-form-field>
            <mat-form-field class="col-span-4" *ngIf="isDamageReport || isInjuryReport">
              <mat-label>{{ 'shared.actionPointsManager.uefaRepresentative' | translate }}</mat-label>
              <textarea
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="2"
                matInput
                type="text"
                name="uefaRepresentative"
                [(ngModel)]="data.uefaRepresentative"
                [required]="isFieldRequired('uefaRepresentative')"
                [disabled]="!canEdit"
              ></textarea>
            </mat-form-field>
            <mat-form-field class="col-span-4" *ngIf="isDamageReport || isInjuryReport">
              <mat-label>{{ 'shared.actionPointsManager.stadiumRepresentative' | translate }}</mat-label>
              <textarea
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="2"
                matInput
                type="text"
                name="stadiumRepresentative"
                [(ngModel)]="data.stadiumRepresentative"
                [disabled]="!canEdit"
                [required]="isFieldRequired('stadiumRepresentative')"
              ></textarea>
            </mat-form-field>
            <svr-media-list-container
              *ngIf="isDamageReport || isInjuryReport"
              class="col-span-8"
              title="{{ 'shared.actionPointsManager.stadiumRepresentativeSignatures' | translate }}"
              [(medias)]="signatures"
              [allowedMimeTypes]="imagesMimeTypes"
              [signaturePicker]="true"
              (mediasChange)="onMediasChanged()"
              (change)="onMediasChanged()"
              [options]="{ deleteButton: false, editButton: false }"
              [disabled]="isFieldRequired('signatures')"
            ></svr-media-list-container>
            <svr-media-list-container
              class="col-span-8"
              title="{{ 'shared.actionPointsManager.documents' | translate }}"
              [(medias)]="documents"
              [allowedMimeTypes]="documentsMimeTypes"
              (mediasChange)="onMediasChanged()"
              (change)="onMediasChanged()"
              [disabled]="!canEdit"
            ></svr-media-list-container>
            <svr-media-list-container
              class="col-span-8"
              title="{{ 'shared.actionPointsManager.images' | translate }}"
              [(medias)]="medias"
              [allowedMimeTypes]="imagesMimeTypes"
              (mediasChange)="onMediasChanged()"
              (change)="onMediasChanged()"
              [disabled]="!canEdit"
            ></svr-media-list-container>
          </div>
        </mat-tab>

        <mat-tab>
          <div class="grid-fields">
            <ng-template mat-tab-label>
              <span>{{ 'shared.actionPointsManager.tabs.comments' | translate: { count: data.comments?.length ?? 0} }}</span>
            </ng-template>

            <svr-action-point-comment
              class="col-span-8"
              [disabled]="!this.canEdit"
              [(comments)]="data.comments"
              (commentsChange)="onCommentsChange()"
              [actionPointId]="actionPointId"
            ></svr-action-point-comment>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </div>
  <div class="panel-body" *ngIf="loading">
    <div class="loading-spinner">
      <mat-spinner diameter="48" strokeWidth="1"></mat-spinner>
    </div>
  </div>
  <div class="panel-footer">
    <button type="button" mat-button color="primary" (click)="onSave()" [disabled]="isSaveDisabled()">
      <i-feather name="save" class="icon"></i-feather>
      <span>{{ 'shared.buttons.save' | translate }}</span>
    </button>
    <button type="button" mat-button color="primary" (click)="onPDF()" [disabled]="!original?.id">
      <i-feather name="file-text" class="icon"></i-feather>
      <span>{{ 'shared.actionPointsManager.pdf' | translate }}</span>
    </button>
  </div>
</div>

<ng-template #booleanTemplate let-label="label">
  <span>{{ 'shared.enums.boolean.' + label.toLowerCase() | translate }}</span>
</ng-template>

<ng-template #damageAcceptedTemplate let-label="label">
  <span>{{ 'shared.actionPointsManager.damageAcceptedTemplate.' + label.toLowerCase() | translate }}</span>
</ng-template>

<!--  Status Template -->
<ng-template #statusTemplate let-value="value" let-label="label">
  <div class="status-template-icon-label">
    <img class="icon" [src]="actionPointStatusIcon(value)" />
    <span>{{ 'shared.enums.actionPointStatus.' + label | translate }}</span>
  </div>
</ng-template>

<!--  Priority Template -->
<ng-template #priorityTemplate let-value="value" let-label="label">
  <div class="priority-template-icon-label">
    <img class="icon" [src]="actionPointsPriorityIcon(value)" />
    <span>{{ 'shared.enums.actionPointPriority.' + label | translate }}</span>
  </div>
</ng-template>

<ng-template #standTemplate let-label="label">
  <span>{{ 'shared.enums.actionPointStand.' + label | translate }}</span>
</ng-template>
