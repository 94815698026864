import { AfterViewInit, Component, Inject, OnDestroy, Renderer2, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { PaginatedResults } from '@uefa-shared/contracts'
import { DialogService, GalleryImage, ImageGalleryComponent, ToastService } from '@uefa-shared/frontend'
import { MediaGalleryItem, MediaRotationDegrees, MediaThumbnailSize } from '@uefa-svr/contracts'
import { ArrayUtils } from '@uefa-vista/contracts'
import { Observable, Subscription } from 'rxjs'
import { MediaQueueService } from '../../services'

export interface MediaGalleryData {
  media: (page: number, records: number) => Observable<PaginatedResults<MediaGalleryItem>>
  onSave?: (media: MediaGalleryItem) => void
  onRotate?: (media: MediaGalleryItem, rotation: MediaRotationDegrees) => void
  onDelete?: (media: string) => void
  onOrderChange?: (medias: MediaGalleryItem[]) => void
  selectedMediaKey?: string
  allowEdit?: boolean
  enterInEditMode?: boolean
  enterInExpandedMode?: boolean
  downloadDisabled?: boolean
}

@Component({
  selector: 'svr-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss'],
})
export class SVRMediaGalleryComponent implements AfterViewInit, OnDestroy {
  @ViewChild('gallery') gallery: ImageGalleryComponent
  public selectedGalleryImage: GalleryImage

  public editingMedia: MediaGalleryItem

  public loading = false
  public records: PaginatedResults<MediaGalleryItem>

  public isEditing: boolean
  public updatedMedias: MediaGalleryItem[] = []
  public editFieldId = 'media-name-edit-text-field'

  private dataAsyncSubscription: Subscription
  private pageSize = 30

  private removeKeyupListener: () => void

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: MediaGalleryData,
    private readonly dialogRef: MatDialogRef<SVRMediaGalleryComponent>,
    private readonly renderer: Renderer2,
    private readonly mediaQueueService: MediaQueueService,
    private readonly toastService: ToastService,
    private readonly dialogService: DialogService
  ) {
    data.allowEdit = data.allowEdit ?? false
    data.enterInEditMode = data.enterInEditMode ?? false
    data.downloadDisabled = data.downloadDisabled ?? false

    if (data.enterInEditMode) {
      this.getRecords()
      this.onStartEdit()
    }

    this.dialogRef.backdropClick().subscribe(() => {
      this.onClose()
    })
  }

  public ngAfterViewInit() {
    this.removeKeyupListener = this.renderer.listen(window, 'keyup', (event) => this.onKeyup(event))
  }

  public ngOnDestroy() {
    this.removeKeyupListener()
  }

  public get selectedMedia() {
    return this.records?.data.find((d) => d.mediaKey === this.selectedGalleryImage?.mediaKey)
  }

  public get showSubtitle() {
    return !!this.selectedMedia?.subTitle
  }

  public onKeyup(event: KeyboardEvent) {
    if (this.isEditing) {
      return
    }

    if (event.keyCode === 39) {
      this.gallery.scrollRight()
      this.gallery.scrollCarrouselTo(this.selectedMedia?.mediaKey)
    }
    if (event.keyCode === 37) {
      this.gallery.scrollLeft()
      this.gallery.scrollCarrouselTo(this.selectedMedia?.mediaKey)
    }
  }

  public onRotate(media: GalleryImage) {
    const mediaToUpdate = this.records.data.find((d) => d.mediaKey === media.mediaKey)
    mediaToUpdate.rotation = media.rotation

    this.updatedMedias.push(mediaToUpdate)

    this.data?.onRotate?.(mediaToUpdate, media.rotation)
  }

  public loadMore() {
    this.dataAsyncSubscription?.unsubscribe()
    this.getRecords(true)
  }

  public isUploading(id: string) {
    return this.mediaQueueService.isMediaUploading(id)
  }

  public onSave() {
    const media = this.editingMedia
    if (!media) {
      return
    }

    if (this.isUploading(media.id)) {
      this.toastService.error('features.monitor.gallery.mediaUploading')
      return
    }

    if (!media.name?.trim()) {
      this.toastService.error('features.monitor.gallery.invalidName')
      return
    }
    // we only "save" like this for new medias
    if (!media.uploadedAt) {
      this.selectedMedia.name = media.name
      this.updatedMedias.push(media)
      this.onFinishEdit()
      return
    }

    if (this.data?.onSave) {
      this.data?.onSave?.(this.editingMedia)
      this.selectedMedia.name = this.editingMedia.name
      this.onFinishEdit()
      return
    }

    this.saveImageName()
  }

  private saveImageName() {
    const mediaToSave = this.editingMedia
    this.selectedMedia.name = mediaToSave.name
    this.updatedMedias.push(mediaToSave)
    this.onFinishEdit()
  }

  public onStartEdit() {
    if (!this.data.allowEdit || this.isEditing) {
      return
    }
    const media = this.selectedMedia

    if (this.isUploading(media.id)) {
      this.toastService.error('features.monitor.gallery.mediaUploading')
      return
    }

    this.isEditing = true
    this.editingMedia = { ...media }
    setTimeout(() => document.getElementById(this.editFieldId)?.focus())
  }

  public onFinishEdit() {
    if (!this.isEditing) {
      return
    }

    this.isEditing = false
    this.editingMedia = null
  }

  public onClose() {
    this.isEditing = false
    this.updatedMedias = ArrayUtils.distinctObjects(this.updatedMedias, 'id')
    this.dialogRef.close(this.updatedMedias)
  }

  public onScrollEnding() {
    if (this.records?.totalResults <= this.records?.data.length) {
      return
    }
    this.loadMore()
  }

  public deleteMedia(media: GalleryImage): void {
    if (!this.data.allowEdit) {
      return
    }
    const deletingSelected = this.selectedMedia.mediaKey === media.mediaKey
    const selectedItemIndex = this.records.data.findIndex((i) => i.mediaKey === media.mediaKey)
    this.data.onDelete(media.mediaKey)
    this.records.data = this.records.data?.filter((m) => m.mediaKey !== media.mediaKey)
    if (deletingSelected && this.records.data?.length > 1) {
      if (selectedItemIndex === 0) {
        this.gallery.scrollRight()
      } else {
        this.gallery.scrollLeft()
      }
    } else if (this.records.data?.length === 1) {
      this.gallery.selectImage(this.records.data[0])
    }
  }

  public onOrderChange(medias: GalleryImage[]): void {
    this.records.data = this.records.data
      .map((m: MediaGalleryItem) => {
        const item = medias.find((media) => media.mediaKey === m.mediaKey)
        if (item) {
          m.order = item.order
        }
        return m
      })
      .sort((a, b) => a.order - b.order)
    this.data.onOrderChange(this.records.data)
  }

  private getRecords(loadMore?: boolean) {
    this.loading = true
    this.dataAsyncSubscription = this.data
      .media(this.pageSize, this.records?.currentPage + 1 ?? 1)
      .subscribe((res: PaginatedResults<MediaGalleryItem>): void => {
        let data = this.records?.data ?? []
        const dataWithThumbnail = this.mapWithThumbnailUrl(res.data)
        if (loadMore) {
          data = [...data, ...dataWithThumbnail]
        } else {
          data = [...dataWithThumbnail]
        }
        this.records = {
          ...this.records,
          ...res,
          data: data,
        }
        this.loading = false
        if (!this.selectedGalleryImage) {
          this.selectedGalleryImage = this.records.data.find((d) => d.mediaKey === this.data.selectedMediaKey)
          if (!this.selectedGalleryImage) {
            this.loadMore()
          } else {
            setTimeout(() => this.gallery?.scrollCarrouselTo(this.data.selectedMediaKey))
          }
        }
      })
  }

  private mapWithThumbnailUrl(data: MediaGalleryItem[]) {
    const size = MediaThumbnailSize.THUMB_100x100
    return data.map((d) => ({ ...d, thumbnailUrl: d.thumbnailUrl ?? `${d.url}?size=${size}` }))
  }
}
