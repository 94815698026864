import { Component, Input, TemplateRef } from '@angular/core'

@Component({
  selector: 'svr-map-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
})
export class SidePanelComponent {
  @Input() template: TemplateRef<any> = null
}
