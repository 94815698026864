import { ArrayParse, BooleanParse, LabelValueDTO, NumberParse, StringCleanup, StringTrim } from '@uefa-shared/contracts'
import { Type } from 'class-transformer'
import {
  ArrayMinSize,
  IsArray,
  IsBoolean,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsUUID,
  Length,
  ValidateIf,
  ValidateNested,
} from 'class-validator'
import { SVRMediaDTO } from '../shared'

export enum FieldCondition {
  MANDATORY = 'MANDATORY',
  MANDATORY_IF_YES = 'MANDATORY_IF_YES',
  MANDATORY_IF_NO = 'MANDATORY_IF_NO',
  OPTIONAL = 'OPTIONAL',
}

export enum FieldAnswerType {
  NUMERIC = 'NUMERIC',
  YES_NO = 'YES_NO',
  PLAIN_TEXT = 'PLAIN_TEXT',
  SIGNATURE = 'SIGNATURE',
  LIST = 'LIST',
  TABLE = 'TABLE',
}

export enum FieldChildOperator {
  EQUALS = 'EQUALS',
}

export class FieldTableCellDTO {
  public id: string
  public value: string

  constructor() {
    this.id = Math.round(Math.random() * 1000 * 12345).toString()
    this.value = ''
  }
}

export class FieldTableConfigDTO {
  columns: FieldTableCellDTO[]
  rows: FieldTableCellDTO[][]
  adjustable?: boolean

  constructor() {
    this.columns = []
    this.rows = []
  }
}

export class FieldDTO {
  id: string
  name: string
  description?: string
  type: FieldAnswerType
  condition: FieldCondition
  order: number
  values?: LabelValueDTO[]
  multiple?: boolean
  table?: FieldTableConfigDTO
  templates?: SVRMediaDTO[]
  children?: FieldChildDTO[]
}

export class FieldChildDTO {
  id: string
  name: string
  description?: string
  type: FieldAnswerType
  condition: string
  order: number
  values?: LabelValueDTO[]
  multiple?: boolean
  table?: FieldTableConfigDTO
  templates?: SVRMediaDTO[]
  operator: FieldChildOperator
  parentId: string
  parentExpectedAnswer: string
}

export class FieldUpdateDTO {
  @IsOptional()
  @StringTrim()
  id?: string

  @IsNotEmpty()
  @Length(1)
  @StringTrim()
  @StringCleanup()
  name: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  description?: string

  @IsNotEmpty()
  @IsEnum(FieldAnswerType)
  type: FieldAnswerType

  @IsNotEmpty()
  @IsEnum(FieldCondition)
  condition: FieldCondition = FieldCondition.OPTIONAL

  @IsOptional()
  @IsNumber()
  @NumberParse()
  order?: number

  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ArrayMinSize(1)
  @ValidateIf(field => field.type === FieldAnswerType.LIST)
  values?: LabelValueDTO[]

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  multiple?: boolean

  @IsNotEmpty()
  @ValidateNested()
  @Type(() => FieldTableConfigDTO)
  @ValidateIf(field => field.type === FieldAnswerType.TABLE)
  table?: FieldTableConfigDTO

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @ValidateNested({ each: true })
  @Type(() => SVRMediaDTO)
  @ValidateIf(field => field.templates?.length > 0)
  templates?: SVRMediaDTO[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @ValidateNested({ each: true })
  @Type(() => FieldChildUpdateDTO)
  @ValidateIf(field => field.children?.length > 0)
  children?: FieldChildUpdateDTO[]

  constructor(model?: FieldDTO) {
    this.templates = []
    this.values = []
    if (model) {
      this.id = model.id
      this.description = model.description
      this.name = model.name
      this.type = model.type
      this.condition = model.condition
      this.order = model.order
      this.values = model.values
      this.multiple = model.multiple
      this.table = model.table
      this.templates = model.templates ?? []
      this.children = model.children?.map(c => new FieldChildUpdateDTO(c)) ?? []
    }
  }
}
export class FieldCreateDTO extends FieldUpdateDTO {}

export class FieldChildUpdateDTO extends FieldUpdateDTO {
  @IsOptional()
  @StringTrim()
  id?: string

  @IsUUID()
  @IsOptional()
  parentId: string

  @IsNotEmpty()
  @IsEnum(FieldChildOperator)
  operator: FieldChildOperator

  @IsNotEmpty()
  @Length(1)
  @StringTrim()
  @StringCleanup()
  parentExpectedAnswer: string

  @IsNotEmpty()
  @Length(1)
  @StringTrim()
  @StringCleanup()
  name: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  description?: string

  @IsNotEmpty()
  @IsEnum(FieldAnswerType)
  type: FieldAnswerType

  @IsNotEmpty()
  @IsEnum(FieldCondition)
  condition: FieldCondition = FieldCondition.OPTIONAL

  @IsOptional()
  @IsNumber()
  @NumberParse()
  order?: number

  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ArrayMinSize(1)
  @ValidateIf(field => field.type === FieldAnswerType.LIST)
  values?: LabelValueDTO[]

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  multiple?: boolean

  @IsNotEmpty()
  @ValidateNested()
  @Type(() => FieldTableConfigDTO)
  @ValidateIf(field => field.type === FieldAnswerType.TABLE)
  table?: FieldTableConfigDTO

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @ValidateNested({ each: true })
  @Type(() => SVRMediaDTO)
  @ValidateIf(field => field.templates?.length > 0)
  templates?: SVRMediaDTO[]

  constructor(model?: FieldChildDTO) {
    super(
      model
        ? {
            id: model?.id,
            description: model?.description,
            name: model?.name,
            type: model?.type,
            condition: FieldCondition[model?.condition],
            order: model?.order,
            values: model?.values,
            multiple: model?.multiple,
            table: model?.table,
            templates: model?.templates ?? [],
          }
        : null
    )

    this.templates = []
    this.values = []
    if (model) {
      this.id = model.id
      this.description = model.description
      this.name = model.name
      this.type = FieldAnswerType[model.type]
      this.condition = FieldCondition[model.condition]
      this.order = model.order
      this.values = model.values
      this.multiple = model.multiple
      this.table = model.table
      this.templates = model.templates ?? []

      this.parentId = model.parentId
      this.parentExpectedAnswer = model.parentExpectedAnswer
      this.operator = model.operator
    }
  }
}

export class QuestionFieldUtils {
  public static getFieldAnswerTypeLabel(answerType: FieldAnswerType): string {
    switch (answerType) {
      case FieldAnswerType.NUMERIC:
        return 'Numeric'
      case FieldAnswerType.YES_NO:
        return 'Yes / No'
      case FieldAnswerType.PLAIN_TEXT:
        return 'Plain Text'
      case FieldAnswerType.SIGNATURE:
        return 'Signature'
      case FieldAnswerType.LIST:
        return 'List'
      case FieldAnswerType.TABLE:
        return 'Table'
      default:
        ''
    }
  }

  public static getFieldConditionLabel(condition: FieldCondition): string {
    switch (condition) {
      case FieldCondition.MANDATORY:
        return 'Yes'
      case FieldCondition.MANDATORY_IF_YES:
        return 'If Yes'
      case FieldCondition.MANDATORY_IF_NO:
        return 'If No'
      case FieldCondition.OPTIONAL:
        return 'No'
      default:
        ''
    }
  }

  public static getFieldAnswerTypeIcon(value: FieldAnswerType): string {
    switch (value) {
      case FieldAnswerType.PLAIN_TEXT:
        return 'type'
      case FieldAnswerType.NUMERIC:
        return 'hash'
      case FieldAnswerType.SIGNATURE:
        return 'pen-tool'
      case FieldAnswerType.LIST:
        return 'list'
      case FieldAnswerType.YES_NO:
        return 'check-circle'
      case FieldAnswerType.TABLE:
        return 'grid'

      default:
        return 'help-circle'
    }
  }
}
