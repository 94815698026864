import { ArrayParse, BooleanParse, LabelValueDTO, PaginatedSearchDTO } from '@uefa-shared/contracts'
import { IsArray, IsBoolean, IsEnum, IsNotEmpty, IsOptional, ValidateIf } from 'class-validator'
import { KeyPolicy, QuestionDTO, QuestionStatus, QuestionTarget } from '../question/question.dto'
import { WorkingVisitStatus } from '../shared/enums'
import { ColorRanking } from '../shared/enums/ranking.enum'
import { SVRMediaDTO } from '../shared/media.dto'
import {
  WorkingVisitQuestionKey,
  WorkingVisitQuestionPermissions,
  WorkingVisitQuestionProject,
} from '../working-visit-question/working-visit-question.dto'

export class MonitorQuestionSearchDTO extends PaginatedSearchDTO {
  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  siteVisitIds: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  sectionIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  workingVisitIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  projectIds?: string[]

  @IsOptional()
  @ArrayParse()
  keyIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  facilityIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  levelIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @IsEnum(WorkingVisitStatus, { each: true })
  @ValidateIf(wv => !!wv.status?.length)
  status?: WorkingVisitStatus[]

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  isOwner?: boolean
}

export class MonitorQuestionListDTO {
  id: string
  title: string
  prefillQuestionId?: string
  hasChangesFromPrefill?: boolean
  totalActionPoints?: number
  activeActionPoints?: number
  sectionId: string
  sectionName: string
  status: QuestionStatus
  updatedBy?: string
  updatedAt?: Date
  completed?: boolean
  keyPolicy: KeyPolicy
  target: QuestionTarget

  ranking?: ColorRanking
  keys?: WorkingVisitQuestionKey[]
  projects?: WorkingVisitQuestionProject[]
  level?: string

  permissions?: WorkingVisitQuestionPermissions
  canManage?: boolean

  constructor(model?: QuestionDTO) {
    if (model) {
      this.id = model.id
      this.title = model.title
      // TODO: other props
    }
  }
}

export class MonitorQuestionFieldDTO {
  id: string
  description: string
  answer: string
}

export class MonitorQuestionDTO {
  id: string
  sectionId: string
  title: string
  guidelines: string
  status: QuestionStatus
  fields: MonitorQuestionFieldDTO[]
  images: SVRMediaDTO[]
  documents: SVRMediaDTO[]
  updatedBy?: string
  updatedAt?: Date
}

export class PlanningResultDTO {
  public siteVisit: LabelValueDTO
  public answeredQuestions: number
  public generationResult: number
}
