import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LabelValueDTO, PaginatedResults } from '@uefa-shared/contracts'
import { BaseService } from '@uefa-shared/frontend'
import { WorkingVisitOverviewDTO, WorkingVisitOverviewSearchDTO } from '@uefa-svr/contracts'
import { Observable } from 'rxjs'
import { environment } from '../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class WorkingVisitService extends BaseService {
  constructor(http: HttpClient) {
    super(environment, 'working-visit', http)
  }

  public getWorkingVisitOverviewPaginated(options: WorkingVisitOverviewSearchDTO): Observable<PaginatedResults<WorkingVisitOverviewDTO>> {
    return this.getPaginatedResult<WorkingVisitOverviewDTO>(`${this.basePath}/status-overview`, options)
  }

  public getWorkingVisitOverviewProjects(options: WorkingVisitOverviewSearchDTO): Observable<LabelValueDTO[]> {
    const queryParams = this.getQueryParams<WorkingVisitOverviewSearchDTO>(options)
    const url = `${this.basePath}/status-overview/projects`
    return this.http.get<LabelValueDTO[]>(`${url}?${queryParams.join('&')}`)
  }
}
