import { ArrayParse, BooleanParse, LabelValueDTO, StringCleanup, StringTrim } from '@uefa-shared/contracts'
import { Type } from 'class-transformer'
import { ArrayMinSize, IsArray, IsEnum, IsNotEmpty, IsOptional, IsUUID, ValidateIf, ValidateNested } from 'class-validator'
import { BaseConfigurationDTO } from '../entity-configurations'
import { BadRequest } from '../exceptions'
import { QuestionTarget } from '../question/question.dto'
import { ApprovalWorkflow, Prefill, SiteVisitType, WorkingVisitStatus } from '../shared/enums'
import { WorkingVisitCreateDTO, WorkingVisitDTO, WorkingVisitUpdateDTO } from '../working-visit'
import { SiteVisitNotificationDTO, SiteVisitNotificationUpdateDTO } from './site-visit-notification.dto'
import { SiteVisitUserDTO, SiteVisitUserUpdateDTO } from './site-visit-user.dto'

export class CustomOrder {
  id: string
  order: number
}

export class QuestionDetails extends CustomOrder {
  prefills?: Prefill[]
}

export class SiteVisitLabelValueDTO extends LabelValueDTO {
  constructor(public type: SiteVisitType, value?: string, label?: string) {
    super(value, label)
    this.type = type
  }
}

export class SiteVisitListDTO {
  id: string
  prefix: string
  title: string
  suffix: string
  type: SiteVisitType
  venueId: string
  venueName: string
  siteId: string
  siteName: string
  teamId: string
  teamName: string
  nextVisit: Date
  canEdit?: boolean
  editMessage?: string
  disabled?: boolean

  events?: LabelValueDTO[]
  competitions?: LabelValueDTO[]

  constructor(model?: SiteVisitDTO) {
    if (model) {
      this.id = model.id
      this.title = model.title
      this.type = model.type

      this.competitions = model.competitions

      this.events = model.events

      this.siteId = model.site.value
      this.siteName = model.site.label

      this.venueId = model.venue.value
      this.venueName = model.venue.label

      this.teamId = model.team?.value ?? null
      this.teamName = model.team?.label ?? null

      this.nextVisit = model.nextVisit
    }
  }
}

export class SiteVisitDTO {
  id: string
  prefix: string
  name: string
  title: string
  suffix: string
  disabled?: boolean
  eventCycle: LabelValueDTO
  defaultEvent: LabelValueDTO
  autoDefaultEvent: boolean
  competitions: LabelValueDTO[]
  prefillExcludedCompetitions: LabelValueDTO[]
  projects: LabelValueDTO[]
  events: LabelValueDTO[]
  printingTemplate: LabelValueDTO
  actionPointsDamageReports: boolean
  automaticallyPrefillAnswers: boolean
  type: SiteVisitType
  approvalWorkflow: ApprovalWorkflow
  venue?: LabelValueDTO
  site?: LabelValueDTO
  siteType?: LabelValueDTO
  team?: LabelValueDTO
  editors: SiteVisitEditor[]
  visits?: WorkingVisitDTO[]
  excludedSections?: string[]
  excludedQuestions?: string[]
  status: WorkingVisitStatus
  nextVisit: Date
  activeWorkingVisitId: string
  includeOverview: boolean
  allowEditMap: boolean
  sectionsOrder?: CustomOrder[]
  questionsOrder?: CustomOrder[]
  prefills?: QuestionTemplatePrefill[]
  users: SiteVisitUserDTO[]
  notifications: SiteVisitNotificationDTO[]
  notificationsEnabled?: boolean
  actionPointNotificationsEnabled?: boolean
}

export class SiteVisitEditor {
  @IsNotEmpty({ message: BadRequest.INVALID_EDITORS })
  @Type(() => LabelValueDTO)
  public project: LabelValueDTO

  @IsNotEmpty({ message: BadRequest.INVALID_EDITORS })
  @IsArray({ message: BadRequest.INVALID_EDITORS })
  @ArrayMinSize(1, { message: BadRequest.INVALID_EDITORS })
  public users: LabelValueDTO[]

  projectId: string
  userIds: string[]

  constructor(project?: LabelValueDTO, users?: LabelValueDTO[]) {
    if (project) {
      this.project = project
      this.projectId = project.value
    }

    if (users?.length) {
      this.users = users
      this.userIds = users.map(u => u.value)
    }
  }
}

export class QuestionTemplatePrefill {
  @IsNotEmpty()
  @IsUUID()
  questionId: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @IsEnum(Prefill, { each: true })
  prefills?: Prefill[]
}

export class SiteVisitUpdateDTO {
  @IsOptional()
  @StringTrim()
  @StringCleanup()
  prefix?: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  title?: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  name?: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  suffix?: string

  eventCycleId?: string
  competitionIds?: string[]
  eventIds?: string[]

  @IsNotEmpty()
  @IsUUID()
  venueId: string

  @IsNotEmpty()
  @IsUUID()
  siteId: string

  @IsOptional()
  @IsUUID()
  printingTemplateId?: string

  @IsOptional()
  @IsUUID()
  teamId?: string

  @IsOptional()
  @IsUUID()
  defaultEventId?: string

  @IsOptional()
  @BooleanParse()
  actionPointsDamageReports?: boolean

  @IsOptional()
  @BooleanParse()
  automaticallyPrefillAnswers?: boolean

  @IsOptional()
  @BooleanParse()
  autoDefaultEvent?: boolean

  @IsOptional()
  @BooleanParse()
  includeOverview?: boolean

  @IsOptional()
  @BooleanParse()
  allowEditMap?: boolean

  @IsOptional()
  @BooleanParse()
  disabled?: boolean

  @IsOptional()
  @BooleanParse()
  notificationsEnabled?: boolean

  @IsOptional()
  @BooleanParse()
  actionPointNotificationsEnabled?: boolean

  @IsNotEmpty()
  @IsEnum(SiteVisitType)
  type: SiteVisitType

  @IsOptional()
  @IsEnum(ApprovalWorkflow)
  approvalWorkflow?: ApprovalWorkflow

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => SiteVisitEditor)
  editors?: SiteVisitEditor[]

  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ArrayMinSize(1)
  @ValidateNested({ each: true })
  @Type(() => WorkingVisitUpdateDTO)
  visits: WorkingVisitUpdateDTO[]

  @IsOptional()
  @ArrayParse()
  sectionsOrder?: CustomOrder[]

  @IsOptional()
  @ArrayParse()
  questionsOrder?: CustomOrder[]

  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ValidateNested({ each: true })
  @Type(() => QuestionTemplatePrefill)
  @ValidateIf((visit: SiteVisitUpdateDTO) => visit.prefills?.length > 0)
  prefills?: QuestionTemplatePrefill[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @IsUUID('4', { each: true })
  prefillExcludedCompetitionIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @IsUUID('4', { each: true })
  projectIds?: string[]
  // little helper
  projects?: LabelValueDTO[]

  @IsOptional()
  @IsArray()
  @Type(() => SiteVisitUserUpdateDTO)
  @ValidateNested({ each: true })
  users?: SiteVisitUserUpdateDTO[]

  @IsOptional()
  @IsArray()
  @Type(() => SiteVisitNotificationUpdateDTO)
  @ValidateNested({ each: true })
  notifications?: SiteVisitNotificationUpdateDTO[]

  constructor(model?: SiteVisitDTO) {
    this.visits = []
    this.projectIds = []
    this.projects = []

    if (model) {
      this.prefix = model.prefix
      this.title = model.title
      this.name = model.name
      this.suffix = model.suffix
      this.printingTemplateId = model.printingTemplate?.value
      this.actionPointsDamageReports = model.actionPointsDamageReports
      this.automaticallyPrefillAnswers = model.automaticallyPrefillAnswers
      this.type = model.type
      this.approvalWorkflow = model.approvalWorkflow
      this.allowEditMap = model.allowEditMap
      this.venueId = model.venue?.value
      this.siteId = model.site?.value
      this.teamId = model.team?.value
      this.visits = model.visits.map(v => new WorkingVisitUpdateDTO(v, model.users))
      this.projectIds = model.projects?.map(p => p.value)
      this.projects = model.projects
    }

    this.disabled = model?.disabled ?? false
    this.includeOverview = model?.includeOverview ?? false
    this.eventCycleId = model?.eventCycle?.value
    this.competitionIds = model?.competitions?.map(c => c.value) ?? []
    this.eventIds = model?.events?.map(c => c.value) ?? []
    this.defaultEventId = model?.defaultEvent?.value
    this.autoDefaultEvent = model?.autoDefaultEvent ?? false
    this.sectionsOrder = model?.sectionsOrder?.map(o => ({ ...o })) ?? []
    this.questionsOrder = model?.questionsOrder?.map(o => ({ ...o })) ?? []
    this.prefills = model?.prefills?.map(p => ({ ...p })) ?? []
    this.prefillExcludedCompetitionIds = model?.prefillExcludedCompetitions?.map(p => p.value) ?? []
    this.users = model?.users?.map(u => new SiteVisitUserUpdateDTO(u)) ?? []
    this.editors = model?.editors ?? []
    this.notifications = model?.notifications?.map(n => new SiteVisitNotificationUpdateDTO(n)) ?? []
    this.notificationsEnabled = model?.notificationsEnabled ?? true
    this.actionPointNotificationsEnabled = model?.actionPointNotificationsEnabled ?? true
  }
}

export class SiteVisitCreateDTO extends SiteVisitUpdateDTO {
  @ValidateIf(m => !m.eventIds?.length)
  @IsNotEmpty()
  @IsUUID('4')
  eventCycleId: string

  @ValidateIf(m => !m.eventCycleId)
  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ArrayMinSize(1)
  @IsUUID('4', { each: true })
  eventIds: string[]

  @ValidateIf(m => !m.eventCycleId)
  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ArrayMinSize(1)
  @IsUUID('4', { each: true })
  competitionIds: string[]

  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ArrayMinSize(1)
  @ValidateNested({ each: true })
  @Type(() => WorkingVisitCreateDTO)
  visits: WorkingVisitCreateDTO[]
}

export class SiteVisitDuplicateDTO {
  @IsNotEmpty()
  @IsUUID()
  originalSiteVisitId: string

  @IsOptional()
  @ArrayParse()
  @IsArray()
  @IsUUID('all', { each: true })
  eventIds: string[]

  @IsOptional()
  @ArrayParse()
  @IsArray()
  @IsUUID('4', { each: true })
  siteIds?: string[]
}

export class SiteVisitConfigurationDTO extends BaseConfigurationDTO {
  displayName?: string
  secondaryDisplayName?: string
  icon?: string
  defaultQuestionTarget?: QuestionTarget
  defaultAllowActionPoint?: boolean
  defaultApprovalWorkflow?: ApprovalWorkflow
  defaultTrackPeople?: boolean
  defaultPrefillAnswers?: boolean
  includeOverview?: boolean
}
