<div class="media-list-wrapper" [ngClass]="{ 'with-media-gallery': listOptions.withMediaGallery }">
  <button
    *ngIf="listOptions.addButton"
    type="button"
    mat-icon-button
    class="media-list-add-button"
    matTooltip="{{ 'shared.buttons.add' | translate }}"
    color="primary"
    (click)="onAddMediaClick($event)"
    [disabled]="disabled"
  >
    <i-feather name="plus-circle" class="media-list-icon"></i-feather>
  </button>

  <input
    hidden
    type="file"
    [accept]="allowedMimeTypes"
    #mediaInput
    (change)="onMediasUpload(mediaInput.files)"
    multiple
    [disabled]="false"
  />

  <div class="media-list-container" *ngIf="!!medias.length">
    <div class="media-container" *ngFor="let media of medias" [ngClass]="{ 'only-thumbnail': !listOptions.withDetails }">
      <div
        *ngIf="!isUploading(media.id) && media.uploadSuccess"
        (click)="onMediaClick($event, media)"
        class="media-list-thumbnail"
        [class]="'rotate-' + media.rotation"
        [style.background-image]="'url(' + getThumbnail(media) + ')'"
        [ngClass]="{ document: isDocument(media) }"
      ></div>
      <div class="media-list-thumbnail loading" *ngIf="isUploading(media.id)">
        <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
      </div>
      <div
        class="media-list-thumbnail upload-error"
        *ngIf="!isUploading(media.id) && !media.uploadSuccess"
        [matTooltip]="'shared.message.uploadFailed' | translate"
      >
        <i-feather class="upload-error-image" name="alert-triangle"></i-feather>
      </div>
      <svr-media-list-actions
        class="media-list-actions"
        *ngIf="!listOptions.withDetails && !isUploading(media.id)"
        [deleteButton]="listOptions.deleteButton"
        [editButton]="listOptions.editButton"
        (deleteClick)="onDeleteMedia(media)"
        (editClick)="onEditMedia(media)"
        [disabled]="disabled"
      ></svr-media-list-actions>

      <div class="media-list-details" *ngIf="listOptions.withDetails">
        <div [matTooltip]="getMediaNameWithoutExtensions(media) | translate" class="text-truncate media-list-text">
          {{ getMediaNameWithoutExtensions(media) }}
        </div>
        <div
          *ngIf="media.organization"
          matTooltip="{{ 'shared.enums.organization.' + media.organization | translate }}"
          class="text-truncate media-list-text"
        >
          {{ 'shared.enums.organization.' + media.organization | translate }}
        </div>
        <div class="flex-grow"></div>
        <div class="media-list-footer">
          <div class="text-truncate media-list-text small">{{ media.uploadDate | date: 'yyyy-MM-dd - HH:mm' }}</div>
          <svr-media-list-actions
            class="media-list-actions"
            *ngIf="!isUploading(media.id)"
            [deleteButton]="listOptions.deleteButton"
            [editButton]="listOptions.editButton && media.uploadSuccess"
            (deleteClick)="onDeleteMedia(media)"
            (editClick)="onEditMedia(media)"
            [disabled]="disabled"
          ></svr-media-list-actions>
        </div>
      </div>
    </div>
  </div>
</div>
<button
  *ngIf="listOptions.withMediaGallery"
  type="button"
  mat-icon-button
  class="media-list-action-button"
  matTooltip="{{ 'shared.buttons.maximizeMedia' | translate }}"
  color="primary"
  (click)="onMediaExpand()"
  [disabled]="disabled || !medias?.length"
>
  <i-feather name="maximize-2" class="media-list-icon"></i-feather>
</button>
