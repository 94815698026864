import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BaseService } from '@uefa-shared/frontend'
import { ApplicationDTO, ApplicationSearchDTO } from '@uefa-vista/contracts'
import { Observable } from 'rxjs'
import { environment } from '../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class ApplicationsService extends BaseService {
  constructor(http: HttpClient) {
    super(environment, 'application', http)
  }

  public getAll(search?: ApplicationSearchDTO): Observable<ApplicationDTO[]> {
    return this.http.get<ApplicationDTO[]>(`${this.basePath}`, { params: { ...search } })
  }
}
