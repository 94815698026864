import { IsArray, IsOptional } from 'class-validator'
import { ArrayParse, BooleanParse } from '../decorators'
import { PaginatedSearchDTO } from '../shared/paginated-search.dto'

export class SubEventSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIntegrationIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: Array<string>

  @IsOptional()
  @BooleanParse()
  allEvents?: boolean = false
}
