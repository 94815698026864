import { Component, EventEmitter, Input, Output } from '@angular/core'
import { environment } from '../../../../environments/environment'
import { RichTextEditorManagerService } from '../../services'

@Component({
  selector: 'svr-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
})
export class RichTextEditorComponent {
  @Input() id: string
  @Input() answer: string
  @Input() disabled = false
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('disabled') set changeDisabled(disabled: boolean) {
    this.disabled = disabled

    if (this.disabled) {
      this.richTextConfig.showToolbar = false
    }

    this.richTextConfig.editable = !this.disabled
  }

  @Output() answerChange = new EventEmitter<string>()

  public richTextConfig = { ...environment.richTextConfig, showToolbar: false }

  public constructor(private readonly richTextEditorManagerService: RichTextEditorManagerService) {
    this.richTextEditorManagerService.onBlurChange().subscribe((id) => {
      this.onFocusBlurChange(id, false)
    })

    this.richTextEditorManagerService.onFocusChange().subscribe((id) => {
      this.onFocusBlurChange(id, true)
    })
  }

  public onFocusBlurChange(id: string, showToolbar: boolean) {
    if (this.disabled) {
      return
    }

    if (id && this.id === id) {
      this.richTextConfig = { ...this.richTextConfig, showToolbar }
    }
  }

  public onChange() {
    this.answerChange.emit(this.answer)
  }
}
