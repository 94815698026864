import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'svr-media-list-actions',
  templateUrl: './media-list-actions.component.html',
  styleUrls: ['./media-list-actions.component.scss'],
})
export class MediaListActionsComponent {
  @Input() deleteButton = true
  @Input() editButton = true
  @Input() disabled = false

  @Output() deleteClick = new EventEmitter<void>()
  @Output() editClick = new EventEmitter<void>()
}
