import { Type } from 'class-transformer'
import { ArrayMinSize, IsArray, IsBoolean, IsNotEmpty, IsOptional, IsUUID, ValidateIf, ValidateNested } from 'class-validator'
import { ArrayParse, BooleanParse, StringCleanup, StringTrim } from '../decorators'
import { EventDTO } from '../event/event.dto'
import { VistaDTO, VistaListDTO } from '../shared/vista.dto'

export class EventCycleDataCopyConfigurationDTO {
  @IsNotEmpty()
  @IsUUID('all')
  copyFromEventOrCycleId: string

  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ArrayMinSize(1)
  layerTypeIds: string[]

  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ArrayMinSize(1)
  @ValidateNested({ each: true })
  @Type(() => EventCycleDataCopyVenueConfigurationDTO)
  venues: EventCycleDataCopyVenueConfigurationDTO[]

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  mergeData?: boolean

  userId?: string
  copyDataStartDate?: Date
}

export class EventCycleDataCopyVenueConfigurationDTO {
  @IsNotEmpty()
  @IsUUID('all')
  venueId: string

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  includeOverviewLevel?: boolean

  @IsOptional()
  @IsArray()
  @ArrayParse()
  sites?: EventCycleDataCopySiteConfigurationDTO[]
}

export class EventCycleDataCopySiteConfigurationDTO {
  @IsNotEmpty()
  @IsUUID('all')
  siteId: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @IsUUID('all', { each: true })
  levelIds?: string[]
}

export class EventCycleDTO extends VistaDTO {
  name: string
  shortName: string
  manageLayers: boolean
  imageUrl: string
  events?: Array<EventDTO>
  hasLayersToCopy?: boolean
  layerCreationProcessRunning?: boolean
  allLevelsHaveDots: boolean
  allLevelsHaveKeys: boolean
  allLevelsHaveLabels: boolean
  allLevelsHaveFlows: boolean
  allLevelsHaveZones: boolean

  constructor() {
    super()
    this.shortName = null
    this.events = null
    this.imageUrl = null
  }
}

export class EventCycleListDTO extends VistaListDTO {
  name: string
  shortName: string
  imageUrl: string
  numberOfEvents: number
  hasLayersToCopy?: boolean

  constructor() {
    super()
    this.shortName = null
    this.imageUrl = null
    this.numberOfEvents = 0
  }
}

export class EventCycleUpdateDTO {
  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  name: string

  @IsBoolean()
  @BooleanParse()
  @IsOptional()
  manageLayers: boolean

  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  shortName: string

  @IsArray()
  @IsNotEmpty()
  events: Array<EventDTO>

  @IsOptional()
  @ValidateIf(e => !!e?.dataCopyConfiguration)
  @Type(() => EventCycleDataCopyConfigurationDTO)
  @ValidateNested()
  dataCopyConfiguration: EventCycleDataCopyConfigurationDTO

  @IsBoolean()
  allLevelsHaveDots: boolean

  @IsBoolean()
  allLevelsHaveKeys: boolean

  @IsBoolean()
  allLevelsHaveLabels: boolean

  @IsBoolean()
  allLevelsHaveFlows: boolean

  @IsBoolean()
  allLevelsHaveZones: boolean

  constructor(model?: EventCycleDTO) {
    this.name = model?.name
    this.manageLayers = model?.manageLayers ?? true
    this.shortName = model?.shortName
    this.events = model?.events ?? []
    this.allLevelsHaveDots = model?.allLevelsHaveDots ?? false
    this.allLevelsHaveKeys = model?.allLevelsHaveKeys ?? false
    this.allLevelsHaveLabels = model?.allLevelsHaveLabels ?? false
    this.allLevelsHaveFlows = model?.allLevelsHaveFlows ?? false
    this.allLevelsHaveZones = model?.allLevelsHaveZones ?? false
  }
}

export class EventCycleCreateDTO extends EventCycleUpdateDTO {}
