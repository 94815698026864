import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BaseService, UserService } from '@uefa-shared/frontend'
import { FacilityCreateDTO, FacilityUpdateDTO, MediaPatchDTO } from '@uefa-svr/contracts'
import { KeyListDTO, LatLng } from '@uefa-vista/contracts'
import { Observable } from 'rxjs'
import { environment } from '../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class FacilityService extends BaseService {
  constructor(http: HttpClient, private readonly userService: UserService) {
    super(environment, `map/${environment.mapApplicationId}`, http)
  }

  public create(eventId: string, layerName: string, model: FacilityCreateDTO): Observable<{ id: string }> {
    const facilityData: FacilityData = {
      key: {
        id: model.key,
      },
      notes: model.notes,
      createdBy: this.userService.userInfo.name,
      geometry: model.polygon,
    }

    return this.http.post<{ id: string }>(`${this.basePath}/event/${eventId}/layer/${layerName}/facility`, facilityData)
  }

  public update(id: string, eventId: string, model: FacilityUpdateDTO): Observable<void> {
    const facilityData: FacilityData = {
      key: {
        id: model.key,
      },
      notes: model.notes,
      updatedBy: this.userService.userInfo.name,
      geometry: model.polygon,
    }

    return this.http.put<void>(`${this.basePath}/event/${eventId}/facility/${id}`, facilityData)
  }

  public delete(id: string, eventId: string): Observable<void> {
    return this.http.delete<void>(`${this.basePath}/event/${eventId}/facility/${id}`)
  }

  public uploadMedia(facilityId: string, file: File): Observable<void> {
    const uploadData = new FormData()
    uploadData.append('file', file, file.name)
    return this.http.post<void>(`${this.basePath}/facility/${facilityId}/media`, uploadData)
  }

  public deleteMedia(facilityId: string, fileId: string) {
    return this.http.delete(`${this.basePath}/facility/${facilityId}/media/${fileId}`)
  }

  public patchMedia(facilityId: string, fileId: string, model: MediaPatchDTO) {
    return this.http.put<{ fileName: string }>(`${this.basePath}/facility/${facilityId}/media/${fileId}`, model)
  }
}
export class FacilityData {
  key: KeyListDTO
  geometry: LatLng[]
  notes?: string
  createdBy?: string
  updatedBy?: string
}
