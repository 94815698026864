import { Injectable } from '@angular/core'
import { DialogService } from '@uefa-shared/frontend'
import { Observable, of, Subject } from 'rxjs'

export enum EditEntity {
  FACILITY = 'FACILITY',
  ACTION_POINT = 'ACTION_POINT',
  WORKING_VISIT_QUESTION = 'WORKING_VISIT_QUESTION',
}

@Injectable({ providedIn: 'root' })
export class EditStateService {
  private readonly stateSubject = new Subject<Set<EditEntity>>()
  public readonly $state = this.stateSubject.asObservable()
  private readonly stateSet: Set<EditEntity> = new Set()

  constructor(private readonly dialogService: DialogService) {}

  public edit(...entities: EditEntity[]) {
    entities.forEach((e) => this.stateSet.add(e))
    this.stateSubject.next(this.stateSet)
  }

  public discard(...entities: EditEntity[]) {
    entities.forEach((e) => this.stateSet.delete(e))
    this.stateSubject.next(this.stateSet)
  }

  public isEdit(...entities: EditEntity[]): boolean {
    if (!this.stateSet.size) {
      return false
    }
    if (!entities.length) {
      return true
    }
    return entities.some((e) => this.stateSet.has(e))
  }

  public editGuard(...entities: EditEntity[]): Observable<boolean> {
    if (!this.isEdit(...entities)) {
      return of(true)
    }
    return new Observable<boolean>((src) => {
      this.dialogService.confirm(
        { action: 'discard' },
        () => {
          entities.length ? this.discard(...entities) : this.clear()
          src.next(true)
          src.complete()
        },
        () => {
          src.next(false)
          src.complete()
        }
      )
    })
  }

  private clear() {
    this.stateSet.clear()
    this.stateSubject.next(this.stateSet)
  }
}
