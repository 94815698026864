import { BooleanParse, LabelValueDTO, PaginatedSearchDTO, SortDirection } from '@uefa-shared/contracts'
import { ArrayParse } from '@uefa-vista/contracts'
import { IsArray, IsBoolean, IsEnum, IsNotEmpty, IsOptional, IsString, IsUUID } from 'class-validator'
import { SVRVistaListDTO } from './vista.dto'

export class SVRSiteListDTO extends SVRVistaListDTO {
  // eslint-disable @typescript-eslint/camelcase
  imageUrl_200x200?: string
  marker?: string
  imageUrl?: string
  latitude?: number
  longitude?: number
  venueId?: string
}

export class SVRSiteSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  venueIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @IsString()
  role?: string

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  withoutSiteVisits?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  siteVisitsInfo?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  allEvents?: boolean
}

export class SVRSiteLevelSearchDTO extends PaginatedSearchDTO {
  @IsNotEmpty()
  @IsString()
  @IsUUID()
  siteId?: string

  @IsOptional()
  @IsEnum(SortDirection)
  sortDirection?: SortDirection = SortDirection.DESC
}

export class SiteLabelValueDTO extends LabelValueDTO {
  numberOfSiteVisits?: number
  siteVisitsTitles?: string

  constructor(value?: string, label?: string, numberOfSiteVisits?: number, siteVisitsTitles?: string) {
    super(value, label)
    this.numberOfSiteVisits = numberOfSiteVisits
    this.siteVisitsTitles = siteVisitsTitles
  }
}
