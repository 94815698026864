import { CdkTextareaAutosize } from '@angular/cdk/text-field'
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { LabelValueDTO, PaginatedResults } from '@uefa-shared/contracts'
import { DialogService, MediaField, SelectOption, SelectSearchComponent, ToastService } from '@uefa-shared/frontend'
import {
  FacilityCreateDTO,
  FacilityDTO,
  FacilityUpdateDTO,
  LatLng,
  MediaGalleryItem,
  MediaType,
  SVRKeyListDTO,
  SVRMediaDTO,
  SVRProjectSearchDTO,
  WorkingVisitQuestionDTO,
  mimeTypes,
} from '@uefa-svr/contracts'
import { LevelLayerDTO, SiteDTO, SiteLevelDTO } from '@uefa-vista/contracts'
import {
  VistaMapLayer,
  VistaMapLayerType,
  VistaMapMarker,
  VistaMapSite,
  VistaMapSiteLevel,
  VistaMapStandaloneComponent,
  VistaMapStandaloneComponentOptions,
} from '@uefa-vista/map'
import { Observable, Subject, Subscription, of } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators'
import { MonitorShellComponent } from '../../../features/monitor/components/monitor-shell/monitor-shell.component'
import { vistaMapConfiguration } from '../../../map-configuration'
import {
  DictionaryService,
  EditEntity,
  EditStateService,
  FacilityService,
  MapContext,
  MapService,
  MediaQueueService,
  MediaToDelete,
  MediaToUpload,
  MonitorShellService,
  VistaService,
} from '../../services'
import { FacilitySelect, MediaDTO, VistaBaseMap } from './copy-models.models'

export interface SVRMapOptions extends VistaMapStandaloneComponentOptions {
  showAddFacilityButton?: boolean
  showLayersButton?: boolean
  showFullScreenButton?: boolean
  allowIdentifyEdit?: boolean
}

const defaultLayerConfig = {
  labels: false,
  panoramas: false,
  keys: true,
  dots: false,
  flows: false,
  zones: false,
  blueprints: true,
}

export class SVRMapMarker extends VistaMapMarker {
  onChange?: (marker: VistaMapMarker) => void
}

@Component({
  selector: 'svr-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class SVRMapComponent implements OnChanges, OnDestroy {
  @ViewChild('identifyTemplate') identifyTemplate: TemplateRef<unknown>
  @ViewChild('layersTemplate') layersTemplate: TemplateRef<unknown>
  @ViewChild('mapComponent') mapComponent: VistaMapStandaloneComponent
  @ViewChild('autosize') autosize: CdkTextareaAutosize
  @ViewChild('keyDropdown') keyDropdown: SelectSearchComponent<string>
  @ViewChild('f') ngForm: NgForm
  @ViewChild('f', { static: false }) set form(form: NgForm) {
    this.setForm(form)
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('mapOptions') set changeOptions(newOptions: SVRMapOptions) {
    this.mapOptions = {
      ...this.mapOptions,
      ...newOptions,
    }
  }
  @Input() fullscreen = false
  @Input() highlightedLevelIds: string[]
  @Input() allowEditMap = false
  @Input() isOnlyReader = false

  @Output() fullscreenChange = new EventEmitter<boolean>()
  @Output() levelChange = new EventEmitter<string>()
  @Output() overviewChange = new EventEmitter<boolean>()

  public mapLayerTypes: VistaMapLayerType[] = []
  public overviewLayers: VistaMapLayer[] = []
  public mapOptions: SVRMapOptions = this.mapService.getMapOptions()

  public originalSite: SiteDTO
  public site: VistaMapSite
  public siteId: string
  public actionPointId: string
  public venueId: string
  public levelId: string
  public previousApModeLevelId: string
  public previousOverviewToggledLevelId: string
  public overviewToggled = false
  public facilityId: string
  public previousOverviewToggled = false
  public question: WorkingVisitQuestionDTO = null
  public keyIds: string[] = []
  public markers: SVRMapMarker[] = []

  public panelActive = false
  public selectedFacility: FacilitySelect
  public panelTemplate: TemplateRef<unknown> = null
  public resumeIdentify = false
  public layerConfig = defaultLayerConfig
  public map: google.maps.Map<Element> = null

  public shell: MonitorShellComponent

  public actionsLocked = false
  public editMode = false
  public facilityModel: FacilityUpdateDTO
  public documents: MediaField[] = []
  public medias: MediaField[] = []
  public documentsMimeTypes = mimeTypes.DOCUMENTS
  public imagesMimeTypes = mimeTypes.IMAGES

  public facilityModelProjects: string

  private _eventId: string

  private facilityPolygon: google.maps.Polygon
  private facilityPolyline: google.maps.Polyline
  private mapClickEventListener: google.maps.MapsEventListener

  private mediaQueueSubscription: Subscription
  private currentMediaQueue: MediaToUpload[] = []
  private mediaChanged = false

  private changesSubscription: Subscription
  private editCloseSubscription: Subscription

  private ignoreFacilityChange = false

  private $ngUnsubscribe = new Subject<void>()

  private changeLayerTypesSubject = new Subject()
  private $facilitySelect = new Subject<FacilitySelect>()

  private highlightedFacility: FacilitySelect = null

  private maxProjectChips = 4
  private eventSiteHasKeys = false

  public get eventId() {
    return this.shell?.siteVisit?.cycleManageLayers ? this.shell?.siteVisit?.eventCycleId : this._eventId
  }

  public set eventId(value: string) {
    this._eventId = value
  }

  constructor(
    private readonly toastService: ToastService,
    private readonly monitorShellService: MonitorShellService,
    private readonly mapService: MapService,
    private readonly mediaQueue: MediaQueueService,
    private readonly dictionaryService: DictionaryService,
    private readonly vistaService: VistaService,
    private readonly editStateService: EditStateService,
    private readonly dialogService: DialogService,
    private readonly dialog: MatDialog,
    private readonly facilityService: FacilityService
  ) {
    this.mapService.mapComponent = this
    this.shell = monitorShellService.shellComponent
    this.setupListeners()
  }

  public get overflowFacilityProjects(): string {
    const projects = this.selectedFacility?.facility?.projects
    return (
      projects
        ?.slice(this.maxProjectChips, projects.length)
        ?.map((p) => p.name)
        .join(', ') ?? ''
    )
  }

  public get selectedFacilityProjects(): string[] {
    return this.selectedFacility?.facility?.projects?.slice(0, this.maxProjectChips)?.map((p) => p.name) ?? []
  }

  public get numberOfOverflowProjects() {
    const projects = this.selectedFacility?.facility?.projects ? [...this.selectedFacility?.facility?.projects] : []
    projects.splice(0, this.maxProjectChips)
    return projects.length
  }

  public get facilityArea(): string {
    return this.facilityModel?.area ? this.facilityModel.area.toFixed(2) : '0'
  }

  public get facilityPerimeter(): string {
    return this.facilityModel?.perimeter ? this.facilityModel?.perimeter.toFixed(2) : '0'
  }

  public get layersActive(): boolean {
    return this.panelTemplate === this.layersTemplate
  }

  public get altered(): boolean {
    return this.editStateService.isEdit(EditEntity.FACILITY)
  }

  public get imageMediaType(): MediaType {
    return MediaType.IMAGE
  }

  public get documentMediaType(): MediaType {
    return MediaType.DOCUMENT
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!changes?.fullscreen) {
      return
    }
    this.fullscreen = changes.fullscreen.currentValue
  }

  public ngOnDestroy() {
    this.$ngUnsubscribe.next()
    this.$ngUnsubscribe.complete()
    this.mediaQueueSubscription?.unsubscribe()
  }

  public onMapCreated(map: google.maps.Map<Element>) {
    this.map = map
    this.changeLayerTypes([VistaMapLayerType.BLUEPRINT, VistaMapLayerType.KEYS])
    this.eventId = this.mapService.currentEventId
    this.siteId = this.mapService.currentSiteId
    this.facilityId = this.mapService.currentFacilityId
    this.getSite()

    this.mapService.mapContextChange.pipe(takeUntil(this.$ngUnsubscribe)).subscribe((context) => {
      const eventChanged = this.eventId !== context.eventId
      const siteChanged = this.siteId !== context.siteId
      const questionChanged = this.question?.id !== context.question?.id
      const actionPointChanged = this.actionPointId !== context.actionPointId
      const levelChanged = this.levelId !== context.levelId
      const overviewChanged = this.overviewToggled !== context.overviewToggled
      const facilityIdChanged = this.facilityId !== context.facilityId

      if (eventChanged) {
        this.eventId = context.eventId ?? this.eventId
        this.keyDropdown?.reloadDataAsync()
      }

      if (siteChanged) {
        this.siteId = context.siteId
      }

      if (siteChanged || eventChanged) {
        this.getSite()
      }

      if (questionChanged) {
        this.question = context.question
      }

      if (facilityIdChanged) {
        this.facilityId = context.facilityId
      }

      if (this.question?.facilityId && questionChanged) {
        this.keyIds = []

        if (this.eventId) {
          this.vistaService.getFacility(this.question.facilityId, this.siteId, this.eventId).subscribe((facilities) => {
            if (facilities.length) {
              this.highlightedFacility = new FacilitySelect(
                this.siteId,
                facilities[0].levelId,
                facilities[0].levelName,
                facilities[0].facilities[0],
                facilities[0].isOverview,
                facilities[0].layerType,
                facilities[0].layerName
              )
              this.mapComponent.onSelectFeature(this.highlightedFacility)
              this.setMapIdentify(false)
            }
          })
        }
      } else if (this.question?.keys && questionChanged) {
        this.mapComponent.onSelectFeature(null)
        this.highlightedFacility = null
        this.keyIds = this.question?.keys?.map((k) => k.value) ?? []
        this.setMapIdentify(false)
      } else if (questionChanged) {
        this.highlightedFacility = null
        this.keyIds = []
        this.identifySelectedFacility()
      } else if (facilityIdChanged && !this.question?.keys && !this.question?.facilityId) {
        this.identifySelectedFacility()
      }

      if (actionPointChanged) {
        this.changeMapToActionPointMode(context)
      }

      if (levelChanged) {
        this.mapComponent.onLevelChange(context.levelId)
      }

      if (overviewChanged) {
        if (this.mapComponent.overviewToggled !== context.overviewToggled) this.mapComponent.onOverviewToggle()
      }

      this.updateMapOptions()
    })

    this.changeBaseMap(vistaMapConfiguration.baseMaps[0])
    this.changeLayerTypesSubject
      .pipe(takeUntil(this.$ngUnsubscribe))
      .pipe(debounceTime(500))
      .subscribe(() => this.updateLayerTypes())
  }

  public shouldShowAddFacilityButton(): boolean {
    const isKeyLayerActive = this.mapLayerTypes.some((t) => t === VistaMapLayerType.KEYS)
    if (!isKeyLayerActive || !this.eventSiteHasKeys || !this.site) {
      return false
    }

    if (this.overviewToggled) {
      return this.mapOptions.showAddFacilityButton && this.hasKeyLayer(this.overviewLayers)
    }

    const levelLayers = this.site.levels.find((l) => l.id === this.levelId)?.layers ?? []
    return this.mapOptions.showAddFacilityButton && this.hasKeyLayer(levelLayers)
  }

  public onCenterChange(center: LatLng) {
    this.mapService.updateMapContext({ center })
  }

  public onBaseMapChange(baseMapIndex: number): void {
    this.changeBaseMap(vistaMapConfiguration.baseMaps[baseMapIndex])
  }

  public onFacilityModelKeyChange(key: string) {
    if (this.facilityModel) {
      this.facilityModel.key = key
    }
    this.setFacilityProjects(key)
  }

  public isCurrentBasemap(baseMapIndex: number) {
    return this.mapComponent.baseMap === vistaMapConfiguration.baseMaps[baseMapIndex]
  }

  public onLayerConfigChange() {
    this.changeLayerTypesSubject.next()
  }

  public async onAddFacilityClick() {
    if (!(await this.editStateService.editGuard(EditEntity.FACILITY).toPromise())) {
      return
    }
    this.setFacility(null)
    this.editFacility()
    this.setIdentifyPanel()
    this.mapComponent.map.setOptions({ draggableCursor: 'crosshair' })
  }

  public onLayersClick() {
    if (this.panelTemplate === this.identifyTemplate) {
      this.resumeIdentify = true
    }
    this.panelActive = !this.layersActive
    this.panelTemplate = !this.layersActive ? this.layersTemplate : null
    if (!this.layersActive && this.resumeIdentify) {
      this.setIdentifyPanel()
      if (!this.editMode) {
        this.mapComponent.onSelectFeature(this.selectedFacility)
      }
    }
  }

  public async onFullscreenClick() {
    const edits = Object.keys(EditEntity)
      .filter((k) => k !== 'FACILITY')
      .map((k) => EditEntity[k])
    if (!(await this.editStateService.editGuard(...edits).toPromise())) {
      return
    }
    this.fullscreen = !this.fullscreen
    this.fullscreenChange.emit(this.fullscreen)
  }

  public onFacilityClick(facilitySelect: FacilitySelect) {
    if (this.question) {
      if (this.question.requireLocation) {
        this.mapService.setQuestionFacility(facilitySelect)
      } else if (this.highlightedFacility && facilitySelect?.facility.id !== this.highlightedFacility.facility.id) {
        setTimeout(() => this.mapComponent.onSelectFeature(this.highlightedFacility))
      }
      return
    }

    if (this.editMode) {
      return
    }

    if (this.ignoreFacilityChange && !facilitySelect && this.selectedFacility) {
      setTimeout(() => this.mapComponent.onSelectFeature(this.selectedFacility))
      return
    }
    if (facilitySelect && !facilitySelect.facility.key?.id) {
      setTimeout(() => this.mapComponent.onSelectFeature(null))
      return
    }
    if (this.ignoreFacilityChange) {
      this.ignoreFacilityChange = false
      return
    }
    this.$facilitySelect.next(facilitySelect)
  }

  public onSubmit() {
    for (const ctrl in this.ngForm.controls) {
      this.ngForm.controls[ctrl].markAsTouched()
      this.ngForm.controls[ctrl].updateValueAndValidity()
    }
  }

  public editFacility() {
    this.editMode = true
    this.mapComponent.onSelectFeature(null)
    this.editCloseSubscription = this.editStateService.$state
      .pipe(
        takeUntil(this.$ngUnsubscribe),
        map((set) => set.has(EditEntity.FACILITY)),
        distinctUntilChanged(),
        filter((has) => !has)
      )
      .subscribe(() => setTimeout(() => this.closeIdentify()))
    this.setupMapEditor()
  }

  public onMediasChanged() {
    this.mediaChanged = true
    this.editStateService.edit(EditEntity.FACILITY)
  }

  public saveFacility() {
    this.ngForm.onSubmit(undefined)
    if (!this.ngForm.valid) {
      this.toastService.error('shared.message.invalidFormMessage', 'shared.message.invalidFormTitle')
      return
    }
    if (!this.facilityPolygon) {
      this.toastService.error('shared.message.invalidFormMessage', 'features.monitor.map.invalidPolygon')
      return
    }
    this.actionsLocked = true

    this.facilityModel.polygon = this.facilityPolygon
      .getPath()
      .getArray()
      .map((c) => c.toJSON())

    if (this.selectedFacility) {
      this.facilityService.update(this.selectedFacility.facility.id, this.eventId, this.facilityModel).subscribe(
        () => {
          this.toastService.success('features.monitor.map.editFacilitySuccess')

          if (this.mediaChanged) {
            this.uploadMedias(this.selectedFacility.facility.id)
          }

          this.setFacility(null)
          this.highlightedFacility = null
          this.mapComponent.randomize()
        },
        () => (this.actionsLocked = false),
        () => (this.actionsLocked = false)
      )
    } else {
      this.facilityService
        .create(
          this.eventId,
          this.mapComponent.getVisibleLayers().filter((l) => l.type === VistaMapLayerType.KEYS)[0].name,
          this.facilityModel
        )
        .subscribe(
          ({ id }) => {
            this.toastService.success('features.monitor.map.createFacilitySuccess')

            if (this.mediaChanged) {
              this.uploadMedias(id)
            }

            this.setFacility(null)
            this.highlightedFacility = null
            this.mapComponent.randomize()
          },
          () => (this.actionsLocked = false),
          () => (this.actionsLocked = false)
        )
    }
  }

  private uploadMedias(facilityId: string) {
    facilityId = facilityId.split('.').pop()
    const newMedias = [...this.medias, ...this.documents].filter((m) => m.isNewImage)
    const mediasToUpload: MediaToUpload[] = []
    const mediasToDelete: MediaToDelete[] = []

    for (const newMedia of newMedias) {
      const file = newMedia.file
      mediasToUpload.push({ facilityId, file })
    }

    const oldMedias = [...this.facilityModel.documents, ...this.facilityModel.medias]
    const existingMedias = [...this.documents, ...this.medias].filter((m) => !m.isNewImage)

    for (const oldMedia of oldMedias) {
      const stillExists = existingMedias.find((m) => m.id === oldMedia.id)
      if (stillExists) {
        continue
      }
      const mediaUrlParams = oldMedia.url.split('/')
      const mediaId = mediaUrlParams[mediaUrlParams.length - 2]
      mediasToDelete.push({ entityId: facilityId, mediaId })
    }

    this.mediaQueue.addToQueue(mediasToUpload)
    this.mediaQueue.addToDeleteQueue(mediasToDelete)
  }

  public changeMediaName = (media: MediaGalleryItem) => {
    if (!this.selectedFacility?.facility) {
      return
    }
    const facilityId = this.selectedFacility.facility.id.split('.').pop()
    const mediaUrlParams = media.id.split('/')
    const mediaId = mediaUrlParams[mediaUrlParams.length - 2]
    this.facilityService.patchMedia(facilityId, mediaId, { name: media.name }).subscribe((res) => {
      const facilityMedias = [...this.documents, ...this.medias]
      const facilityMedia = facilityMedias.find((m) => m.id === media.id)

      if (facilityMedia) {
        const urlParts = res.fileName.split('_')
        const fileName = urlParts[urlParts.length - 1]
        facilityMedia.name = fileName
        facilityMedia.fileName = fileName
      }
    })
  }

  public deleteFacility() {
    this.dialogService.confirm({ action: 'confirm' }, () => {
      this.facilityService.delete(this.selectedFacility.facility.id, this.eventId).subscribe(() => {
        this.toastService.success('features.monitor.map.deleteFacilitySuccess')
        this.setFacility(null)
        this.highlightedFacility = null
        this.mapComponent.randomize()
      })
    })
  }

  public async closeIdentify() {
    if (!(await this.editStateService.editGuard(EditEntity.FACILITY).toPromise())) {
      return
    }
    this.reset()
    this.panelTemplate = null
    this.panelActive = null
    this.mapComponent.onSelectFeature(null)
  }

  public closeLayers() {
    if (this.resumeIdentify) {
      this.setIdentifyPanel()
      if (!this.editMode) {
        this.mapComponent.onSelectFeature(this.selectedFacility)
      }
    } else {
      this.panelTemplate = null
      this.panelActive = null
    }
  }

  public closePanels() {
    this.closeLayers()
    this.closeIdentify()
  }

  public setFacilityProjects(key: string) {
    if (!key) {
      this.facilityModelProjects = null
      return
    }
    const options: SVRProjectSearchDTO = {
      currentPage: 1,
      pageSize: 100,
      keyIds: [key],
    }
    this.dictionaryService.getProjectsPaginated(options).subscribe((result) => {
      this.facilityModelProjects = result.data?.map((p) => p.label).join(', ')
    })
  }

  public keyOptionsAsync = (searchTerm?: string): Observable<PaginatedResults<SelectOption<string>>> => {
    if (!this.shell?.siteVisit) {
      return of(new PaginatedResults())
    }

    const eventId = this._eventId || this.shell.siteVisit.eventCycleId
    return this.vistaService.getVistaKeysByEventAndSite(eventId, this.siteId).pipe(
      map((result) => {
        // NO PAGINATED RESULTS IN VISTA :'(
        return {
          currentPage: 1,
          pageSize: result.length,
          totalPages: 1,
          totalResults: result.length,
          data: result
            .map((k: SVRKeyListDTO) => new LabelValueDTO(k.id, `${k.name} - ${k.label}`))
            // NO SEARCH FUNCTIONALITY IN VISTA :'(
            .filter((f) => f.label.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || !searchTerm),
        }
      })
    )
  }

  public onMarkerChange(marker: VistaMapMarker) {
    const svrMarker = this.markers.find((m) => m.id === marker.id)
    svrMarker.onChange(marker)
  }

  public onOverviewToggle(toggled: boolean) {
    this.overviewToggled = toggled
    this.mapService.updateMapContext({ overviewToggled: toggled })
    this.overviewChange.emit(toggled)

    if (!this.actionPointId) {
      // Not in "Action Point" mode, so the map should behave normally
      return
    }

    if (toggled) {
      this.previousOverviewToggledLevelId = this.levelId
      this.hideLevelLayers()
    } else if (!this.levelId) {
      this.mapComponent.onLevelChange(this.previousOverviewToggledLevelId)
    }
  }

  public onLevelChange(levelId: string) {
    this.levelId = levelId
    this.mapService.updateMapContext({ levelId })
    this.levelChange.emit(levelId)

    if (this.overviewToggled && !!levelId && this.actionPointId) {
      // In "Action Point" mode, so the map should not have the Level and OV turned on
      this.mapComponent.onOverviewToggle()
    }

    if (!this.highlightedFacility) {
      return
    }

    if (this.highlightedFacility?.levelId === levelId) {
      this.mapComponent.onSelectFeature(this.highlightedFacility)
    }
  }

  private setMapIdentify(identifyEnable: boolean) {
    let identifyLayersFilter = null
    if (!identifyEnable) {
      identifyLayersFilter = () => false
    }
    this.mapOptions = {
      ...this.mapOptions,
      identifyLayersFilter,
    }
  }

  private loadFacility() {
    this.reset()
    if (!this.selectedFacility) {
      this.facilityModel = new FacilityCreateDTO()
      this.facilityModelProjects = null
      if (this.question?.keys?.length === 1) {
        const keyFilter = {
          ids: [this.question?.keys[0].value],
          eventIds: [this.eventId],
          siteIds: [this.siteId],
          parentId: this.question?.keys[0].parentId,
        }

        this.vistaService.getVistaKeysPaginated(keyFilter).subscribe((result) => {
          if (result.totalResults) {
            this.facilityModel.key = this.question.keys[0].value
          }
        })
      }
      return
    }
    const facility = this.selectedFacility.facility
    this.facilityModel = new FacilityUpdateDTO(facility as FacilityDTO)
    this.facilityModel.documents = this.mapMediaDTOToSVRMediaDTO(facility.documents)
    this.facilityModel.medias = this.mapMediaDTOToSVRMediaDTO(facility.medias)
    this.facilityModelProjects = facility.projects.map((p) => p.name).join(', ')
    this.loadImages()
    this.loadDocuments()
  }

  private loadImages() {
    this.medias =
      this.facilityModel?.medias.map(
        (i) =>
          ({
            id: i.id,
            url: i.url,
            name: i.name,
            fileName: i.name,
            uploadDate: i.uploadDate,
            uploadBy: i.uploadedBy,
            mimeType: i.mimeType,
            uploadSuccess: i.uploadSuccess ?? true,
            isLoading: !!this.currentMediaQueue.find((m) => m.id === i.id),
            isNewImage: false,
          } as MediaField)
      ) || []
  }

  private loadDocuments() {
    this.documents =
      this.facilityModel?.documents.map(
        (d) =>
          ({
            id: d.id,
            url: d.url,
            downloadUrl: d.url,
            name: d.name,
            fileName: d.name,
            uploadDate: d.uploadDate,
            uploadBy: d.uploadedBy,
            mimeType: d.mimeType,
            uploadSuccess: d.uploadSuccess ?? true,
            isLoading: !!this.currentMediaQueue.find((m) => m.id === d.id),
            isNewImage: false,
          } as MediaField)
      ) || []
  }

  private getSite() {
    if (!this.eventId || !this.siteId) {
      return
    }

    const sameSite = this.originalSite?.id === this.siteId
    const sameEvent = this.originalSite?.events.every((e) => e.id === this.eventId)
    const sameEventCycle = this.originalSite?.eventOrEventCycleId === this.eventId
    if (sameSite && (sameEvent || sameEventCycle)) {
      return
    }

    this.keyOptionsAsync('').subscribe((res) => {
      this.eventSiteHasKeys = !!res.totalResults
    })

    this.vistaService.getMapSite(this.siteId, this.eventId).subscribe((site) => {
      this.venueId = site.venueId
      this.originalSite = site
      this.site = this.mapSiteModelToSite(site, this.eventId)
      this.updateOverviewLayers()
      this.mapComponent.onLevelChange(this.levelId)
      this.markers = [...this.markers]
      this.identifySelectedFacility()
    })
  }

  private updateOverviewLayers(siteModel?: SiteDTO) {
    siteModel = siteModel ?? this.originalSite

    if (!siteModel) return

    let ovLayers: VistaMapLayer[] = []
    const overviewLayers = siteModel.overviewConfiguration?.layers ?? []
    if (this.monitorShellService.selectedSiteVisit?.includeOverview) {
      ovLayers = overviewLayers?.map((l) => this.mapLevelLayerDTOtoVistaMapLayer(l))
    }
    this.overviewLayers = ovLayers
  }

  private updateLayerTypes() {
    const types: VistaMapLayerType[] = []
    const { labels, panoramas, keys, dots, flows, zones, blueprints } = this.layerConfig

    if (labels) {
      types.push(VistaMapLayerType.LABELS)
    }
    if (panoramas) {
      types.push(VistaMapLayerType.PANORAMA)
    }
    if (keys) {
      types.push(VistaMapLayerType.KEYS)
    }
    if (dots) {
      types.push(VistaMapLayerType.ACCS_Dots)
    }
    if (flows) {
      types.push(VistaMapLayerType.ACCS_Flows)
    }
    if (zones) {
      types.push(VistaMapLayerType.ACCS_Zoning)
    }
    if (blueprints) {
      types.push(VistaMapLayerType.BLUEPRINT)
    }

    this.changeLayerTypes(types)
  }

  private changeLayerTypes(types: VistaMapLayerType[]) {
    this.mapLayerTypes = types
    if (!types.includes(VistaMapLayerType.KEYS)) {
      this.selectedFacility = null
      this.resumeIdentify = false
    }
    this.ignoreFacilityChange = true
  }

  private changeBaseMap(baseMap: VistaBaseMap) {
    if (this.map) {
      this.mapComponent.onBaseMapChange(baseMap)
    }
  }

  private setFacility(facilitySelect: FacilitySelect) {
    this.resumeIdentify = facilitySelect && this.layersActive
    this.selectedFacility = facilitySelect
    this.mapService.facilitySelected(this.selectedFacility)
    this.loadFacility()

    if (this.editMode) {
      return
    }

    if (this.question) {
      return
    }

    if (facilitySelect) {
      this.setIdentifyPanel(!!this.selectedFacility?.facility)
    } else {
      this.panelTemplate = null
      this.panelActive = null
    }
  }

  private setupListeners() {
    this.$facilitySelect
      .pipe(takeUntil(this.$ngUnsubscribe))
      .pipe(distinctUntilChanged((a, b) => this.selectedFacility && a?.facility?.id === b?.facility?.id))
      .subscribe(async (facilitySelect) => {
        const allowed = await this.editStateService.editGuard(EditEntity.FACILITY).toPromise()
        if (allowed) {
          this.setFacility(facilitySelect)
        } else {
          this.ignoreFacilityChange = true
          setTimeout(() => this.mapComponent.onSelectFeature(this.selectedFacility))
        }
      })

    this.mediaQueueSubscription = this.mediaQueue.uploadSubject.subscribe((medias) => {
      this.currentMediaQueue = medias
      this.updateMediaUploads(medias)
    })

    this.mediaQueue.uploadFinishedSubject.pipe(takeUntil(this.$ngUnsubscribe)).subscribe((value) => {
      this.onUploadFinished(value.id, value.success)
    })

    this.mapService.markersChange.pipe(takeUntil(this.$ngUnsubscribe)).subscribe((markers) => {
      if (!markers) {
        return
      }
      this.markers = [...markers]
    })
  }

  private onUploadFinished(id: string, success: boolean) {
    MediaField.updateMediaAfterUpload(id, success, this.medias)
    MediaField.updateMediaAfterUpload(id, success, this.documents)
  }

  private reset() {
    this.ngForm?.resetForm()
    this.changesSubscription?.unsubscribe()
    this.editCloseSubscription?.unsubscribe()
    this.editStateService.discard(EditEntity.FACILITY)
    this.facilityModel = null
    this.actionsLocked = false
    this.mediaChanged = false
    this.editMode = false
    this.medias = []
    this.documents = []
    this.mapOptions = {
      ...this.mapOptions,
      levelSwitchControl: true,
      identifyLayersFilter: null,
    }
    if (this.facilityPolygon) {
      this.facilityPolygon.setMap(null)
      google.maps.event.clearInstanceListeners(this.facilityPolygon.getPath())
      this.facilityPolygon = undefined
    }
    if (this.facilityPolyline) {
      this.facilityPolyline.setMap(null)
      google.maps.event.clearInstanceListeners(this.facilityPolyline.getPath())
      this.facilityPolyline = undefined
      google.maps.event.removeListener(this.mapClickEventListener)
    }
  }

  private updateMediaUploads(uploadingMedias: MediaToUpload[]) {
    this.medias.forEach((m) => {
      m.isLoading = !!uploadingMedias.find((um) => um.id === m.id)
    })
  }

  private mapMediaDTOToSVRMediaDTO(medias: MediaDTO[] = []) {
    return medias.map(
      (m) =>
        ({
          id: m.id,
          name: m.name,
          mimeType: m.mimeType,
          uploadDate: m.uploadDate,
          url: m.url,
        } as SVRMediaDTO)
    )
  }

  private setupMapEditor() {
    this.mapOptions = {
      ...this.mapOptions,
      levelSwitchControl: false,
      identifyLayersFilter: () => false,
    }

    const hasPath = this.facilityModel.polygon?.length

    if (hasPath) {
      this.setupFacilityPolygon(this.facilityModel.polygon)
      return
    } else {
      this.mapClickEventListener = this.mapComponent.map.addListener('click', (event) => {
        this.editStateService.edit(EditEntity.FACILITY)
        this.facilityPolyline.getPath().push(event.latLng)
      })

      this.facilityPolyline = new google.maps.Polyline({
        map: this.mapComponent.map,
        draggable: true,
        editable: true,
        strokeColor: '#123985',
        strokeWeight: 5,
        zIndex: 2,
      })
      this.facilityPolyline.addListener('click', (ev: google.maps.MouseEvent) => {
        const path = this.facilityPolyline.getPath().getArray()
        const firstLatLng = path[0]
        if (firstLatLng.equals(ev.latLng)) {
          this.onFacilityPolylineClose()
        }
      })
    }
  }

  private onFacilityPolylineClose() {
    const path = this.facilityPolyline
      .getPath()
      .getArray()
      .map((p) => p.toJSON())
    google.maps.event.clearInstanceListeners(path)

    this.facilityPolyline.setMap(null)
    google.maps.event.clearInstanceListeners(this.facilityPolyline.getPath())
    this.facilityPolyline = undefined

    google.maps.event.removeListener(this.mapClickEventListener)
    this.setupFacilityPolygon(path)
    this.mapComponent.map.setOptions({ draggableCursor: '' })
  }

  private setupFacilityPolygon(path: LatLng[]) {
    this.facilityPolygon = new google.maps.Polygon({
      map: this.mapComponent.map,
      draggable: true,
      editable: true,
      strokeColor: '#123985',
      strokeWeight: 5,
      strokeOpacity: 1,
      zIndex: 2,
      paths: path,
    })
    this.updateFacilityPolygon()

    google.maps.event.addListener(this.facilityPolygon.getPath(), 'insert_at', this.updateFacilityPolygon.bind(this))

    google.maps.event.addListener(this.facilityPolygon.getPath(), 'remove_at', this.updateFacilityPolygon.bind(this))

    google.maps.event.addListener(this.facilityPolygon.getPath(), 'set_at', this.updateFacilityPolygon.bind(this))
  }

  private updateFacilityPolygon() {
    this.editStateService.edit(EditEntity.FACILITY)
    const path = this.facilityPolygon.getPath().getArray()
    this.facilityModel.area = google.maps.geometry?.spherical.computeArea(path, 0) ?? 0
    this.facilityModel.perimeter = google.maps.geometry?.spherical.computeLength(path) ?? 0
  }

  private setIdentifyPanel(active: boolean = true) {
    this.panelTemplate = this.identifyTemplate
    this.panelActive = active
  }

  private setForm(form: NgForm) {
    if (!form) {
      this.changesSubscription?.unsubscribe()
      return
    }
    this.changesSubscription = this.ngForm.statusChanges
      .pipe(
        takeUntil(this.$ngUnsubscribe),
        map(() => this.ngForm.dirty),
        distinctUntilChanged(),
        filter((d) => d)
      )
      .subscribe(() => this.editStateService.edit(EditEntity.FACILITY))
  }

  // Little hack in order to have only the OV toggled and not the OV and the Level
  private hideLevelLayers() {
    const siteLevels = this.mapComponent.site?.levels ?? []
    this.mapComponent.site.levels = [{} as VistaMapSiteLevel]
    this.mapComponent.onLevelChange('')
    this.mapComponent.site.levels = siteLevels
  }

  private changeMapToActionPointMode(context: MapContext) {
    this.actionPointId = context.actionPointId

    if (!this.actionPointId) {
      this.setMapToRegularMode()
    } else {
      this.setMapToActionPointMode()
    }
  }

  private setMapToRegularMode() {
    this.updateMapOptions()
    if (this.previousOverviewToggled !== this.mapComponent.overviewToggled) this.mapComponent.onOverviewToggle()
    if (this.previousApModeLevelId !== this.mapComponent.levelId) this.mapComponent.onLevelChange(this.previousApModeLevelId)
  }

  private setMapToActionPointMode() {
    // We disable the facilities search in the map when an Action Point is open
    this.updateMapOptions()

    this.previousApModeLevelId = this.levelId
    this.previousOverviewToggled = this.overviewToggled
    if (this.overviewToggled) this.mapComponent.onOverviewToggle()
  }

  private updateMapOptions() {
    this.mapOptions = this.mapService.getMapOptions(this.isOnlyReader ?? false, this.allowEditMap ?? false)
  }

  private mapSiteModelToSite(siteModel: SiteDTO, eventId: string): VistaMapSite {
    const levels: VistaMapSiteLevel[] = []

    const siteLevels = siteModel?.configuration?.levels ?? []
    for (const level of siteLevels) {
      if (level.default && !this.levelId) {
        this.levelId = level.id
      }
      const newLevel = this.mapSiteLevelDTOtoVistaMapSiteLevel(level)
      levels.push(newLevel)
    }

    return {
      id: siteModel.id,
      eventId: eventId,
      siteType: siteModel.siteType.name,
      label: siteModel.name,
      lat: siteModel.latitude,
      lng: siteModel.longitude,
      bbox: siteModel.configuration.bbox,
      levels: levels,
    } as VistaMapSite
  }

  private mapSiteLevelDTOtoVistaMapSiteLevel(level: SiteLevelDTO): VistaMapSiteLevel {
    const dto = new VistaMapSiteLevel()

    dto.id = level.id
    dto.default = level.default
    dto.description = level.description
    dto.layers = level.layers?.map((l) => this.mapLevelLayerDTOtoVistaMapLayer(l)) ?? []

    return dto
  }

  private mapLevelLayerDTOtoVistaMapLayer(layer: LevelLayerDTO): VistaMapLayer {
    return {
      name: layer.name,
      type: (layer.type as unknown) as VistaMapLayerType,
    }
  }

  private hasKeyLayer(layers: VistaMapLayer[] = []): boolean {
    return layers?.some((l) => l.type === VistaMapLayerType.KEYS) ?? false
  }

  /**
   * Identifies the facility that was selected in the map's context
   */
  private identifySelectedFacility() {
    if (this.facilityId && this.siteId && this.eventId) {
      this.vistaService.getFacility(this.facilityId, this.siteId, this.eventId).subscribe((facilities) => {
        if (facilities.length) {
          this.highlightedFacility = new FacilitySelect(
            this.siteId,
            facilities[0].levelId,
            facilities[0].levelName,
            facilities[0].facilities[0],
            facilities[0].isOverview,
            facilities[0].layerType,
            facilities[0].layerName
          )
          this.mapComponent.onSelectFeature(this.highlightedFacility)
          this.setMapIdentify(true)
        }
      })
    } else if (!this.facilityId) {
      this.highlightedFacility = null
      this.mapComponent.onSelectFeature(null)
      this.setMapIdentify(true)
    }
  }
}
