import { Transform } from 'class-transformer'
import { IsNumber } from 'class-validator'

export function NumberParse() {
  return function (obj: Record<string, any>, propertyName: string) {
    IsNumber()(obj, propertyName)
    Transform(({ value }) => (typeof value === 'string' ? Number(value) : value))(obj, propertyName)
  }
}

export function BooleanParse() {
  return function (obj: Record<string, any>, propertyName: string) {
    Transform(({ value }) => (typeof value === 'boolean' ? value : value?.toLowerCase() === 'true'))(obj, propertyName)
  }
}

export function ArrayParse() {
  return function (obj: Record<string, any>, propertyName: string) {
    Transform(({ value }) => (!value || Array.isArray(value) ? value : [value]))(obj, propertyName)
  }
}
