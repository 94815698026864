export class ArrayUtils {
  public static distinctObjects<T>(array: T[], compare?: keyof T | ((a: T, b: T) => boolean)): T[] {
    return array?.filter((a, index) => {
      if (!compare) {
        return array.indexOf(a) === index
      }

      const foundIndex = array.findIndex(b => {
        if (typeof compare === 'function') {
          return compare(a, b)
        } else {
          return a[compare] === b[compare]
        }
      })

      return foundIndex === index
    })
  }

  public static distinct<T>(array: T[]): T[] {
    return Array.from(new Set(array))
  }

  public static sort<T>(array: T[], field: keyof T, order: 'asc' | 'desc' = 'asc'): T[] {
    return array?.sort((a, b) => {
      const aValue = a[field]
      const bValue = b[field]

      let result = 0
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        result = aValue?.toLowerCase() > bValue.toLowerCase() ? 1 : -1
      } else {
        result = aValue > bValue ? 1 : -1
      }

      return order === 'asc' ? result : result * -1
    })
  }

  public static flatten<T>(array: T[][]): T[] {
    return array?.reduce((acc, curr) => acc.concat(curr), [])
  }

  public static sum(array: number[]): number {
    return array?.reduce((acc, curr) => acc + curr, 0)
  }

  public static intersection<T>(arr1: T[], arr2: T[]) {
    return arr1?.filter(x => arr2.includes(x))
  }

  public static difference<T>(arr1: T[], arr2: T[], compare?: keyof T | ((a: T, b: T) => boolean)): T[] {
    if (typeof compare === 'function') {
      return arr1?.filter(x => !arr2.some(y => compare(x, y)))
    } else if (compare) {
      return arr1?.filter(x => !arr2.some(y => x[compare] === y[compare]))
    }
    return arr1?.filter(x => !arr2.includes(x))
  }

  public static symmetricDifference<T>(arr1: T[], arr2: T[]) {
    const diff1 = ArrayUtils.difference(arr1, arr2) || []
    const diff2 = ArrayUtils.difference(arr2, arr1) || []
    return diff1.concat(diff2)
  }

  public static isArrayEqual<T, U = T>(
    array1: T[] = [],
    array2: U[] = [],
    compareFn: (a: T, b: U) => boolean = (a: T, b: U) => JSON.stringify(a) === JSON.stringify(b),
    checkOrder: boolean = false
  ): boolean {
    array1 = array1 || []
    array2 = array2 || []

    if (array1.length !== array2.length) {
      return false
    }

    return array1.every((a, ai) => array2.some((b, bi) => compareFn(a, b) && (!checkOrder || ai === bi)))
  }

  public static chunk<T>(array: T[], size: number): T[][] {
    return array?.reduce((acc, curr, index) => {
      if (index % size === 0) {
        acc.push([curr])
      } else {
        acc[acc.length - 1].push(curr)
      }
      return acc
    }, [])
  }

  /**
   * Run the given async work in parallel with the given number of workers.
   *
   * @param numOfWorkers The number of workers to run in parallel.
   * @param workToDo The work to do. The work should be a function that returns a promise.
   * @param stopOnError If true, the work will stop if any of the workers throws an error.
   */
  public static async runAsyncJobsParallel(numOfWorkers: number, workToDo: Function[], stopOnError = false) {
    const iterator = workToDo.values()
    let done = false
    const promises = Array.from({ length: numOfWorkers }, async (_, __) => {
      for (const work of iterator) {
        if (done) {
          return
        }
        try {
          await work()
        } catch (err) {
          if (stopOnError) {
            done = true
          }
        }
      }
    })

    await Promise.all(promises)
  }
}
