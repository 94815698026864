import { IsEnum, IsNotEmpty } from 'class-validator'

export enum LogoName {
  EVENT_REPORTING = 'event-reporting.png',
  SITE_VISIT_REPORT = 'site-visit-report.png',
  VISTA_MANAGER = 'vista-manager.png',
}

export class LogoSearchDTO {
  @IsNotEmpty()
  @IsEnum(LogoName)
  name: LogoName
}
