<vista-map-standalone
  #mapComponent
  [options]="mapOptions"
  [sites]="site ? [site] : null"
  [markers]="markers"
  [layerTypes]="mapLayerTypes"
  [overviewLayers]="overviewLayers"
  [siteId]="siteId"
  [eventId]="eventId"
  [venueId]="venueId"
  [keyIds]="!eventId ? [] : keyIds"
  [projectIds]="[]"
  [overviewToggled]="overviewToggled"
  [highlightedLevelIds]="highlightedLevelIds"
  (centerChange)="onCenterChange($event)"
  (markerChange)="onMarkerChange($event)"
  (mapCreated)="onMapCreated($event)"
  (facilityChange)="onFacilityClick($event)"
  (levelIdChange)="onLevelChange($event)"
  (overviewToggledChange)="onOverviewToggle($event)"
>
  <div class="top-right-buttons">
    <div
      matTooltip="{{ 'features.monitor.map.addFacilityTooltip' | translate }}"
      *ngIf="shouldShowAddFacilityButton()"
      class="button"
      (click)="onAddFacilityClick()"
    >
      <i-feather class="icon" name="plus-circle"></i-feather>
    </div>
    <div
      matTooltip="{{ 'features.monitor.map.layersTooltip' | translate }}"
      (click)="onLayersClick()"
      [ngClass]="{ active: layersActive }"
      *ngIf="mapOptions.showLayersButton"
      class="button"
    >
      <i-feather class="icon" name="layers"></i-feather>
    </div>
    <div
      matTooltip="{{ 'features.monitor.map.fullscreenTooltip' | translate }}"
      [ngClass]="{ active: fullscreen }"
      *ngIf="mapOptions.showFullScreenButton"
      class="button"
      (click)="onFullscreenClick()"
    >
      <i-feather class="icon" name="maximize-2"></i-feather>
    </div>
  </div>
</vista-map-standalone>
<svr-map-side-panel *ngIf="panelActive" [template]="panelTemplate"></svr-map-side-panel>

<ng-template #identifyTemplate>
  <form class="identify-panel" #f="ngForm" autocomplete="off" (ngSubmit)="onSubmit()">
    <div class="panel-header">
      <span class="title" matTooltip="{{ selectedFacility.levelDescription }}" *ngIf="selectedFacility">
        {{ selectedFacility.levelDescription }}
      </span>
      <span class="title" *ngIf="!selectedFacility">{{ 'features.monitor.map.newFacility' | translate }}</span>
      <div class="actions">
        <button
          mat-icon-button
          color="primary"
          matTooltip="{{ 'shared.buttons.edit' | translate }}"
          class="action-button"
          (click)="editFacility()"
          *ngIf="!editMode && mapOptions.allowIdentifyEdit"
          [disabled]="actionsLocked"
        >
          <i-feather name="edit-2" class="icon"></i-feather>
        </button>
        <button
          mat-icon-button
          color="accent"
          matTooltip="{{ 'shared.buttons.delete' | translate }}"
          class="action-button"
          (click)="deleteFacility()"
          *ngIf="editMode"
          [disabled]="!selectedFacility || actionsLocked"
        >
          <i-feather name="trash" class="icon"></i-feather>
        </button>
        <button
          mat-icon-button
          color="primary"
          matTooltip="{{ 'shared.buttons.save' | translate }}"
          class="action-button"
          (click)="saveFacility()"
          *ngIf="editMode"
          [disabled]="!altered || actionsLocked"
        >
          <i-feather name="save" class="icon"></i-feather>
        </button>
        <button
          mat-icon-button
          color="primary"
          matTooltip="{{ 'shared.buttons.close' | translate }}"
          class="action-button"
          (click)="closeIdentify()"
          [disabled]="actionsLocked"
        >
          <i-feather name="x-circle" class="icon"></i-feather>
        </button>
      </div>
    </div>
    <div class="panel-body">
      <div class="top-details">
        <div>
          {{ 'features.monitor.map.perimeter' | translate: { perimeter: facilityPerimeter } }} |
          {{ 'features.monitor.map.area' | translate: { area: facilityArea } }}
        </div>
        <div class="chip-container">
          <div class="project-chip" *ngFor="let projectName of selectedFacilityProjects">{{ projectName }}</div>
          <div class="project-chip" *ngIf="numberOfOverflowProjects" matTooltip="{{ overflowFacilityProjects }}">
            +{{ numberOfOverflowProjects }}
          </div>
        </div>
      </div>
      <vista-select-search
        class="font-x-small"
        #keyDropdown
        label="{{ 'features.monitor.map.key' | translate }}"
        name="key"
        [ngModel]="facilityModel?.key ?? ''"
        (ngModelChange)="onFacilityModelKeyChange($event)"
        [dataAsync]="keyOptionsAsync"
        [required]="true"
        [disabled]="!editMode"
        [disableLocalFilter]="true"
      ></vista-select-search>
      <mat-form-field class="font-x-small">
        <mat-label>{{ 'features.monitor.map.notes' | translate }}</mat-label>
        <textarea
          [disabled]="!editMode"
          name="notes"
          matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          [ngModel]="facilityModel?.notes ?? ''"
          (ngModelChange)="facilityModel ? (facilityModel.notes = $event) : null"
        >
        </textarea>
      </mat-form-field>
      <svr-media-list-container
        class="font-x-small"
        name="documents"
        [(medias)]="documents"
        (mediasChange)="onMediasChanged()"
        [allowedMimeTypes]="documentsMimeTypes"
        [title]="'features.monitor.map.documents' | translate"
        [onChangeMediaName]="changeMediaName"
        [disabled]="!editMode"
      ></svr-media-list-container>
      <svr-media-list-container
        class="font-x-small"
        name="medias"
        [(medias)]="medias"
        (mediasChange)="onMediasChanged()"
        [allowedMimeTypes]="imagesMimeTypes"
        [onChangeMediaName]="changeMediaName"
        [title]="'features.monitor.map.media' | translate"
        [disabled]="!editMode"
      ></svr-media-list-container>
    </div>
  </form>
</ng-template>

<ng-template #layersTemplate>
  <div class="layers-side-panel">
    <div class="panel-header">
      <div class="title">{{ 'features.monitor.map.layersTitle' | translate }}</div>
      <div class="actions">
        <button
          mat-icon-button
          color="primary"
          matTooltip="{{ 'shared.buttons.close' | translate }}"
          class="action-button"
          (click)="closeLayers()"
        >
          <i-feather name="x-circle" class="icon"></i-feather>
        </button>
      </div>
    </div>
    <div class="layers-body">
      <div class="basemap-container">
        <img src="assets/images/basemap-dark.png" [ngClass]="{ active: isCurrentBasemap(1) }" (click)="onBaseMapChange(1)" />
        <img src="assets/images/basemap-light.png" [ngClass]="{ active: isCurrentBasemap(0) }" (click)="onBaseMapChange(0)" />
        <img src="assets/images/basemap-satellite.png" [ngClass]="{ active: isCurrentBasemap(2) }" (click)="onBaseMapChange(2)" />
      </div>
      <div class="layer-options">
        <div class="option-toggle">
          <mat-slide-toggle color="primary" [(ngModel)]="layerConfig.labels" (change)="onLayerConfigChange()">
            <div>{{ 'features.monitor.map.layerTypes.labels' | translate }}</div>
          </mat-slide-toggle>
        </div>
        <div class="option-toggle">
          <mat-slide-toggle color="primary" [(ngModel)]="layerConfig.dots" (change)="onLayerConfigChange()">
            <div>{{ 'features.monitor.map.layerTypes.dots' | translate }}</div>
          </mat-slide-toggle>
        </div>
        <div class="option-toggle">
          <mat-slide-toggle color="primary" [(ngModel)]="layerConfig.flows" (change)="onLayerConfigChange()">
            <div>{{ 'features.monitor.map.layerTypes.flows' | translate }}</div>
          </mat-slide-toggle>
        </div>
        <div class="option-toggle">
          <mat-slide-toggle color="primary" [(ngModel)]="layerConfig.zones" (change)="onLayerConfigChange()">
            <div>{{ 'features.monitor.map.layerTypes.zones' | translate }}</div>
          </mat-slide-toggle>
        </div>
        <!-- TODO: This is still not in the map component. should we add it? -->
        <!-- <div class="option-toggle">
          <mat-slide-toggle color="primary" [(ngModel)]="layerConfig.panoramas" (change)="onLayerConfigChange()"></mat-slide-toggle>
          <div>{{ 'features.monitor.map.layerTypes.panoramas' | translate }}</div>
        </div> -->
        <div class="option-toggle">
          <mat-slide-toggle color="primary" [(ngModel)]="layerConfig.keys" (change)="onLayerConfigChange()" [disabled]="editMode">
            <div>{{ 'features.monitor.map.layerTypes.keys' | translate }}</div>
          </mat-slide-toggle>
        </div>
        <div class="option-toggle">
          <mat-slide-toggle color="primary" [(ngModel)]="layerConfig.blueprints" (change)="onLayerConfigChange()">
            <div>{{ 'features.monitor.map.layerTypes.blueprints' | translate }}</div>
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
</ng-template>
