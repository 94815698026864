export abstract class BaseDTO {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  createdBy?: string
  updatedBy?: string
}

export abstract class VistaListDTO extends BaseDTO {
  name?: string
  integrationId?: string
}

export abstract class VistaDTO extends VistaListDTO {
  isFameIntegrated?: boolean
}

export interface VistaBulkSyncResultDTO {
  total: number
  created: number
  updated: number
  failedCreate: number
  failedUpdate: number
  skipped: number
}
