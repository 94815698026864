import { BooleanParse } from '@uefa-vista/contracts'
import { IsBoolean, IsOptional } from 'class-validator'
import { SVREventSearchDTO } from '../vista'

export class MonitorEventListDTO {
  id: string
  imageUrl_200x200?: string
  imageUrl?: string
  shortName?: string
  startDate?: Date
  endDate?: Date
  numberOfSiteVisits?: number
  numberOfHOHB?: number
  type: 'event' | 'event-cycle'
}

export class MonitorEventSearchDTO extends SVREventSearchDTO {
  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  withSiteVisits?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  mySiteVisits?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  completedSiteVisits?: boolean
}
