import { IsEnum } from 'class-validator'

export enum MobilePlatform {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export class MobileSearchDTO {
  @IsEnum(MobilePlatform)
  platform: MobilePlatform
}

export class MobileVersionDTO {
  version = 0
}
