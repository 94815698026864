<div class="vista-dialog-header">
  <h1 mat-dialog-title class="vista-dialog-title">{{ 'shared.actionPointsTable.statusDialog.title' | translate }}</h1>
  <button mat-icon-button mat-dialog-close color="primary">
    <i-feather name="x-circle"></i-feather>
  </button>
</div>
<div class="vista-dialog-content" mat-dialog-content>
  <p class="vista-dialog-content-description">{{ 'shared.actionPointsTable.statusDialog.description' | translate }}</p>
  <vista-select-search
    class="col-span-2"
    label="shared.actionPointsTable.statusDialog.newStatus"
    name="status"
    [(ngModel)]="status"
    [optionTemplate]="statusTemplate"
    [data]="statusOptions"
    [showSearch]="false"
    [required]="true"
  ></vista-select-search>
</div>
<div class="vista-dialog-actions" mat-dialog-actions>
  <button mat-raised-button color="primary" mat-dialog-close>
    {{ 'shared.buttons.cancel' | translate | uppercase }}
  </button>
  <button mat-raised-button color="accent" [mat-dialog-close]="status" cdkFocusInitial>
    {{ 'shared.buttons.save' | translate | uppercase }}
  </button>
</div>

<ng-template #statusTemplate let-label="label">
  <span>{{ 'shared.enums.actionPointStatus.' + label | translate }}</span>
</ng-template>
