export class ArrayUtils {
  public static isArrayEqual<T>(
    array1: T[] | undefined,
    array2: T[] | undefined,
    compareFn: (a: T, b: T) => boolean = (a: T, b: T) => a === b
  ) {
    array1 = array1 || []
    array2 = array2 || []

    if (array1.length !== array2.length) {
      return false
    }

    return array1.every(a => array2 && array2.some(b => compareFn(a, b)))
  }

  public static distinctObjects<T>(array: T[], compare?: keyof T | ((a: T, b: T) => boolean)): T[] {
    return array?.filter((a, index) => {
      if (!compare) {
        return array.indexOf(a) === index
      }

      const foundIndex = array.findIndex(b => {
        if (typeof compare === 'function') {
          return compare(a, b)
        } else {
          return a[compare] === b[compare]
        }
      })

      return foundIndex === index
    })
  }

  public static distinct<T>(array: T[]): T[] {
    return array?.filter((item, index) => array.indexOf(item) === index)
  }

  public static sort<T>(array: T[], field: keyof T, order: 'asc' | 'desc' = 'asc'): T[] {
    return array?.sort((a, b) => {
      const aValue = a[field]
      const bValue = b[field]

      let result = 0
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        result = aValue?.toLowerCase() > bValue.toLowerCase() ? 1 : -1
      } else {
        result = aValue > bValue ? 1 : -1
      }

      return order === 'asc' ? result : result * -1
    })
  }

  public static flatten<T>(array: T[][]): T[] {
    return array?.reduce((acc, curr) => acc.concat(curr), [])
  }

  public static group<T>(array: T[], groupSize = 1): T[][] {
    const result: T[][] = []

    for (let i = 0, j = array?.length; i < j; i += groupSize) {
      result.push(array.slice(i, i + groupSize))
    }

    return result
  }

  public static flatAndDistinct<T>(array: T[][]): T[] {
    const flat = this.flatten(array)
    return this.distinct(flat)
  }

  public static mapParamsToArray<T>(params: T | T[]): T[] {
    let array = (params as T[]) ?? []
    if (!Array.isArray(array)) {
      array = [array]
    }
    return array
  }

  public static sum(array: number[]): number {
    return array?.reduce((acc, curr) => acc + curr, 0)
  }

  public static intersection<T>(arr1: T[], arr2: T[]) {
    return arr1?.filter(x => arr2.includes(x))
  }

  public static difference<T>(arr1: T[], arr2: T[]) {
    return arr1?.filter(x => !arr2.includes(x))
  }

  public static symmetricDifference<T>(arr1: T[], arr2: T[]) {
    const diff1 = ArrayUtils.difference(arr1, arr2) || []
    const diff2 = ArrayUtils.difference(arr2, arr1) || []
    return diff1.concat(diff2)
  }
}
