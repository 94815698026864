import { VistaMapLayerType } from '@uefa-vista/map'

export abstract class BaseDTO {
  id: string
  name: string
}

export class KeyDTO extends BaseDTO {
  label: string
  keysetId: number
  projectIds: string[]
  vistaKeyId?: string
}

export class MediaDTO {
  id?: string
  mimeType?: string
  name: string
  url?: string
  uploadDate?: Date
}

export enum DotPlanScope {
  NMD = 'ACCS NMD',
  MD_1 = 'ACCS MD-1',
  MD = 'ACCS MD',
  Entrances = 'Entrances',
  ETRN_Parking_Codes = 'ETRN Parking Codes',
  ETRN_Load_Zones = 'ETRN Load Zones',
  TEAM = 'TEAM',
  ETRN_MD = 'ETRN MD',
  ETRN_MD_1 = 'ETRN MD-1',
  ETRN_NMD = 'ETRN NMD',
  SSNS_VSOP_MD = 'SSNS/VSOP MD',
  SSNS_VSOP_MD_1 = 'SSNS/VSOP MD-1',
  SSNS_VSOP_NMD = 'SSNS/VSOP NMD',
  PITCH = 'PITCH',
}

export enum FlowScope {
  Ingress = 'Ingress',
  Egress = 'Egress',
  NMD = 'NMD',
  TEAM = 'TEAM',
  PITCH = 'PITCH',
}

export enum ZoningScope {
  NMD = 'NMD Perimeters',
  MD = 'MD Perimeters',
  Zoning = 'ACCR/ACCS Zoning',
  TEAM = 'TEAM',
  PITCH = 'PITCH',
}

export type Scope = DotPlanScope | FlowScope | ZoningScope

class ProjectDTO {
  id: string
  name: string
  description: string
}

export interface LatLng {
  lat: number
  lng: number
}

export class Facility {
  id: string
  notes: string
  polygon: Array<LatLng>
  key: KeyDTO
  projects: Array<ProjectDTO>
  scope?: Scope
  area?: number
  perimeter?: number
  documents?: Array<MediaDTO>
  medias?: Array<MediaDTO>
  activityPeriods?: string[]
  supplier?: string
  length?: number
  symbology?: string
}

export class FacilitySelect {

  constructor(
    public siteId: string,
    public levelId: string,
    public levelLabel: string,
    public facility: Facility,
    public isOverview?: boolean,
    public layerType?: VistaMapLayerType,
    public layerName?: string,
    public fromSearch?: boolean,
  ) {}

  get levelDescription(): string {
    return `${this.facility?.key?.name} - ${this.facility?.key?.label}, ${this.isOverview ? `Overview` : `${this.levelLabel}`}`
  }

  get hasKey(): boolean { return true }
  get keyName(): string { return '' }
  get keyLabel(): string { return '' }
}


export type VistaBaseMap = 'google-roadmap' | 'google-hybrid' | 'uefa-light' | 'uefa-dark'
