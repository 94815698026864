import { PaginatedSearchDTO } from '@uefa-shared/contracts'
import { IsEnum, IsOptional } from 'class-validator'

export enum TaskStatus {
  READY = 'ready',
  RUNNING = 'running',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}

export class TaskListDTO {
  id: string
  name: string
  schedule: string
  lastSuccess?: Date
  lastRun?: Date
  status: TaskStatus
}

export enum TaskSearchSortField {
  NAME = 'name',
  LAST_SUCCESS = 'lastSuccess',
  LAST_RUN = 'lastRun',
  STATUS = 'status',
}

export class TaskSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsEnum(TaskSearchSortField)
  sortBy?: string
}
