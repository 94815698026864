import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PaginatedResults } from '@uefa-shared/contracts'
import { BaseService } from '@uefa-shared/frontend'
import { TaskListDTO, TaskSearchDTO } from '@uefa-svr/contracts'
import { Observable } from 'rxjs'
import { environment } from '../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class TasksService extends BaseService {
  constructor(http: HttpClient) {
    super(environment, 'task', http)
  }

  public getPaginated(options: TaskSearchDTO): Observable<PaginatedResults<TaskListDTO>> {
    return this.getPaginatedResult<TaskListDTO>(this.basePath, options)
  }

  public get(id: string): Observable<TaskListDTO> {
    return this.http.get<TaskListDTO>(`${this.basePath}/${id}`)
  }

  public run(id: string): Observable<TaskListDTO> {
    return this.http.put<TaskListDTO>(`${this.basePath}/${id}/run`, null)
  }
}
