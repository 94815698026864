import { LabelValueDTO, PaginatedSearchDTO } from '@uefa-shared/contracts'
import { ArrayParse, BooleanParse } from '@uefa-vista/contracts'
import { ArrayMinSize, IsArray, IsBoolean, IsNotEmpty, IsOptional, IsString, IsUUID } from 'class-validator'
import { SVRVistaListDTO } from './vista.dto'

export class SVREventListDTO extends SVRVistaListDTO {
  // eslint-disable @typescript-eslint/camelcase
  id?: string
  imageUrl_200x200?: string
  imageUrl?: string
  shortName?: string
  startDate?: Date
  endDate?: Date
}

export class EventLabelValueDTO extends LabelValueDTO {
  competitionId?: string
  competition?: LabelValueDTO
}

export class SVREventSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  competitionIds?: string[]

  @IsOptional()
  @IsString()
  role?: string

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  withSiteVisits?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  allEvents?: boolean

  @IsOptional()
  @BooleanParse()
  withActionPoints?: boolean
}

export class SVREventPrintTemplateSearchDTO extends PaginatedSearchDTO {
  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ArrayMinSize(1)
  @IsUUID('4', { each: true })
  eventIds?: string[]
}
