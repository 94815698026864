import { Location } from '@angular/common'
import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { UserGroupRole } from '@uefa-vista/contracts'

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private history: string[] = []

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    })
  }

  public back(base = '/'): void {
    this.history.pop()
    if (!this.history.length) {
      this.router.navigateByUrl(base)
      return
    }

    this.location.back()
  }

  static get menuNavigation() {
    return [
      {
        label: 'navigation.dashboard',
        icon: 'pie-chart',
        link: '/dashboard',
        allowed: [UserGroupRole.ADMINISTRATOR, UserGroupRole.MANAGER, UserGroupRole.EDITOR],
      },
      {
        label: 'navigation.monitor',
        icon: 'monitor',
        link: '/monitor',
        allowed: [UserGroupRole.ADMINISTRATOR, UserGroupRole.MANAGER, UserGroupRole.EDITOR, UserGroupRole.READER],
        withBreadCrumbs: true,
      },
      {
        label: 'navigation.incidents',
        icon: 'flag',
        link: '/action-points',
        allowed: [UserGroupRole.ADMINISTRATOR, UserGroupRole.MANAGER, UserGroupRole.EDITOR],
      },
      {
        label: 'navigation.surveyor',
        icon: 'smartphone',
        link: '/mobile',
        allowed: [UserGroupRole.ADMINISTRATOR, UserGroupRole.MANAGER, UserGroupRole.EDITOR, UserGroupRole.READER],
      },
      {
        divider: true,
        allowed: [UserGroupRole.ADMINISTRATOR, UserGroupRole.MANAGER],
      },
      {
        label: 'navigation.builder',
        icon: 'book-open',
        link: '/builder',
        allowed: [UserGroupRole.ADMINISTRATOR, UserGroupRole.MANAGER],
      },
      {
        label: 'navigation.sections',
        icon: 'server',
        link: '/sections',
        allowed: [UserGroupRole.ADMINISTRATOR, UserGroupRole.MANAGER],
      },
      {
        label: 'navigation.templates',
        icon: 'sliders',
        link: '/templates',
        allowed: [UserGroupRole.ADMINISTRATOR, UserGroupRole.MANAGER],
      },
      {
        label: 'navigation.questions',
        icon: 'help-circle',
        link: '/questions',
        allowed: [UserGroupRole.ADMINISTRATOR, UserGroupRole.MANAGER],
      },
      {
        label: 'navigation.tasks',
        icon: 'settings',
        link: '/tasks',
        allowed: [UserGroupRole.ADMINISTRATOR, UserGroupRole.MANAGER],
      },
    ]
  }
}
