import { ArrayNotEmpty, ArrayUnique, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsUUID } from 'class-validator'
import { StringCleanup, StringTrim } from '../decorators'
import { ProjectDTO, ProjectListDTO } from '../project/project.dto'
import { VistaDTO, VistaListDTO } from '../shared/vista.dto'

export enum KeyHatch {
  NONE = 'None',
  HATCH_0 = '0º',
  HATCH_45 = '45º',
  HATCH_90 = '90º',
  HATCH_135 = '135º',
}

export const keyHatchToDegrees = (hatch: KeyHatch): number => {
  switch (hatch) {
    case KeyHatch.HATCH_45:
      return 45
    case KeyHatch.HATCH_90:
      return 90
    case KeyHatch.HATCH_135:
      return 135
    default:
      return 0
  }
}

export class KeyDTO extends VistaDTO {
  label: string
  keysetId: number
  order?: number
  parentId?: string
  children?: KeyDTO[]
  color?: string
  hatch?: KeyHatch
  hatchColor?: string
  siteTypeId?: string
  projects?: ProjectDTO[]

  constructor() {
    super()
    this.label = null
    this.keysetId = null
    this.children = []
    this.color = null
    this.hatch = KeyHatch.NONE
    this.hatchColor = null
    this.order = null
    this.siteTypeId = null
    this.projects = []
  }
}

export class KeyEventAlias {
  constructor(public eventId: string, public alias: string) {}
}

export class KeyListDTO extends VistaListDTO {
  label?: string
  keysetId?: number
  order?: number
  parentId?: string
  children?: KeyListDTO[]
  color?: string
  hatch?: KeyHatch
  hatchColor?: string
  projects?: ProjectListDTO[]
  siteTypeId?: string
  siteTypeName?: string
  vistaKeyId?: string
  numberOfChildren?: number
  eventsAlias?: KeyEventAlias[]

  constructor() {
    super()
    this.label = null
    this.keysetId = null
    this.children = []
    this.color = null
    this.hatch = KeyHatch.NONE
    this.hatchColor = null
    this.order = null
    this.projects = []
    this.siteTypeId = null
    this.numberOfChildren = 0
  }
}

export class KeyCreateDTO {
  @IsUUID()
  @IsOptional()
  parentId?: string

  integrationId?: string

  @IsUUID()
  @IsNotEmpty()
  siteTypeId: string

  @IsNotEmpty()
  @StringTrim()
  @StringCleanup()
  name: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  label: string

  @IsNumber()
  order: number

  @IsNotEmpty()
  color: string

  @IsEnum(KeyHatch)
  @IsOptional()
  hatch?: KeyHatch

  @IsOptional()
  hatchColor?: string

  projects?: ProjectDTO[]

  constructor(key?: KeyDTO) {
    this.parentId = null
    this.siteTypeId = null
    this.name = ''
    this.label = ''
    this.color = '#ffffff'
    this.hatch = KeyHatch.NONE
    this.hatchColor = ''
    ;(this.order = 0), (this.projects = [])

    if (key) {
      this.parentId = key.parentId
      this.integrationId = key.integrationId
      this.siteTypeId = key.siteTypeId
      this.name = key.name
      this.label = key.label
      this.color = key.color
      this.hatch = key.hatch
      this.hatchColor = key.hatchColor
      this.order = key.order
      this.projects = key.projects
    }
  }
}

export class KeyUpdateParentDTO {
  @IsUUID()
  @IsOptional()
  parentId?: string
}

export class KeyUpdateOrderDTO {
  @ArrayNotEmpty()
  @ArrayUnique()
  keys: string[]
}
