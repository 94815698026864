import { IsNotEmpty, IsString, IsUUID } from 'class-validator'
import { BaseDTO } from '../shared'

export enum NotificationActionEnum {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export class NotificationActionDTO extends BaseDTO {
  id: string
  name: string
  description: string
  entityId: string
  entityName: string
}

export class NotificationActionListDTO extends NotificationActionDTO {}

export class NotificationActionCreateDTO {
  @IsNotEmpty()
  @IsString()
  name: string

  @IsNotEmpty()
  @IsString()
  description: string

  @IsNotEmpty()
  @IsUUID()
  entityId: string
}

export class NotificationActionUpdateDTO extends NotificationActionCreateDTO {}
