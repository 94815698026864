import { IsNotEmpty, IsOptional, IsString, IsUUID, Max, Min } from 'class-validator'
import { StringTrim } from '../decorators'

export enum EventPrintTemplateType {
  PORTAL = 'PORTAL',
  SVR = 'SVR',
  SITE_CATALOG_REPORT = 'SITE_CATALOG_REPORT',
  EVENT_REPORTING = 'EVENT_REPORTING',
}

export class EventPrintTemplateListDTO {
  id: string
  name: string
  siteTypeId: string
  siteTypeName: string
  printSiteName: boolean
  template: string
  type?: EventPrintTemplateType
  titlePositionTop?: number
  titlePositionLeft?: number
  breakSections?: boolean
  breakQuestions?: boolean
  sectionHeaderColor?: string // hex color
  marginLeft?: number
  marginRight?: number
  marginTop?: number
  marginBottom?: number
  headerMarginTop?: number
  footerMarginBottom?: number
}

export class EventPrintTemplateManageDTO {
  @IsUUID()
  @IsOptional()
  id: string

  @IsUUID()
  @IsNotEmpty()
  siteTypeId: string

  @StringTrim()
  @IsNotEmpty()
  name: string

  @StringTrim()
  @IsOptional()
  template: string

  @IsOptional()
  printSiteName: boolean

  @IsOptional()
  type: EventPrintTemplateType

  @IsOptional()
  @Min(0)
  @Max(297) // A4 height = 297
  titlePositionTop: number

  @IsOptional()
  @Min(0)
  @Max(210) // A4 width = 210
  titlePositionLeft: number

  @IsOptional()
  breakSections: boolean

  @IsOptional()
  breakQuestions: boolean

  @IsOptional()
  sectionHeaderColor: string // hex color

  @IsOptional()
  @Min(0)
  @Max(210) // A4 width = 210
  marginLeft: number

  @IsOptional()
  @Min(0)
  @Max(210) // A4 width = 210
  marginRight: number

  @IsOptional()
  @Min(0)
  @Max(210) // A4 height = 297
  marginTop: number

  @IsOptional()
  @Min(0)
  @Max(297) // A4 height = 297
  marginBottom: number

  @IsOptional()
  @Min(0)
  @Max(297) // A4 height = 297
  headerMarginTop: number

  @IsOptional()
  @Min(0)
  @Max(297) // A4 height = 297
  footerMarginBottom: number

  @IsOptional()
  @IsString()
  copyFromId?: string

  @IsOptional()
  @IsString()
  copyFromEventId?: string

  constructor(eventPrintTemplate?: EventPrintTemplateListDTO) {
    this.id = eventPrintTemplate?.id
    this.name = eventPrintTemplate?.name
    this.siteTypeId = eventPrintTemplate?.siteTypeId
    this.printSiteName = eventPrintTemplate?.printSiteName
    this.template = eventPrintTemplate?.template
    this.type = eventPrintTemplate?.type
    this.titlePositionTop = eventPrintTemplate?.titlePositionTop
    this.titlePositionLeft = eventPrintTemplate?.titlePositionLeft
    this.breakSections = eventPrintTemplate?.breakSections
    this.breakQuestions = eventPrintTemplate?.breakQuestions
    this.sectionHeaderColor = eventPrintTemplate?.sectionHeaderColor
    this.marginLeft = eventPrintTemplate?.marginLeft
    this.marginRight = eventPrintTemplate?.marginRight
    this.marginTop = eventPrintTemplate?.marginTop
    this.marginBottom = eventPrintTemplate?.marginBottom
    this.headerMarginTop = eventPrintTemplate?.headerMarginTop
    this.footerMarginBottom = eventPrintTemplate?.footerMarginBottom
  }
}
