import { ArrayParse, LabelValueDTO, PaginatedSearchDTO, StringTrim } from '@uefa-shared/contracts'
import { IsArray, IsBoolean, IsEnum, IsOptional, IsUUID, Length } from 'class-validator'

export class SectionListDTO {
  id: string
  name: string
  parentId: string
  numberOfQuestions: number
  numberOfChildren: number
  competitions: LabelValueDTO[]
  order: number
  allCompetitions: boolean
}

export class SectionDTO {
  id: string
  name: string
  parentId: string
  numberOfChildren: number
  numberOfQuestions: number
  competitions: LabelValueDTO[]
  allCompetitions: boolean
}

export enum SectionSearchSortField {
  NAME = 'name',
  ORDER = 'order',
}

export class SectionUpdateDTO {
  @IsOptional()
  @IsUUID()
  parentId?: string

  @Length(1)
  @StringTrim()
  name?: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  competitions?: string[]

  @IsOptional()
  @IsBoolean()
  allCompetitions?: boolean

  constructor(section?: SectionDTO) {
    this.parentId = section?.parentId
    this.name = section?.name
    this.competitions = section?.competitions?.map(c => c.value) ?? []
    this.allCompetitions = section?.allCompetitions
  }
}

export class SectionCreateDTO extends SectionUpdateDTO {}

export class SectionSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @ArrayParse()
  competitionsIds?: string[]

  @IsOptional()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @IsEnum(SectionSearchSortField)
  sortBy?: string

  @IsOptional()
  @StringTrim()
  name?: string

  @IsOptional()
  @IsUUID()
  parentId?: string

  @IsOptional()
  allLevels?: boolean
}

export class SectionChildrenSearchDTO {
  @IsOptional()
  @ArrayParse()
  competitionsIds?: string[]
}
export class SectionReorderDTO {
  @IsArray()
  @ArrayParse()
  orders: {
    id: string
    order: number
  }[]
}
