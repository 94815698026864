import { IsNotEmpty, IsNumber, IsOptional, IsUUID } from 'class-validator'
import { StringCleanup, StringTrim } from '../decorators'
import { EventCycleDTO } from '../event-cycle'
import { VistaDTO, VistaListDTO } from '../shared'
import { LevelLayerDTO, SiteDTO } from '../site/site.dto'

export class VenueDTO extends VistaDTO {
  latitude?: number
  longitude?: number
  region?: string
  country?: string
  province?: string
  timezone?: string
  defaultSiteId?: string
  events?: Array<VenueEventDTO>
  eventCycles?: Array<EventCycleDTO>
  sites?: Array<SiteDTO>
  acronym?: string
  imageUrl?: string
  alias?: string
  /* eslint-disable @typescript-eslint/camelcase */
  imageUrl_200x200?: string
  overviewLayers?: Array<LevelLayerDTO>
  blueprintLayerId: string
  blueprintLayerCreatedAt: Date
  blueprintLayerDisplayName: string

  constructor() {
    super()
    this.events = null
    this.sites = null
    this.latitude = null
    this.longitude = null
    this.region = null
    this.country = null
    this.province = null
    this.timezone = null
    this.acronym = null
    this.imageUrl = null
    this.imageUrl_200x200 = null
    this.overviewLayers = []
    this.alias = null
    this.blueprintLayerId = null
  }
}

export interface VenueEventDTO {
  id: string
  name: string
  shortName: string
  venueAlias: string
  defaultSiteId: string
}

export class VenueListDTO extends VistaListDTO {
  numberOfEvents?: number
  numberOfSites?: number
  latitude?: number
  longitude?: number
  region: string
  country: string
  defaultSiteId?: string
  imageUrl?: string
  // eslint-disable @typescript-eslint/camelcase
  imageUrl_200x200?: string
  events: VenueEventDTO[]
  blueprintLayerId?: string
  blueprintLayerDisplayName?: string
  blueprintLayerCreatedAt?: Date
  timezone: string

  constructor() {
    super()
    this.numberOfEvents = 0
    this.numberOfSites = 0
    this.latitude = null
    this.longitude = null
    this.region = null
    this.country = null
    this.imageUrl = null
    this.imageUrl_200x200 = null
  }
}

export class VenueUpdateDTO {
  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  name: string

  @StringTrim()
  @IsOptional()
  integrationId?: string

  @IsNotEmpty()
  @IsNumber()
  latitude: number

  @IsNotEmpty()
  @IsNumber()
  longitude: number

  region: string

  country: string

  province: string

  timezone: string

  @IsOptional()
  @IsUUID()
  defaultSiteId?: string

  constructor(venue?: VenueDTO) {
    this.latitude = null
    this.longitude = null
    this.region = ''
    this.country = ''
    this.province = ''
    this.timezone = ''
    this.defaultSiteId = null
    this.integrationId = ''

    if (venue) {
      this.name = venue.name
      this.integrationId = venue.isFameIntegrated ? venue.integrationId : ''
      this.latitude = venue.latitude
      this.longitude = venue.longitude
      this.region = venue.region
      this.country = venue.country
      this.province = venue.province
      this.timezone = venue.timezone
      this.defaultSiteId = venue.defaultSiteId
    }
  }
}

export class VenueCreateDTO extends VenueUpdateDTO {
  constructor(venue?: VenueDTO) {
    super(venue)
  }
}
