import { IsArray, IsBoolean, IsOptional } from 'class-validator'
import { ArrayParse, BooleanParse } from '../decorators'
import { PaginatedSearchDTO } from '../shared/paginated-search.dto'

export class EventSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  allEvents?: boolean = false

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  withSites?: boolean = false

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  withKeys?: boolean = false

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  withProjects?: boolean = false

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  withMatches?: boolean

  @IsOptional()
  @IsArray()
  @ArrayParse()
  competitionIntegrationIds?: Array<string>

  @IsOptional()
  @IsArray()
  @ArrayParse()
  competitionIds?: Array<string>

  @IsOptional()
  seasonId?: number

  @IsOptional()
  @IsArray()
  @ArrayParse()
  projectIds?: Array<string>

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  showPortal?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  showAM?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  showSVR?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  showERT?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  showMatchDayReport?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  showIncidents?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  showActivityPlan?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  includeCompetitionTeamType?: boolean

  @IsOptional()
  @IsArray()
  @ArrayParse()
  applications?: Array<string>

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  showInMobile?: boolean
}

export class EventFilterDTO {
  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  showPortal?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  showAM?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  showSVR?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  onlyDefaultSites?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  withSitesAndVenues?: boolean
}

export class EventRoleSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: Array<string>
}
