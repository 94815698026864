import { BooleanParse, LabelValueDTO } from '@uefa-shared/contracts'
import { IsBoolean, IsOptional } from 'class-validator'
import { SiteVisitType, WorkingVisitStatus } from '../shared/enums'
import { SiteVisitSearchDTO } from '../site-visit/site-visit.search.dto'
import { DescriptionLabelValueDTO } from '../working-visit-question'

export class MonitorSiteVisitSearchDTO extends SiteVisitSearchDTO {
  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  completed?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  mySiteVisits?: boolean
}

export class SiteVisitPermissionsDTO {
  isAdmin?: boolean
  isManager?: boolean
  isEditor?: boolean
  isReader?: boolean
  canEdit?: boolean

  public get isHighestRoleEditor() {
    return this.isEditor && !this.isAdmin && !this.isManager
  }
}

export class MonitorSiteVisitListDTO {
  id: string
  title: string
  status: WorkingVisitStatus
  numberOfTotalQuestions: number
  numberOfActiveQuestions: number
  numberOfTotalActionPoints: number
  numberOfActiveActionPoints: number
  progress: number
  activeWorkingVisitId: string
  activeWorkingVisitName: string
  events: LabelValueDTO[]
  eventCycleId: string
  defaultEventId?: string
  siteId: string
  siteName: string
  venueName: string
  type: SiteVisitType
  siteImageUrl_200x200?: string
  siteImageUrl?: string
  actionPointsAllowed?: boolean
  numberOfPendingMandatoryQuestions: number
  numberOfPendingOptionalQuestions: number
  permissions?: SiteVisitPermissionsDTO
  disabled?: boolean
}

export class MonitorSiteVisitDTO {
  id: string
  title: string
  activeWorkingVisitId: string
  visits: DescriptionLabelValueDTO[]
  eventIds: string[]
  eventCycleId: string
  cycleManageLayers?: boolean
  defaultEventId?: string
  siteId: string
  siteName: string
  siteTypeId: string
  siteTypeName: string
  venueId: string
  type: SiteVisitType
  includeOverview: boolean
  allowEditMap: boolean
  isReadyToSubmit: boolean
  actionPointsAllowed?: boolean
  numberOfPendingMandatoryQuestions: number
  numberOfPendingOptionalQuestions: number
  numberOfTotalActionPoints: number
  numberOfActiveActionPoints: number
  permissions?: SiteVisitPermissionsDTO
  disabled?: boolean
}

export class SubmittableProject {
  constructor(
    public projectId?: string,
    public projectName?: string,
    public projectDescription?: string,
    public numberPendingQuestions?: number,
    public canSubmit?: boolean,
    public alreadySubmited?: boolean
  ) {}
}
