import { IsArray, IsNotEmpty, IsOptional } from 'class-validator'
import { StringCleanup, StringTrim } from '../decorators'
import { EventDTO } from '../event/event.dto'
import { VistaDTO, VistaListDTO } from '../shared/vista.dto'

export class ProjectDTO extends VistaDTO {
  description?: string
  acronym?: string
  events?: EventDTO[]
  keys?: string[]

  constructor() {
    super()
    this.description = null
    this.acronym = null
    this.events = null
    this.keys = null
  }
}

export class ProjectListDTO extends VistaListDTO {
  description?: string
  numberOfEvents?: number

  constructor() {
    super()
    this.description = null
    this.numberOfEvents = 0
  }
}

export class ProjectUpdateDTO {
  @IsNotEmpty()
  @StringTrim()
  @StringCleanup()
  name?: string

  @IsNotEmpty()
  @StringTrim()
  description?: string

  @StringTrim()
  @IsOptional()
  integrationId?: string

  @IsArray()
  events?: Array<string>

  acronym?: string

  constructor(project?: ProjectDTO) {
    this.name = ''
    this.description = ''
    this.integrationId = ''
    this.acronym = ''
    this.events = []

    if (project) {
      this.name = project.name
      this.integrationId = project.isFameIntegrated ? project.integrationId : ''
      this.description = project.description
      this.acronym = project.acronym
      this.events = project.events?.map(event => event.id) || []
    }
  }
}

export class ProjectCreateDTO extends ProjectUpdateDTO {}
