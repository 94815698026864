import { IsArray, IsOptional } from 'class-validator'
import { ArrayParse, BooleanParse } from '../decorators'
import { ApplicationName } from './application.dto'

export class ApplicationSearchDTO {
  @IsOptional()
  @BooleanParse()
  inManagerEvents?: boolean

  @IsOptional()
  @BooleanParse()
  inApplicationMenu?: boolean

  @IsOptional()
  @IsArray()
  @ArrayParse()
  names?: ApplicationName[]

  @IsOptional()
  @BooleanParse()
  showInSuite?: boolean

  @IsOptional()
  @BooleanParse()
  showInMobile?: boolean
}
