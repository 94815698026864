import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BaseService } from '@uefa-shared/frontend'
import { ActionPointPriority, ActionPointStatus, QuestionStatus, SectionStatus, WorkingVisitStatus } from '@uefa-svr/contracts'
import { environment } from '../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class IconsService extends BaseService {
  constructor(http: HttpClient) {
    super(environment, 'icon', http)
  }

  public getWorkingVisitStatus(status: WorkingVisitStatus): string {
    return `${this.basePath}/working-visit/status?status=${status}`
  }

  public getQuestionStatus(status: QuestionStatus | SectionStatus, completed: boolean): string {
    return `${this.basePath}/question/status?status=${status}&completed=${completed}`
  }

  public getActionPointStatus(status: ActionPointStatus): string {
    return `${this.basePath}/action-point/status?status=${status}`
  }

  public getActionPointPriority(priority: ActionPointPriority): string {
    return `${this.basePath}/action-point/priority?priority=${priority}`
  }
}
